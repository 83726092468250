import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandate-rsvform',
  templateUrl: './mandate-rsvform.component.html',
  styleUrls: ['./mandate-rsvform.component.css']
})
export class MandateRsvformComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  svform = false;
  svFixed = false;
  rsvform = false;
  rsvFixed = true;
  rsvreFix = false;
  rsvDone = false;
  rsvFixedsubbtn = false;
  leadid = this.route.snapshot.params['id'];
  visitedpropertylists: any;
  cancelledpropertylists: any;
  suggestchecked: any;
  executeid: any;
  csid: any;
  status: any;
  selectedpropertylists: any;
  selectedlists: any;
  visitupdate: any;
  propid: any;
  activestagestatus: any;
  hideafterfixed = true;
  hidebeforefixed = false;
  finalnegoform = false;
  negofixed = false;
  leadclosedform = false;
  leadclosed = false;
  junkform = false;
  junk = false;
  followform = false;
  followup = false;
  followdownform = false;
  followupdown = false;
  buttonhiders = true;
  autoremarks: any;
  userid:any;
  username:any;
  propertyremarks: any;
  suggestedpropertiesname: any;
  selectedproperty_commaseperated: any;
  intrestbtn: boolean = false;
  filterLoader: boolean = true;
  visitstatusupdate = false;
  commonhide = true;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice) { }

    private isCountdownInitialized: boolean;

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');
    this.loadimportantapi();

    this._phpService
      .getassignedrm(this.leadid,this.userid)
      .subscribe(cust => {
        this.executeid = cust[0].executiveid;
         // Adding RSV Visit date time to RSV Submission Section
         $('#RSVvisiteddate').val(cust[0]['suggestedprop'][0].followupdate);
         $('#RSVvisitedtime').val(cust[0]['suggestedprop'][0].followuptime);
       // Adding RSV Visit date time to RSV Submission Section
      })

    this._phpService
      .getassignedcs(this.leadid)
      .subscribe(cust => {
        this.csid = cust[0].executiveid;
      })

      this._phpService
      .getactiveleadsstatus(this.leadid,this.userid)
      .subscribe(stagestatus => {
        this.filterLoader = false;
        this.activestagestatus = stagestatus['activeleadsstatus'];
        if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "1"){
          this.hideafterfixed = false;
          this.rsvFixed = false;
          this.hidebeforefixed = true;
          this.rsvreFix = true;
          $('#sectionselector').val('RSV');
        }else if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "2"){
          this.hideafterfixed = false;
          this.rsvFixed = false;
          this.hidebeforefixed = true;
          this.rsvreFix = true;
          $('#sectionselector').val('RSV');
        }else if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "0"){
          this.hideafterfixed = false;
          this.hidebeforefixed = false;
          this.rsvDone = true;
          this.rsvFixed = false;
          this.commonhide = false;
          $('#sectionselector').val('RSV');
        }else if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "1"){
          this.hideafterfixed = true;
          this.hidebeforefixed = false;
          this.rsvDone = false;
          this.rsvFixed = true;
          $('#sectionselector').val('RSV');
        }else{
          this.hideafterfixed = true;
        }
      });

    // this.suggestchecked = "";
    this.visitupdate = "";

    if( $('#sectionselector').val() == "SV" || $('#sectionselector').val() == "USV" || $('#sectionselector').val() == "Final Negotiation"){
      this.buttonhiders = false;
    }else{
      this.buttonhiders = true;
    }
  }

  ngAfterViewChecked(){
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      this.scriptfunctions();
    }
  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.visitedcalendardate').calendar({
      type: 'date',
      minDate: this.priorDatebefore,
      maxDate: this.date,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
  }

  loadimportantapi(){
    var param = {
      leadid: this.leadid,
      execid: this.userid,
      stage:  "RSV",
    }

    this._phpService
    .rsvselectproperties(param)
    .subscribe(selectsuggested => {
      if(selectsuggested['status'] == 'True'){
        this.selectedpropertylists = selectsuggested['selectedrsvlists'];
        this.selectedlists = selectsuggested;
        
        // this.suggestchecked = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
      }else{
        this.selectedpropertylists = selectsuggested['selectedrsvlists'];
        this.selectedlists = selectsuggested;
        this.visitstatusupdate = true;
        // this.suggestchecked = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
      }
      
    });

    this._phpService
    .getvisitedsuggestproperties(param)
    .subscribe(visitsuggested => {
      this.visitedpropertylists = visitsuggested['visitedlists'];
      this.suggestchecked = this.visitedpropertylists.map((item) => { return item.propid }).join(',');
      
    });

    this._phpService
    .getcancelledsuggestproperties(param)
    .subscribe(cancelsuggested => {
      this.cancelledpropertylists = cancelsuggested['cancelledlists'];
    });
  }
  mainpropid: any;
  onrsvFixed(){
    this.rsvFixed = true;
    this.rsvreFix = false;
    this.rsvDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val('RSV');
  }

  onrsvreFix(){
    this.rsvFixed = false;
    this.rsvreFix = true;
    this.rsvDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val('RSV');
  }
  mainpropertyid: any;
  onrsvDone(){
    this.rsvFixed = false;
    this.rsvreFix = false;
    this.rsvDone = true;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val('RSV');
    // Loading this API again only for fetching the walkin date & time and write to the html view hidden visited date and time input boxes after the usvform in true condition
    this._phpService
    .getassignedrm(this.leadid,this.userid)
    .subscribe(cust => {
      // Adding RSV Visit date time to RSV Submission Section
      $('#RSVvisiteddate').val(cust[0]['suggestedprop'][0].followupdate);
      $('#RSVvisitedtime').val(cust[0]['suggestedprop'][0].followuptime);
      // Adding RSV Visit date time to RSV Submission Section
    });
     // Loading this API again only for fetching the walkin date & time and write to the html view hidden visited date and time input boxes after the usvform in true condition
  }

  onfollowup(){
    this.followform = true;
    this.followup = true;
    this.junkform = false;
    this.junk = false;
    this.rsvFixed = false;
    this.rsvreFix = false;
    this.rsvDone = false;
    $('#sectionselector').val("");
  }
  
  onjunk(){
    this.junkform = true;
    this.junk = true;
    this.rsvFixed = false;
    this.rsvreFix = false;
    this.rsvDone = false;
    this.followform = false;
    this.followup = false;
  }

  followupdownbtn(){
    this.followdownform = true;
    this.followupdown = true;
    this.svform = false;
    this.svFixed = false;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
  }

  onsvFixed(){
    this.svform = true;
    this.svFixed = true;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onrsvFixedsubbtn(){
    this.rsvFixedsubbtn = true;
    this.rsvform = true;
    this.svform = false;
    this.svFixed = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onnegofixed(){
    this.finalnegoform = true;
    this.negofixed = true;
    this.rsvFixedsubbtn = false;
    this.rsvform = false;
    this.svform = false;
    this.svFixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onleadclosed(){
    this.leadclosedform = true;
    this.leadclosed = true;
    this.finalnegoform = false;
    this.negofixed = false;
    this.rsvFixedsubbtn = false;
    this.rsvform = false;
    this.svform = false;
    this.svFixed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  removeValue(list, value) {
    return list.replace(new RegExp(",?" + value + ",?"), function(match) {
        var first_comma = match.charAt(0) === ',',
            second_comma;
  
        if (first_comma &&
            (second_comma = match.charAt(match.length - 1) === ',')) {
          return ',';
        }
        return '';
      });
  };

  // Selecting the suggested properties for fix the RSV
  selectsuggested(i,id,propname) {
    if($('#rsvcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programmingnew']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " added the "+propname+" for RSV while fixing the meeting.";

      // var param = {
      //   leadid: this.leadid,
      //   suggestproperties: this.suggestchecked
      // }
      // this._phpService.addrsvselected(param).subscribe((success) => {
      //   this.status = success.status;
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "RSV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the RSV scheduled lists.";
      this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
            this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedrsvlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when remove the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when remove the property for the history storing
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
    
  }
  // Selecting the suggested properties for fix the RSV

  // Selecting the suggested properties for fix the SV again after finished RSV
  selectsuggestedsub(i,id,propname) {
    if($('#subrsvcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " added the "+propname+" for RSV while fixing the meeting.";

      // var param = {
      //   leadid: this.leadid,
      //   suggestproperties: id
      // }
      // this._phpService.addrsvselected(param).subscribe((success) => {
      //   this.status = success.status;
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "RSV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the RSV scheduled lists.";
      this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
            this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedrsvlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when remove the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when remove the property for the history storing
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
    
  }

  // Selecting the suggested properties for fix the SV again after finished RSV

  refixsuggested(i,id,propname) {
    if($('#suggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programmingrefix']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      // console.log(this.suggestchecked);
    //   var param = {
    //   leadid: this.leadid,
    //   suggestproperties: this.suggestchecked
    //   }
    //   this.autoremarks = " added the "+propname+" for RSV while refix the meeting.";
    // this._phpService.addrsvselected(param).subscribe((success) => {
    //   this.status = success.status;
    //   if(this.status == "True"){
    //     var param = {
    //       leadid: this.leadid,
    //       execid: this.userid,
    //       stage:  "RSV",
    //     }
    //       this._phpService
    //   .rsvselectproperties(param)
    //   .subscribe(selectsuggested => {
    //     this.selectedpropertylists = selectsuggested['selectedrsvlists'];
    //     this.selectedlists = selectsuggested;
    //   });
    //   }
    // }, (err) => {
    //   console.log("Failed to Update");
    // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "RSV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the RSV scheduled lists.";
      this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
            this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedrsvlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when remove the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when remove the property for the history storing
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
  }

  rsvfixing(){

    var nextdate = $('#rsvnextactiondate').val();
    var nexttime = $('#rsvnextactiontime').val();
    var textarearemarks = $('#rsvtextarearemarks').val();
    // var textarearemarks = "RSV Fixed";
    var dateformatchange = new Date(nextdate).toDateString();
    

    // USV DONE with RSV Fixing
    if( $('#sectionselector').val() == "USV"){
      
      
      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }

      if ($('#USVvisiteddate').val() == "") {
        $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisiteddate').removeAttr("style");
      }
  
      if ($('#USVvisitedtime').val() == "") {
        $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisitedtime').removeAttr("style");
      }
  
      // if ($('#usvfinalremarks').val() == "") {
      //   $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#usvfinalremarks').removeAttr("style");
      // }
  
      if ($('#rsvnextactiondate').val() == "") {
        $('#rsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiondate').removeAttr("style");
      }
      if ($('#rsvnextactiontime').val() == "") {
        $('#rsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#rsvtextarearemarks').val() == "") {
        $('#rsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#rsvtextarearemarks').removeAttr("style");
      }
      var visitedparam = {
        leadid: this.leadid,
        propid: this.suggestchecked,
        execid: this.userid,
        visitupdate: $('#visitupdate').val(),
        remarks: $('#propertyremarks').val(),
        stage: "USV",
      }

      this.filterLoader = true;
      var usvdate = $('#USVvisiteddate').val();
      var usvtime = $('#USVvisitedtime').val();
      // var usvfinalremarks = $('#usvfinalremarks').val();
      // var usvfinalremarks = "USV Done";
      var usvfinalremarks = $('#propertyremarks').val();

      var param = {
        leadid: this.leadid,
        nextdate: nextdate,
        nexttime: nexttime,
        suggestproperties: this.suggestchecked
      }

      this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          this._phpService.addrsvselected(param).subscribe((success) => {
            this.status = success.status;
            if(this.status == "True"){
              var param2 = {
                leadid: this.leadid,
                execid: this.userid,
                stage:  "RSV",
              }
              this._phpService.rsvselectproperties(param2).subscribe(selectsuggested => {
                this.selectedpropertylists = selectsuggested['selectedrsvlists'];
                // Joining the object values as comma seperated when remove the property for the history storing
                this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
                // Joining the object values as comma seperated when remove the property for the history storing
        
                this.autoremarks = " Changed the status to RSV after Successfully completed USV";
                var leadusvdoneparam = {
                  leadid: this.leadid,
                  closedate: usvdate,
                  closetime: usvtime,
                  leadstage: "USV",
                  stagestatus: '3',
                  textarearemarks: usvfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: this.suggestchecked
                }
                this._phpService.addleadhistory(leadusvdoneparam).subscribe((success)=> {
                  this.status = success.status;
                  if(this.status == "True"){
                    
                    this.autoremarks = " Scheduled the RSV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                    var leadrsvfixparam = {
                      leadid: this.leadid,
                      closedate: nextdate,
                      closetime: nexttime,
                      leadstage: "RSV",
                      stagestatus: '1',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      autoremarks: this.autoremarks,
                      property: this.suggestchecked
                    }
                    this._phpService.addleadhistory(leadrsvfixparam).subscribe((success)=> {
                      this.status = success.status;
                      if(this.status == "True"){
                        this.filterLoader = false;
                        $('#nextactiondate').val('');
                        $('#nextactiontime').val('');
                        $('#customer_phase4').val('');
                        $('#rsvtextarearemarks').val('');
                      swal({
                        title: 'RSV Fixed Succefully',
                        type: "success",
                        confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                                  this.router.navigate([currentUrl]);
                              });
                          }
                        });
                      }
                    },(err) => {
                      console.log("Failed to Update");
                    });
                  }
                },(err) => {
                  console.log("Failed to Update");
                });
        
              });
            }
           
    
          }, (err) => {
            console.log("Failed to Update");
          })
        }
      }, (err) => {
        console.log("Failed to Update");
      })

      

    }
    // USV DONE with RSV Fixing

    // DIRECT RSV Fixing - Wrong Condition - Need to Check Later
    else if($('#sectionselector').val() == "RSV"){
      
      if ($('#rsvnextactiondate').val() == "") {
        $('#rsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiondate').removeAttr("style");
      }
      if ($('#rsvnextactiontime').val() == "") {
        $('#rsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      // if ($('#rsvtextarearemarks').val() == "") {
      //   $('#rsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      //   return false;
      // }
      // else {
      //   $('#rsvtextarearemarks').removeAttr("style");
      // }
      this.filterLoader = true;
      var nextactiondate = $('#rsvnextactiondate').val();
      var nextactiontime = $('#rsvnextactiontime').val();

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }
      this._phpService.addrsvselected(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
          this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedrsvlists'];
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
    
            this.autoremarks = " Scheduled the RSV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadrsvfixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "RSV",
              stagestatus: '1',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this._phpService.addleadhistory(leadrsvfixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
                $('#nextactiondate').val('');
                $('#nextactiontime').val('');
                $('#customer_phase4').val('');
                $('#rsvtextarearemarks').val('');
              swal({
                title: 'RSV Fixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
          });
        }
        

      }, (err) => {
        console.log("Failed to Update");
      })

      
      
  
    }
    // DIRECT RSV Fixing - Wrong Condition - Need to Check Later

    // NEGOTIATION DONE with RSV Fixing

    else if( $('#sectionselector').val() == "Final Negotiation"){

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please Confirm Property Revisited or Not',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#negovisiteddate').val() == "") {
        $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisiteddate').removeAttr("style");
      }
  
      if ($('#negovisitedtime').val() == "") {
        $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisitedtime').removeAttr("style");
      }
  
      // if ($('#negofinalremarks').val() == "") {
      //   $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
      //   return false;
      // }
      // else {
      //   $('#negofinalremarks').removeAttr("style");
      // }

      if ($('#rsvnextactiondate').val() == "") {
        $('#rsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiondate').removeAttr("style");
      }
      if ($('#rsvnextactiontime').val() == "") {
        $('#rsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiontime').removeAttr("style");
      }
      if (this.suggestchecked == "") {
        swal({
          title: 'Property Not Selected',
          text: 'Please select atleast one property for the RSV',
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }
      else {
        
      }
      if ($('#rsvtextarearemarks').val() == "") {
        $('#rsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#rsvtextarearemarks').removeAttr("style");
      }

      var negovisitparam = {
        leadid: this.leadid,
        propid: this.suggestchecked,
        execid: this.userid,
        visitupdate: $('#visitupdate').val(),
        remarks: $('#propertyremarks').val(),
        stage: "Final Negotiation",
      }

      this.filterLoader = true;
      var visiteddate = $('#negovisiteddate').val();
      var visitedtime = $('#negovisitedtime').val();
      // var negofinalremarks = $('#negofinalremarks').val();
      var negofinalremarks = "Final Negotiation Finished";

      var nextactiondate = $('#rsvnextactiondate').val();
      var nextactiontime = $('#rsvnextactiontime').val();

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }
      
      this._phpService.addpropertyvisitupdate(negovisitparam).subscribe((success) => {
        this._phpService.addrsvselected(param).subscribe((success) => {
          this.status = success.status;
          if(this.status == "True"){
            var param = {
              leadid: this.leadid,
              execid: this.userid,
              stage:  "RSV",
            }
            this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectedrsvlists'];
              // Joining the object values as comma seperated when remove the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when remove the property for the history storing
      
              this.autoremarks = " Scheduled the RSV after Successfully completed Final negotiation";
              var leadnegodoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "Final Negotiation",
                stagestatus: '3',
                textarearemarks: negofinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: this.suggestchecked
              }
              this._phpService.addleadhistory(leadnegodoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  this.autoremarks = " Scheduled the RSV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadrsvfixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "RSV",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks,
                    property: this.suggestchecked
                  }
                  this._phpService.addleadhistory(leadrsvfixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      this.filterLoader = false;
                      $('#nextactiondate').val('');
                      $('#nextactiontime').val('');
                      $('#customer_phase4').val('');
                      $('#rsvtextarearemarks').val('');
                    swal({
                      title: 'RSV Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                                this.router.navigate([currentUrl]);
                            });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });
      
            });
          }
          
  
        }, (err) => {
          console.log("Failed to Update");
        })
      }, (err) => {
        console.log("Failed to Update");
      })
      
    } 

    else{
      if ($('#rsvnextactiondate').val() == "") {
        $('#rsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiondate').removeAttr("style");
      }
      if ($('#rsvnextactiontime').val() == "") {
        $('#rsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#rsvnextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#rsvtextarearemarks').val() == "") {
        $('#rsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#rsvtextarearemarks').removeAttr("style");
      }
      this.filterLoader = true;
      var nextactiondate = $('#rsvnextactiondate').val();
      var nextactiontime = $('#rsvnextactiontime').val();

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }
      this._phpService.addrsvselected(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
          this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedrsvlists'];
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
    
            this.autoremarks = " Scheduled the RSV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadrsvfixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "RSV",
              stagestatus: '1',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this._phpService.addleadhistory(leadrsvfixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
                $('#nextactiondate').val('');
                $('#nextactiontime').val('');
                $('#customer_phase4').val('');
                $('#rsvtextarearemarks').val('');
              swal({
                title: 'RSV Fixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
          });
        }
        

      }, (err) => {
        console.log("Failed to Update");
      })
    }
    

    // NEGOTIATION DONE with RSV Fixing

  }

  rsvrefixing(){
    // if(this.suggestchecked == ""){
    //   swal({
    //     title: 'Property Not Selected',
    //     text: 'Please select atleast one property for the Sitevisit',
    //     type: 'error',
    //     confirmButtonText: 'ok'
    //   })
    //   return false;
    // }else{
    // }

    if ($('#refixdate').val() == "") {
      $('#refixdate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixdate').removeAttr("style");
    }
    if ($('#refixtime').val() == "") {
      $('#refixtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixtime').removeAttr("style");
    }
    if ($('#refixtextarearemarks').val() == "") {
      $('#refixtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#refixtextarearemarks').removeAttr("style");
    }
    this.filterLoader = true;
    var nextdate = $('#refixdate').val();
    var nexttime = $('#refixtime').val();
    var textarearemarks = $('#refixtextarearemarks').val();
    var dateformatchange = new Date(nextdate).toDateString();

    var param = {
      leadid: this.leadid,
      nextdate: nextdate,
      nexttime: nexttime,
      suggestproperties: this.suggestchecked
    }
    this._phpService.addrsvselectedrefix(param).subscribe((success) => {
      this.status = success.status;
      if(this.status == "True"){
        var param = {
          leadid: this.leadid,
          execid: this.userid,
          stage:  "RSV",
        }
        this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedrsvlists'];
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
    
            this.autoremarks = " ReFixed the RSV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadrsvrefixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "RSV",
              stagestatus: '2',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this._phpService.addleadhistory(leadrsvrefixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
              swal({
                title: 'RSV Refixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })

  }

  rsvdonewithfixing(){
    if ($('#visitupdate').val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please select any actions',
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
      $('#visitupdate').removeAttr("style");
    }
    if ($('#propertyremarks').val() == "") {
      $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks').removeAttr("style");
    }
    if ($('#RSVvisiteddate').val() == "") {
      $('#RSVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#RSVvisiteddate').removeAttr("style");
    }

    if ($('#RSVvisitedtime').val() == "") {
      $('#RSVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#RSVvisitedtime').removeAttr("style");
    }

    // if ($('#rsvfinalremarks').val() == "") {
    //   $('#rsvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the RSV');
    //   return false;
    // }
    // else {
    //   $('#rsvfinalremarks').removeAttr("style");
    // }

    if ($('#subrsvnextactiondate').val() == "") {
      $('#subrsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subrsvnextactiondate').removeAttr("style");
    }
    if ($('#subrsvnextactiontime').val() == "") {
      $('#subrsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subrsvnextactiontime').removeAttr("style");
    }
    // if (this.suggestchecked == "") {
    //   swal({
    //     title: 'Property Not asasasas',
    //     text: 'Please select atleast one property for the RSV',
    //     type: 'error',
    //     confirmButtonText: 'ok'
    //   })
    //   return false;
    // }
    // else {
      
    // }
    if ($('#subrsvtextarearemarks').val() == "") {
      $('#subrsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#subrsvtextarearemarks').removeAttr("style");
    }

    var visitedparam = {
      leadid: this.leadid,
      propid: this.suggestchecked,
      execid: this.userid,
      visitupdate: $('#visitupdate').val(),
      remarks: $('#propertyremarks').val(),
      stage: $('#customer_phase4').val(),
    }

    this.filterLoader = true;
    var nextactiondate = $('#subrsvnextactiondate').val();
    var nextactiontime = $('#subrsvnextactiontime').val();
    var param = {
      leadid: this.leadid,
      nextdate: nextactiondate,
      nexttime: nextactiontime,
      suggestproperties: this.suggestchecked
    }

    this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True"){
        this._phpService.addrsvselected(param).subscribe((success) => {
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "RSV",
          }
          this._phpService.rsvselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedrsvlists'];
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
      
            var rsvvisiteddate = $('#RSVvisiteddate').val();
            var rsvvisitedtime = $('#RSVvisitedtime').val();
            // var rsvfinalremarks = $('#rsvfinalremarks').val();
            var rsvfinalremarks = "RSV Done"
            this.autoremarks = " Completed the RSV Successfully";
            var leadrsvdoneparam = {
              leadid: this.leadid,
              closedate: rsvvisiteddate,
              closetime: rsvvisitedtime,
              leadstage: "RSV",
              stagestatus: '3',
              textarearemarks: rsvfinalremarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this._phpService.addleadhistory(leadrsvdoneparam).subscribe((success)=> {
              this.status = success.status;
                if(this.status == "True"){
                  var nextdate = $('#subrsvnextactiondate').val();
            var nexttime = $('#subrsvnextactiontime').val();
            var textarearemarks = $('#subrsvtextarearemarks').val();
            this.autoremarks = " again scheduled the RSV";
            var dateformatchange = new Date(nextdate).toDateString();
      
            this.autoremarks = " Scheduled the RSV again for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadrsvfixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "RSV",
              stagestatus: '1',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this._phpService.addleadhistory(leadrsvfixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
                $('#nextactiondate').val('');
                $('#nextactiontime').val('');
                $('#customer_phase4').val('');
                $('#rsvtextarearemarks').val('');
              swal({
                title: 'RSV Fixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
                }
            },(err) => {
              console.log("Failed to Update");
            });
            
            
          });
        }, (err) => {
          console.log("Failed to Update");
        })
      }
    
  }, (err) => {
    console.log("Failed to Update");
  })

    

    
  }


  visitclick(propertyname)
  {
    // this.intrestbtn = true;
    // $('#visitupdate'+i).val("3");
    // $('#propertyremarks'+i).html('ReVisited '+propertyname+' and Not Intrested.');
    $('#visitupdate').val("1");
    $('.nextactionmaindiv').removeAttr('style');
    $('.visitupdatebtn').attr("style", "display:none;");
  }

  cancelclick(propertyname)
  {
    // this.intrestbtn = false;
    // $('#visitupdate'+i).val("5");
    // $('#propertyremarks'+i).html('Cancelled the Revisit for '+propertyname);
    $('#visitupdate').val("3");
    $('.visitupdatebtn').removeAttr('style');
    $('.nextactionmaindiv').attr("style", "display:none;");
    this.followdownform = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.leadclosedform = false;
  }

  // doLikeButton(i,propertyname) {
  //   $(".heart_icon"+i).removeClass('liked-shaked');
  //   $(".heart_icon"+i).toggleClass('liked');
  //   $(".heart_icon"+i).toggleClass('not-liked');
  //   $(".heart_icon"+i).toggleClass('fas');
  //   $(".heart_icon"+i).toggleClass('far');
  //   // $(".heartbreak_icon"+i).toggleClass('bi-heartbreak');
  //   // $(".heartbreak_icon"+i).toggleClass('bi-heartbreak-fill');
  //   $(".lkebtns"+i).toggleClass('likeactive');
  //   if($(".heart_icon"+i).hasClass("liked")) {
  //     $(".heart_icon"+i).addClass('liked-shaked');
  //     $('#propertyremarks'+i).html('Revisited '+propertyname+' and they are Intrested in this property.');
  //     $('#visitupdate'+i).val("1");
  //   }else{
  //     $('#propertyremarks'+i).html('Revisited '+propertyname+' and Not Intrested');
  //     $('#visitupdate'+i).val("3");
  //   } 
  // }

  updatepropertyvisit(propertyid,propertyname)
  {
    if($('#visitupdate').val() == "1"){
      this.visitupdate = "Visited";
    }else{
      this.visitupdate = "Not Visited";
    }
    this.propertyremarks = $('#propertyremarks').val();

    if ($('#visitupdate').val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please Confirm Property Revisited or Not',
        heightAuto: false,
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
      $('#visitupdate').removeAttr("style");
    }

    if ($('#propertyremarks').val() == "") {
      $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks').removeAttr("style");
    }
    this.filterLoader = true;
    var param = {
      leadid: this.leadid,
      propid: propertyid,
      execid: this.userid,
      visitupdate: $('#visitupdate').val(),
      remarks: $('#propertyremarks').val(),
      stage: "RSV",
    }
    this.autoremarks = " Marked the "+ propertyname +" as "+this.visitupdate+". Here is the property remarks - "+this.propertyremarks;
    this._phpService.addpropertyvisitupdate(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        this.filterLoader = false;
        swal({
          title: 'Data Updated Successfully',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            this.loadimportantapi();
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
  }

}
