import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-junkform',
  templateUrl: './junkform.component.html',
  styleUrls: ['./junkform.component.css']
})
export class JunkformComponent implements OnInit {
  junkcategories: any;
  suggestchecked: any;
  junkcatognames: any;
  leadid = this.route.snapshot.params['id'];
  executeid: any;
  csid: any;
  status: any;
  filterLoader: boolean = true;
  userid: any;
  autoremarks: any;
  selectedpropertylists:any;
  propertyid: any;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice) { }

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this._phpService
    .getassignedrm(this.leadid,this.userid)
    .subscribe(cust => {
      this.executeid = cust[0].executiveid;
      this.propertyid = cust[0]['suggestedprop'][0].propid;
    })

  this._phpService
    .getassignedcs(this.leadid)
    .subscribe(cust => {
      this.csid = cust[0].executiveid;
    })

    this._phpService
    .getjunksections()
    .subscribe(junkcategos => {
      this.filterLoader = false;
      this.junkcategories = junkcategos['JunkCategories'];
    });

    // this.suggestchecked = "";

  //   this._phpService
  // .getmandateselectedsuggestproperties(this.leadid,this.userid)
  // .subscribe(selectsuggested => {
  //   this.selectedpropertylists = selectsuggested['selectedlists'];
  //   this.propertyid = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
  // });
  }

  selectsuggested(id,name) {
    var checkid = $("input[name='programming']:checked").map(function () {
      return this.value;
    }).get().join(',');
    this.suggestchecked = checkid;
    this.junkcatognames = name;
  }

  junkmoving(){

    // USV DONE with JUNK Fixing

    // if( $('#customer_phase4').val() == "USV"){
    //   if ($('#USVvisiteddate').val() == "") {
    //     $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //     return false;
    //   }
    //   else {
    //     $('#USVvisiteddate').removeAttr("style");
    //   }
  
    //   if ($('#USVvisitedtime').val() == "") {
    //     $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //     return false;
    //   }
    //   else {
    //     $('#USVvisitedtime').removeAttr("style");
    //   }
  
    //   if ($('#usvfinalremarks').val() == "") {
    //     $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
    //     return false;
    //   }
    //   else {
    //     $('#usvfinalremarks').removeAttr("style");
    //   }

    //   if (this.suggestchecked == "") {
    //     swal({
    //       title: 'Select any JUNK Reason',
    //       text: 'Select any Reason for the JUNK',
    //       type: 'error',
    //       confirmButtonText: 'OK'
    //     })
    //     return false;
    //   }
    //   else {
        
    //   }
    //   if ($('#junkremarks').val() == "") {
    //     $('#junkremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for Move to Junk');
    //     return false;
    //   }
    //   else {
    //     $('#junkremarks').removeAttr("style");
    //   }

    //   var visiteddate = $('#USVvisiteddate').val();
    //   var visitedtime = $('#USVvisitedtime').val();
    //   var usvleadstage = "USV";
    //   var usvstagestatus = '3';
    //   var usvfinalremarks = $('#usvfinalremarks').val();

    //   var closedate = "";
    //   var closetime = "";
    //   var stagestatus = this.suggestchecked;
    //   var negoleadstage = "Move to Junk";
    //   var textarearemarks = $('#junkremarks').val();
    //   var leadid = this.leadid;
    //   var userid = localStorage.getItem('UserId');
    //   var assignid = this.executeid;
    //   var csid = this.csid;

    //   this._phpService.addleadhistory(leadid,visiteddate,visitedtime,usvleadstage,usvstagestatus,usvfinalremarks,userid,assignid,csid).subscribe((success)=> {
    //     this.status = success.status;
    //     if(this.status == "True"){
    //       this._phpService.addleadhistory(leadid,closedate,closetime,negoleadstage,stagestatus,textarearemarks,userid,assignid,csid).subscribe((success)=> {
    //         this.status = success.status;
    //         if(this.status == "True"){
    //         swal({
    //           title: 'Lead Moved Succefully',
    //           type: "success",
    //           confirmButtonText: 'OK!',
    //           }).then((result) => {
    //             if (result.value) {
    //               location.reload();
    //             }
    //           });
    //         }
    //       },(err) => {
    //         console.log("Failed to Update");
    //       });
    //     } 
    //   },(err) => {
    //     console.log("Failed to Update");
    //   });


    // }

    // USV DONE with JUNK Fixing

    // SV DONE with JUNK Fixing

    // else if( $('#customer_phase4').val() == "SV"){
    //   if ($('#SVvisiteddate').val() == "") {
    //     $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //     return false;
    //   }
    //   else {
    //     $('#SVvisiteddate').removeAttr("style");
    //   }
  
    //   if ($('#SVvisitedtime').val() == "") {
    //     $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //     return false;
    //   }
    //   else {
    //     $('#SVvisitedtime').removeAttr("style");
    //   }
  
    //   if ($('#svfinalremarks').val() == "") {
    //     $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
    //     return false;
    //   }
    //   else {
    //     $('#svfinalremarks').removeAttr("style");
    //   }

    //   if (this.suggestchecked == "") {
    //     swal({
    //       title: 'Select any JUNK Reason',
    //       text: 'Select any Reason for the JUNK',
    //       type: 'error',
    //       confirmButtonText: 'OK'
    //     })
    //     return false;
    //   }
    //   else {
        
    //   }
    //   if ($('#junkremarks').val() == "") {
    //     $('#junkremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for Move to Junk');
    //     return false;
    //   }
    //   else {
    //     $('#junkremarks').removeAttr("style");
    //   }

    //   var visiteddate = $('#SVvisiteddate').val();
    //   var visitedtime = $('#SVvisitedtime').val();
    //   var svleadstage = "SV";
    //   var svstagestatus = '3';
    //   var svfinalremarks = $('#svfinalremarks').val();

    //   var closedate = "";
    //   var closetime = "";
    //   var stagestatus = this.suggestchecked;
    //   var negoleadstage = "Move to Junk";
    //   var textarearemarks = $('#junkremarks').val();
    //   var leadid = this.leadid;
    //   var userid = localStorage.getItem('UserId');
    //   var assignid = this.executeid;
    //   var csid = this.csid;

    //   this._phpService.addleadhistory(leadid,visiteddate,visitedtime,svleadstage,svstagestatus,svfinalremarks,userid,assignid,csid).subscribe((success)=> {
    //     this.status = success.status;
    //     if(this.status == "True"){
    //       this._phpService.addleadhistory(leadid,closedate,closetime,negoleadstage,stagestatus,textarearemarks,userid,assignid,csid).subscribe((success)=> {
    //         this.status = success.status;
    //         if(this.status == "True"){
    //         swal({
    //           title: 'Lead Moved Succefully',
    //           type: "success",
    //           confirmButtonText: 'OK!',
    //           }).then((result) => {
    //             if (result.value) {
    //               location.reload();
    //             }
    //           });
    //         }
    //       },(err) => {
    //         console.log("Failed to Update");
    //       });
    //     }
    //   },(err) => {
    //     console.log("Failed to Update");
    //   });
    // }

    // SV DONE with JUNK Fixing

    // DIRECT JUNK FIXING

    // else{
      if (this.suggestchecked == "") {
        swal({
          title: 'Select any JUNK Reason',
          text: 'Select any Reason for the JUNK',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        
      }
      if ($('#junkremarks').val() == "") {
        $('#junkremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for Move to Junk');
        return false;
      }
      else {
        $('#junkremarks').removeAttr("style");
      }
      
      this.filterLoader = true;
      var textarearemarks = $('#junkremarks').val();
      var userid = localStorage.getItem('UserId');

      this.autoremarks = " Moved the lead to Junk, Because of "+this.junkcatognames;
      var leadjunkparam = {
        leadid: this.leadid,
        closedate: "",
        closetime: "",
        leadstage: "Move to Junk",
        stagestatus: this.suggestchecked,
        textarearemarks: textarearemarks,
        userid: userid,
        assignid: this.executeid,
        autoremarks: this.autoremarks,
        property: this.propertyid
      }
  
  
      this._phpService.addleadhistory(leadjunkparam).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
          this.filterLoader = false;
        swal({
          title: 'Lead Moved Succefully',
          type: "success",
          confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.router.navigateByUrl('/rmleadassign?todayscheduled=1&from=&to=');
            }
          });
        }
      },(err) => {
        console.log("Failed to Update");
      });
    // }

    // DIRECT JUNK FIXING

  }

}
