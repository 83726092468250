import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-rmtldashboard',
  templateUrl: './rmtldashboard.component.html',
  styleUrls: ['./rmtldashboard.component.css']
})
export class RMTLDashboardComponent implements OnInit {

  RMTLview:boolean;
  

  constructor(private router: Router,private _phpService:phpservice) {
    this.RMTLview = false;
   }

  ngOnInit() {
    $('.ui.dropdown').dropdown();
    this.getcounts();
    this.getteam();
    this.getwishers();
    this.getquickview();
    this.getmeetings();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if(localStorage.getItem('Role') == '50001')
            {
              this.RMTLview = true;
            }
  }
  teamleader: any;
  leaderid: any;
  counts:any;
  Assigncounts:any;
  activecounts:any;
  inactivecounts:any;
  junkcounts:any;
  followcounts:any;
  face2facecounts:any;
  usvcounts:any;
  svcounts:any;
  rsvcounts:any;
  negocounts:any;
  closedcounts:any;
  teams: any;
  wishername: any;
  executives: any;
  clients: any;

  getwishers(){
    this._phpService
    .getwish()
    .subscribe(wish => {
      this.wishername = wish[0];
    })
  }

  getteam(){
    this.teamleader = localStorage.getItem('Name');
    var id = localStorage.getItem('UserId');
    this.leaderid = localStorage.getItem('UserId');
    this._phpService.getmyteam(id).subscribe(team => {this.teams = team;})
  }

  onSelect(id){
    this._phpService
    .gettldashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
    // this._phpService.getmeetings(id).subscribe(meet => {
    //   this.clients = meet;
    // })
    
  }
  
  getcounts(){
    var id = localStorage.getItem('UserId');
    this._phpService
    .gettldashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
  }

  getquickview()
  {
    var id = localStorage.getItem('UserId');
    this._phpService.gettlquickview(id).subscribe(quick => {
      this.executives = quick;
    })
  }

  getmeetings()
  {
    var id = localStorage.getItem('UserId');
    // this._phpService.getmeetings(id).subscribe(meet => {
    //   this.clients = meet;
    // })
  }

}

@Component({
  selector: 'app-rmexecdashboard',
  templateUrl: './rmtldashboard.component.html',
  styleUrls: ['./rmtldashboard.component.css']
})

export class RMExecDashboardComponent{

  RMEXview:boolean;

  counts:any;
  Assigncounts:any;
  activecounts:any;
  inactivecounts:any;
  junkcounts:any;
  followcounts:any;
  face2facecounts:any;
  usvcounts:any;
  svcounts:any;
  rsvcounts:any;
  negocounts:any;
  closedcounts:any;
  clients:any;
  wishername:any;

  constructor(private router: Router,private _phpService:phpservice) {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
    }, 1000);
  }

  ngOnInit() {
    this.getcounts();
    // this.getmeetings();
    this.getwishers();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if(localStorage.getItem('Role') == '50002')
            {
              this.RMEXview = true;
            }
  }

  getwishers(){
    this._phpService
    .getwish()
    .subscribe(wish => {
      this.wishername = wish[0];
    })
  }

  getcounts(){
    var id = localStorage.getItem('UserId');
    this._phpService
    .gettldashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
  }

  // getmeetings()
  // {
  //   var id = localStorage.getItem('UserId');
  //   this._phpService.getmeetings(id).subscribe(meet => {
  //     this.clients = meet;
  //   })
  // }

}
