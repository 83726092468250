import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-svform',
  templateUrl: './svform.component.html',
  styleUrls: ['./svform.component.css']
})
export class SvformComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  svFixed = true;
  svreFix = false;
  svDone = false;
  hideafterfixed = true;
  hidebeforefixed = false;
  leadid = this.route.snapshot.params['id'];
  activestagestatus: any;
  othersvisitedlists: any;
  visitedpropertylists: any;
  properties: any;
  suggestedpropertylists: any;
  nonsuggestedpropertylists: any;
  suggestedproperties: any;
  status: any;
  suggestchecked: any;
  executeid: any;
  csid: any;
  selectedpropertylists: any;
  selectedproperty_commaseperated: any;
  selectedlists: any;
  cancelledpropertylists: any;
  svFixedsubbtn = false;
  svform =  false;
  rsvform = false;
  rsvFixed = false;
  finalnegoform = false;
  negofixed = false;
  leadclosedform = false;
  leadclosed = false;
  junkform = false;
  junk = false;
  followform = false;
  followup = false;
  followdownform = false;
  followupdown = false;
  buttonhiders = true;
  autoremarks: any;
  userid:any;
  username:any;
  visitupdate: any;
  propertyremarks: any;
  suggestedpropertiesname: any;
  filterLoader: boolean = true;
  intrestbtn: boolean = false;

  showDiv = {
    previous : true,
  }

  afterselectvisitdate = false;
  beforeselectvisitdate = true;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice) { }

    private isCountdownInitialized: boolean;

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');
    this.loadimportantapi();

    var param = {
      leadid: this.leadid,
      execid: this.userid
    }

    this._phpService
      .propertylist(param)
      .subscribe(propertylist => {
        this.properties = propertylist;
      });

      

    this._phpService
      .getvisitpropertyothers(this.leadid,this.userid)
      .subscribe(visitedwithothers => {
        this.othersvisitedlists = visitedwithothers['visitedothers'];
      });

    this._phpService
      .getassignedrm(this.leadid,this.userid)
      .subscribe(cust => {
        this.executeid = cust[0].executiveid;
      })

    this._phpService
      .getassignedcs(this.leadid)
      .subscribe(cust => {
        this.csid = cust[0].executiveid;
      })

    this._phpService
      .getactiveleadsstatus(this.leadid,this.userid)
      .subscribe(stagestatus => {
        this.filterLoader = false;
        this.activestagestatus = stagestatus['activeleadsstatus'];
        if(this.activestagestatus[0].stage == "SV" && this.activestagestatus[0].stagestatus == "1"){
          this.hideafterfixed = false;
          this.svFixed = false;
          this.hidebeforefixed = true;
          this.svreFix = true;
        }else  if(this.activestagestatus[0].stage == "SV" && this.activestagestatus[0].stagestatus == "2"){
          this.hideafterfixed = false;
          this.svFixed = false;
          this.hidebeforefixed = true;
          this.svreFix = true;
        }else if(this.activestagestatus[0].stage == "SV" && this.activestagestatus[0].stagestatus == "3"){
          this.hideafterfixed = true;
          this.hidebeforefixed = false;
          this.svDone = false;
          this.svFixed = true;
        }else{
          this.hideafterfixed = true;
          this.svFixed = true;
        }
      });
      this.suggestchecked = "";
      if( $('#sectionselector').val() == "USV" || $('#sectionselector').val() == "RSV" || $('#sectionselector').val() == "Final Negotiation"){
        this.buttonhiders = false;
      }else{
        this.buttonhiders = true;
      }
  }

  ngAfterViewChecked(){
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      this.scriptfunctions();
    }
  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.visitedcalendardate').calendar({
      type: 'date',
      minDate: this.priorDatebefore,
      maxDate: this.date,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
  }

  datefocusOut(){
    if ($('#SVvisiteddate').val() == ""){
      $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }else if($('#SVvisitedtime').val() == ""){
      $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisiteddate').removeAttr("style");
      $('#SVvisitedtime').removeAttr("style");
      this.afterselectvisitdate = true;
      this.beforeselectvisitdate = false;
    }
  }

  timefocusOut(){
    if ($('#SVvisiteddate').val() == ""){
      $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }else if($('#SVvisitedtime').val() == ""){
      $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisiteddate').removeAttr("style");
      $('#SVvisitedtime').removeAttr("style");
      this.afterselectvisitdate = true;
      this.beforeselectvisitdate = false;
    }
  }

  loadimportantapi(){
    var param = {
      leadid: this.leadid,
      assignid: this.userid,
      stage:  "SV",
    }
    this._phpService
    .svselectproperties(param)
    .subscribe(selectsuggested => {
      this.selectedpropertylists = selectsuggested['selectedsvlists'];
      this.selectedlists = selectsuggested;
      this.suggestchecked = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
    });

    this._phpService
      .getsuggestedproperties(param)
      .subscribe(suggested => {
        this.suggestedpropertylists = suggested['suggestedlists'];
      });

      this._phpService
      .getnonselectedproperties(this.leadid)
      .subscribe(suggested => {
        this.nonsuggestedpropertylists = suggested['nonselectedlists'];
      });

    this._phpService
    .getvisitedsuggestproperties(param)
    .subscribe(visitsuggested => {
      this.visitedpropertylists = visitsuggested['visitedlists'];
    });

    this._phpService
    .getcancelledsuggestproperties(param)
    .subscribe(cancelsuggested => {
      this.cancelledpropertylists = cancelsuggested['cancelledlists'];
    });

    var params = {
      leadid: this.leadid,
      execid: this.userid
    }

  this._phpService
    .propertylist(params)
    .subscribe(propertylist => {
      this.properties = propertylist;
    });

  }

  onsvFixed(){
    this.svFixed = true;
    this.svreFix = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onsvreFix(){
    this.svreFix = true;
    this.svFixed = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onsvDone(){
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = true;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val('SV');
  }

  onfollowup()
  {
    this.followform = true;
    this.followup = true;
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    $('#sectionselector').val("");
  }

  onjunk(){
    this.junkform = true;
    this.junk = true;
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = false;
    this.followform = false;
    this.followup = false;
  }

  followupdownbtn(){
    // if ($('#SVvisiteddate').val() == "") {
    //   $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //   return false;
    // }
    // else {
    //   $('#SVvisiteddate').removeAttr("style");
    // }

    // if ($('#SVvisitedtime').val() == "") {
    //   $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
    //   return false;
    // }
    // else {
    //   $('#SVvisitedtime').removeAttr("style");
    // }
    // if(this.selectedlists.status == "True"){
    //   swal({
    //     title: 'Action Not Took',
    //     text: 'Please Confirm Site Visited or Not',
    //     heightAuto: false,
    //     type: 'error',
    //     confirmButtonText: 'OK'
    //   })
    //   return false;
    // }else{
    // }
    // if ($('#svfinalremarks').val() == "") {
    //   $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
    //   return false;
    // }
    // else {
    //   $('#svfinalremarks').removeAttr("style");
    // }
    this.showDiv.previous = !this.showDiv.previous
    this.followdownform = true;
    this.followupdown = true;
    // this.followupdown = !this.followupdown;
    this.svFixedsubbtn = false;
    this.svform = false;
    this.rsvform = false;
    this.rsvFixed = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
  }

  onsvFixsubbtn(){
    this.svFixedsubbtn = true;
    this.svform = true;
    this.rsvform = false;
    this.rsvFixed = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onrsvFixed(){
    this.rsvform = true;
    this.rsvFixed = true;
    this.svFixedsubbtn = false;
    this.svform = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.junkform = false;
    this.junk = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onnegofixed(){
    this.finalnegoform = true;
    this.negofixed = true;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svform = false;
    this.svFixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.junkform = false;
    this.junk = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onleadclosed(){
    this.leadclosedform = true;
    this.leadclosed = true;
    this.finalnegoform = false;
    this.negofixed = false;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svform = false;
    this.svFixed = false;
    this.junkform = false;
    this.junk = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  removeValue(list, value) {
    return list.replace(new RegExp(",?" + value + ",?"), function(match) {
        var first_comma = match.charAt(0) === ',',
            second_comma;
  
        if (first_comma &&
            (second_comma = match.charAt(match.length - 1) === ',')) {
          return ',';
        }
        return '';
      });
  };

// Selecting the suggested properties for fix the SV
  selectsuggested(i,id,propname) {
    if($('#svcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " added the "+propname+" for SV while fixing the meeting.";

      // var param = {
      //   leadid: this.leadid,
      //   suggestproperties: this.suggestchecked
      // }
      // this._phpService.addsvselectedproperties(param).subscribe((success) => {
      //   this.status = success.status;
      //   this._phpService.svselectproperties(this.leadid).subscribe(selectsuggested => {
      //     this.selectedpropertylists = selectsuggested['selectedsvlists'];
      //     this.selectedlists = selectsuggested;
      //     // Joining the object values as comma seperated when add the property for the history storing
      //     this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
      //     // Joining the object values as comma seperated when add the property for the history storing
      //   });
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
        var param2 = {
          leadid: this.leadid,
          suggestproperties: id,
          stage: "SV",
          execid: this.userid,
          }
          this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the SV scheduled lists.";
        this._phpService.removeselectedproperties(param2).subscribe((success) => {
          this.status = success.status;
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "SV",
          }
          this._phpService.svselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
          });
        }, (err) => {
          console.log("Failed to Update");
        })
    }

  }
// Selecting the suggested properties for fix the SV


// Selecting the suggested properties for fix the SV again after finished SV

selectsuggestedsub(i,id,propname) {
  if($('#subsvcheckbox'+i).is(':checked')){
    var checkid = $("input[name='programming']:checked").map(function () {
      return this.value;
    }).get().join(',');
    this.suggestchecked = checkid;
    this.autoremarks = " added the "+propname+" for SV while fixing the meeting.";

  }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "SV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the SV scheduled lists.";
        this._phpService.removeselectedproperties(param2).subscribe((success) => {
          this.status = success.status;
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "SV",
          }
          this._phpService.svselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
          });
        }, (err) => {
          console.log("Failed to Update");
        })

  }

}

// Selecting the suggested properties for fix the SV again after finished SV

  refixsuggested(i,id,propname) {
    if($('#suggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;

    //   var param = {
    //   leadid: this.leadid,
    //   suggestproperties: this.suggestchecked
    //   }
    //   this.autoremarks = " added the "+propname+" for SV while refixing the meeting.";
    // this._phpService.addsvselectedproperties(param).subscribe((success) => {
    //   this.status = success.status;
    //   if(this.status == "True"){
    //     var param = {
    //       leadid: this.leadid,
    //       execid: this.userid,
    //       stage:  "SV",
    //     }
    //       this._phpService
    //   .svselectproperties(param)
    //   .subscribe(selectsuggested => {
    //     this.selectedpropertylists = selectsuggested['selectedsvlists'];
    //     this.selectedlists = selectsuggested;
    //   });
    //   }
    // }, (err) => {
    //   console.log("Failed to Update");
    // })

    }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "SV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the SV scheduled lists.";
        this._phpService.removeselectedproperties(param2).subscribe((success) => {
          this.status = success.status;
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "SV",
          }
          this._phpService.svselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
          });
        }, (err) => {
          console.log("Failed to Update");
        })

    }
  }

  moresuggested(i,id,propname) {

    if ($('#SVvisiteddate').val() == "") {
      $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisiteddate').removeAttr("style");
    }

    if ($('#SVvisitedtime').val() == "") {
      $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisitedtime').removeAttr("style");
    }
    var visiteddate = $('#SVvisiteddate').val();
    var visitedtime = $('#SVvisitedtime').val();

    if($('#moresuggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      var param = {
      leadid: this.leadid,
      nextdate: visiteddate,
      nexttime: visitedtime,
      suggestproperties: this.suggestchecked
      }
      this.autoremarks = " added the "+propname+" for SV during the sitevisit.";
    this._phpService.addsvselectedproperties(param).subscribe((success) => {
      this.status = success.status;
      if(this.status == "True"){
        this.suggestchecked = "";
        var param = {
          leadid: this.leadid,
          execid: this.userid,
          stage:  "SV",
        }
          this._phpService
      .svselectproperties(param)
      .subscribe(selectsuggested => {
        this.selectedpropertylists = selectsuggested['selectedsvlists'];
        this.selectedlists = selectsuggested;
      });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
    }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "SV",
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the SV scheduled lists.";
        this._phpService.removeselectedproperties(param2).subscribe((success) => {
          this.status = success.status;
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "SV",
          }
          this._phpService.svselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
          });
        }, (err) => {
          console.log("Failed to Update");
        })

    }
  }

  moresuggestedbeforeselection(){
    swal({
      title: 'Select Visited Date and Time!',
      type: 'error',
      confirmButtonText: 'ok'
    })
  }

  addpropertiestolist01() {
    this.suggestedproperties = $(".onchangeforsuggestproperties01").dropdown("get value");
    this.suggestedpropertiesname = $('.onchangeforsuggestproperties01 option:selected').toArray().map(item => item.text).join();
    if (this.suggestedproperties == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties01').removeAttr("style");
    }
    if(this.suggestedproperties.length == 0){
      // alert("No need push to database");
    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "SV",
      }
      this.autoremarks = "While During the SiteVisit "+this.username+" some more properties suggested like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".onchangeforsuggestproperties01").dropdown('clear');
        $(".onchangeforsuggestproperties01").dropdown('destroy');
        $(".onchangeforsuggestproperties01").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })
    }
    
  }

  addpropertiestolist02() {
    this.suggestedproperties = $(".onchangeforsuggestproperties02").dropdown("get value");
    this.suggestedpropertiesname = $('.onchangeforsuggestproperties02 option:selected').toArray().map(item => item.text).join();
    if (this.suggestedproperties == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties02').removeAttr("style");
    }

    if(this.suggestedproperties.length == 0){

    }else{

      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "SV",
      }
      this.autoremarks = "While During the SiteVisit "+this.username+" some more properties suggested like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".onchangeforsuggestproperties02").dropdown('clear');
        $(".onchangeforsuggestproperties02").dropdown('destroy');
        $(".onchangeforsuggestproperties02").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })

    }
    
  }

  addpropertiestolist03() {
    this.suggestedproperties = $(".onchangeforsuggestproperties03").dropdown("get value");
    this.suggestedpropertiesname = $('.onchangeforsuggestproperties03 option:selected').toArray().map(item => item.text).join();
    if (this.suggestedproperties == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties03').removeAttr("style");
    }
    if(this.suggestedproperties.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "SV",
      }
      this.autoremarks = "While During the SiteVisit "+this.username+" some more properties suggested like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".onchangeforsuggestproperties03").dropdown('clear');
        $(".onchangeforsuggestproperties03").dropdown('destroy');
        $(".onchangeforsuggestproperties03").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })
    }
    
  }

  subaddpropertiestolist() {
    this.suggestedproperties = $(".subonchangeforsuggestproperties").dropdown("get value");
    this.suggestedpropertiesname = $('.subonchangeforsuggestproperties option:selected').toArray().map(item => item.text).join();

    if (this.suggestedproperties == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.subonchangeforsuggestproperties').removeAttr("style");
    }

    if(this.suggestedproperties.length == 0){

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "SV",
      }
      this.autoremarks = "While During the SiteVisit "+this.username+" some more properties suggested like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".subonchangeforsuggestproperties").dropdown('clear');
        $(".subonchangeforsuggestproperties").dropdown('destroy');
        $(".subonchangeforsuggestproperties").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })
    }

    
  }

  svfixing() {

    // Common Elements 

    var nextdate = $('#svnextactiondate').val();
    var nexttime = $('#svnextactiontime').val();
    var textarearemarks = $('#svtextarearemarks').val();
    var dateformatchange = new Date(nextdate).toDateString();

    // Common Elements 

      // USV DONE with SV Fixing
    if( $('#customer_phase4').val() == "USV")
    {
      if ($('#USVvisiteddate').val() == "") {
        $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisiteddate').removeAttr("style");
      }
  
      if ($('#USVvisitedtime').val() == "") {
        $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisitedtime').removeAttr("style");
      }
  
      // if ($('#usvfinalremarks').val() == "") {
      //   $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#usvfinalremarks').removeAttr("style");
      // }

      if ($('#svnextactiondate').val() == "") {
        $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiondate').removeAttr("style");
      }
      if ($('#svnextactiontime').val() == "") {
        $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiontime').removeAttr("style");
      }
      if (this.suggestchecked == "") {
        swal({
          title: 'Property Not Selected',
          text: 'Please select atleast one property for the Sitevisit',
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }
      else {
      }
      if ($('#svtextarearemarks').val() == "") {
        $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#svtextarearemarks').removeAttr("style");
      }

    var visiteddate = $('#USVvisiteddate').val();
    var visitedtime = $('#USVvisitedtime').val();
    // var usvfinalremarks = $('#usvfinalremarks').val();
    var usvfinalremarks = "USV Done";

    var nextdate = $('#svnextactiondate').val();
    var nexttime = $('#svnextactiontime').val();

    var param = {
      leadid: this.leadid,
      nextdate: nextdate,
      nexttime: nexttime,
      suggestproperties: this.suggestchecked
    }
    this._phpService.addsvselectedproperties(param).subscribe((success) => {
      this.status = success.status;
      var param = {
        leadid: this.leadid,
        execid: this.userid,
        stage:  "SV",
      }
      this._phpService.svselectproperties(param).subscribe(selectsuggested => {
        this.selectedpropertylists = selectsuggested['selectedsvlists'];
        this.selectedlists = selectsuggested;
        // Joining the object values as comma seperated when add the property for the history storing
        this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
        // Joining the object values as comma seperated when add the property for the history storing

        this.autoremarks = " Changed the status to SV after Successfully completed USV";
        var leadusvdoneparam = {
          leadid: this.leadid,
          closedate: visiteddate,
          closetime: visitedtime,
          leadstage: "USV",
          stagestatus: '3',
          textarearemarks: usvfinalremarks,
          userid: this.userid,
          assignid: this.executeid,
          autoremarks: this.autoremarks
        }
        this._phpService.addleadhistory(leadusvdoneparam).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            this.autoremarks = "Scheduled the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadsvfixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "SV",
              stagestatus: '1',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks
            }
            this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                $('#svnextactiondate').val('');
                $('#svnextactiontime').val('');
                $('#customer_phase4').val('');
                $('#svtextarearemarks').val('');
              swal({
                title: 'SV Fixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.router.navigate([currentUrl]);
                    });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
          }
        },(err) => {
          console.log("Failed to Update");
        });

      });
    }, (err) => {
      console.log("Failed to Update");
    })
    
    }
        // USV DONE with SV Fixing

        // DIRECT SV Fixing
    
    else if( $('#customer_phase4').val() == "SV")
    {
      if ($('#svnextactiondate').val() == "") {
        $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiondate').removeAttr("style");
      }
      if ($('#svnextactiontime').val() == "") {
        $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiontime').removeAttr("style");
      }
        if (this.suggestchecked == "") {
          swal({
            title: 'Property Not Selected',
            text: 'Please select atleast one property for the Sitevisit',
            type: 'error',
            confirmButtonText: 'ok'
          })
          return false;
        }
        else {
        }
        
        if ($('#svtextarearemarks').val() == "") {
          $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
          return false;
        }
        else {
          $('#svtextarearemarks').removeAttr("style");
        }

        var nextdate = $('#svnextactiondate').val();
        var nexttime = $('#svnextactiontime').val();

        var param = {
          leadid: this.leadid,
          nextdate: nextdate,
          nexttime: nexttime,
          suggestproperties: this.suggestchecked
        }
        this._phpService.addsvselectedproperties(param).subscribe((success) => {
          this.status = success.status;
          var param = {
            leadid: this.leadid,
            execid: this.userid,
            stage:  "SV",
          }
          this._phpService.svselectproperties(param).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when add the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when add the property for the history storing

            this.autoremarks = "Scheduled the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
            var leadsvfixparam = {
              leadid: this.leadid,
              closedate: nextdate,
              closetime: nexttime,
              leadstage: "SV",
              stagestatus: '1',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks
            }
            this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                $('#subsvnextactiondate').val('');
                $('#subsvnextactiontime').val('');
                $('#customer_phase4').val('');
                $('#subsvtextarearemarks').val('');
              swal({
                title: 'SV Fixed Succefully',
                type: "success",
                confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.router.navigate([currentUrl]);
                    });
                  }
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });

          });
        }, (err) => {
          console.log("Failed to Update");
        })
    }
        // DIRECT SV Fixing

        // RSV DONE with SV Fixing

        else if( $('#customer_phase4').val() == "RSV"){
          if ($('#RSVvisiteddate').val() == "") {
            $('#RSVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#RSVvisiteddate').removeAttr("style");
          }
      
          if ($('#RSVvisitedtime').val() == "") {
            $('#RSVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#RSVvisitedtime').removeAttr("style");
          }
      
          // if ($('#rsvfinalremarks').val() == "") {
          //   $('#rsvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
          //   return false;
          // }
          // else {
          //   $('#rsvfinalremarks').removeAttr("style");
          // }

          if ($('#svnextactiondate').val() == "") {
            $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiondate').removeAttr("style");
          }
          if ($('#svnextactiontime').val() == "") {
            $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiontime').removeAttr("style");
          }
          if (this.suggestchecked == "") {
            swal({
              title: 'Property Not Selected',
              text: 'Please select atleast one property for the Sitevisit',
              type: 'error',
              confirmButtonText: 'ok'
            })
            return false;
          }
          else {
          }
         
          if ($('#svtextarearemarks').val() == "") {
            $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
            return false;
          }
          else {
            $('#svtextarearemarks').removeAttr("style");
          }

          var visiteddate = $('#RSVvisiteddate').val();
          var visitedtime = $('#RSVvisitedtime').val();
          // var rsvfinalremarks = $('#rsvfinalremarks').val();
          var rsvfinalremarks = "RSV Finished";

          var nextdate = $('#svnextactiondate').val();
          var nexttime = $('#svnextactiontime').val();

          var param = {
            leadid: this.leadid,
            nextdate: nextdate,
            nexttime: nexttime,
            suggestproperties: this.suggestchecked
          }
          this._phpService.addsvselectedproperties(param).subscribe((success) => {
            this.status = success.status;
            var param = {
              leadid: this.leadid,
              execid: this.userid,
              stage:  "SV",
            }
            this._phpService.svselectproperties(param).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectedsvlists'];
              this.selectedlists = selectsuggested;
              // Joining the object values as comma seperated when add the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when add the property for the history storing

              this.autoremarks = " Changed the status to SV after Successfully completed RSV";
              var leadrsvdoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "RSV",
                stagestatus: '3',
                textarearemarks: rsvfinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks
              }

              this._phpService.addleadhistory(leadrsvdoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  this.autoremarks = "Scheduled the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadsvfixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "SV",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks
                  }
                  this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      $('#svnextactiondate').val('');
                      $('#svnextactiontime').val('');
                      $('#customer_phase4').val('');
                      $('#svtextarearemarks').val('');
                    swal({
                      title: 'SV Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });

            });
          }, (err) => {
            console.log("Failed to Update");
          })

        }

        // RSV DONE with SV Fixing

        // NEGOTIATION DONE with SV Fixing

        else if( $('#customer_phase4').val() == "Final Negotiation"){
          if ($('#negovisiteddate').val() == "") {
            $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#negovisiteddate').removeAttr("style");
          }
      
          if ($('#negovisitedtime').val() == "") {
            $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#negovisitedtime').removeAttr("style");
          }
      
          // if ($('#negofinalremarks').val() == "") {
          //   $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
          //   return false;
          // }
          // else {
          //   $('#negofinalremarks').removeAttr("style");
          // }

          if ($('#svnextactiondate').val() == "") {
            $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiondate').removeAttr("style");
          }
          if ($('#svnextactiontime').val() == "") {
            $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiontime').removeAttr("style");
          }
          if (this.suggestchecked == "") {
            swal({
              title: 'Property Not Selected',
              text: 'Please select atleast one property for the SV',
              type: 'error',
              confirmButtonText: 'ok'
            })
            return false;
          }
          else {
          }
          
          if ($('#svtextarearemarks').val() == "") {
            $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
            return false;
          }
          else {
            $('#svtextarearemarks').removeAttr("style");
          }

          var visiteddate = $('#negovisiteddate').val();
          var visitedtime = $('#negovisitedtime').val();
          // var negofinalremarks = $('#negofinalremarks').val();
          var negofinalremarks = "Final Negotiation Finished";

          var nextdate = $('#svnextactiondate').val();
          var nexttime = $('#svnextactiontime').val();

          var param = {
            leadid: this.leadid,
            nextdate: nextdate,
            nexttime: nexttime,
            suggestproperties: this.suggestchecked
          }
          this._phpService.addsvselectedproperties(param).subscribe((success) => {
            this.status = success.status;
            var param = {
              leadid: this.leadid,
              execid: this.userid,
              stage:  "SV",
            }
            this._phpService.svselectproperties(param).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectedsvlists'];
              this.selectedlists = selectsuggested;
              // Joining the object values as comma seperated when add the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when add the property for the history storing

              this.autoremarks = " Changed the status to SV after Successfully completed Final negotiation";
              var leadnegodoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "Final Negotiation",
                stagestatus: '3',
                textarearemarks: negofinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks
              }
              this._phpService.addleadhistory(leadnegodoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  this.autoremarks = "Scheduled the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadsvfixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "SV",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks
                  }
                  this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      $('#svnextactiondate').val('');
                      $('#svnextactiontime').val('');
                      $('#customer_phase4').val('');
                      $('#svtextarearemarks').val('');
                    swal({
                      title: 'SV Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });

            });
          }, (err) => {
            console.log("Failed to Update");
          })
          

          
        }

        // NEGOTIATION DONE with SV Fixing

        
  };

  svrefixing(){
    if(this.suggestchecked == ""){
      swal({
        title: 'Property Not Selected',
        text: 'Please select atleast one property for the Sitevisit',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    }else{
    }

    if ($('#refixdate').val() == "") {
      $('#refixdate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixdate').removeAttr("style");
    }
    if ($('#refixtime').val() == "") {
      $('#refixtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixtime').removeAttr("style");
    }
    if ($('#refixtextarearemarks').val() == "") {
      $('#refixtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#refixtextarearemarks').removeAttr("style");
    }

    var nextdate = $('#refixdate').val();
    var nexttime = $('#refixtime').val();
    var textarearemarks = $('#refixtextarearemarks').val();
    var dateformatchange = new Date(nextdate).toDateString();
    var userid = localStorage.getItem('UserId');
    
    var param = {
      leadid: this.leadid,
      nextdate: nextdate,
      nexttime: nexttime,
      suggestproperties: this.suggestchecked
      }
    this._phpService.addsvselectedpropertiesrefix(param).subscribe((success) => {
      this.status = success.status;
      if(this.status == "True"){
        var param = {
          leadid: this.leadid,
          execid: this.userid,
          stage:  "SV",
        }
        this._phpService.svselectproperties(param).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedsvlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when add the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when add the property for the history storing
    
          this.autoremarks = " ReFixed the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
          var leadsvrefixparam = {
            leadid: this.leadid,
            closedate: nextdate,
            closetime: nexttime,
            leadstage: "SV",
            stagestatus: '2',
            textarearemarks: textarearemarks,
            userid: userid,
            assignid: this.executeid,
            autoremarks: this.autoremarks
          }
          this._phpService.addleadhistory(leadsvrefixparam).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
            swal({
              title: 'SV ReFixed Succefully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                      this.router.navigate([currentUrl]);
                  });
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });
        }); 
      }
    }, (err) => {
      console.log("Failed to Update");
    })
    
       
  }

  svdonewithfixing()
  {

    if ($('#SVvisiteddate').val() == "") {
      $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisiteddate').removeAttr("style");
    }

    if ($('#SVvisitedtime').val() == "") {
      $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisitedtime').removeAttr("style");
    }

    // if ($('#svfinalremarks').val() == "") {
    //   $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
    //   return false;
    // }
    // else {
    //   $('#svfinalremarks').removeAttr("style");
    // }

    if (this.suggestchecked == "") {
      swal({
        title: 'Property Not Selected',
        text: 'Please select atleast one property for the Sitevisit',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    }
    else {
    }
    if ($('#subsvnextactiondate').val() == "") {
      $('#subsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subsvnextactiondate').removeAttr("style");
    }
    if ($('#subsvnextactiontime').val() == "") {
      $('#subsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subsvnextactiontime').removeAttr("style");
    }
    if ($('#subsvtextarearemarks').val() == "") {
      $('#subsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#subsvtextarearemarks').removeAttr("style");
    }

    var nextdate = $('#subsvnextactiondate').val();
    var nexttime = $('#subsvnextactiontime').val();

    var param = {
      leadid: this.leadid,
      nextdate: nextdate,
      nexttime: nexttime,
      suggestproperties: this.suggestchecked
    }
    this._phpService.addsvselectedproperties(param).subscribe((success) => {
      this.status = success.status;
      var param = {
        leadid: this.leadid,
        execid: this.userid,
        stage:  "SV",
      }
      this._phpService.svselectproperties(param).subscribe(selectsuggested => {
        this.selectedpropertylists = selectsuggested['selectedsvlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when add the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when add the property for the history storing
            
            var visiteddate = $('#SVvisiteddate').val();
            var visitedtime = $('#SVvisitedtime').val();
            // var svfinalremarks = $('#svfinalremarks').val();
            var svfinalremarks = "SV Done";
            var userid = localStorage.getItem('UserId');
            this.autoremarks = " Completed the SV Successfully ";
            var leadsvdoneparam = {
              leadid: this.leadid,
              closedate: visiteddate,
              closetime: visitedtime,
              leadstage: "SV",
              stagestatus: '3',
              textarearemarks: svfinalremarks,
              userid: userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks
            }
            this._phpService.addleadhistory(leadsvdoneparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                var nextdate = $('#subsvnextactiondate').val();
                var nexttime = $('#subsvnextactiontime').val();
                var textarearemarks = $('#subsvtextarearemarks').val();
                var dateformatchange = new Date(nextdate).toDateString();
                var userid = localStorage.getItem('UserId');

                this.autoremarks = " again Scheduled the SV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                var leadsvfixparam = {
                  leadid: this.leadid,
                  closedate: nextdate,
                  closetime: nexttime,
                  leadstage: "SV",
                  stagestatus: "1",
                  textarearemarks: textarearemarks,
                  userid: userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks
                }
                this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
                  this.status = success.status;
                  if(this.status == "True"){
                    $('#subsvnextactiondate').val('');
                    $('#subsvnextactiontime').val('');
                    $('#customer_phase4').val('');
                    $('#subsvtextarearemarks').val('');
                  swal({
                    title: 'SV Fixed Succefully',
                    type: "success",
                    confirmButtonText: 'OK!',
                    }).then((result) => {
                      if (result.value) {
                        let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                      }
                    });
                  }
                },(err) => {
                  console.log("Failed to Update");
                });
              }
            },(err) => {
              console.log("Failed to Update");
            });
      });
    }, (err) => {
      console.log("Failed to Update");
    })

    
  }

  visitclick(i,propertyname)
  {
    this.intrestbtn = true;
    $('#visitupdate'+i).val("3");
    $('#propertyremarks'+i).html('Visited '+propertyname+' and Not Intrested.');
  }

  cancelclick(i,propertyname)
  {
    this.intrestbtn = false;
    $('#visitupdate'+i).val("2");
    $('#propertyremarks'+i).html('Cancelled the visit for '+propertyname);
  }

  doLikeButton(i,propertyname) {
    $(".heart_icon"+i).removeClass('liked-shaked');
    $(".heart_icon"+i).toggleClass('liked');
    $(".heart_icon"+i).toggleClass('not-liked');
    $(".heart_icon"+i).toggleClass('fas');
    $(".heart_icon"+i).toggleClass('far');
    // $(".heartbreak_icon"+i).toggleClass('bi-heartbreak');
    // $(".heartbreak_icon"+i).toggleClass('bi-heartbreak-fill');
    $(".lkebtns"+i).toggleClass('likeactive');
    if($(".heart_icon"+i).hasClass("liked")) {
      $(".heart_icon"+i).addClass('liked-shaked');
      $('#propertyremarks'+i).html('Visited '+propertyname+' and they are Intrested in this property.');
      $('#visitupdate'+i).val("1");
    }else{
      $('#propertyremarks'+i).html('Visited '+propertyname+' and Not Intrested');
      $('#visitupdate'+i).val("3");
    } 
}

  updatepropertyvisit(propertyid,propertyname,i)
  {
    if($('#visitupdate'+i).val() == "1"){
      this.visitupdate = "Visited";
    }else{
      this.visitupdate = "Not Visited";
    }
    this.propertyremarks = $('#propertyremarks'+i).val();

    if ($('#visitupdate'+i).val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please Confirm Site Visited or Not',
        heightAuto: false,
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
      $('#visitupdate'+i).removeAttr("style");
    }

    if ($('#propertyremarks'+i).val() == "") {
      $('#propertyremarks'+i).focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks'+i).removeAttr("style");
    }

    var param = {
      leadid: this.leadid,
      propid: propertyid,
      execid: this.userid,
      visitupdate: $('#visitupdate'+i).val(),
      remarks: $('#propertyremarks'+i).val(),
      stage: $('#customer_phase4').val(),
    }
    this.autoremarks = " Marked the "+ propertyname +" as "+this.visitupdate+". Here is the property remarks - "+this.propertyremarks;
    this._phpService.addpropertyvisitupdate(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        swal({
          title: 'Data Updated Successfully',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            this.loadimportantapi();
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
  }

}
