import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormsModule, FormGroup } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MyDatePickerModule  } from 'mydatepicker';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { UniquePipe,SearchPipe,Searchproperty,duplicatePipe } from './pipe-filter';
import { AppComponent } from './app.component';
import { AuthService } from './auth-service';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoginComponent } from './templates/login/login.component';
import { phpservice } from './php.service';
import { mandateservice } from './mandate.service';
import {Md2AccordionModule} from 'md2-accordion/accordion';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mergeMap';
// TEMPLATES
import { DashboardComponent } from './templates/dashboard/dashboard.component';
import { HeaderComponent } from './templates/header/header.component';
import { AccordianComponent } from './templates/accordian/accordian.component';
import { CustomersComponent } from './templates/customers/customers.component';
import { RMTLDashboardComponent,RMExecDashboardComponent } from './templates/rmtldashboard/rmtldashboard.component';
import { CsexecdashboardComponent,csexecutdashboard } from './templates/csexecdashboard/csexecdashboard.component';
import { LeadsComponent } from './templates/leads/leads.component';
import { EnquiryComponent } from './templates/enquiry/enquiry.component';
import { ExecutivesComponent } from './templates/executives/executives.component';
import { LeadassignComponent } from './templates/leadassign/leadassign.component';
import { ActiveComponent } from './templates/active/active.component';
import { InactiveComponent } from './templates/inactive/inactive.component';
import { FollowupComponent } from './templates/followup/followup.component';
import { FacetofaceComponent } from './templates/facetoface/facetoface.component';
import { UsvComponent } from './templates/usv/usv.component';
import { SvComponent } from './templates/sv/sv.component';
import { RsvComponent } from './templates/rsv/rsv.component';
import { NegotiateComponent } from './templates/negotiate/negotiate.component';
import { ClosedleadsComponent } from './templates/closedleads/closedleads.component';
import { JunkComponent } from './templates/junk/junk.component';
import { RegistrationdataComponent } from './templates/registrationdata/registrationdata.component';
import { AssignedrmComponent } from './templates/assignedrm/assignedrm.component';
import { TodaysComponent } from './templates/todays/todays.component';
import { F2fformComponent } from './templates/f2fform/f2fform.component';
import { UsvformComponent } from './templates/usvform/usvform.component';
import { SvformComponent } from './templates/svform/svform.component';
import { RsvformComponent } from './templates/rsvform/rsvform.component';
import { FollowupformComponent } from './templates/followupform/followupform.component';
import { NegoformComponent } from './templates/negoform/negoform.component';
import { ClosedformComponent } from './templates/closedform/closedform.component';
import { JunkformComponent } from './templates/junkform/junkform.component';
import { AddpropertiesComponent } from './templates/addproperties/addproperties.component';
import { MandatelistingComponent } from './templates/mandatelisting/mandatelisting.component';
import { MandateleaddetailsComponent } from './templates/mandateleaddetails/mandateleaddetails.component';
import { MandatefollowupformComponent } from './templates/mandatefollowupform/mandatefollowupform.component';
import { MandatejunkformComponent } from './templates/mandatejunkform/mandatejunkform.component';
import { MandateclosedComponent } from './templates/mandateclosed/mandateclosed.component';
import { MandatetodaysComponent } from './templates/mandatetodays/mandatetodays.component';
import { MandateassignedComponent } from './templates/mandateassigned/mandateassigned.component';
import { AddcpComponent } from './templates/addcp/addcp.component';
import { TestComponent } from './test/test.component';
import { SearchDropdown } from './templates/search-dropdown/search-dropdown';
import { AssisstedClientsComponent } from './templates/assissted-clients/assissted-clients.component';
import { MandateCustomerDetailsComponent } from './templates/mandate-customer-details/mandate-customer-details.component';
import { MandateUsvformComponent } from './templates/mandate-usvform/mandate-usvform.component';
import { MandateRsvformComponent } from './templates/mandate-rsvform/mandate-rsvform.component';
import { MandateNegoformComponent } from './templates/mandate-negoform/mandate-negoform.component';
import { MandateClosedformComponent } from './templates/mandate-closedform/mandate-closedform.component';
import { ClientleadsComponent } from './templates/clientleads/clientleads.component';
import { NewdashboardComponent } from './templates/newdashboard/newdashboard.component';
import { DashboardheaderComponent } from './templates/dashboardheader/dashboardheader.component';
import { BuildermandaterequestComponent } from './templates/buildermandaterequest/buildermandaterequest.component';



// TEMPLATES

@NgModule({
  declarations: [
    AppComponent,
    UniquePipe,
    SearchPipe,
    Searchproperty,
    duplicatePipe,
    LoginComponent,
    DashboardComponent,
    HeaderComponent,
    AccordianComponent,
    CustomersComponent,
    RMTLDashboardComponent,
    RMExecDashboardComponent,
    CsexecdashboardComponent,
    csexecutdashboard,
    LeadsComponent,
    EnquiryComponent,
    ExecutivesComponent,
    LeadassignComponent,
    ActiveComponent,
    InactiveComponent,
    FollowupComponent,
    FacetofaceComponent,
    UsvComponent,
    SvComponent,
    RsvComponent,
    NegotiateComponent,
    ClosedleadsComponent,
    JunkComponent,
    RegistrationdataComponent,
    AssignedrmComponent,
    TodaysComponent,
    F2fformComponent,
    UsvformComponent,
    SvformComponent,
    RsvformComponent,
    FollowupformComponent,
    NegoformComponent,
    ClosedformComponent,
    JunkformComponent,
    AddpropertiesComponent,
    MandatelistingComponent,
    MandateleaddetailsComponent,
    MandatefollowupformComponent,
    MandatejunkformComponent,
    MandateclosedComponent,
    MandatetodaysComponent,
    MandateassignedComponent,
    AddcpComponent,
    TestComponent,
    SearchDropdown,
    AssisstedClientsComponent,
    MandateCustomerDetailsComponent,
    MandateUsvformComponent,
    MandateRsvformComponent,
    MandateNegoformComponent,
    MandateClosedformComponent,
    ClientleadsComponent,
    NewdashboardComponent,
    DashboardheaderComponent,
    BuildermandaterequestComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AngularMultiSelectModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    HttpModule,
    InfiniteScrollModule,
    AppRoutingModule,
    AutoCompleteModule,
    MyDatePickerModule,
    Md2AccordionModule,
    Ng2SearchPipeModule
  ],
  providers: [phpservice,mandateservice, UniquePipe,SearchPipe,Searchproperty,duplicatePipe,DatePipe,AuthService],
  bootstrap: [AppComponent]
  
})

export class AppModule { }
