import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-registrationdata',
  templateUrl: './registrationdata.component.html',
  styleUrls: ['./registrationdata.component.css']
})
export class RegistrationdataComponent implements OnInit {

  filterLoader: boolean = true;

  constructor(private _phpService:phpservice,) { }

  registrations: any;
  static count: number;
  registrationcounts: any;
  filterproperties="";
  today = new Date();
  dd = String(this.today.getDate()).padStart(2, '0');
  mm = String(this.today.getMonth() + 1).padStart(2, '0');
  yyyy = this.today.getFullYear();
  date = this.yyyy + '-' + this.mm + '-' + this.dd ;
  beginingdate = this.yyyy + '-' + this.mm + '-' + "01";
  

  ngOnInit() {
    // console.log("Current Date - ",this.date);
    // console.log("Starting Date - ",this.beginingdate);
    this.getleads();
    RegistrationdataComponent.count = 0;
  }

  getleads()
  {
    this.filterLoader = true;
    RegistrationdataComponent.count = 0;
    var limitparam = 0;
    var limitrows = 7000;
    var fromdate = this.beginingdate;
    var todate = this.date;
    this._phpService.getregistrationlist(limitparam,limitrows,fromdate,todate).subscribe(regs =>
      {
        this.registrations = regs;
        this.filterLoader = false;
      })
    this._phpService.getregistrationlistcounts(fromdate,todate).subscribe(regscount =>
      {
        this.registrationcounts = regscount[0].registrationcounts;
      })

  }

  refresh()
  {
    this.registrations = "";
    this.getleads();
    $('#fromdate').val("");
    $('#todate').val("");
    $('#source_dropdown').dropdown('clear');
    $('#locality_dropdown').dropdown('clear');
  }

  loadMore(){
    const limit = RegistrationdataComponent.count+=30;
    const counts = this.registrationcounts;
    let limitrows = 30;
    let livecount = this.registrations.length;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    if(livecount <= counts){
      return this._phpService.getregistrationlist(limit,limitrows,fromdate,todate).subscribe(regs =>{
        this.registrations = this.registrations.concat(regs);
      })
    }else{
      
    }
  }

  selecttodate()
  {
    this.registrations = "";
    this.filterLoader = true;
    RegistrationdataComponent.count = 0;
    var limitparam = 0;
    var limitrows = 10000;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    this._phpService.getregistrationlist(limitparam,limitrows,fromdate,todate).subscribe(regs =>{
      this.registrations = regs;
      this.filterLoader = false;
    })
    this._phpService.getregistrationlistcounts(fromdate,todate).subscribe(regscount =>
      {
        this.registrationcounts = regscount[0].registrationcounts;
      })
  }

}
