import { Injectable, OnInit } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from "@angular/http";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()

export class mandateservice implements OnInit {

    ngOnInit(){

    }

    apiurl: string;
    indiaestatesapi: string;

    constructor(private _http: Http, private http: Http, private router: Router) {
       
        // this.apiurl = 'http://localhost/homes247newbackend/cpclientreg';
        this.apiurl = 'https://superadmin.right2shout.in/cpclientreg';
        this.indiaestatesapi = 'https://www.indiaestates.in';
        
    }
    checkMe: any;

    handleError(error: HttpErrorResponse) {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // Server-side errors
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // window.alert(errorMessage);
        return _throw(errorMessage);
    }

    getmandatelist() {
        return this._http.get(this.apiurl + "/mandateprojects")
            .map((response: Response) => response.json().Properties);
    }

    getmandateleads(params) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('limit', params.limit);
        urlSearchParams.set('limitrows', params.limitrows);
        urlSearchParams.set('FromDate', params.fromdate);
        urlSearchParams.set('ToDate', params.todate);
        urlSearchParams.set('property', params.property);
        urlSearchParams.set('RMID', params.rmid);
        urlSearchParams.set('FRESHID', params.freshid);
        return this._http
            .get(this.apiurl + "/completemandateleads" + "?", { search: urlSearchParams })
            .pipe(map(response => response.json().Leads));
    }

    getmandateleadcounts(params) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('FromDate', params.fromdate);
        urlSearchParams.set('ToDate', params.todate);
        urlSearchParams.set('property', params.property);
        urlSearchParams.set('RMID', params.rmid);
        return this._http
            .get(this.apiurl + "/completemandateleadscounts" + "?", { search: urlSearchParams })
            .pipe(map(response => response.json().Leads));
    }

    addmandatelead(params) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('client', params.clientname);
        urlSearchParams.append('clientnum', params.clientnumber);
        urlSearchParams.append('clientmail', params.clientmail);
        urlSearchParams.append('source', params.clientsource);
        urlSearchParams.append('propertyId', params.propid);
        urlSearchParams.append('bhksize', params.bhk);
        urlSearchParams.append('budgetrange', params.budget);
        urlSearchParams.append('sitevisitdate', params.svdate);
        urlSearchParams.append('remarks', params.remarks);
        urlSearchParams.append('rmmail', params.rmmail);
        urlSearchParams.append('rmname', params.rmname);
        urlSearchParams.append('rmid', params.rmid);
        urlSearchParams.append('usermail', params.usermail);
        urlSearchParams.append('username', params.username);
        urlSearchParams.append('userid', params.userid);
        urlSearchParams.append('regname', params.registeredname);
        urlSearchParams.append('regid', params.registeredid);
        let body = urlSearchParams.toString()
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/leadadding", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    rmmandateleadadd(params) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('client', params.clientname);
        urlSearchParams.append('clientnum', params.clientnumber);
        urlSearchParams.append('clientmail', params.clientmail);
        urlSearchParams.append('source', params.clientsource);
        urlSearchParams.append('propertyId', params.propid);
        urlSearchParams.append('bhksize', params.bhk);
        urlSearchParams.append('budgetrange', params.budget);
        urlSearchParams.append('sitevisitdate', params.svdate);
        urlSearchParams.append('remarks', params.remarks);
        urlSearchParams.append('rmmail', params.rmmail);
        urlSearchParams.append('rmname', params.rmname);
        urlSearchParams.append('rmid', params.rmid);
        urlSearchParams.append('usermail', params.usermail);
        urlSearchParams.append('username', params.username);
        urlSearchParams.append('userid', params.userid);
        urlSearchParams.append('regname', params.registeredname);
        urlSearchParams.append('regid', params.registeredid);
        let body = urlSearchParams.toString()
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/rmleadadding", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    getcustomeredit(id) {
        return this._http.get(this.apiurl + "/geteditcustomer/" + id).map((response: Response) => response.json().Customerview);
    }

    addfollowuphistory(leadid, followdate, followtime, leadstage, followupaction, textarearemarks, userid, assignid) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('LeadID', leadid);
        urlSearchParams.append('Leadstatus', leadstage);
        urlSearchParams.append('FollowupSection', followupaction);
        urlSearchParams.append('Actiondate', followdate);
        urlSearchParams.append('Actiontime', followtime);
        urlSearchParams.append('remarks', textarearemarks);
        urlSearchParams.append('userid', userid);
        urlSearchParams.append('assignID', assignid);
        // urlSearchParams.append('CSID', csid);
        let body = urlSearchParams.toString()
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/addfollowupleadhistory", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    addleadhistory(leadid, nextdate, nexttime, leadstage, stagestatus, textarearemarks, userid, assignid) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('LeadID', leadid);
        urlSearchParams.append('Leadstatus', leadstage);
        urlSearchParams.append('Actiondate', nextdate);
        urlSearchParams.append('Actiontime', nexttime);
        urlSearchParams.append('Stagestatus', stagestatus);
        urlSearchParams.append('remarks', textarearemarks);
        urlSearchParams.append('userid', userid);
        urlSearchParams.append('assignID', assignid);
        // urlSearchParams.append('CSID', csid);
        let body = urlSearchParams.toString()
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/addleadhistory", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    getassignedrm(id) {
        return this._http.get(this.apiurl + "/getassignedrm/" + id).map((response: Response) => response.json().RMname);
    }

    getactiveleadsstatus(leadid) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('LeadID', leadid);
        let body = urlSearchParams.toString();
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/getactiveleadsstatus", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    gethistory(leadid) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('LeadID', leadid);
        let body = urlSearchParams.toString()
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/leadhistory", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    gettodaymeetings(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('limit', param.limit);
        urlSearchParams.set('limitrows', param.limitrows);
        urlSearchParams.set('RMid', param.rmid);
        urlSearchParams.set('CSid', param.csid);
        urlSearchParams.set('status', param.stages);
        urlSearchParams.set('OnlyRM', param.onlyrm);
        urlSearchParams.set('OnlyCS', param.onlycs);
        urlSearchParams.set('Unassign', param.unassign);
        let body = urlSearchParams.toString()
        return this.http
            .get(this.apiurl + "/todaymeetings?", { search: urlSearchParams })
            .pipe(map(response => response.json().Todaymeeting));
    }

    gettodaymeetingscounts(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('RMid', param.rmid);
        urlSearchParams.set('CSid', param.csid);
        urlSearchParams.set('status', param.status);
        urlSearchParams.set('OnlyRM', param.onlyrm);
        urlSearchParams.set('OnlyCS', param.onlycs);
        let body = urlSearchParams.toString()
        return this.http
            .get(this.apiurl + "/todaymeetingscounts?", { search: urlSearchParams })
            .pipe(map(response => response.json().meetingcounts));
    }

    getmissedmeetings(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('limit', param.limit);
        urlSearchParams.set('limitrows', param.limitrows);
        urlSearchParams.set('RMid', param.rmid);
        urlSearchParams.set('CSid', param.csid);
        urlSearchParams.set('status', param.stages);
        urlSearchParams.set('OnlyRM', param.onlyrm);
        urlSearchParams.set('OnlyCS', param.onlycs);
        urlSearchParams.set('Unassign', param.unassign);
        let body = urlSearchParams.toString()
        return this.http
            .get(this.apiurl + "/missedmeetings?", { search: urlSearchParams })
            .pipe(map(response => response.json().Missedmeeting));
    }

    getmissedmeetingscounts(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('RMid', param.rmid);
        urlSearchParams.set('CSid', param.csid);
        urlSearchParams.set('status', param.status);
        urlSearchParams.set('OnlyRM', param.onlyrm);
        urlSearchParams.set('OnlyCS', param.onlycs);
        let body = urlSearchParams.toString()
        return this.http
            .get(this.apiurl + "/missedmeetingscounts?", { search: urlSearchParams })
            .pipe(map(response => response.json().missedcounts));
    }

    completeassignedRMLeads(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('FromDate', param.datefrom);
        urlSearchParams.set('ToDate', param.dateto);
        urlSearchParams.set('status', param.statuss);
        urlSearchParams.set('rmid', param.executid);
        urlSearchParams.set('followup', param.followup);
        urlSearchParams.set('svfix', param.svfix);
        urlSearchParams.set('svrefix', param.svrefix);
        urlSearchParams.set('svdone', param.svdone);
        urlSearchParams.set('junk', param.junk);
        urlSearchParams.set('limit', param.limit);
        urlSearchParams.set('limitrows', param.limitrows);
        let body = urlSearchParams.toString()

        var headers = new Headers();
        return this.http
            .get(this.apiurl + "/rmleads?", { search: urlSearchParams })
            .pipe(map(response => response.json().RMLeads));
    }

    completeassignedRMLeadscounts(countparam) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('FromDate', countparam.fromdate);
        urlSearchParams.set('ToDate', countparam.todate);
        urlSearchParams.set('rmid', countparam.executid);
        return this.http
            .get(this.apiurl + "/rmleadcounts?", { search: urlSearchParams })
            .pipe(map(response => response.json().RMLeadCounts));
    }

    leadassignrm(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('RMID', param.customersupport);
        urlSearchParams.append('LeadID', param.assignedleads);
        let body = urlSearchParams.toString();
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiurl + "/Rmleadassign", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    completejunks(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.set('FromDate', param.datefrom);
        urlSearchParams.set('ToDate', param.dateto);
        urlSearchParams.set('rmid', param.executid);
        urlSearchParams.set('limit', param.limit);
        urlSearchParams.set('limitrows', param.limitrows);
        return this.http
            .get(this.apiurl + "/getjunk?", { search: urlSearchParams })
            .pipe(map(response => response.json().RMLeads));
    }

    cplists()
    {
        return this._http.get(this.indiaestatesapi + "/cplists")
            .pipe(map(response => response.json().CPlists));
    }

    addcp(param) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('name', param.cpname);
        urlSearchParams.append('person', param.person);
        urlSearchParams.append('number', param.cpnumber);
        urlSearchParams.append('mail', param.cpmail);
        urlSearchParams.append('secondarymail', param.cpsecondmail);
        urlSearchParams.append('addedby', param.addeduser);
        urlSearchParams.append('rerano', param.rera);
        urlSearchParams.append('gstno', param.gst);
        let body = urlSearchParams.toString();
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.indiaestatesapi + "/addcp", body, { headers: headers })
            .pipe(map(response => response.json()));
    }

    mandaterequestlists(){
        return this._http.get(this.indiaestatesapi + "/builderlists")
            .pipe(map(response => response.json().BuilderLists));
    }



}