import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandate-negoform',
  templateUrl: './mandate-negoform.component.html',
  styleUrls: ['./mandate-negoform.component.css']
})
export class MandateNegoformComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  leadid = this.route.snapshot.params['id'];
  visitedpropertylists: any;
  negotiatedproperty: any;
  cancelledpropertylists: any;
  selectedpropertylists: any;
  selectedproperty_commaseperated: any;
  selectedlists: any;
  suggestchecked: any;
  svform = false;
  svFixed = false;
  rsvform = false;
  rsvFixed = false;
  negoFixed = true;
  negoDone = false;
  finalnegoform = false;
  subnegofixed = false;
  executeid: any;
  csid: any;
  status: any;
  leadclosedform = false;
  leadclosed = false;
  junkform = false;
  junk = false;
  negoreFix = false;
  followform = false;
  followup = false;
  followdownform = false;
  followupdown = false;
  hideafterfixed = true;
  hidebeforefixed = false;
  activestagestatus: any;
  buttonhiders = true;
  autoremarks: any;
  userid:any;
  username:any;
  propertyremarks: any;
  suggestedpropertiesname: any;
  visitupdate: any;
  intrestbtn: boolean = false;
  filterLoader: boolean = true;
  visitstatusupdate = false;
  mainpropid: any;
  commonhide = true;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice) { }

    private isCountdownInitialized: boolean;

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');
    this.loadimportantapi();
    this._phpService
    .getassignedrm(this.leadid,this.userid)
    .subscribe(cust => {
      this.executeid = cust[0].executiveid;
      // Adding RSV Visit date time to RSV Submission Section
      $('#negovisiteddate').val(cust[0]['suggestedprop'][0].followupdate);
      $('#negovisitedtime').val(cust[0]['suggestedprop'][0].followuptime);
    // Adding RSV Visit date time to RSV Submission Section
    });

  this._phpService
    .getassignedcs(this.leadid)
    .subscribe(cust => {
      this.csid = cust[0].executiveid;
    });

    this._phpService
      .getactiveleadsstatus(this.leadid,this.userid)
      .subscribe(stagestatus => {
        this.filterLoader = false;
        this.activestagestatus = stagestatus['activeleadsstatus'];
        if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "1"){
          this.hideafterfixed = false;
          this.negoFixed = false;
          this.hidebeforefixed = true;
          this.negoreFix = true;
          $('#sectionselector').val('Final Negotiation');
        }else if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "2"){
          this.hideafterfixed = false;
          this.negoFixed = false;
          this.hidebeforefixed = true;
          this.negoreFix = true;
          $('#sectionselector').val('Final Negotiation');
        }else if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "0"){
          this.hideafterfixed = false;
          this.hidebeforefixed = false;
          this.negoDone = false;
          this.negoFixed = false;
          this.negoDone = true;
          this.commonhide = false;
          $('#sectionselector').val('Final Negotiation');
        }else if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "1"){
          this.hideafterfixed = true;
          this.hidebeforefixed = false;
          this.negoDone = false;
          this.negoFixed = true;
          $('#sectionselector').val('Final Negotiation');
        }else{
          this.hideafterfixed = true;
        }
      });

    // this.suggestchecked = "";
    if( $('#sectionselector').val() == "SV" || $('#sectionselector').val() == "USV" || $('#sectionselector').val() == "RSV"){
      this.buttonhiders = false;
    }else{
      this.buttonhiders = true;
    }
  }

  ngAfterViewChecked(){
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      this.scriptfunctions();
    }
  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.visitedcalendardate').calendar({
      type: 'date',
      minDate: this.priorDatebefore,
      maxDate: this.date,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
  }

  loadimportantapi(){
    var param = {
      leadid: this.leadid,
      execid: this.userid,
      stage:  $('#customer_phase4').val(),
    }

    this._phpService
    .negoselectproperties(this.leadid)
    .subscribe(selectsuggested => {
      if(selectsuggested['status'] == 'True'){
        this.selectedpropertylists = selectsuggested['selectednegolists'];
        this.selectedlists = selectsuggested;
      }else{
        this.selectedpropertylists = selectsuggested['selectednegolists'];
        this.selectedlists = selectsuggested;
        this.visitstatusupdate = true;
      }
    });

    this._phpService
    .getnegotiatedproperties(param)
    .subscribe(negotiated => {
      this.negotiatedproperty = negotiated['negotiatedlists'];
    });

    this._phpService
    .getvisitedsuggestproperties(param)
    .subscribe(visitsuggested => {
      this.visitedpropertylists = visitsuggested['visitedlists'];
      this.suggestchecked = this.visitedpropertylists.map((item) => { return item.propid }).join(',');
    });

    this._phpService
    .getcancelledsuggestproperties(param)
    .subscribe(cancelsuggested => {
      this.cancelledpropertylists = cancelsuggested['cancelledlists'];
    });
  }

  onnegoFixed(){
    this.negoFixed = true;
    this.negoDone = false;
    this.junkform = false;
    this.junk = false;
    this.negoreFix = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onnegoreFix()
  {
    this.negoreFix = true;
    this.negoFixed = false;
    this.negoDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onnegoDone(){
    this.negoFixed = false;
    this.negoDone = true;
    this.junkform = false;
    this.junk = false;
    this.negoreFix = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val('Final Negotiation');
  }

  onfollowup(){
    this.followform = true;
    this.followup = true;
    this.negoFixed = false;
    this.negoDone = false;
    this.junkform = false;
    this.junk = false;
    this.negoreFix = false;
    $('#sectionselector').val("");
  }

  onjunk(){
    this.junkform = true;
    this.junk = true;
    this.negoFixed = false;
    this.negoDone = false;
    this.negoreFix = false;
    this.followform = false;
    this.followup = false;
  }

  followupdownbtn(){
    this.followdownform = true;
    this.followupdown = true;
    this.svform = false;
    this.svFixed = false;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.subnegofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
  }

  onsvFixed(){
    this.svform = true;
    this.svFixed = true;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.subnegofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onrsvFixed(){
    this.rsvform = true;
    this.rsvFixed = true;
    this.svFixed = false;
    this.svform = false;
    this.finalnegoform = false;
    this.subnegofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onsubnegofixed()
  {
    this.finalnegoform = true;
    this.subnegofixed = true;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svFixed = false;
    this.svform = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onleadclosed(){
    this.leadclosedform = true;
    this.leadclosed = true;
    this.finalnegoform = false;
    this.subnegofixed = false;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svFixed = false;
    this.svform = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  removeValue(list, value) {
    return list.replace(new RegExp(",?" + value + ",?"), function(match) {
        var first_comma = match.charAt(0) === ',',
            second_comma;
  
        if (first_comma &&
            (second_comma = match.charAt(match.length - 1) === ',')) {
          return ',';
        }
        return '';
      });
  };

  selectsuggested(i,id,propname) {
    if($('#negocheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " Selected the "+propname+" for Finalnegotiation.";
      // $('#negotextarearemarks').html(' Fixed the Final Negotiation for '+propname);
      // var param = {
      //   leadid: this.leadid,
      //   suggestproperties: this.suggestchecked
      // }
      // this._phpService.addnegoselected(param).subscribe((success) => {
      //   this.status = success.status;
      //   this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
      //     this.selectedpropertylists = selectsuggested['selectedlists'];
      //     this.selectedlists = selectsuggested;
      //     // Joining the object values as comma seperated when add the property for the history storing
      //     this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
      //     // Joining the object values as comma seperated when add the property for the history storing
      //   });
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: 'Final Negotiation',
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the Finalnegotiation scheduled lists.";
        this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          // $('#negotextarearemarks').html("");
            this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectednegolists'];
            this.selectedlists = selectsuggested;
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
    
  }

  selectsuggestedsub(i,id,propname) {
    if($('#subnegocheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " Selected the "+propname+" for Finalnegotiation.";
      // $('#negotextarearemarks').html(' Fixed the Final Negotiation for '+propname);
      // var param = {
      //   leadid: this.leadid,
      //   suggestproperties: this.suggestchecked
      // }
      // this._phpService.addnegoselected(param).subscribe((success) => {
      //   this.status = success.status;
      //   this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
      //     this.selectedpropertylists = selectsuggested['selectedlists'];
      //     this.selectedlists = selectsuggested;
      //     // Joining the object values as comma seperated when add the property for the history storing
      //     this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
      //     // Joining the object values as comma seperated when add the property for the history storing
      //   });
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: 'Final Negotiation',
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the Finalnegotiation scheduled lists.";
        this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
          // $('#negotextarearemarks').html("");
            this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectednegolists'];
            this.selectedlists = selectsuggested;
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
    
  }

  refixsuggested(i,id,propname) {
    if($('#suggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programmingrefix']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
    //   var param = {
    //   leadid: this.leadid,
    //   suggestproperties: this.suggestchecked
    //   }
    //   this.autoremarks = " added the "+propname+" for Finalnegotiation while refix the meeting.";
    // this._phpService.addnegoselected(param).subscribe((success) => {
    //   this.status = success.status;
    //   if(this.status == "True"){
    //       this._phpService
    //   .negoselectproperties(this.leadid)
    //   .subscribe(selectsuggested => {
    //     this.selectedpropertylists = selectsuggested['selectednegolists'];
    //     this.selectedlists = selectsuggested;
    //   });
    //   }
    // }, (err) => {
    //   console.log("Failed to Update");
    // })
    }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: 'Final Negotiation',
        execid: this.userid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the Finalnegotiation scheduled lists while refixing the meeting.";
      this._phpService.removeselectedproperties(param2).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
            this._phpService
        .negoselectproperties(this.leadid)
        .subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectednegolists'];
          this.selectedlists = selectsuggested;
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })
    }
  }


  negofixing(){

      var nextdate = $('#negonextactiondate').val();
      var nexttime = $('#negonextactiontime').val();
      var textarearemarks = $('#negotextarearemarks').val();
      var assignid = this.executeid;
      var dateformatchange = new Date(nextdate).toDateString();

      

    // USV DONE with NEGOTIATION Fixing

    if( $('#sectionselector').val() == "USV"){

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }

      if ($('#USVvisiteddate').val() == "") {
        $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisiteddate').removeAttr("style");
      }
  
      if ($('#USVvisitedtime').val() == "") {
        $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisitedtime').removeAttr("style");
      }
  
      // if ($('#usvfinalremarks').val() == "") {
      //   $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#usvfinalremarks').removeAttr("style");
      // }

      if ($('#negonextactiondate').val() == "") {
        $('#negonextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiondate').removeAttr("style");
      }
      if ($('#negonextactiontime').val() == "") {
        $('#negonextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#negotextarearemarks').val() == "") {
        $('#negotextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the final negotiation');
        return false;
      }
      else {
        $('#negotextarearemarks').removeAttr("style");
      }

      var visitedparam = {
        leadid: this.leadid,
        propid: this.suggestchecked,
        execid: this.userid,
        visitupdate: $('#visitupdate').val(),
        remarks: $('#propertyremarks').val(),
        stage: "USV",
      }

      this.filterLoader = true;
      var visiteddate = $('#USVvisiteddate').val();
      var visitedtime = $('#USVvisitedtime').val();
      // var usvfinalremarks = $('#usvfinalremarks').val();
      var nextactiondate = $('#negonextactiondate').val();
      var nextactiontime = $('#negonextactiontime').val();
      var usvfinalremarks = "USV Done";

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked,
      }

      this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          this._phpService.addnegoselected(param).subscribe((success) => {
            this.status = success.status;
            this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectednegolists'];
              this.selectedlists = selectsuggested;
              // Joining the object values as comma seperated when add the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when add the property for the history storing
              this.autoremarks = " Changed the status to Final Negotiation after Successfully completed USV";
              var leadusvdoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "USV",
                stagestatus: '3',
                textarearemarks: usvfinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: this.suggestchecked
              }
              this._phpService.addleadhistory(leadusvdoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  this.autoremarks = "Scheduled the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadnegofixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "Final Negotiation",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks,
                    property: this.suggestchecked
                  }
                  this._phpService.addleadhistory(leadnegofixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      this.filterLoader = false;
                    swal({
                      title: 'Negotiation Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                    });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });
    
            });
          }, (err) => {
            console.log("Failed to Update");
          })
        }
      }, (err) => {
          console.log("Failed to Update");
        })

      
    }

    // USV DONE with NEGOTIATION Fixing

    // RSV DONE with NEGOTIATION Fixing

    else if( $('#sectionselector').val() == "RSV"){

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#RSVvisiteddate').val() == "") {
        $('#RSVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisiteddate').removeAttr("style");
      }
  
      if ($('#RSVvisitedtime').val() == "") {
        $('#RSVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisitedtime').removeAttr("style");
      }
  
      // if ($('#rsvfinalremarks').val() == "") {
      //   $('#rsvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#rsvfinalremarks').removeAttr("style");
      // }

      if ($('#negonextactiondate').val() == "") {
        $('#negonextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiondate').removeAttr("style");
      }
      if ($('#negonextactiontime').val() == "") {
        $('#negonextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#negotextarearemarks').val() == "") {
        $('#negotextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the final negotiation');
        return false;
      }
      else {
        $('#negotextarearemarks').removeAttr("style");
      }

      var visitedparam = {
        leadid: this.leadid,
        propid: this.suggestchecked,
        execid: this.userid,
        visitupdate: $('#visitupdate').val(),
        remarks: $('#propertyremarks').val(),
        stage: "RSV",
      }

      this.filterLoader = true;
      var visiteddate = $('#RSVvisiteddate').val();
      var visitedtime = $('#RSVvisitedtime').val();
      // var rsvfinalremarks = $('#rsvfinalremarks').val();
      var nextactiondate = $('#negonextactiondate').val();
      var nextactiontime = $('#negonextactiontime').val();
      var rsvfinalremarks = "RSV Finished";

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }

      this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True"){
          this._phpService.addnegoselected(param).subscribe((success) => {
            this.status = success.status;
            this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectednegolists'];
              this.selectedlists = selectsuggested;
              // Joining the object values as comma seperated when add the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when add the property for the history storing
              this.autoremarks = " Changed the status to Final Negotiation after Successfully completed RSV";
              var leadrsvdoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "RSV",
                stagestatus: '3',
                textarearemarks: rsvfinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: this.suggestchecked
              }
              this._phpService.addleadhistory(leadrsvdoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  this.autoremarks = "Scheduled the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadnegofixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "Final Negotiation",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks,
                    property: this.suggestchecked
                  }
                  this._phpService.addleadhistory(leadnegofixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      this.filterLoader = false;
                    swal({
                      title: 'Negotiation Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                    });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });
            });
          }, (err) => {
            console.log("Failed to Update");
          })
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }

    // RSV DONE with NEGOTIATION Fixing

    // DIRECT Negotiation Fixing

    else if( $('#sectionselector').val() == "Final Negotiation"){
      if ($('#negonextactiondate').val() == "") {
        $('#negonextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiondate').removeAttr("style");
      }
      if ($('#negonextactiontime').val() == "") {
        $('#negonextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#negotextarearemarks').val() == "") {
        $('#negotextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the final negotiation');
        return false;
      }
      else {
        $('#negotextarearemarks').removeAttr("style");
      }

      this.filterLoader = true;
      var nextdate = $('#negonextactiondate').val();
      var nexttime = $('#negonextactiontime').val();
      var textarearemarks = $('#negotextarearemarks').val();
      this.autoremarks = " Scheduled the Finalnegotiation";

      var param = {
        leadid: this.leadid,
        nextdate: nextdate,
        nexttime: nexttime,
        suggestproperties: this.suggestchecked
      }
      this._phpService.addnegoselected(param).subscribe((success) => {
        this.status = success.status;
        this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectednegolists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when add the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when add the property for the history storing

          this.autoremarks = "Scheduled the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
          var leadnegofixparam = {
            leadid: this.leadid,
            closedate: nextdate,
            closetime: nexttime,
            leadstage: "Final Negotiation",
            stagestatus: '1',
            textarearemarks: textarearemarks,
            userid: this.userid,
            assignid: this.executeid,
            autoremarks: this.autoremarks,
            property: this.suggestchecked
          }
          this._phpService.addleadhistory(leadnegofixparam).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
              this.filterLoader = false;
            swal({
              title: 'Final Negotiation Fixed Succefully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                });
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });
        });
      }, (err) => {
        console.log("Failed to Update");
      })

    } else{
      if ($('#negonextactiondate').val() == "") {
        $('#negonextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiondate').removeAttr("style");
      }
      if ($('#negonextactiontime').val() == "") {
        $('#negonextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negonextactiontime').removeAttr("style");
      }
      // if (this.suggestchecked == "") {
      //   swal({
      //     title: 'Property Not Selected',
      //     text: 'Please select atleast one property for the RSV',
      //     type: 'error',
      //     confirmButtonText: 'ok'
      //   })
      //   return false;
      // }
      // else {
        
      // }
      if ($('#negotextarearemarks').val() == "") {
        $('#negotextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the final negotiation');
        return false;
      }
      else {
        $('#negotextarearemarks').removeAttr("style");
      }

      this.filterLoader = true;
      var nextdate = $('#negonextactiondate').val();
      var nexttime = $('#negonextactiontime').val();
      var textarearemarks = $('#negotextarearemarks').val();
      this.autoremarks = " Scheduled the Finalnegotiation";

      var param = {
        leadid: this.leadid,
        nextdate: nextdate,
        nexttime: nexttime,
        suggestproperties: this.suggestchecked
      }
      this._phpService.addnegoselected(param).subscribe((success) => {
        this.status = success.status;
        this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectednegolists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when add the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when add the property for the history storing

          this.autoremarks = "Scheduled the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
          var leadnegofixparam = {
            leadid: this.leadid,
            closedate: nextdate,
            closetime: nexttime,
            leadstage: "Final Negotiation",
            stagestatus: '1',
            textarearemarks: textarearemarks,
            userid: this.userid,
            assignid: this.executeid,
            autoremarks: this.autoremarks,
            property: this.suggestchecked
          }
          this._phpService.addleadhistory(leadnegofixparam).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
              this.filterLoader = false;
            swal({
              title: 'Final Negotiation Fixed Succefully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                });
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });
        });
      }, (err) => {
        console.log("Failed to Update");
      })
    }

    // DIRECT Negotiation Fixing

  }

  negorefixing(){

    // if(this.suggestchecked == ""){
    //   swal({
    //     title: 'Property Not Selected',
    //     text: 'Please select atleast one property for the Sitevisit',
    //     type: 'error',
    //     confirmButtonText: 'ok'
    //   })
    //   return false;
    // }else{
    // }

    if ($('#refixdate').val() == "") {
      $('#refixdate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixdate').removeAttr("style");
    }
    if ($('#refixtime').val() == "") {
      $('#refixtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixtime').removeAttr("style");
    }
    if ($('#refixtextarearemarks').val() == "") {
      $('#refixtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the Final Negotiation');
      return false;
    }
    else {
      $('#refixtextarearemarks').removeAttr("style");
    }
    this.filterLoader = true;
      var nextdate = $('#refixdate').val();
      var nexttime = $('#refixtime').val();
      var textarearemarks = $('#refixtextarearemarks').val();
      var dateformatchange = new Date(nextdate).toDateString();

      var param = {
        leadid: this.leadid,
        nextdate: nextdate,
        nexttime: nexttime,
        suggestproperties: this.suggestchecked
      }
      this._phpService.addnegoselectedrefix(param).subscribe((success) => {
        this.status = success.status;
        this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectednegolists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when add the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when add the property for the history storing
          this.autoremarks = " ReFixed the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
          var leadnegorefixparam = {
            leadid: this.leadid,
            closedate: nextdate,
            closetime: nexttime,
            leadstage: "Final Negotiation",
            stagestatus: '2',
            textarearemarks: textarearemarks,
            userid: this.userid,
            assignid: this.executeid,
            autoremarks: this.autoremarks,
            property: this.suggestchecked
          }
          this._phpService.addleadhistory(leadnegorefixparam).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
              this.filterLoader = false;
            swal({
              title: 'Refixed Final Negotiation Succefully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                        this.router.navigate([currentUrl]);
                    });
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });

        });
      }, (err) => {
        console.log("Failed to Update");
      })

      

      

  }

  negodonewithfixing(){
    if ($('#visitupdate').val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please select any actions',
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
      $('#visitupdate').removeAttr("style");
    }
    if ($('#propertyremarks').val() == "") {
      $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks').removeAttr("style");
    }
    if ($('#negovisiteddate').val() == "") {
      $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#negovisiteddate').removeAttr("style");
    }

    if ($('#negovisitedtime').val() == "") {
      $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#negovisitedtime').removeAttr("style");
    }

    // if ($('#negofinalremarks').val() == "") {
    //   $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
    //   return false;
    // }
    // else {
    //   $('#negofinalremarks').removeAttr("style");
    // }

    if ($('#subnegonextactiondate').val() == "") {
      $('#subnegonextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subnegonextactiondate').removeAttr("style");
    }
    if ($('#subnegonextactiontime').val() == "") {
      $('#subnegonextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subnegonextactiontime').removeAttr("style");
    }
    // if (this.suggestchecked == "") {
    //   swal({
    //     title: 'Property Not Selected',
    //     text: 'Please select atleast one property for the RSV',
    //     type: 'error',
    //     confirmButtonText: 'ok'
    //   })
    //   return false;
    // }
    // else {
      
    // }
    if ($('#subnegotextarearemarks').val() == "") {
      $('#subnegotextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the final negotiation');
      return false;
    }
    else {
      $('#subnegotextarearemarks').removeAttr("style");
    }

    var visitedparam = {
      leadid: this.leadid,
      propid: this.suggestchecked,
      execid: this.userid,
      visitupdate: $('#visitupdate').val(),
      remarks: $('#propertyremarks').val(),
      stage: "Final Negotiation",
    }

    this.filterLoader = true;
    var nextactiondate = $('#subnegonextactiondate').val();
    var nextactiontime = $('#subnegonextactiontime').val();

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }

      this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True"){
          this._phpService.addnegoselected(param).subscribe((success) => {
            this.status = success.status;
            this._phpService.negoselectproperties(this.leadid).subscribe(selectsuggested => {
              this.selectedpropertylists = selectsuggested['selectednegolists'];
              this.selectedlists = selectsuggested;
              // Joining the object values as comma seperated when add the property for the history storing
              this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
              // Joining the object values as comma seperated when add the property for the history storing
              var visiteddate = $('#negovisiteddate').val();
              var visitedtime = $('#negovisitedtime').val();
              // var negofinalremarks = $('#negofinalremarks').val();
              var negofinalremarks = "Final Negotiation Finished";
              this.autoremarks = " Changed the status again to Final Negotiation after Successfully completed one negotiation Meeting";
              var leadnegodoneparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "Final Negotiation",
                stagestatus: '3',
                textarearemarks: negofinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: this.suggestchecked
              }
              this._phpService.addleadhistory(leadnegodoneparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  var nextdate = $('#subnegonextactiondate').val();
                  var nexttime = $('#subnegonextactiontime').val();
                  var textarearemarks = $('#subnegotextarearemarks').val();
                  var dateformatchange = new Date(nextdate).toDateString();
                  this.autoremarks = "Again Scheduled the Final Negotiation for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
                  var leadnegofixparam = {
                    leadid: this.leadid,
                    closedate: nextdate,
                    closetime: nexttime,
                    leadstage: "Final Negotiation",
                    stagestatus: '1',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    autoremarks: this.autoremarks,
                    property: this.suggestchecked
                  }
                  this._phpService.addleadhistory(leadnegofixparam).subscribe((success)=> {
                    this.status = success.status;
                    if(this.status == "True"){
                      this.filterLoader = false;
                    swal({
                      title: 'Negotiation Fixed Succefully',
                      type: "success",
                      confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                        });
                        }
                      });
                    }
                  },(err) => {
                    console.log("Failed to Update");
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });
    
            });
          }, (err) => {
            console.log("Failed to Update");
          })
        }
      }, (err) => {
        console.log("Failed to Update");
      })

      

      var param = {
        leadid: this.leadid,
        nextdate: nextactiondate,
        nexttime: nextactiontime,
        suggestproperties: this.suggestchecked
      }
  
      this._phpService.addnegoselected(param).subscribe((success) => {
        this.status = success.status;
      }, (err) => {
        console.log("Failed to Update");
      })


  }

  visitclick(i,propertyname)
  {
    // this.intrestbtn = true;
    // $('#visitupdate'+i).val("4");
    // $('#propertyremarks'+i).html('Negotiation successfully finished with the '+propertyname+'.');
    $('#visitupdate').val("4");
    $('.nextactionmaindiv').removeAttr('style');
    $('.visitupdatebtn').attr("style", "display:none;");
  }

  cancelclick(i,propertyname)
  {
    // this.intrestbtn = false;
    // $('#visitupdate'+i).val("2");
    // $('#propertyremarks'+i).html('Cancelled the Negotiation Meeting for '+propertyname);
    $('#visitupdate').val("2");
    $('.visitupdatebtn').removeAttr('style');
    $('.nextactionmaindiv').attr("style", "display:none;");
    this.followdownform = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.leadclosedform = false;
  }

  updatepropertyvisit(propertyid,propertyname,i)
  {
    if($('#visitupdate').val() == "1"){
      this.visitupdate = "Negotiation Done";
    }else{
      this.visitupdate = "Negotiation Cancelled";
    }
    this.propertyremarks = $('#propertyremarks'+i).val();

    if ($('#visitupdate').val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please Confirm Property Revisited or Not',
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
    }

    if ($('#propertyremarks').val() == "") {
      $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks').removeAttr("style");
    }
    this.filterLoader = true;
    var param = {
      leadid: this.leadid,
      propid: propertyid,
      execid: this.userid,
      visitupdate: $('#visitupdate').val(),
      remarks: $('#propertyremarks').val(),
      stage: "Final Negotiation",
    }
    // this.autoremarks = " Marked the "+ propertyname +" as "+this.visitupdate+". Here is the negotiation details - "+this.propertyremarks;
    this._phpService.addpropertyvisitupdate(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        this.filterLoader = false;
        swal({
          title: 'Data Updated Successfully',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            this.loadimportantapi();
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
  }

}
