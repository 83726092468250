import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute,Router} from '@angular/router';
import { mandateservice } from '../../mandate.service';
import { phpservice } from '../../php.service';
import { AuthService } from '../../auth-service';
import { UniquePipe } from '../../pipe-filter';
import { Enquiry ,Follow, Face, Usv, Finalnego, closure, leadforward} from '../customers/customer';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandateleaddetails',
  templateUrl: './mandateleaddetails.component.html',
  styleUrls: ['./mandateleaddetails.component.css']
})
export class MandateleaddetailsComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  @ViewChild('cancel')cancel:ElementRef;
  leadid = this.route.snapshot.params['id'];
  face_toshow:boolean;
  usv_showHide:boolean;
  sv_showHide:boolean;
  usv_toshow:boolean;
  sv_toshow:boolean;
  rsv_showHide:boolean;
  rsv_toshow:boolean;
  showHide:boolean;
  commentshow:boolean;
  modelaadd = new Follow();
  facemodel = new Face();
  addusvmodel = new Usv();
  svmodel = new Usv();
  negmodel = new Finalnego();
  addclose = new closure();
  addpay = new closure();
  usvmodelone : any;
  resposeData : any;
  countries : any[];
  sections : any;
  projects : any;
  activeinputs : any;
  locality: any;
  newpropertybox = false;
  adminview:boolean;
  followupform = false;
  junkform = false;
  commonformbtn = false;
  followupformbtn = false;
  junkformbtn = false;

  f2fform = false;
  usvform = false;
  svform = false;
  rsvform = false;
  leadclosedform = false;
  directleadclose = false;

  followdownform = false;
  followupdown = false;
  followform = false;
  followup = false;
  svFixed = false;
  svreFix = false;
  svDone = false;
  junk = false;
  hideafterfixed = true;
  hidebeforefixed = false;
  notintrestedcatogs: any;
  notintrestsection = false;
  intrestsection = false;
  svFixedsubbtn = false;
  leadclosed = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _mandateService:mandateservice,
    private _phpService:phpservice,
    public authService: AuthService,
    private _unique:UniquePipe,
    private _location: Location
  ) {
    // this.ngOnInit();
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    
    this.sv_showHide = true;
    this.sv_toshow = false;
    this.usv_showHide = true;
    this.usv_toshow = false;
    this.rsv_showHide = true;
    this.rsv_toshow = true;
    this.face_toshow = false;
    this.showHide = true;
    this.commentshow = false; 
    $('.ui.dropdown').dropdown();
    this.activeinputs = false;
  }
  private isCountdownInitialized: boolean;
  backClicked() {
    this._location.back();
}

checkbox: boolean;
suggestchecked: any;


  ngOnDestroy(){
    $('.modal').remove();
  }
  followups_date: any;
  payment_name: any;

  ngOnInit() {
    this.getcustomerview();
    this.getcustomerviewfollow();
    this.getsize();
    this.getprprtytype();
    this.getlocalitylist();
    this.loadhistory();
    this._mandateService
      .getactiveleadsstatus(this.leadid)
      .subscribe(stagestatus => {
        this.activestagestatus = stagestatus['activeleadsstatus'];
        if((this.activestagestatus[0].stage == "SV" || this.activestagestatus[0].stage == "Refix SV") && this.activestagestatus[0].stagestatus == "1"){
          this.hideafterfixed = false;
          this.svFixed = false;
          this.hidebeforefixed = true;
          this.svreFix = true;
        }else if((this.activestagestatus[0].stage == "SV" || this.activestagestatus[0].stage == "Refix SV") && this.activestagestatus[0].stagestatus == "2"){
          this.hideafterfixed = false;
          this.hidebeforefixed = true;
          this.svDone = false;
          this.svFixed = false;
          this.svreFix = true;
        }else if((this.activestagestatus[0].stage == "SV" || this.activestagestatus[0].stage == "Refix SV") && this.activestagestatus[0].stagestatus == "3"){
          this.hideafterfixed = true;
          this.hidebeforefixed = false;
          this.svDone = false;
          this.svFixed = true;
        }else{
          this.hideafterfixed = true;
          this.svFixed = true;
        }


      });
    this._phpService
    .getnotintrestsections()
    .subscribe(notintrested => {
      this.notintrestedcatogs = notintrested['NotintrestCateg'];
    });
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } else if (localStorage.getItem('Role') == '1')
    {
      this.adminview = true;
    }else{
      this.adminview = false;
    }
    this.suggestchecked = "";
  }

  selectsuggested(id) {
    var checkid = $("input[name='programming']:checked").map(function () {
      return this.value;
    }).get().join(',');
    this.suggestchecked = checkid;
  }

  ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      this.scriptfunctions();
    }
  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.visitedcalendardate').calendar({
      type: 'date',
      minDate: this.priorDatebefore,
      maxDate: this.date,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
  }

  onsvFixed(){
    this.svFixed = true;
    this.svform = true;
    this.svreFix = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.leadclosedform = false;
    this.directleadclose = false;
  }

  onsvreFix(){
    this.svreFix = true;
    this.svFixed = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.leadclosedform = false;
    this.directleadclose = false;
  }

  onsvDone(){
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = true;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.leadclosedform = false;
    this.directleadclose = false;
    $('#sectionselector').val('SV');
  }

  onfollowup()
  {
    this.followform = true;
    this.followup = true;
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.leadclosedform = false;
    this.directleadclose = false;
    $('#sectionselector').val("");
  }

  onjunk(){
    this.junkform = true;
    this.junk = true;
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = false;
    this.followform = false;
    this.followup = false;
    this.leadclosedform = false;
    this.directleadclose = false;
  }
  
  followupdownbtn(){
    this.followdownform = true;
    this.followupdown = true;
    this.svFixedsubbtn = false;
    this.svform = false;
    this.leadclosedform = false;
    this.leadclosed = false;
  }

  onsvFixsubbtn(){
    this.svFixedsubbtn = true;
    this.svform = true;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  directleadclosed(){
    this.leadclosedform = true;
    this.directleadclose = true;
    this.followform = false;
    this.followup = false;
    this.svFixed = false;
    this.svreFix = false;
    this.svDone = false;
    this.junkform = false;
    this.junk = false;
    this.followdownform = false;
    this.followupdown = false;
    $('#sectionselector').val("");
  }

  onleadclosed(){
    this.leadclosedform = true;
    this.leadclosed = true;
    this.svform = false;
    this.svFixedsubbtn = false;
    this.svFixed = false;
    this.junkform = false;
    this.junk = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  intrestclick(i)
  {
    this.intrestsection = true;
    this.notintrestsection = false;
  }

  notintrestclick(i)
  {
    this.notintrestsection = true;
    this.intrestsection = false;
    this.followdownform = false;
    this.svFixedsubbtn = false;
    this.leadclosedform = false;
  }

  addusv(){
    this.usvs.push({});
  }

usvs=[];
rsvs=[];
rsvmodel: any={};
closedmodel: any={};
executeid: any;
csid: any;
assignedrm: any;
assignedcs: any;
// CUSTOMER-VIEW-FROM-ENQUIRY
getcustomerview(){

  this._mandateService
  .getcustomeredit(this.id)
  .subscribe(cust => {
     this.show_cnt = cust[0];
     if(this.show_cnt['customer_phase'] == null){
      this.show_cnt['customer_phase'] = 'Fresh lead';
    }else{
    }
  })

  this._mandateService
  .getassignedrm(this.id)
  .subscribe(cust => {
     this.assignedrm = cust[0];
     this.executeid = cust[0].executiveid;
  })

  // this._phpService
  // .getassignedcs(this.id)
  // .subscribe(cust => {
  //    this.assignedcs = cust[0];
  //    this.csid = cust[0].executiveid;
  // })
  
};

showinput()
{
this.newpropertybox = true;
}

getlocalitylist(){
  this._phpService.localitylist().subscribe(localities =>{
    this.locality = localities;
  })
}

getprprtytype(){
  this._phpService
  .getpropertytypelist()
  .subscribe(proprttype => {
    this.typelist = proprttype;
  })
}

  // CUSTOMER-VIEW-INSIDE-MODAL
  followmodel:any={};
  // MODAL-VIEW-FROM-ENQUIRY
  id = this.route.snapshot.params['id'];


  // ONLY-CUSTOMER-ViEW-FROM-DB-based-ON-ID
  show_cnt: any;
  executlist: any;
  sources:any;
  followmodels: any;
  facemodels: any;
  usvmodels:any;
  svmodels:any;
  rsvmodels:any;
  finalmodels:any;
  closedmodels:any;
  typelist: any;
  propetytimeline: any;
  propertiespurpose: any;
  properties: any;
  propertysize: any;
  usvdates: any;
  current: number = 0;
  items: Array<any>;
  activestagestatus: any;

  getsize(){
    this._phpService.getbhk().subscribe(propsize => {this.propertysize = propsize;})
  }

  // FOLLOWUP-ACCORDION-SECTION-ONLY-VIEW & UPDATE
  
   getactive(id){
    var idpk = id;
    this._phpService
    .getfollowupcustomeredit(idpk)
    .subscribe(followcstmr => {
    this.followmodels = followcstmr;
      })
    };

  // FOLLOWUP-ACCORDION-SECTION-ONLY-VIEW & UPDATE
   
  getcustomerviewfollow(){
    // var id = this.route.snapshot.params['id'];
    this._mandateService
    .getcustomeredit(this.id)
    .subscribe(cust => {
      //  this.modelaadd = cust[0];
       this.facemodel = cust[0];
      //  this.usvmodelone = cust[0];
      //  this.addusvmodel = cust[0];
      //  this.svmodel= cust[0];
      //  this.negmodel = cust[0];
      //  this.addclose= cust[0];
      //  this.addpay=cust[0];
      //  this.closedmodel=cust[0];
    })
    
  };
  
    // CUSTOMER-VIEW-FROM-ENQUIRY-ENDS
   // UPDATE-CUSTOMER-DETAILS-IN-DB

   updateCustomer(){
    if ($('#customer_name').val() === '') {
      $('#customer_name').focus().css('border-color', 'red').attr('placeholder', 'Please Enter Name');
      return false;
    } else {
      var nameFilter = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
      if (nameFilter.test($('#customer_name').val())) {
        $('#customer_name').removeAttr('style');
      } else {
        $('#customer_name').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid name').val('');
        return false;
      }
    }
   

      if($('#customer_mail').val()=="")
      {
        $('#customer_mail').focus().css("border-color","red").attr('placeholder','Please Enter Name');
        return false;
      }
      else {
        let enameFilter = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (enameFilter.test($('#customer_mail').val())) {
          $('#customer_mail').removeAttr('style');
        } else {
          $('#customer_mail').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid email').val('');
          return false;
        }
      }
      if($('#customer_budget').val()=="")
      {
        $('#customer_budget').focus().css("border-color","red").attr('placeholder','Please Enter the Budget');
        return false;
      }
      else{    
            $('#customer_budget').removeAttr("style"); 
          }
      if($('#customer_location').val()=="")
          {
            $('.customerlocation').focus().css("border-color","red").attr('placeholder','Please Select Location');
            return false;
          }
          else{    
                $('.customerlocation').removeAttr("style"); 
              }
              
              
              if($('#customer_proptype').val()=="")
              {
                $('#customer_proptype').focus().css("border-color","red").attr('placeholder','Please Select Property Type');
                return false;
              }
              else{    
                    $('#customer_proptype').removeAttr("style"); 
                  }
       
      if($('#customer_size').val()=="")
      {
        $('#customer_size').focus().css("border-color","red").attr('placeholder','Please Select BHK');
        return false;
      }
      else{    
            $('#customer_size').removeAttr("style"); 
          }
              // if($('#property_select').val()=="")
              // {
              //   swal({
              //           title: 'Please Select atleat 1 property',
              //           type: 'error',
              //           confirmButtonText: 'ok'
              //           })
              //   return false;
              // }
              // else{    
              //       $('#property_select').removeAttr("style"); 
              //     }
              //     if($('#customer_address').val()=="")
              //     {
              //       $('#customer_address').focus().css("border-color","red").attr('placeholder','Please Enter the Address');
              //       return false;
              //     }
              //     else{    
              //           $('#customer_address').removeAttr("style"); 
              //         }
                      var customername = $('#customer_name').val();
                      var customermail = $('#customer_mail').val();
                      var customerbudget = $('#customer_budget').val();
                      var customerlocation = $('#customer_location').val();
                      var customerproptype = $('#customer_proptype').val();
                      var customersize = $('#customer_size').val();
                      var propertyselect = $('#property_select').val();
                      var customeraddress = $('#customer_address').val();
                      // var propertiesstring = propertyselect.join();
                      
                      var param = {
                        name: customername,
                        mail: customermail,
                        budget: customerbudget,
                        location: customerlocation,
                        proptype: customerproptype,
                        size: customersize,
                        property: propertyselect,
                        address: customeraddress,
                        leadid: this.id,
                      }
                      this._phpService.datashortupdate(param).subscribe((success)=> {
                        this.status = success.status;
                        this.resposeData = success;
                        if(this.status == "True"){
                        swal({
                          title: 'Updated Succefully',
                          type: 'success',
                          confirmButtonText: 'ok'
                          });
                        }
                      },(err) => {
                        console.log("Failed to Update");
                      })
 }
  

  actionChange(val){
    $('#sectionselector').val("");
    if(val == "Follow Up"){
      this.followform = true;
      this.followupform = true;
      this.followupformbtn = true;
      this.f2fform = false;
      this.usvform = false;
      this.svform = false;
      this.rsvform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
      this.commonformbtn = false;
      
    }else if(val == "Face to Face Meeting"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.f2fform = true;
      this.usvform = false;
      this.svform = false;
      this.rsvform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
    } else if(val == "USV"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.usvform = true;
      this.f2fform = false;
      this.svform = false;
      this.rsvform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
    } else if(val == "SV"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.svform = true;
      this.usvform = false;
      this.f2fform = false;
      this.rsvform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
    }else if(val == "RSV"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = true;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
    }else if(val == "Final Negotiation"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
    }else if(val == "Lead Closed"){
      this.leadclosedform = true;
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.junkform = false;
      this.junkformbtn = false;
    }
    else if(val == "Move to Junk"){
      this.junkform = true;
      this.junkformbtn = true;
      this.f2fform = false;
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.commonformbtn = false;
      this.leadclosedform = false;
    }
    else{
      this.followupform = false;
      this.junkform = false;
      this.commonformbtn = true;
      this.followupformbtn = false;
      this.junkformbtn = false;
      
    }
    
  }
  status:any;

  openjourney() {
    document.getElementById("myNav").style.width = "100%";
  }

  closejourney() {
    // @ts-ignore
    document.getElementById("myNav").style.width = "0%";
  }

 


addjunkdata(){
  if($('#junksection').val()==null)
          {
            swal({
              title: 'Please Select one Reason for Junk',
              type: 'error',
              confirmButtonText: 'ok'
              })
            return false;
          }
    else{    
      $('#junksection').removeAttr("style"); 
          }
  if($('#nextactiondate').val()=="")
          {
            $('#nextactiondate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
            return false;
          }
    else{    
      $('#nextactiondate').removeAttr("style"); 
          }
          if($('#textarearemarks').val()=="")
          {
            $('#textarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
            return false;
          }
    else{    
      $('#textarearemarks').removeAttr("style"); 
          }
    var junksection = $('#junksection').val();
    var nextdate = $('#nextactiondate').val();
    var leadstage = $('#customer_phase4').val();
    var textarearemarks = $('#textarearemarks').val();
    var leadid = this.id;
    var userid = localStorage.getItem('UserId');

    this._phpService.addjunkhistory(leadid,nextdate,leadstage,junksection,textarearemarks,userid).subscribe((success)=> {
      this.status = success.status;
      if(this.status == "True"){
        $('#nextactiondate').val('');
        $('#customer_phase4').val('');
        $('#textarearemarks').val('');
      swal({
        title: 'Updated Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.ngOnInit();
        this.router.navigate(['/todaysmeetings']);
      }
    },(err) => {
      console.log("Failed to Update");
    })
};

  // PREVIOUS-DATE-FETCHING-FOLLOWUP
  getfollowdatelist(){
    var id = "";
    this._phpService
    .getfollowuplist(id)
    .subscribe(followdate =>{
      this.followups_date = followdate;
    })
  }

  // PREVIOUS-DATE-FETCHING-FOLLOWUP
  leadtrack: any;
  loadhistory(){
    this._mandateService
    .gethistory(this.id)
    .subscribe(history => {
      this.leadtrack = history['Leadhistory'];
      // console.log("Lead Track - ", this.leadtrack);
    })
  }

  //  ASSIGN LEAD SECTION
leadforwards= new leadforward();
executives: any;
csleadassign = false;
rmleadassign = false;
getexecutives(event)
{
  const id = event.target.options[event.target.options.selectedIndex].value;
  if(id == '50002'){
    this.rmleadassign = true;
    this.csleadassign = false;
  }else if(id == '50004'){
    this.rmleadassign = false;
    this.csleadassign = true;
  }
  this._phpService.getexecutivesbasedid(id).subscribe(execute => {this.executives = execute;})
}

getexecutivesnames(event)
{
  const name = event.target.options[event.target.options.selectedIndex].text;
  this.leadforwards.telecallername = name;
}

assigntorm(){
  
  if($('#executives').val()== "")
        {
          swal({
            title: 'Please Select One Executive!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('.executives').removeAttr("style");
        }
        this.leadforwards.assignedleads = this.id;
        var param = this.leadforwards;
        this._phpService.leadassignrm(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
        swal({
        title: 'Assigned Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.cancel.nativeElement.click();
        $('.ui.dropdown').dropdown('clear');
        this.ngOnInit();
  }else{
    swal({
    title: 'Authentication Failed!',
    text: 'Please try agin',
    type: 'error',
    confirmButtonText: 'ok'
  })
}
}, (err) => {
console.log("Connection Failed")
});
}

assigntocs()
{

  if($('#executives').val()== "")
        {
          swal({
            title: 'Please Select One Executive!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('.executives').removeAttr("style");
        }
        this.leadforwards.assignedleads = this.id;
        var param = this.leadforwards;
        this._phpService.leadassigncs(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
        swal({
        title: 'Assigned Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.cancel.nativeElement.click();
        $('.ui.dropdown').dropdown('clear');
        this.ngOnInit();
  }else{
    swal({
    title: 'Authentication Failed!',
    text: 'Please try agin',
    type: 'error',
    confirmButtonText: 'ok'
  })
}
}, (err) => {
console.log("Connection Failed")
});
}

  //  ASSIGN LEAD SECTION

  svfixing() {
    // Common Elements 
    var nextdate = $('#svnextactiondate').val();
    var nexttime = $('#svnextactiontime').val();
    var textarearemarks = $('#svtextarearemarks').val();
    var userid = localStorage.getItem('UserId');
    var leadid = this.leadid;
    var svleadstage = "SV";
    var stagestatus = "1";
    var assignid = this.executeid;

    var leadsvfixparam = {
      leadid: this.leadid,
      closedate: nextdate,
      closetime: nexttime,
      leadstage: "SV",
      stagestatus: '1',
      textarearemarks: textarearemarks,
      userid: userid,
      assignid: this.executeid,
    }

    var param = {
      leadid: this.leadid
    }
    
    // Common Elements 

        // DIRECT SV Fixing
    
    if( $('#sectionselector').val() == "")
    {
      if ($('#svnextactiondate').val() == "") {
        $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiondate').removeAttr("style");
      }
      if ($('#svnextactiontime').val() == "") {
        $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#svnextactiontime').removeAttr("style");
      }
        
        if ($('#svtextarearemarks').val() == "") {
          $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
          return false;
        }
        else {
          $('#svtextarearemarks').removeAttr("style");
        }

        this._mandateService.addleadhistory(leadid,nextdate,nexttime,svleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            $('#subsvnextactiondate').val('');
            $('#subsvnextactiontime').val('');
            $('#customer_phase4').val('');
            $('#subsvtextarearemarks').val('');
          swal({
            title: 'SV Fixed Succefully',
            type: "success",
            confirmButtonText: 'OK!',
            }).then((result) => {
              if (result.value) {
                location.reload();
              }
            });
          }
        },(err) => {
          console.log("Failed to Update");
        });

    }
        // DIRECT SV Fixing

        // NEGOTIATION DONE with SV Fixing

        else if( $('#sectionselector').val() == "Final Negotiation"){
          if ($('#negovisiteddate').val() == "") {
            $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#negovisiteddate').removeAttr("style");
          }
      
          if ($('#negovisitedtime').val() == "") {
            $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#negovisitedtime').removeAttr("style");
          }
      
          if ($('#negofinalremarks').val() == "") {
            $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
            return false;
          }
          else {
            $('#negofinalremarks').removeAttr("style");
          }

          if ($('#svnextactiondate').val() == "") {
            $('#svnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiondate').removeAttr("style");
          }
          if ($('#svnextactiontime').val() == "") {
            $('#svnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
            return false;
          }
          else {
            $('#svnextactiontime').removeAttr("style");
          }
          
          if ($('#svtextarearemarks').val() == "") {
            $('#svtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
            return false;
          }
          else {
            $('#svtextarearemarks').removeAttr("style");
          }

          var visiteddate = $('#negovisiteddate').val();
          var visitedtime = $('#negovisitedtime').val();
          var negofinalremarks = $('#negofinalremarks').val();

          var leadnegodoneparam = {
            leadid: this.leadid,
            closedate: visiteddate,
            closetime: visitedtime,
            leadstage: "Final Negotiation",
            stagestatus: '3',
            textarearemarks: negofinalremarks,
            userid: userid,
            assignid: this.executeid,
          }

          this._phpService.addleadhistory(leadnegodoneparam).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
              this._phpService.addleadhistory(leadsvfixparam).subscribe((success)=> {
                this.status = success.status;
                if(this.status == "True"){
                  $('#svnextactiondate').val('');
                  $('#svnextactiontime').val('');
                  $('#customer_phase4').val('');
                  $('#svtextarearemarks').val('');
                swal({
                  title: 'SV Fixed Succefully',
                  type: "success",
                  confirmButtonText: 'OK!',
                  }).then((result) => {
                    if (result.value) {
                      location.reload();
                    }
                  });
                }
              },(err) => {
                console.log("Failed to Update");
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });


        }

        // NEGOTIATION DONE with SV Fixing
  };

  svrefixing(){

    if ($('#refixdate').val() == "") {
      $('#refixdate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixdate').removeAttr("style");
    }
    if ($('#refixtime').val() == "") {
      $('#refixtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixtime').removeAttr("style");
    }
    if ($('#refixtextarearemarks').val() == "") {
      $('#refixtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#refixtextarearemarks').removeAttr("style");
    }

    var nextdate = $('#refixdate').val();
    var nexttime = $('#refixtime').val();
    var svleadstage = "Refix SV";
    var stagestatus = '2';
    var textarearemarks = $('#refixtextarearemarks').val();
    var leadid = this.leadid;
    var userid = localStorage.getItem('UserId');
    var assignid = this.executeid;
    var csid = this.csid;

    this._mandateService.addleadhistory(leadid,nextdate,nexttime,svleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
      this.status = success.status;
      if(this.status == "True"){
      swal({
        title: 'SV ReFixed Succefully',
        type: "success",
        confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            location.reload();
          }
        });
      }
    },(err) => {
      console.log("Failed to Update");
    });


  }

  svdonewithfixing()
  {
    var nextdate = $('#subsvnextactiondate').val();
    var nexttime = $('#subsvnextactiontime').val();
    var svleadstage = "SV";
    var stagestatus = '1';
    var textarearemarks = $('#subsvtextarearemarks').val();

    var visiteddate = $('#SVvisiteddate').val();
    var visitedtime = $('#SVvisitedtime').val();
    var svleadstage = "SV";
    var svstagestatus = '3';
    var svfinalremarks = $('#svfinalremarks').val();
    var leadid = this.leadid;
    var userid = localStorage.getItem('UserId');
    var assignid = this.executeid;
    var csid = this.csid;

    if ($('#SVvisiteddate').val() == "") {
      $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisiteddate').removeAttr("style");
    }

    if ($('#SVvisitedtime').val() == "") {
      $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#SVvisitedtime').removeAttr("style");
    }

    if ($('#svfinalremarks').val() == "") {
      $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
      return false;
    }
    else {
      $('#svfinalremarks').removeAttr("style");
    }

    
    if ($('#subsvnextactiondate').val() == "") {
      $('#subsvnextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subsvnextactiondate').removeAttr("style");
    }
    if ($('#subsvnextactiontime').val() == "") {
      $('#subsvnextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#subsvnextactiontime').removeAttr("style");
    }
    if ($('#subsvtextarearemarks').val() == "") {
      $('#subsvtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#subsvtextarearemarks').removeAttr("style");
    }

  this._mandateService.addleadhistory(leadid,visiteddate,visitedtime,svleadstage,svstagestatus,svfinalremarks,userid,assignid).subscribe((success)=> {
    this.status = success.status;
    if(this.status == "True"){
      this._mandateService.addleadhistory(leadid,nextdate,nexttime,svleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
          $('#subsvnextactiondate').val('');
          $('#subsvnextactiontime').val('');
          $('#customer_phase4').val('');
          $('#subsvtextarearemarks').val('');
        swal({
          title: 'SV Fixed Succefully',
          type: "success",
          confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              location.reload();
            }
          });
        }
      },(err) => {
        console.log("Failed to Update");
      });
    }
  },(err) => {
    console.log("Failed to Update");
  });
  

  }

  notintrestedsubmit(){

    if( $('#sectionselector').val() == "SV"){
      if ($('#SVvisiteddate').val() == "") {
        $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisiteddate').removeAttr("style");
      }
  
      if ($('#SVvisitedtime').val() == "") {
        $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisitedtime').removeAttr("style");
      }
  
      if ($('#svfinalremarks').val() == "") {
        $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
        return false;
      }
      else {
        $('#svfinalremarks').removeAttr("style");
      }

      if (this.suggestchecked == "") {
        swal({
          title: 'Select any JUNK Reason',
          text: 'Select any Reason for the JUNK',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        
      }

      var visiteddate = $('#SVvisiteddate').val();
      var visitedtime = $('#SVvisitedtime').val();
      var svleadstage = "SV";
      var svstagestatus = '3';
      var svfinalremarks = $('#svfinalremarks').val();

      var closedate = "";
      var closetime = "";
      var stagestatus = this.suggestchecked;
      var negoleadstage = "Move to Junk";
      var textarearemarks = $('#svfinalremarks').val();
      var leadid = this.leadid;
      var userid = localStorage.getItem('UserId');
      var assignid = this.executeid;
      var csid = this.csid;

      this._mandateService.addleadhistory(leadid,visiteddate,visitedtime,svleadstage,svstagestatus,svfinalremarks,userid,assignid).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
          this._mandateService.addleadhistory(leadid,closedate,closetime,negoleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
            swal({
              title: 'Lead Moved To Junk Successfully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  location.reload();
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });
        }
      },(err) => {
        console.log("Failed to Update");
      });
    }
  }

}
