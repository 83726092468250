import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth-service';
import { phpservice } from '../../php.service';
import {ActivatedRoute, Router} from '@angular/router';
import { Junk } from './junk';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-junk',
  templateUrl: './junk.component.html',
  styleUrls: ['./junk.component.css']
})
export class JunkComponent implements OnInit {
  public userDetails: any;

  constructor(private _phpService:phpservice,
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
   }

   junkfiles: any;

  ngOnInit() {
    this.getjunk();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getjunk(){
    if (localStorage.getItem('Role') == '1'){
      this._phpService
    .getjunks()
    .subscribe(junk => {
      this.junkfiles = junk;
    })
    }else if(localStorage.getItem('Role') == '50002'){
      var id = localStorage.getItem('UserId');
      this._phpService
    .getjunksrm(id)
    .subscribe(junk => {
      this.junkfiles = junk;
    })
    }else if(localStorage.getItem('Role') == '50004'){
      var id = localStorage.getItem('UserId');
      this._phpService
    .getjunkscs(id)
    .subscribe(junk => {
      this.junkfiles = junk;
    })

    }
    
  }

  junkmove(){
    this.router.navigate(['/ActiveLeads']);
  }

  junkrevert = new Junk();
  resposeData : any;
  modela:any={};

  revertdetails(id){
    this._phpService
    .junkdatas(id)
    .subscribe(execview => {
      this.modela = execview[0];
    })
    $('.ui.modal.junk_modal')
    .modal({
      blurring: true,
      closable: false,
      observeChanges: false,
      onApprove : function() {
        swal({
          title: 'Successfully Restored',
          type: 'success',
          showConfirmButton: false,
          timer: 2000
        })
        $('body').removeClass('scrolling');
    $('body').removeClass('dimmable');
    $('body').removeClass('blurring');
      }
    })
    .modal('show')
  }

  revertactive(){
    
      this.authService.postData(this.modela, "revertjunk").then((result) =>{
        console.log("Results:",this.modela);
      this.junkmove();
      this.resposeData = result;
    },(err) => {
      console.log("Failed to Insert");
    });
    
    this.authService.postData(this.modela, "junkdelete").then((result) =>{
      this.junkmove();
      this.resposeData = result;
    },(err) => {
      console.log("Failed to Delete");
     });

  }

  button_closejunk(){
    $('body').removeClass('scrolling');
    $('body').removeClass('dimmable');
    $('body').removeClass('blurring');
    window.scrollTo(0, 0)
  }
  
  delete(id){
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
      //   this._phpService
      //   .deletejunk(id)
      //   .subscribe(() => {
      //   this.getjunk();
      // } )
       
      this.authService.postData(this.modela, "deletjunk/" + id).then((result) =>{
         this.save();
           console.log("Results:",this.modela);
           this.resposeData = result;
         }, (err) => {
         });
        swal({
          title: "File has been deleted!",
          type: "success",
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        swal("Junk Details is safe!");
      }
    });
  }

  save(){
    this.ngOnInit();
   }
  



}
