import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router,ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { from } from 'rxjs/observable/from';
const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY =  'lastAction';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  private routeSub: Subscription;
  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  constructor(private router: Router,private location: Location) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
    this.router.events.subscribe((event: Event) => {
      $('.ui.dropdown').dropdown();
      $('.ui.checkbox').checkbox();
    });
   }

   initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout)  {
      localStorage.clear();
      this.router.navigate(['./login']);
    }
  }

  ngOnInit(){
    if (this.location.path().indexOf('dashboard?') > -1){
      
    }else{
      let element5 = document.getElementById('dashboard_dynamic_links_5');
      let element6 = document.getElementById('dashboard_dynamic_links_6');
      element5.parentNode.removeChild(element5);
      element6.parentNode.removeChild(element6);
    }
    
  }
}
