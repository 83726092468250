import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';

@Component({
  selector: 'app-rsv',
  templateUrl: './rsv.component.html',
  styleUrls: ['./rsv.component.css']
})
export class RsvComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpservice:phpservice ) { 
  }

  repeatsite:any;
  ngOnInit() {
    this.getrepeatsitevisit();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getrepeatsitevisit(){
    if (localStorage.getItem('Role') == '1')
    {
    this._phpservice
    .getrsv()
    .subscribe(repeat => {
      this.repeatsite = repeat;
    })
  } else if(localStorage.getItem('Role') == '50002') {
    var id = localStorage.getItem('UserId');
    this._phpservice
    .getrmrsv(id)
    .subscribe(repeat => {
      this.repeatsite = repeat;
    })
  } else if(localStorage.getItem('Role') == '50004') {
    var id = localStorage.getItem('UserId');
    this._phpservice
    .getcsrsv(id)
    .subscribe(repeat => {
      this.repeatsite = repeat;
    })
  }
  }
}
