import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
import { Executive,Executiveupdate } from './executive';
import {ActivatedRoute,Router} from '@angular/router';
import { AuthService } from '../../auth-service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-executives',
  templateUrl: './executives.component.html',
  styleUrls: ['./executives.component.css']
})
export class ExecutivesComponent implements OnInit {

  resposeData : any;
  default_desig:boolean;
  select_desig:boolean;
  loadershow:boolean;

  constructor( 
    private _phpservice:phpservice,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute
  ) { 
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
    }, 1000);
    this.default_desig = true;
    this.select_desig = false;
    this.loadershow = false;
  }

  myexecutives:any;
  
  ngOnInit() {
    this.getexecutives();
    this.getdeparts();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getexecutives(){
    this._phpservice.getexecutiveslist().subscribe(execute => {this.myexecutives = execute;})
  }

 // MODAL-VIEW-OF-EXECUTIVES

 id = this.route.snapshot.params['id'];

 // MODAL-VIEW-OF-EXECUTIVES

executeload(){
  this.ngOnInit();
}

  signupData =  new Executive();
  wishers = new Executive();
  dobdate : string;
  exec_desig = [];
  edit_desig = [];
  exec_departments = [];
  status : any;
  modela = new Executiveupdate();
  editmodela = new Executiveupdate();

  addExecutive(){
    if($('#exec_name').val()=="")
      {
          $('#exec_name').focus().css("border-color","red").attr('placeholder','Please Enter the Name');
          return false;
      }
      else
      {
        var nameFilter=/^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        if(nameFilter.test($('#exec_name').val()))
        {
          $('#exec_name').removeAttr("style");
        }
        else
        {
          $('#exec_name').focus().css("border-color","red").attr('placeholder','Please enter valid name').val('');
          return false;
        }
      }
      if($('#exec_mobile').val()=="")
      {
          $('#exec_mobile').focus().css("border-color","red").attr('placeholder','Please Enter Phone Number');
          return false;
      }
      else{
        var mobileno=/^[0-9]{10}$/;
          if(mobileno.test($('#exec_mobile').val()))
          {
              $('#exec_mobile').removeAttr("style");
          }
           else{
                $('#exec_mobile').focus().css("border-color","red").attr('placeholder','Please enter valid contact number').val('');
                return false;
           }
       }
      if($('#exec_date').val()=="")
      {
          $('#exec_date').focus().css("border-color","red").attr('placeholder','Please Enter DOB');
          return false;
      }
      else
      {
        $('#exec_date').removeAttr("style"); 
      }
      if($('#exec_email').val()=="")
                  {
                      $('#exec_email').focus().css("border-color","red").attr('placeholder','Please Enter Email-id');
                      return false;
                  }
                 else{
                    var email=/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
                      if(email.test($('#exec_email').val()))
                      {
                        $('#exec_email').removeAttr("style");
                      }
                       else
                       {
                          $('#exec_email').focus().css("border-color","red").attr('placeholder','Please enter valid email-id').val('');
                          return false;
                       }
                   }
      if($('#exec_department').val()==null)
                   {
                       $('.exec_department').focus().css("border-color","red").attr('placeholder','Please select the department');
                       return false;
                   }
                   else
                   {
                    $('.exec_department').removeAttr("style");
                   }
      if($('#exec_designation').val()==null)
                   {
                       $('.exec_designation').focus().css("border-color","red").attr('placeholder','Please select the designation');
                       return false;
                   }
                   else
                   {
                    $('.exec_designation').removeAttr("style");
                   }
      if($('#exec_address').val()=="")
                   {
                       $('#exec_address').focus().css("border-color","red").attr('placeholder','Please Enter the address');
                       return false;
                   }
            else
            {
              $('#exec_address').removeAttr("style");
            }

            var param = this.signupData;
            var dob = $('#exec_date').val();
            this.signupData.dobdate = dob;
            
            this.loadershow = true;
      this._phpservice.addexecutive(param,dob).subscribe((success) => {
      this.status = success.status;
      // console.log("Status:", this.status);
      if(this.status == "True"){
        this.loadershow = false;
        swal({
        title: 'Updated Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        $('#cancel').click();
        this.getexecutives();
      }else if(this.status == "Mobile"){
        this.loadershow = false;
        swal({
          title: 'Mobile Number Already Registered!',
          text: 'Please try again',
          type: 'error',
          confirmButtonText: 'ok'
        })
      }
      else{
        swal({
        title: 'Authentication Failed!',
        text: 'Please try agin',
        type: 'error',
        confirmButtonText: 'ok'
      })
    }
  }, (err) => {
    console.log("Connection Failed")
  });
}

  // UPDATE-FUNC-STARTS
  
  updateExecutive(){
    if($('#executives_firstname').val()=="")
      {
          $('#executives_firstname').focus().css("border-color","red").attr('placeholder','Please Enter the Name');
          return false;
      }
      else
      {
        var nameFilter=/^([a-zA-Z]+\s)*[a-zA-Z]+$/;
        if(nameFilter.test($('#executives_firstname').val()))
        {
          $('#executives_firstname').removeAttr("style");
        }
        else
        {
          $('#executives_firstname').focus().css("border-color","red").attr('placeholder','Please enter valid name').val('');
          return false;
        }
      }
      if($('#executives_number').val()=="")
      {
          $('#executives_number').focus().css("border-color","red").attr('placeholder','Please Enter Phone Number');
          return false;
      }
      else{
        var mobileno=/^[0-9]{10}$/;
          if(mobileno.test($('#executives_number').val()))
          {
              $('#executives_number').removeAttr("style");
          }
           else{
                $('#executives_number').focus().css("border-color","red").attr('placeholder','Please enter valid contact number').val('');
                return false;
           }
           if($('#executives_dob').val()=="")
           {
               $('#executives_dob').focus().css("border-color","red").attr('placeholder','Please Enter DOB');
               return false;
           }
           else
           {
             $('#executives_dob').removeAttr("style"); 
           }
           if($('#executives_email').val()=="")
                       {
                           $('#executives_email').focus().css("border-color","red").attr('placeholder','Please Enter Email-id');
                           return false;
                       }
                      else{
                         var email=/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
                           if(email.test($('#executives_email').val()))
                           {
                             $('#executives_email').removeAttr("style");
                           }
                            else
                            {
                               $('#executives_email').focus().css("border-color","red").attr('placeholder','Please enter valid email-id').val('');
                               return false;
                            }
                        }
           if($('#executive_department').val()=="")
                        {
                            $('#executive_department').focus().css("border-color","red").attr('placeholder','Please select the department');
                            return false;
                        }
                        else
                        {
                         $('#executive_department').removeAttr("style");
                        }
           if($('#executive_designation').val()=="")
                        {
                            $('#executive_designation').focus().css("border-color","red").attr('placeholder','Please select the designation');
                            return false;
                        }
                        else
                        {
                         $('#executive_designation').removeAttr("style");
                        }
           if($('#executive_address').val()=="")
                        {
                            $('#executive_address').focus().css("border-color","red").attr('placeholder','Please Enter the address');
                            return false;
                        }
                 else
                 {
                   $('#executive_address').removeAttr("style");
                 }
       }

            var param = this.editmodela;
            var dob = $('#executives_dob').val();
           
      this._phpservice.updatexecutives(param,dob).subscribe((success) => {
      this.status = success.status;
      // console.log("Status:", this.status);
      if(this.status == "True"){
        swal({
        title: 'Updated Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        $('#update_cancel').click();
        this.getexecutives();
      }else{
        swal({
        title: 'Authentication Failed!',
        text: 'Please try agin',
        type: 'error',
        confirmButtonText: 'ok'
      })
    }
  }, (err) => {
    console.log("Connection Failed")
  });
   
 };

  // GET_DEPARTMENTS
  getdeparts(){
    this._phpservice.getdepartments().subscribe(departs => {this.exec_departments = departs;})
  }
  // GET_DEPARTMENTS

  // GET_DESIG_FOR_EDIT_MODAL

  changedepart(event)
  {
    const depid = event.target.value;
    this.editmodela.exec_dept_IDFK = depid;
    this.editmodela.exec_desig_IDFK = "";
    this._phpservice.getdesignations(depid).subscribe((success) => {
      this.edit_desig = success;
      this.default_desig = false;
      this.select_desig = true;
      this.exec_desig = success;
      // this.exec_desig = success;
      // console.log("Status:", this.exec_desig);
    }, (err) => {
      console.log("Connection Failed")
    });
  }

  changedesgntns(event)
  {
    var desgid = event.target.value;
    this.editmodela.exec_desig_IDFK = desgid;
  }

  // GET_DESIGNATIONS_BASED_ON_DEPARTMENTS
  getdesgntns(event)
  {
    const departid = event.target.value;
    this._phpservice.getdesignations(departid).subscribe((success) => {
      this.exec_desig = success;
      // console.log("Status:", this.exec_desig);
    }, (err) => {
      console.log("Connection Failed")
    });
  }
  // GET_DESIGNATIONS_BASED_ON_DEPARTMENTS

  // ADD_PERFORMER_DETAILS
  addperformers()
  {
    if($('#perfrm_name').val()=="")
      {
          $('#perfrm_name').focus().css("border-color","red").attr('placeholder','Please Enter Performer Name');
          return false;
      }
      else
      {
        $('#perfrm_name').removeAttr("style"); 
      }
      if($('#perfrm_bday').val()=="")
      {
          $('#perfrm_bday').focus().css("border-color","red").attr('placeholder','Please Enter Birthday Person Name');
          return false;
      }
      else
      {
        $('#perfrm_bday').removeAttr("style"); 
      }

      this._phpservice.wisherupdate(this.wishers.performer,this.wishers.birthday).subscribe((success) => {
      this.status = success.status;
      
      console.log("Status:", this.status);
      if(this.status == "True"){
        swal({
        title: 'Updated Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        $('#cancel_btn').click();
      }else{
        swal({
        title: 'Authentication Failed!',
        text: 'Please try agin',
        type: 'error',
        confirmButtonText: 'ok'
      })
    }
  }, (err) => {
    console.log("Connection Failed")
  });
}
// ADD_PERFORMER_DETAILS

getexecutiveupdate(id)
 {
  this.default_desig = true;
  this.select_desig = false;
   this._phpservice.getexecuitiveview(id).subscribe(test =>{
      this.modela = test[0];
      this.editmodela=test[0];
      var deptid = this.editmodela.exec_dept_IDFK;
      this._phpservice.getdesignations(deptid).subscribe((success) => {
        this.edit_desig = success;
        // console.log("Status:", this.exec_desig);
      }, (err) => {
        console.log("Connection Failed")
      });
      // alert(deptid);
      })
    // var deptid = $('#exec_dept_IDFK').val();
    
  }

ngOnDestroy(){
  $('.modal').remove();
}

save(){
  this.ngOnInit();
  // $('.modal').removeClass('in');
  // $('#add_exec').modal("hide");
  // $('#edit_test').hide();
  // $('.modal-backdrop').remove();
  // $('body').removeClass('modal-open');
  // $('body').css('padding-right',"");
  //  window.location.reload();
 }

  executiveupdate(){
    this.ngOnInit();
  }
  // UPDATE-FUNC-ENDS

  // DELETE-FUNC

deleteEmp(id)
{
    swal({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.authService.postData(this.editmodela, "deletexecutive/" + id).then((result) =>{
        this.save();
          console.log("Results:",this.editmodela);
          this.resposeData = result;
        }, (err) => {
        });
        swal({
          title: "File has been deleted!",
          type: "success",
          showConfirmButton: false,
          timer: 1500
        })
      } else {
        swal("Executive Details is safe!");
      }
    });
}
// DELETE-FUNC

}
