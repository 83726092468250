import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.css']
})
export class InactiveComponent implements OnInit {

  adminview:boolean;
  rmexecview:boolean;
  assignedview:boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _phpService:phpservice,) {
      setTimeout(() => {
        $('.ui.dropdown').dropdown();
      });
     }

  ngOnInit() {
    
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if (localStorage.getItem('Role') == '1')
    {
      this.getinactive();
      this.adminview = true;
    }
    else if(localStorage.getItem('Role') == '50001')
    {
      this.getexecinactive();
      this.rmexecview = true;
    }
    else if(localStorage.getItem('Role') == '50002')
    {
      this.getexecinactive();
      this.rmexecview = true;
    }
    else if(localStorage.getItem('Role') == '50003')
    {
      this.getcustinactive();
      this.assignedview = true;
    }
    else if(localStorage.getItem('Role') == '50004')
    {
      this.getcustinactive();
      this.assignedview = true;
    }
  }

  inactiveleads: any;
  inactiveexecleads: any;

  getinactive(){
    this._phpService
    .getinactiveleadslist()
    .subscribe(inact => {
        this.inactiveleads = inact;
    })
  }
  getexecinactive()
  {
    var id = localStorage.getItem('UserId');
    this._phpService
    .getexecinactive(id)
    .subscribe(inactivelead => {
        this.inactiveexecleads = inactivelead;
    })
  }
  getcustinactive()
  {
    var id = localStorage.getItem('UserId');
    this._phpService
    .getcustinactive(id)
    .subscribe(inactivelead => {
        this.inactiveexecleads = inactivelead;
    })
  }

  getinactivecust(id){
    this.router.navigate(['/Customer-Details/' + id]);
  }

}
