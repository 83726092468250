import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';

@Component({
  selector: 'app-negotiate',
  templateUrl: './negotiate.component.html',
  styleUrls: ['./negotiate.component.css']
})
export class NegotiateComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpService:phpservice ) {
   }

  negotiate:any;
  ngOnInit() {
    this.getnegotiate();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getnegotiate(){
    if (localStorage.getItem('Role') == '1')
    {
    this._phpService
    .getnegotiatelist()
    .subscribe(fnegotiate => {
      this.negotiate = fnegotiate;
    })
  } else if(localStorage.getItem('Role') == '50002') {
    var id = localStorage.getItem('UserId');
    this._phpService
    .getnegotiatelistrm(id)
    .subscribe(fnegotiate => {
      this.negotiate = fnegotiate;
    })

  } else if(localStorage.getItem('Role') == '50004') {
    var id = localStorage.getItem('UserId');
    this._phpService
    .getnegotiatelistcs(id)
    .subscribe(fnegotiate => {
      this.negotiate = fnegotiate;
    })

  }
  }

}
