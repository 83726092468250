import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { phpservice } from '../../php.service';
import { visitwithothers } from '../usvform/usv';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandate-usvform',
  templateUrl: './mandate-usvform.component.html',
  styleUrls: ['./mandate-usvform.component.css']
})
export class MandateUsvformComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  usvFixed = true;
  usvreFix = false;
  usvDone = false;
  svform = false;
  rsvform = false;
  finalnegoform = false;
  leadclosedform = false;
  leadclosed = false;
  svFixed = false;
  rsvFixed = false;
  negofixed = false;
  junkform = false;
  junk = false;
  properties: any;
  suggestedpropertylists: any;
  nonsuggestedpropertylists: any;
  selectedpropertylists: any;
  selectedproperty_commaseperated: any;
  visitedpropertylists: any;
  cancelledpropertylists: any;
  leadid = this.route.snapshot.params['id'];
  formdata = new visitwithothers();
  datavisitwithothersid: any;
  datavisitwithothersname: any;
  datavisitwithothers: any;
  suggestedproperties: any;
  suggestedpropertiesname: any;
  status: any;
  executeid: any;
  csid: any;
  suggestchecked: any;
  activestagestatus: any;
  hideafterfixed = true;
  hiderefixed = false;
  hidebeforefixed = false;
  leadclose = false;
  junkmove = false;
  selectedlists: any;
  followform = false;
  followup = false;
  followdownform = false;
  followupdown = false;
  autoremarks: any;
  userid:any;
  username:any;
  visitupdate: any;
  propertyremarks: any;
  filterLoader: boolean = true;
  intrestbtn: boolean = false;
  afterselectvisitdate = false;
  beforeselectvisitdate = true;
  visitstatusupdate = false;

  constructor(private router: Router,
    private route: ActivatedRoute, private _location: Location, private _phpService: phpservice,) { }

    private isCountdownInitialized: boolean;

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');

    this._phpService
      .getassignedrm(this.leadid,this.userid)
      .subscribe(cust => {
        this.executeid = cust[0].executiveid;
        this.suggestchecked = cust[0]['suggestedprop'][0].propid;
      });

      var param = {
        leadid: this.leadid,
        execid: this.userid
      }

    this._phpService
      .propertylist(param)
      .subscribe(propertylist => {
        this.properties = propertylist;
      });

    

    

    // this._phpService
    //   .getassignedcs(this.leadid)
    //   .subscribe(cust => {
    //     this.csid = cust[0].executiveid;
    //   })

      this._phpService
      .getactiveleadsstatus(this.leadid,this.userid)
      .subscribe(stagestatus => {
        this.filterLoader = false;
        this.activestagestatus = stagestatus['activeleadsstatus'];
        if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "1"){
          this.hideafterfixed = false;
          this.usvFixed = false;
          this.hidebeforefixed = true;
          this.hiderefixed = true;
          this.usvreFix = true;
          $('#sectionselector').val('USV');
        }else if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "2"){
          this.hideafterfixed = false;
          this.usvFixed = false;
          this.hidebeforefixed = true;
          this.hiderefixed = true;
          this.usvreFix = true;
          $('#sectionselector').val('USV');
        }else if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "0"){
          this.hideafterfixed = false;
          this.hiderefixed = false;
          this.hidebeforefixed = false;
          this.usvFixed = false;
          this.usvreFix = false;
          this.usvDone = true;
          $('#sectionselector').val('USV');
        }else{
          this.hideafterfixed = true;
        }
      });

      // this.suggestchecked = "";
      this.loadimportantapi();

  }

  ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      this.scriptfunctions();
    }
  }
  visitdate: any;
  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    
    $('.visitedcalendardate').calendar({
      type: 'date',
      minDate: this.priorDatebefore,
      maxDate: this.date,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      },
      
    });

    $('.calendartime').calendar({
      type: 'time'
    });
    $.extend($.expr[':'], {
      unchecked: function (obj) {
          return ((obj.type == 'checkbox' || obj.type == 'radio') && !$(obj).is(':checked'));
      }
  });
  }

  datefocus(){
    // console.log("Input Focused Now");
  }
  datefocusOut(){
    if ($('#USVvisiteddate').val() == ""){
      $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }else if($('#USVvisitedtime').val() == ""){
      $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#USVvisiteddate').removeAttr("style");
      $('#USVvisitedtime').removeAttr("style");
      this.afterselectvisitdate = true;
      this.beforeselectvisitdate = false;
    }
  }

  timefocusOut(){
    if ($('#USVvisiteddate').val() == ""){
      $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }else if($('#USVvisitedtime').val() == ""){
      $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#USVvisiteddate').removeAttr("style");
      $('#USVvisitedtime').removeAttr("style");
      this.afterselectvisitdate = true;
      this.beforeselectvisitdate = false;
    }
  }
  visitedremarks: any;
  loadimportantapi(){
    var param = {
      leadid: this.leadid,
      execid: this.userid,
      stage:  $('#customer_phase4').val(),
      
    }

    this._phpService
      .getsuggestedproperties(param)
      .subscribe(suggested => {
        this.suggestedpropertylists = suggested['suggestedlists'];
      });

      this._phpService
      .getnonselectedproperties(this.leadid)
      .subscribe(suggested => {
        this.nonsuggestedpropertylists = suggested['nonselectedlists'];
      });

    this._phpService
  .getmandateselectedsuggestproperties(this.leadid,this.userid)
  .subscribe(selectsuggested => {
    this.selectedpropertylists = selectsuggested['selectedlists'];
    this.selectedlists = selectsuggested;
    // this.suggestchecked = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
  });

    this._phpService
    .getvisitedsuggestproperties(param)
    .subscribe(visitsuggested => {
      this.visitedpropertylists = visitsuggested['visitedlists'];
      if(visitsuggested['status'] == 'True'){
        this.visitedremarks = this.visitedpropertylists[0].remarks;
        $('#visitupdate').val("4");
        this.visitstatusupdate = true;
      }else{

      }
    });

    this._phpService
    .getcancelledsuggestproperties(param)
    .subscribe(cancelsuggested => {
      this.cancelledpropertylists = cancelsuggested['cancelledlists'];
    });

   
      var params = {
        leadid: this.leadid,
        execid: this.userid
      }

    this._phpService
      .propertylist(params)
      .subscribe(propertylist => {
        this.properties = propertylist;
      });

  }

  onusvFixed() {
    this.usvFixed = true;
    this.usvreFix = false;
    this.usvDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onusvreFix() {
    this.usvFixed = false;
    this.usvreFix = true;
    this.usvDone = false;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onusvDone() {
    this.followdownform = false;
    this.followupdown = false;
    this.usvFixed = false;
    this.usvreFix = false;
    this.usvDone = true;
    this.junkform = false;
    this.junk = false;
    this.followform = false;
    this.followup = false;
    $('#sectionselector').val('USV');
  }

  onfollowup(){
    this.followform = true;
    this.followup = true;
    this.usvFixed = false;
    this.usvreFix = false;
    this.usvDone = false;
    this.junkform = false;
    this.junk = false;
    $('#sectionselector').val("");
  }

  onjunk(){
    this.junkform = true;
    this.junk = true;
    this.usvFixed = false;
    this.usvreFix = false;
    this.usvDone = false;
    this.followform = false;
    this.followup = false;
  }

  followupdownbtn()
  {
    this.followdownform = true;
    this.followupdown = true;
    this.svform = false;
    this.svFixed = false;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
  }

  onsvFixed(){
    this.followdownform = false;
    this.followupdown = false;
    this.svform = true;
    this.svFixed = true;
    this.rsvFixed = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    
  }

  onrsvFixed(){
    this.rsvform = true;
    this.rsvFixed = true;
    this.svform = false;
    this.svFixed = false;
    this.finalnegoform = false;
    this.negofixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onnegofixed(){
    this.finalnegoform = true;
    this.negofixed = true;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svform = false;
    this.svFixed = false;
    this.leadclosedform = false;
    this.leadclosed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  onleadclosed(){
    this.leadclosedform = true;
    this.leadclosed = true;
    this.finalnegoform = false;
    this.negofixed = false;
    this.rsvform = false;
    this.rsvFixed = false;
    this.svform = false;
    this.svFixed = false;
    this.followdownform = false;
    this.followupdown = false;
  }

  removeValue(list, value) {
    return list.replace(new RegExp(",?" + value + ",?"), function(match) {
        var first_comma = match.charAt(0) === ',',
            second_comma;
  
        if (first_comma &&
            (second_comma = match.charAt(match.length - 1) === ',')) {
          return ',';
        }
        return '';
      });
  };

  // Selecting the suggested properties for fix the USV
  selectsuggested(i,id,propname) {
    if($('#checkbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " added the "+propname+" for USV while fixing the meeting.";
      
    }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "USV",
        execid: this.executeid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the USV scheduled lists.";
      this._phpService.removeselectedproperties(param2).subscribe((success) => {
        this.status = success.status;
        this._phpService.getselectedsuggestproperties(this.leadid).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when remove the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when remove the property for the history storing
        });
      }, (err) => {
        console.log("Failed to Update");
      })

    }
  }
  // Selecting the suggested properties for fix the USV

  refixsuggested(i,id,propname) {

    if($('#suggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      this.autoremarks = " added the "+propname+" for USV while refixing the meeting.";

      // var param = {
      // leadid: this.leadid,
      // suggestproperties: this.suggestchecked
      // }
      // this._phpService.addselectedsuggestedproperties(param).subscribe((success) => {
      //   this.status = success.status;
      //   if(this.status == "True"){
      //       this._phpService
      //   .getselectedsuggestproperties(this.leadid)
      //   .subscribe(selectsuggested => {
      //     this.selectedpropertylists = selectsuggested['selectedlists'];
      //     this.selectedlists = selectsuggested;
      //   });
      //   }
      // }, (err) => {
      //   console.log("Failed to Update");
      // })

    }else{
      var param = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "USV",
        execid: this.executeid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the USV scheduled lists while refixing the meeting.";
      this._phpService.removeselectedproperties(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
            this._phpService.getselectedsuggestproperties(this.leadid).subscribe(selectsuggested => {
          this.selectedpropertylists = selectsuggested['selectedlists'];
          this.selectedlists = selectsuggested;
          // Joining the object values as comma seperated when remove the property for the history storing
          this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
          // Joining the object values as comma seperated when remove the property for the history storing
        });
        }
      }, (err) => {
        console.log("Failed to Update");
      })

    }
  }

  moresuggested(i,id,propname) {
    if ($('#USVvisiteddate').val() == "") {
      $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#USVvisiteddate').removeAttr("style");
    }
    if ($('#USVvisitedtime').val() == "") {
      $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#USVvisitedtime').removeAttr("style");
    }
    var visiteddate = $('#USVvisiteddate').val();
    var visitedtime = $('#USVvisitedtime').val();
    if($('#moresuggestcheckbox'+i).is(':checked')){
      var checkid = $("input[name='programming']:checked").map(function () {
        return this.value;
      }).get().join(',');
      this.suggestchecked = checkid;
      var param = {
      leadid: this.leadid,
      nextdate: visiteddate,
      nexttime: visitedtime,
      suggestproperties: this.suggestchecked
      }
      this.autoremarks = " added the "+propname+" for USV during the sitevisit.";
    this._phpService.addselectedsuggestedproperties(param).subscribe((success) => {
      this.status = success.status;
      if(this.status == "True"){
          this._phpService
      .getselectedsuggestproperties(this.leadid)
      .subscribe(selectsuggested => {
        this.selectedpropertylists = selectsuggested['selectedlists'];
        this.selectedlists = selectsuggested;
      });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
    }else{

      var param2 = {
        leadid: this.leadid,
        suggestproperties: id,
        stage: "USV",
        execid: this.executeid,
        }
        this.suggestchecked = this.removeValue(this.suggestchecked, id);
        this.autoremarks = " removed the "+propname+" from the USV scheduled lists.";
        this._phpService.removeselectedproperties(param2).subscribe((success) => {
          this.status = success.status;
          this._phpService.getselectedsuggestproperties(this.leadid).subscribe(selectsuggested => {
            this.selectedpropertylists = selectsuggested['selectedlists'];
            this.selectedlists = selectsuggested;
            // Joining the object values as comma seperated when remove the property for the history storing
            this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
            // Joining the object values as comma seperated when remove the property for the history storing
          });
        }, (err) => {
          console.log("Failed to Update");
        })

    }
  }

  moresuggestedbeforeselection(){
    swal({
      title: 'Select Visited Date and Time!',
      type: 'error',
      confirmButtonText: 'ok'
    })
  }

  addpropertiestolist01() {
    this.datavisitwithothersid = $(".onchangeforvisitwithothers").dropdown("get value");
    this.datavisitwithothersname = $('.onchangeforvisitwithothers option:selected').toArray().map(item => item.text).join();
    this.suggestedproperties = $(".onchangeforsuggestproperties01").dropdown("get value");
    this.suggestedpropertiesname = $('.onchangeforsuggestproperties01 option:selected').toArray().map(item => item.text).join();

    if (this.suggestedproperties == "" && this.datavisitwithothersid == "") {
      swal({
        title: 'Add any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties01').removeAttr("style");
    }

    if(this.suggestedproperties.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "USV",
      }
      this.autoremarks = "While fixing the USV "+" suggested some properties like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        swal({
          title: 'Successfully Addedd',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            this.loadimportantapi();
          }
        });
      }
      $(".onchangeforvisitwithothers").dropdown('clear');
      $(".onchangeforvisitwithothers").dropdown('destroy');
      $(".onchangeforvisitwithothers").dropdown('restore defaults');
      $(".onchangeforsuggestproperties01").dropdown('clear');
      $(".onchangeforsuggestproperties01").dropdown('destroy');
      $(".onchangeforsuggestproperties01").dropdown('restore defaults');
    }, (err) => {
      console.log("Failed to Update");
    })
    }

    if(this.datavisitwithothersid.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param2 = {
        leadid: this.leadid,
        assignid: this.executeid,
        visitedproperties: this.datavisitwithothersid
      }
      this.autoremarks = "While fixing the USV "+this.username+" added few already visited properties with others Like, "+this.datavisitwithothersname;
      this._phpService.addvisitedpropertiesothers(param2).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
      }, (err) => {
        console.log("Failed to Update");
      })
    }

  }

  addpropertiestolist02() {
    this.datavisitwithothersid = $(".onchangeforvisitwithothers").dropdown("get value");
    this.datavisitwithothersname = $('.onchangeforvisitwithothers option:selected').toArray().map(item => item.text).join();
    this.suggestedproperties = $(".onchangeforsuggestproperties02").dropdown("get value");
    this.suggestedpropertiesname = $('.onchangeforsuggestproperties02 option:selected').toArray().map(item => item.text).join();


    if (this.suggestedproperties == "" && this.datavisitwithothersid == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties02').removeAttr("style");
    }

    if(this.suggestedproperties.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "USV",
      }
      this.autoremarks = "While Refixing the USV "+this.username+" suggested some properties like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".onchangeforvisitwithothers").dropdown('clear');
        $(".onchangeforvisitwithothers").dropdown('destroy');
        $(".onchangeforvisitwithothers").dropdown('restore defaults');
        $(".onchangeforsuggestproperties02").dropdown('clear');
        $(".onchangeforsuggestproperties02").dropdown('destroy');
        $(".onchangeforsuggestproperties02").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })

    }

    if(this.datavisitwithothersid.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param2 = {
        leadid: this.leadid,
        visitedproperties: this.datavisitwithothersid
      }
      this.autoremarks = "While Refixing the USV "+this.username+" added few already visited properties with others Like, "+this.datavisitwithothersname;
      this._phpService.addvisitedpropertiesothers(param2).subscribe((success) => {
        this.status = success.status;
      }, (err) => {
        console.log("Failed to Update");
      })
    }
  }

  addpropertiestolist03() {
    this.suggestedproperties = $(".onchangeforsuggestproperties03").dropdown("get value");
    if (this.suggestedproperties == "") {
      swal({
        title: 'Suggest any one property',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangeforsuggestproperties03').removeAttr("style");
    }

    if(this.suggestedproperties.length == 0){
      // alert("No need push to database");
    }else{
      // alert("Should push to database");
      var param = {
        leadid: this.leadid,
        suggestproperties: this.suggestedproperties,
        assignid: this.executeid,
        stage: "USV",
      }
      this.autoremarks = "While During the UniqueSiteVisit "+this.username+" some more properties suggested like "+this.suggestedpropertiesname;
      this._phpService.addsuggestedproperties(param).subscribe((success) => {
        this.status = success.status;
        if (this.status == "True") {
          swal({
            title: 'Successfully Addedd',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              this.loadimportantapi();
            }
          });
        }
        $(".onchangeforsuggestproperties03").dropdown('clear');
        $(".onchangeforsuggestproperties03").dropdown('destroy');
        $(".onchangeforsuggestproperties03").dropdown('restore defaults');
      }, (err) => {
        console.log("Failed to Update");
      })
    }
  }

  usvfixing() {
    if ($('#nextactiondate').val() == "") {
      $('#nextactiondate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#nextactiondate').removeAttr("style");
    }
    if ($('#nextactiontime').val() == "") {
      $('#nextactiontime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#nextactiontime').removeAttr("style");
    }
    
    if ($('#textarearemarks').val() == "") {
      $('#textarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#textarearemarks').removeAttr("style");
    }
    this.filterLoader = true;
    var nextdate = $('#nextactiondate').val();
    var nexttime = $('#nextactiontime').val();
    var textarearemarks = $('#textarearemarks').val();
    var dateformatchange = new Date(nextdate).toDateString();

    // Below Commanded section for Retail Section suggest properties Option


    // Below Commanded section for Retail Section suggest properties Option
    this._phpService.getmandateselectedsuggestproperties(this.leadid,this.userid).subscribe(selectsuggested => {
      
      this.selectedpropertylists = selectsuggested['selectedlists'];
      this.selectedlists = selectsuggested;
      // Joining the object values as comma seperated when add the property for the history storing
      this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
      // Joining the object values as comma seperated when add the property for the history storing

      this.autoremarks = "Scheduled the USV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
        var leadusvhistparam = {
          leadid: this.leadid,
          closedate: nextdate,
          closetime: nexttime,
          leadstage: "USV",
          stagestatus: '1',
          textarearemarks: textarearemarks,
          userid: this.userid,
          assignid: this.executeid,
          autoremarks: this.autoremarks,
          property: this.suggestchecked
        }
        this._phpService.addleadhistory(leadusvhistparam).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            this.filterLoader = false;
            $('#nextactiondate').val('');
            $('#nextactiontime').val('');
            $('#customer_phase4').val('');
            $('#textarearemarks').val('');
          swal({
            title: 'USV Fixed Succefully',
            type: "success",
            confirmButtonText: 'OK!'
            }).then((result) => {
              if (result.value) {
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
              }
            });
          }
        },(err) => {
          console.log("Failed to Update");
        });


    });
    

        
    
    
  };

  usvrefixing() {

    // if(this.suggestchecked == ""){
    //   swal({
    //     title: 'Property Not Selected',
    //     text: 'Please select atleast one property for the Sitevisit',
    //     type: 'error',
    //     confirmButtonText: 'ok'
    //   })
    //   return false;
    // }else{
    // }

    if ($('#refixdate').val() == "") {
      $('#refixdate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixdate').removeAttr("style");
    }
    if ($('#refixtime').val() == "") {
      $('#refixtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
      return false;
    }
    else {
      $('#refixtime').removeAttr("style");
    }
    if ($('#refixtextarearemarks').val() == "") {
      $('#refixtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#refixtextarearemarks').removeAttr("style");
    }

    var nextdate = $('#refixdate').val();
    var nexttime = $('#refixtime').val();
    var textarearemarks = $('#refixtextarearemarks').val();
    var dateformatchange = new Date(nextdate).toDateString();
    this.filterLoader = true;
    var param = {
      leadid: this.leadid,
      nextdate: nextdate,
      nexttime: nexttime,
      suggestproperties: this.suggestchecked
      }
    this._phpService.addselectedsuggestedpropertiesrefix(param).subscribe((success) => {
      this.status = success.status;
    this._phpService.getselectedsuggestproperties(this.leadid).subscribe(selectsuggested => {
      this.selectedpropertylists = selectsuggested['selectedlists'];
        this.selectedlists = selectsuggested;
        // Joining the object values as comma seperated when add the property for the history storing
        this.selectedproperty_commaseperated = this.selectedpropertylists.map((item) => { return item.name }).join(',');
        // Joining the object values as comma seperated when add the property for the history storing

        this.autoremarks = " ReFixed the USV for "+this.selectedproperty_commaseperated+" On "+dateformatchange+" "+nexttime;
        var leadusvhistparam = {
          leadid: this.leadid,
          closedate: nextdate,
          closetime: nexttime,
          leadstage: "USV",
          stagestatus: '2',
          textarearemarks: textarearemarks,
          userid: this.userid,
          assignid: this.executeid,
          autoremarks: this.autoremarks,
          property: this.suggestchecked
        }
        this._phpService.addleadhistory(leadusvhistparam).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            this.filterLoader = false;
          swal({
            title: 'USV Refixed Succefully',
            type: "success",
            confirmButtonText: 'OK!'
            }).then((result) => {
              if (result.value) {
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
              }
            });
          }
        },(err) => {
          console.log("Failed to Update");
        });
     });
    }, (err) => {
      console.log("Failed to Update");
    })
  };

  visitclick(propertyname)
  {
    // this.intrestbtn = true;
    // $('#visitupdate').val("3");
    // $('#propertyremarks').html('Visited '+propertyname+' and Not Intrested.');
    $('#visitupdate').val("1");
    $('.nextactionmaindiv').removeAttr('style');
    $('.visitupdatebtn').attr("style", "display:none;");
  }

  cancelclick(propertyname)
  {
    // this.intrestbtn = false;
    // $('#visitupdate').val("2");
    // $('#propertyremarks').html('Cancelled the visit for '+propertyname);
    $('#visitupdate').val("3");
    $('.visitupdatebtn').removeAttr('style');
    $('.nextactionmaindiv').attr("style", "display:none;");
    this.followdownform = false;
    this.rsvform = false;
    this.finalnegoform = false;
    this.leadclosedform = false;
  }

//   doLikeButton(propertyname) {
//     $(".heart_icon").removeClass('liked-shaked');
//     $(".heart_icon").toggleClass('liked');
//     $(".heart_icon").toggleClass('not-liked');
//     $(".heart_icon").toggleClass('fas');
//     $(".heart_icon").toggleClass('far');
//     $(".lkebtns").toggleClass('likeactive');
//     if($(".heart_icon").hasClass("liked")) {
//       $(".heart_icon").addClass('liked-shaked');
//       $('#propertyremarks').html('Visited '+propertyname+' and they are Intrested in this property.');
//       $('#visitupdate').val("1");
//     }else{
//       $('#propertyremarks').html('Visited '+propertyname+' and Not Intrested');
//       $('#visitupdate').val("3");
//     } 
// }

  updatepropertyvisit(propertyid,propertyname)
  {
    if($('#visitupdate').val() == "1"){
      this.visitupdate = "Visited";
    }else if($('#visitupdate').val() == "3"){
      this.visitupdate = "Visited but not interested";
    }else{
      this.visitupdate = "Not Visited";
    }
    this.propertyremarks = $('#propertyremarks').val();
    if ($('#visitupdate').val() == "") {
      swal({
        title: 'Action Not Took',
        text: 'Please select any actions',
        type: 'error',
        confirmButtonText: 'OK'
      })
      return false;
    }
    else {
      $('#visitupdate').removeAttr("style");
    }

    if ($('#propertyremarks').val() == "") {
      $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
      return false;
    }
    else {
      $('#propertyremarks').removeAttr("style");
    }
    this.autoremarks = " Marked the "+ propertyname +" as "+this.visitupdate+". Here is the property remarks - "+this.propertyremarks;
    this.filterLoader = true;
    var param = {
      leadid: this.leadid,
      propid: propertyid,
      execid: this.userid,
      visitupdate: $('#visitupdate').val(),
      remarks: $('#propertyremarks').val(),
      stage: "USV",
    }
    // const a = document.getElementById("propertyremarks"+i) as HTMLInputElement;
    // $('#usvfinalremarks').append(a.value);
    
    this._phpService.addpropertyvisitupdate(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        $('#visitupdate').val("3");
        // this.filterLoader = true;
        swal({
          title: 'Data Updated Successfully',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if(result.value){
            location.reload();
            // this.router.navigateByUrl('/mandate-customers/122626');
            // this.loadimportantapi();
          }
          // if($('#visitupdate').val() == "3"){
          //   this.router.navigateByUrl('/rmleadassign?allassigned=1&from=&to=');
          // }else{
          //   if(result.value){
          //     this.loadimportantapi();
          //   }
          // }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })

  }

}
