import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { Observable, Subject } from 'rxjs'; 
import { passwordchange } from './header';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  items: any;
  searchs:any;
  public userDetails: any;
  adminview:boolean;
  RMTLview:boolean;
  RMEXview:boolean;
  CSTLview:boolean;
  CSEXview:boolean;
  MandateRMEXview:boolean;
  builderexecview:boolean;

  constructor(
   
    private route: ActivatedRoute,
    private router: Router,
    private _phpService:phpservice
  ) {
    setTimeout(() => {
      $(function ($) {

        $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    });
    
    $("#close-sidebar").click(function() {
      $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
      $(".page-wrapper").addClass("toggled");
    });
       
    });
    }, 1000);
    const data = localStorage.getItem("Name");
    this.userDetails = data;
    this.adminview = false;
    this.RMTLview = false;
    this.CSTLview = false;
    this.CSEXview = false;
   }
   
  // getcustomers(){
  //   this._phpService
  //   .getcustomers()
  //   .subscribe(custlist => {
  //     this.items = custlist;
  //   })
  // }

  backToWelcome(){
    this.router.navigate(['/login']);
  }
  logout(){
    localStorage.clear();
    setTimeout(() => this.backToWelcome(), 1000);
    
  }
     public clients: any;  
     private searchTerms = new Subject<string>();  
     private mandatesearchTerms = new Subject<string>();  
     public customer_name = '';  
     public flag: boolean; 

     ngOnInit(){
            // this.getcustomers();
            this.getcustomer();
            if(localStorage.getItem('Department') == '10004'){
              this.MandateRMEXview = true;
            }else{
              if (localStorage.getItem('Role') == null)
            {
              this.router.navigateByUrl('/login');
            } 
            else if (localStorage.getItem('Role') == '1')
            {
              this.adminview = true;
            }
            else if(localStorage.getItem('Role') == '50001')
            {
              this.RMTLview = true;
            }
            else if(localStorage.getItem('Role') == '50002')
            {
              this.RMEXview = true;
              this.getmandatecustomer();
            }
            else if(localStorage.getItem('Role') == '50003')
            {
              this.CSTLview = true;
            }
            else if(localStorage.getItem('Role') == '50004')
            {
              this.CSEXview = true;
            } else if(localStorage.getItem('Role') == '50011'){
              this.builderexecview = true;
            }
            }
            
            
         }
         customer_id:any;  
         password = new passwordchange();
         status: any;
         
        getcustomer(){
               this.clients = this.searchTerms  
               .debounceTime(300)        // wait for 300ms pause in events  
                 .distinctUntilChanged()   // ignore if next search term is same as previous  
                .switchMap(param => param   // switch to new observable each time  
                // return the http search observable  
                   ? this._phpService.search(param)  
                 // or the observable of empty heroes if no search term  
                 : Observable.of<any[]>([]))  
                .catch(error => {  
                // TODO: real error handling  
                  console.log(error);  
                  return Observable.of<any[]>([]);     
              });     
        }

        getmandatecustomer(){
          var userid = localStorage.getItem('UserId');
          this.clients = this.mandatesearchTerms  
          .debounceTime(300)        // wait for 300ms pause in events  
            .distinctUntilChanged()   // ignore if next search term is same as previous  
           .switchMap(param => param   // switch to new observable each time  
           // return the http search observable  
              ? this._phpService.mandatesearch(param,userid)  
            // or the observable of empty heroes if no search term  
            : Observable.of<any[]>([]))  
           .catch(error => {  
           // TODO: real error handling  
             console.log(error);  
             return Observable.of<any[]>([]);     
         });     
   }

        dataRefresher: any;
     
    searchClient(param: string): void {  
           this.flag = true;  
           this.searchTerms.next(param);
           if(param==""){
            this.flag = false;
           }  
         } 

         mandatesearch(param: string): void {  
          this.flag = true;  
          this.mandatesearchTerms.next(param);
          if(param==""){
           this.flag = false;
          }  
        }  

         onselectClient(id) {     
           if (id.customer_id != 0) {  
             this.customer_name = id.customer_name;       
             this.flag = false;
            this.router.navigate(['/Customer-Details/' + id])
            this.customer_name='';
            //  window.open('http://localhost:4200/Customer-Details/' + id ,'_blank');
           }  
           else {  
             return false;  
         }  
         }  


         passwordchange(){
          if($('#newpass').val()=="")
          {
              $('#newpass').focus().css("border-color","red").attr('placeholder','Please enter your new Password');
              return false;
          }
           else{      
          $('#newpass').removeAttr("style"); 
          }
          var password = $("#newpass").val();
          if($('#repass').val()=="")
          {
              $('#repass').focus().css("border-color","red").attr('placeholder','Please re-enter the Password');
              return false;
          }
           else{
             var confirmPassword = $("#repass").val();
             if(password != confirmPassword)
             {
               $('#repass').focus().css("border-color","red").attr('placeholder','Password Mismatch Please Re-enter').val('');
                 return false;
              
             }
            else{
             $('#repass').removeAttr("style");
            }      
          }
          var param = this.password;
          var id = localStorage.getItem('UserId')
          console.log("Values", param.repassword, id)
          this._phpService.passwordupdate(id,param).subscribe((success) => {
            this.status = success.status;
            // console.log("Status:", this.status);
            if(this.status == "True"){
              
              swal({
              title: 'Password Updated',
              type: 'success',
              confirmButtonText: 'ok'
              })
              $('#cancel').click();
            }else{
              swal({
              title: 'Authentication Failed!',
              text: 'Please try agin',
              type: 'error',
              confirmButtonText: 'ok'
            })
          }
          }, (err) => {
          console.log("Connection Failed")
          });


         }



}
