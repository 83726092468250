import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { leadadd } from '../enquiry/enq';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-todays',
  templateUrl: './todays.component.html',
  styleUrls: ['./todays.component.css']
})
export class TodaysComponent implements OnInit {
  adminview:boolean;
  rmexecview:boolean;
  custsprtview:boolean;
  public userDetails: any;
  leadmodel = new leadadd();
  execleads: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _phpService:phpservice,
    private _DomSanitizer:DomSanitizer
    ) {
      this.activeleads;
      setTimeout(() => {
        $('.ui.dropdown').dropdown();
        $('.ui.checkbox').checkbox();
      }, 1000);
     }
     filterLoader: boolean = true;
  static count: number;
  static count2: number;
  fromdate: any;
  todate: any;
  userid: any;
  activeleadcount: any;
  rmexecutives: any;
  csexecutives: any;
  stageid: any;

  followcount: any;
  f2fcount: any;
  usvcount: any;
  svcount: any;
  rsvcount: any;
  negocount: any;
  rmid: any;
  csid: any;
    
  ngOnInit() {
    $(".wrapper").hide();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if (localStorage.getItem('Role') == '1')
            {
              if (this.router.url.indexOf('todaysmeetings') > -1){
                // var d = new Date();
                // this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                // this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                this.gettodaysmeetings();
              }else if(this.router.url.indexOf('missedmeetings') > -1){
                // this.fromdate = "2019-01-01";
                // this.todate = new Date(Date.now() - 1 * 864e5 - new Date(Date.now() - 1 * 864e5).getTimezoneOffset() * 6e4).toISOString().split('T')[0];
                this.getmissedmeetings();
              }
              this.adminview = true;
            }
    else if(localStorage.getItem('Role') == '50002')
            {
              if (this.router.url.indexOf('todaysmeetings') > -1){
                this.userid = localStorage.getItem('UserId');
                var d = new Date();
                this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                this.gettodaysmeetings();
              }else if(this.router.url.indexOf('missedmeetings') > -1){
                this.userid = localStorage.getItem('UserId');
                this.fromdate = "2019-01-01";
                this.todate = new Date(Date.now() - 1 * 864e5 - new Date(Date.now() - 1 * 864e5).getTimezoneOffset() * 6e4).toISOString().split('T')[0];
                this.getmissedmeetings();
              }
              this.rmexecview = true;
            }
            else if(localStorage.getItem('Role') == '50004')
            {
              if (this.router.url.indexOf('todaysmeetings') > -1){
                this.userid = localStorage.getItem('UserId');
                var d = new Date();
                this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
                this.gettodaysmeetings();
              }else if(this.router.url.indexOf('missedmeetings') > -1){
                this.userid = localStorage.getItem('UserId');
                this.fromdate = "2019-01-01";
                this.todate = new Date(Date.now() - 1 * 864e5 - new Date(Date.now() - 1 * 864e5).getTimezoneOffset() * 6e4).toISOString().split('T')[0];
                this.getmissedmeetings();
              }
              this.rmexecview = true;
            }
    TodaysComponent.count = 0;
    TodaysComponent.count2 = 0;

    var rmid = '50002'
    this._phpService.getexecutivesbasedid(rmid).subscribe(rms =>{this.rmexecutives = rms;})

    var csid = '50004'
    this._phpService.getexecutivesbasedid(csid).subscribe(cs =>{this.csexecutives = cs;})
  }

  gettodaysmeetings(){
    if (localStorage.getItem('Role') == '1'){
      TodaysComponent.count = 0;
      var param5 = {
        limit: 0, 
        limitrows: 30,
        rmid: this.rmid,
        csid: this.csid,  
      }
      this._phpService
      .gettodaymeetings(param5)
      .subscribe(activelead => {
        this.activeleads = activelead.Missedmeeting;
        if(activelead.status == 'True'){
          this.filterLoader = false;
        }else{
          this.filterLoader = false;
        }
      })

      var param6 = {
        rmid: this.rmid,
        csid: this.csid,
      }
      this._phpService
      .gettodaymeetingscounts(param6)
      .subscribe(activecounts => {
          this.activeleadcount = activecounts[0];
          this.followcount = this.activeleadcount.followcount;
          this.f2fcount = this.activeleadcount.facetoface;
          this.usvcount = this.activeleadcount.usvcount;
          this.svcount = this.activeleadcount.svcount;
          this.rsvcount = this.activeleadcount.rsvcount;
          this.negocount = this.activeleadcount.nego_count;
      })
    } 
    else if(localStorage.getItem('Role') == '50002'){
      TodaysComponent.count = 0;
      var param = {
        limit: 0, 
        limitrows: 30,
        rmid: this.userid,
        csid: "",  
        onlyrm: "RMonly",
        onlycs: "",
      }
      this._phpService
      .gettodaymeetings(param)
      .subscribe(activelead => {
        this.activeleads = activelead.Missedmeeting;
        if(activelead.status == 'True'){
          this.filterLoader = false;
        }else{
          this.filterLoader = false;
        }
      })

      var param2 = {
        rmid: this.userid,
        csid: "", 
        onlyrm: "RMonly",
        onlycs: "",
      }
      this._phpService
      .gettodaymeetingscounts(param2)
      .subscribe(activecounts => {
          this.activeleadcount = activecounts[0];
          this.followcount = this.activeleadcount.followcount;
          this.f2fcount = this.activeleadcount.facetoface;
          this.usvcount = this.activeleadcount.usvcount;
          this.svcount = this.activeleadcount.svcount;
          this.rsvcount = this.activeleadcount.rsvcount;
          this.negocount = this.activeleadcount.nego_count;
      })
    }else if(localStorage.getItem('Role') == '50004'){
      TodaysComponent.count = 0;
      var param3 = {
        limit: 0, 
        limitrows: 30,
        rmid: "",
        csid: this.userid,  
        onlyrm: "",
        onlycs: "CSonly",
      }
      this._phpService
      .gettodaymeetings(param3)
      .subscribe(activelead => {
        this.activeleads = activelead.Missedmeeting;
        if(activelead.status == 'True'){
          this.filterLoader = false;
        }else{
          this.filterLoader = false;
        }
      })
  
      var param4 = {
        rmid: "",
        csid: this.userid, 
        onlyrm: "",
        onlycs: "CSonly",
      }
      this._phpService
      .gettodaymeetingscounts(param4)
      .subscribe(activecounts => {
          this.activeleadcount = activecounts[0];
          this.followcount = this.activeleadcount.followcount;
          this.f2fcount = this.activeleadcount.facetoface;
          this.usvcount = this.activeleadcount.usvcount;
          this.svcount = this.activeleadcount.svcount;
          this.rsvcount = this.activeleadcount.rsvcount;
          this.negocount = this.activeleadcount.nego_count;
      })
    }
    

  }

  getmissedmeetings(){
    this.filterLoader = true;
    if (localStorage.getItem('Role') == '1'){
      TodaysComponent.count = 0;
      var param5 = {
        limit: 0, 
        limitrows: 30,
        rmid: this.rmid,
        csid: this.csid,  
      }
      this._phpService
      .getmissedmeetings(param5)
      .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
          
      })

      var param6 = {
        rmid: this.rmid,
        csid: this.csid,
      }
      this._phpService
      .getmissedmeetingscounts(param6)
      .subscribe(activecounts => {
          this.activeleadcount = activecounts[0];
          this.followcount = this.activeleadcount.followcount;
          this.f2fcount = this.activeleadcount.facetoface;
          this.usvcount = this.activeleadcount.usvcount;
          this.svcount = this.activeleadcount.svcount;
          this.rsvcount = this.activeleadcount.rsvcount;
          this.negocount = this.activeleadcount.nego_count;
      })
    } else if(localStorage.getItem('Role') == '50002'){
      TodaysComponent.count = 0;
    var param = {
      limit: 0, 
      limitrows: 30,
      rmid: this.userid,
      csid: "",  
      onlyrm: "RMonly",
      onlycs: "",
    }
    this._phpService
    .getmissedmeetings(param)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param2 = {
      rmid: this.userid,
      csid: "", 
      onlyrm: "RMonly",
      onlycs: "",
    }
    this._phpService
    .getmissedmeetingscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })

    }else if(localStorage.getItem('Role') == '50004'){
      TodaysComponent.count = 0;
    var param3 = {
      limit: 0, 
      limitrows: 30,
      rmid: "",
      csid: this.userid,  
      onlyrm: "",
      onlycs: "CSonly",
    }
    this._phpService
    .getmissedmeetings(param3)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param4 = {
      rmid: "",
      csid: this.userid, 
      onlyrm: "",
      onlycs: "CSonly",
    }
    this._phpService
    .getmissedmeetingscounts(param4)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })
    }
    
  }

  loadMore(){
    this.filterLoader = true;
    if (this.router.url.indexOf('todaysmeetings') > -1){
      const limit = TodaysComponent.count+=30;
    let limitrows = 30;
    let livecount = this.activeleadcount.activecount;
    if (localStorage.getItem('Role') == '1'){
      var param3 = {
        limit: limit, 
        limitrows: limitrows,
        rmid: this.rmid,
        csid: this.csid,  
        stages: this.stageid,
      }
      if(this.activeleads.length <= livecount){
        
        return this._phpService
        .gettodaymeetings(param3)
        .subscribe(activelead => {
            this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }
    else if(localStorage.getItem('Role') == '50002'){
      var param = {
        limit: limit, 
        limitrows: limitrows,
        rmid: this.userid,
        csid: "",  
        stages: this.stageid,
        onlyrm: "RMonly",
        onlycs: "",
      }
      if(this.activeleads.length <= livecount){
        return this._phpService
        .gettodaymeetings(param)
        .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }else if(localStorage.getItem('Role') == '50004'){
      var param2 = {
        limit: limit, 
        limitrows: limitrows,
        rmid: "",
        csid: this.userid,  
        stages: this.stageid,
        onlyrm: "",
        onlycs: "CSonly",
      }
      if(this.activeleads.length <= livecount){
        return this._phpService
        .gettodaymeetings(param2)
        .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }
    }else if(this.router.url.indexOf('missedmeetings') > -1){
      const limit = TodaysComponent.count+=30;
    let limitrows = 30;
    let livecount = this.activeleadcount.activecount;
    if (localStorage.getItem('Role') == '1'){
      var param3 = {
        limit: limit, 
        limitrows: limitrows,
        rmid: this.rmid,
        csid: this.csid,  
        stages: this.stageid,
      }
      if(this.activeleads.length <= livecount){
        return this._phpService
        .getmissedmeetings(param3)
        .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }
    else if(localStorage.getItem('Role') == '50002'){
      var param = {
        limit: limit, 
        limitrows: limitrows,
        rmid: this.userid,
        csid: "",  
        stages: this.stageid,
        onlyrm: "RMonly",
        onlycs: "",
      }
      if(this.activeleads.length <= livecount){
        return this._phpService
        .getmissedmeetings(param)
        .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }else if(localStorage.getItem('Role') == '50004'){
      var param2 = {
        limit: limit, 
        limitrows: limitrows,
        rmid: "",
        csid: this.userid,  
        stages: this.stageid,
        onlyrm: "",
        onlycs: "CSonly",
      }
      if(this.activeleads.length <= livecount){
        return this._phpService
        .getmissedmeetings(param2)
        .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead.Missedmeeting);
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else{
        
      }
    }
    }

  }

  rmchange(val){
    this.filterLoader = true;
    if (this.router.url.indexOf('todaysmeetings') > -1){
      TodaysComponent.count = 0;
    this.rmid = val;
    var param = {
      limit: 0, 
      limitrows: 30,
      rmid: val,
      csid: this.csid,  
      stages: this.stageid,
    }
    this._phpService
    .gettodaymeetings(param)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param2 = {
      rmid: val,
      csid: this.csid, 
    }
    this._phpService
    .gettodaymeetingscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })

    }else if(this.router.url.indexOf('missedmeetings') > -1){
      TodaysComponent.count = 0;
    this.rmid = val;
    var param = {
      limit: 0, 
      limitrows: 30,
      rmid: val,
      csid: this.csid,  
      stages: this.stageid,
    }
    this._phpService
    .getmissedmeetings(param)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param2 = {
      rmid: val,
      csid: this.csid, 
    }
    this._phpService
    .getmissedmeetingscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })

    }
    
  }

  cschange(val){
    this.filterLoader = true;
    if (this.router.url.indexOf('todaysmeetings') > -1){
      this.csid = val;
    TodaysComponent.count = 0;
    var rmid = $('#rm_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      rmid: this.rmid,
      csid: val,  
    }
    this._phpService
    .gettodaymeetings(param)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param2 = {
      rmid: rmid,
      csid: val, 
    }
    this._phpService
    .gettodaymeetingscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })
    }else if(this.router.url.indexOf('missedmeetings') > -1){
      this.csid = val;
    TodaysComponent.count = 0;
    var rmid = $('#rm_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      rmid: this.rmid,
      csid: val,  
    }
    this._phpService
    .getmissedmeetings(param)
    .subscribe(activelead => {
      this.activeleads = activelead.Missedmeeting;
      if(activelead.status == 'True'){
        this.filterLoader = false;
      }else{
        this.filterLoader = false;
      }
    })

    var param2 = {
      rmid: rmid,
      csid: val, 
    }
    this._phpService
    .getmissedmeetingscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
        this.followcount = this.activeleadcount.followcount;
        this.f2fcount = this.activeleadcount.facetoface;
        this.usvcount = this.activeleadcount.usvcount;
        this.svcount = this.activeleadcount.svcount;
        this.rsvcount = this.activeleadcount.rsvcount;
        this.negocount = this.activeleadcount.nego_count;
    })
    }

  }

  followupclick()
  {
    $(".other_section").removeClass("active");
    $(".followup_class").addClass("active");
    this.stageid = "Follow Up";
    this.commonfilterclick();
  }

  f2fclick()
  {
    $(".other_section").removeClass("active");
    $(".f2f_class").addClass("active");
    this.stageid = "Face to Face Meeting";
    this.commonfilterclick();
  }

  usvclick()
  {
    $(".other_section").removeClass("active");
    $(".usv_class").addClass("active");
    this.stageid = "USV";
    this.commonfilterclick();
  }

  svclick()
  {
    $(".other_section").removeClass("active");
    $(".sv_class").addClass("active");
    this.stageid = "SV";
    this.commonfilterclick();
  }

  rsvclick()
  {
    $(".other_section").removeClass("active");
    $(".rsv_class").addClass("active");
    this.stageid = "RSV";
    this.commonfilterclick();
  }

  negoclick()
  {
    $(".other_section").removeClass("active");
    $(".nego_class").addClass("active");
    this.stageid = "Final Negotiation";
    this.commonfilterclick();
  }

  commonfilterclick()
  {
    this.filterLoader = true;
    if (this.router.url.indexOf('todaysmeetings') > -1){
      if (localStorage.getItem('Role') == '1'){
        TodaysComponent.count = 0;
        var param3 = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          rmid: this.rmid,
          csid: this.csid,  
          stages: this.stageid,
          
        }
        this._phpService
        .gettodaymeetings(param3)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
      if(localStorage.getItem('Role') == '50002'){
        TodaysComponent.count = 0;
        var param = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          rmid: this.userid,
          stages: this.stageid,
          onlyrm: "RMonly",
        }
        this._phpService
        .gettodaymeetings(param)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else if(localStorage.getItem('Role') == '50004'){
        TodaysComponent.count = 0;
        var param2 = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          csid: this.userid,  
          stages: this.stageid,
          onlycs: "CSonly",
        }
        this._phpService
        .gettodaymeetings(param2)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.router.url.indexOf('missedmeetings') > -1){
      if (localStorage.getItem('Role') == '1'){
        TodaysComponent.count = 0;
        var param3 = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          rmid: this.rmid,
          csid: this.csid,  
          stages: this.stageid,
          
        }
        this._phpService
        .getmissedmeetings(param3)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
      if(localStorage.getItem('Role') == '50002'){
        TodaysComponent.count = 0;
        var param = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          rmid: this.userid,
          stages: this.stageid,
          onlyrm: "RMonly",
        }
        this._phpService
        .getmissedmeetings(param)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }else if(localStorage.getItem('Role') == '50004'){
        TodaysComponent.count = 0;
        var param2 = {
          limit: 0, 
          limitrows: 30,
          fromdate: this.fromdate,
          todate: this.todate,
          csid: this.userid,  
          stages: this.stageid,
          onlycs: "CSonly",
        }
        this._phpService
        .getmissedmeetings(param2)
        .subscribe(activelead => {
          this.activeleads = activelead.Missedmeeting;
          if(activelead.status == 'True'){
            this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }
    

    

  }

  filterItems: any;
  selected;
  activeleads: any;

  refresh()
  {
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    $('#fromdate').val("");
    $('#todate').val("");
    $('#rm_dropdown').dropdown('clear');
    $('#cs_dropdown').dropdown('clear');
    this.stageid = "";
    this.ngOnInit();
  }

}
