import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Http } from '@angular/http';
import { phpservice } from '../../php.service';
import {Router} from '@angular/router';
import { AuthService } from '../../auth-service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  status : any;
  resposeData :any;

  loginData = {"username":"", "password":"", "number":"", "otpmodel":""};
  constructor(private _phpService:phpservice,
    private http:Http,
    public authService: AuthService,
    private router: Router) { 
    }
    filterLoader: boolean = false;
    usernamelogin = false;
    numberlogin = true;

  ngOnInit() {
    
    this.scriptsloads();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
      this.usernamelogin = false;
      this.numberlogin = true;
      // if(this.router.url == "/adminlogin"){
      //   // this.router.navigateByUrl('/adminlogin');
      //   this.usernamelogin = true;
      //   this.numberlogin = false;
      // }else{
        
      // }
    } 
    else if(localStorage.getItem('Role') != null)
    {
      if (localStorage.getItem('Role') == '1')
    {
      this.router.navigateByUrl('/leads');
    }
    // if (localStorage.getItem('Role') == '1')
    // {
    //   this.router.navigateByUrl('/Admin');
    // }
    // else if(localStorage.getItem('Role') == '50001')
    // {
    //   this.router.navigateByUrl('RMTeamleader/Dashboard');

    // }else if(localStorage.getItem('Role') == '50002')
    // {
    //   this.router.navigateByUrl('RMExecutive/Dashboard');
    // }
    // else if(localStorage.getItem('Role') == '50003')
    // {
    //   this.router.navigateByUrl('CSTeamleader/Dashboard');
    // }
    // else if(localStorage.getItem('Role') == '50004')
    // {
    //   this.router.navigateByUrl('CSExecutive/Dashboard');
    // }
    }
  }

  scriptsloads(){
    var otp_inputs = document.querySelectorAll(".otp__digit");
    const elementArray = Array.from(otp_inputs);
    var mykey = "0123456789".split("");
    let values = Array(4);
    let clipData;
    (otp_inputs[0] as HTMLElement).focus();
    elementArray.forEach((element, index) => {

      const { value } = element as HTMLInputElement;
      element.addEventListener('keyup', (event: KeyboardEvent) => {
        // OLD MEthod
        
          let _finalKey = ""

          elementArray.forEach((element) => {
            const inputElement = element as HTMLInputElement;
            _finalKey += inputElement.value;
          });
        
            if(_finalKey.length == 4){
            const otpElement = document.querySelector("#_otp");
            otpElement.classList.remove("_notok");
            otpElement.classList.add("_ok");
            $('#otpselected').val(_finalKey);
            // $scope.mailsend.otpmodel = _finalKey;
            // $('#verify-btn').removeAttr('disabled');
              
            this.loginData.otpmodel = _finalKey;
            this.otpverify();
            // this.otpverify();
          }else{
            const otpElement = document.querySelector("#_otp");
            otpElement.classList.remove("_ok");
            otpElement.classList.add("_notok");
            $('#verify-btn').attr('disabled', 'true');
          }
          // OLD MEthod
          if (element instanceof HTMLInputElement){
            if(event.code === "Backspace" && hasNoValue(index)) {
              if(index > 0) (elementArray[index - 1] as HTMLElement).focus();
            }
            
            // else if any input move focus to next or out
            else if (element.value !== "") {
              (index < elementArray.length - 1) ? (elementArray[index + 1] as HTMLElement).focus() : element.blur();
            }
          }
        
        
        //add val to array to track prev vals
        values[index] = (event.target as HTMLInputElement).value;
        
      });
  
      element.addEventListener('input', () => {
        //replace digit if already exists
        if (element instanceof HTMLInputElement){
          const numericValue = 10;
          const stringNumber = numericValue.toString();
          if(element.value > stringNumber) {
            element.value = String(Number(element.value) % Number(10));
          }
        }
      });

    })

    
    function hasNoValue(index) {
      if(values[index] || values[index] === 0) 
        return false;
      return true;
    }
  }

  loginswap(){
    this.usernamelogin = !this.usernamelogin;
    this.numberlogin = !this.numberlogin;
  }

  // Method of Based on Username and Password

  loginold(){
    if($('#name').val()==""){
          $('#name').focus().css("border-color","red").attr('placeholder','Please Enter your username');
          return false;
      }else{
        $('#name').removeAttr("style"); 
      }

    if($('#password').val()=="")
      {
          $('#password').focus().css("border-color","red").attr('placeholder','Please Enter your Password');
          return false;
      }
      else
      {
        $('#password').removeAttr("style"); 
      }

      this._phpService.getlogin(this.loginData.username,this.loginData.password).subscribe((success) => {
        this.status = success.status;
        this.resposeData = success;
        // console.log("Status:", this.status);
        if(this.status == "True"){
          var name = success.details[0].name;
          var userid = success.details[0].executives_FKID;
          var mail = success.details[0].email;
          var roleid = success.details[0].role_IDFK;
          var deptid = success.details[0].department_IDFK;
          localStorage.setItem('Name', name);
          localStorage.setItem('UserId', userid);
          localStorage.setItem('Password', "xxxxxxx");
          localStorage.setItem('Mail', mail);
          localStorage.setItem('Role', roleid);
          localStorage.setItem('Department', deptid);
          // localStorage.setItem('userData', JSON.stringify(this.resposeData) )
          if(localStorage.getItem('Department') == '10004'){
            this.router.navigateByUrl('/mandateleads?freshid=2');
          }else{
            if (localStorage.getItem('Role') == null)
          {
            this.router.navigateByUrl('/login');
          } 
          else if(localStorage.getItem('Role') != null)
          {
            if (localStorage.getItem('Role') == '1')
            {
              // this.router.navigateByUrl('/Enquiry');
              this.router.navigateByUrl('dashboard?allvisits=1&from=&to=');
            }
            else{
              this.router.navigateByUrl('dashboard?allvisits=1&from=&to=');
              // this.router.navigateByUrl('rmleadassign?todayscheduled=1&from=&to=');
              // localStorage.clear();
              // swal({
              //   title: 'Restricted Access!!',
              //   text: 'You are not authorized for this access. Please use OTP based login',
              //   type: 'error',
              //   confirmButtonText: 'ok'
              // })
            }
          }
          }
          
       }  
       else{
         swal({
           title: 'Authentication Failed!',
           text: 'Please try agin',
           type: 'error',
           confirmButtonText: 'ok'
         })
       }
      }, (err) => {
        console.log("Connection Failed")
      });

  }

    // Method of Based on Username and Password

  // OTP Based Login

  login(){

    if($('#number').val()=="")
           {
               $('#number').focus().css("border-color","red").attr('placeholder','Please Enter Your Registered Number');
               return false;
           }
    else{
             var mobileno=/^[0-9]{10}$/;
                if(mobileno.test($('#number').val()))
                {
                    $('#number').removeAttr("style");
                }
                else{
                      $('#number').focus().css("border-color","red").attr('placeholder','Please enter valid contact number').val('');
                      return false;
                }
            $('#number').removeAttr("style"); 
            }
            
            this.filterLoader = true;
                this._phpService.loginotpsend(this.loginData.number).subscribe((success) => {
                this.status = success.status;
                this.resposeData = success;
        // console.log("Status:", this.status);
        if(this.status == "True"){
          this.filterLoader = false;
          $('#modalclick').click();
            var fiveMinutes = 30 * 1,
            display = document.querySelector('#time');
            this.startTimer(fiveMinutes, display);
       }  
       else{
        this.filterLoader = false;
         swal({
           title: 'Number Not Registered',
           text: 'Please check your number',
           type: 'error',
           confirmButtonText: 'ok'
         })
       }
      }, (err) => {
        console.log("Connection Failed")
      });
            

  }

  // OTP Based Login


  startTimer(duration, display) {
    let timer: number = duration;
    let minutes: number;
    let seconds: number;
    const __timer1 = setInterval(() => {
      minutes = Math.floor(timer / 60); // Calculate minutes as integer
      seconds = timer % 60; // Calculate seconds as integer
  
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes.toString();
      const formattedSeconds = seconds < 10 ? "0" + seconds : seconds.toString();
      display.textContent = formattedMinutes + ":" + formattedSeconds;
  
      if (--timer < 0) {
        clearInterval(__timer1);
        $('.otpresend').removeAttr("style");
        display.textContent = "00:00";
      }
    }, 1000);
}

otpresend(){
  this.filterLoader = true;
  this._phpService.loginotpsend(this.loginData.number).subscribe((success) => {
    this.status = success.status;
    this.resposeData = success;
    if(this.status == "True"){
    this.filterLoader = false;
    $('.otpresend').attr('style', 'display:none');
    var fiveMinutes = 30 * 1,
    display = document.querySelector('#time');
    this.startTimer(fiveMinutes, display);
    }  
    else{
    this.filterLoader = false;
    swal({
    title: 'Number Not Registered',
    text: 'Please check your number',
    type: 'error',
    confirmButtonText: 'ok'
    })
    }
    }, (err) => {
    console.log("Connection Failed")
    });
}

otpverify(){
  this.filterLoader = true;
  this._phpService.otpvalidate(this.loginData.otpmodel,this.loginData.number).subscribe((success) => {
    var status = success['status'];
    if(status == "True"){
        var logindata = success['success'][0];
        this._phpService.getlogin(logindata.email,logindata.password).subscribe((success) => {
        this.status = success.status;
        this.resposeData = success;
        // console.log("Status:", this.status);
        if(this.status == "True"){
          this.filterLoader = false;
          var name = success.details[0].name;
          var userid = success.details[0].executives_FKID;
          var mail = success.details[0].email;
          var roleid = success.details[0].role_IDFK;
          var deptid = success.details[0].department_IDFK;
          localStorage.setItem('Name', name);
          localStorage.setItem('UserId', userid);
          localStorage.setItem('Password', "xxxxxxx");
          localStorage.setItem('Mail', mail);
          localStorage.setItem('Role', roleid);
          localStorage.setItem('Department', deptid);
          // localStorage.setItem('userData', JSON.stringify(this.resposeData) )
          if(localStorage.getItem('Department') == '10004'){
            this.router.navigateByUrl('/mandateleads?freshid=2');
          }else{
            if (localStorage.getItem('Role') == null)
          {
            this.router.navigateByUrl('/login');
          } 
          else if(localStorage.getItem('Role') != null)
          {
            if (localStorage.getItem('Role') == '1')
            {
              this.router.navigateByUrl('/Enquiry');
            }
            else if(localStorage.getItem('Role') == '50002')
            {
              this.router.navigateByUrl('dashboard?allvisits=1&from=&to=');
            }
            else if(localStorage.getItem('Role') == '50011')
            {
              this.router.navigateByUrl('/clientleads');
            }
            else if(localStorage.getItem('Role') == '50004')
            {
              // this.router.navigateByUrl('CSExecutive/Dashboard');
              this.router.navigateByUrl('csleadassign?allassigned=1&from=&to=');
            }
          }
          }
          $('.modal-backdrop').removeClass('fade');
          $('.modal-backdrop').removeClass('show');
          $('.modal-backdrop').removeClass('modal-backdrop'); 
       }  
       else{
         swal({
           title: 'Authentication Failed!',
           text: 'Please try agin',
           type: 'error',
           confirmButtonText: 'ok'
         })
       }
      }, (err) => {
        console.log("Connection Failed")
      });

    }  
    else{
      this.filterLoader = false;
      swal({
        title: 'Oops Something Error!',
        text: 'Its Not a valid OTP / OTP Expired!',
        type: 'error',
        showConfirmButton: false
      }).then(function() {
    });
    }
    }, (err) => {
    console.log("Connection Failed")
    });

    }



}
