import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.css']
})
export class FollowupComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpService:phpservice) {
   }
   static count: number;
  followups:any;
  followupcounts: any;
  rmexecutives: any;
  csexecutives: any;
  followupsectionid: any;
  ngOnInit() {
    this.getfollowup();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    FollowupComponent.count = 0;
  }

  getfollowup(){
    if (localStorage.getItem('Role') == '1')
    {
    this.followupadmindata();
    } else if(localStorage.getItem('Role') == '50002'){
      var id = localStorage.getItem('UserId');
      this._phpService
    .followuprm(id)
    .subscribe(follow =>{
      this.followups = follow;
    })
    } else if(localStorage.getItem('Role') == '50004'){
      var id = localStorage.getItem('UserId');
      this._phpService
    .followupcs(id)
    .subscribe(follow =>{
      this.followups = follow;
    })
    }
  }

  followupadmindata()
  {
    FollowupComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,  
    }
    this._phpService
    .followup(param)
    .subscribe(follow =>{
      this.followups = follow;
    })
    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid, 
      sectionid: this.followupsectionid,
    }
    this._phpService
    .followupcounts(param2)
    .subscribe(followcounts => {
        this.followupcounts = followcounts[0];
    });
    var defaultrmid = '50002'
    this._phpService.getexecutivesbasedid(defaultrmid).subscribe(rms =>{this.rmexecutives = rms;})

    var defaultcsid = '50004'
    this._phpService.getexecutivesbasedid(defaultcsid).subscribe(cs =>{this.csexecutives = cs;})
  }

  selecttodate(){
    if($('#fromdate').val()== ""){
      swal({
        title: 'Please Select the Date Range!',
        type: 'error',
        confirmButtonText: 'ok'
      })
    return false;
    }else{

    } 
    if($('#todate').val()== ""){
      swal({
        title: 'Please Select the Date Range!',
        type: 'error',
        confirmButtonText: 'ok'
      })
    return false;
    }else{

    }
    FollowupComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,  
      sectionid: this.followupsectionid,
    }
    this._phpService
    .followup(param)
    .subscribe(follow =>{
      this.followups = follow;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid, 
    }
    this._phpService
    .followupcounts(param2)
    .subscribe(followcounts => {
        this.followupcounts = followcounts[0];
    });
  }

  rmchange(val){
    FollowupComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: val,
      csid: csid,  
      sectionid: this.followupsectionid,
    }
    this._phpService
    .followup(param)
    .subscribe(follow =>{
      this.followups = follow;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: val,
      csid: csid, 
    }
    this._phpService
    .followupcounts(param2)
    .subscribe(followcounts => {
        this.followupcounts = followcounts[0];
    });
  }

  cschange(val){
    FollowupComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: val,  
      sectionid: this.followupsectionid,
    }
    this._phpService
    .followup(param)
    .subscribe(follow =>{
      this.followups = follow;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: val, 
    }
    this._phpService
    .followupcounts(param2)
    .subscribe(followcounts => {
        this.followupcounts = followcounts[0];
    });
  }

  loadMore()
  {
    const limit = FollowupComponent.count+=30;
    let limitrows = 30;
    let livecount = this.followupcounts.followupcount;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: limit, 
      limitrows: limitrows,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,  
      sectionid: this.followupsectionid,
    }
    if(this.followups.length <= livecount){
      return this._phpService
      .followup(param)
      .subscribe(follow => {
          this.followups = this.followups.concat(follow);
      })
    }else{

    }
  }

  followupclick()
  {
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    this.followupsectionid = "";
    this.commonfilterclick();
  }
  callbackclick()
  {
    $(".other_section").removeClass("active");
    $(".callback").addClass("active");
    this.followupsectionid = '1';
    this.commonfilterclick();
  }
  rnrclick()
  {
    $(".other_section").removeClass("active");
    $(".rnr").addClass("active");
    this.followupsectionid = '2';
    this.commonfilterclick();
  }
  switchoffclick()
  {
    $(".other_section").removeClass("active");
    $(".switchoff").addClass("active");
    this.followupsectionid = '3';
    this.commonfilterclick();
  }
  notconnectclick()
  {
    $(".other_section").removeClass("active");
    $(".notconnect").addClass("active");
    this.followupsectionid = '4';
    this.commonfilterclick();
  }
  busyclick()
  {
    $(".other_section").removeClass("active");
    $(".numberbusy").addClass("active");
    this.followupsectionid = '5';
    this.commonfilterclick();
  }
  noanswerclick()
  {
    $(".other_section").removeClass("active");
    $(".noanswer").addClass("active");
    this.followupsectionid = '6';
    this.commonfilterclick();
  }
  notreachclick()
  {
    $(".other_section").removeClass("active");
    $(".notreach").addClass("active");
    this.followupsectionid = '7';
    this.commonfilterclick();
  }

  commonfilterclick()
  {
    FollowupComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,
      sectionid: this.followupsectionid,
    }
    this._phpService
    .followup(param)
    .subscribe(follow =>{
      this.followups = follow;
    })
  }
  
  refresh()
  {
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    this.followupsectionid = "";
    $('#fromdate').val("");
    $('#todate').val("");
    $('#rm_dropdown').dropdown('clear');
    $('#cs_dropdown').dropdown('clear');
    this.followupadmindata();
  }


}
