import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';

@Component({
  selector: 'app-sv',
  templateUrl: './sv.component.html',
  styleUrls: ['./sv.component.css']
})
export class SvComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpservice:phpservice ) { 
  }

  uniquesite:any;
  ngOnInit() {
    this.getsv();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getsv(){
    if (localStorage.getItem('Role') == '1')
    {
    this._phpservice.getsvlist()
    .subscribe(unique => {
        this.uniquesite = unique;
    })
    } else if(localStorage.getItem('Role') == '50002'){
      var id = localStorage.getItem('UserId');
      this._phpservice.getrmsvlist(id)
    .subscribe(unique => {
        this.uniquesite = unique;
    })

    } else if(localStorage.getItem('Role') == '50004'){
      var id = localStorage.getItem('UserId');
      this._phpservice.getcssvlist(id)
    .subscribe(unique => {
        this.uniquesite = unique;
    })

    }
  }
}
