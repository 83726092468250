import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-addproperties',
  templateUrl: './addproperties.component.html',
  styleUrls: ['./addproperties.component.css']
})
export class AddpropertiesComponent implements OnInit {

  builders: any;
  properties: any;
  selectedproperties: any;
  builderid: any;
  status: any;
  addedpropertylists: any;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice) { }

  ngOnInit() {
    this._phpService
      .builderlist()
      .subscribe(builderlist => {
        this.builders = builderlist;
      });

      this._phpService
      .propertylistnew()
      .subscribe(proplist => {
        this.addedpropertylists = proplist;
      });
  }

  builderchange(event){
    // alert(event.target.value);
    this.builderid = event.target.value;
    this._phpService
      .getpropertiesbybuilder(this.builderid)
      .subscribe(propertylist => {
        this.properties = propertylist['Properties'];
      });
  }

  addproperties()
  {
    this.selectedproperties = $(".onchangepropertyselected").dropdown("get value");
    if (this.selectedproperties == "") {
      swal({
        title: 'Select Some Properties',
        type: 'error',
        confirmButtonText: 'ok'
      })
      return false;
    } else {
      $('.onchangepropertyselected').removeAttr("style");
    }

    var param = {
      builderid: this.builderid,
      properties: this.selectedproperties,
    }
    
    this._phpService.addproperties(param).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        swal({
          title: 'Successfully Addedd',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            location.reload();
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
  }

}
