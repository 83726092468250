import { Injectable, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 

@Injectable()

@Pipe({
    name: 'unique',
    pure: false
  })
  export class UniquePipe implements PipeTransform {
    transform(items: any[], args: any[]): any {
        // filter items array, items which match and return true will be kept, false will be filtered out

        return _.uniqBy(items, args);
    }
}

@Pipe({
  name: 'NameFilter',
  pure: false
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      let rVal = (val.CustomerName.toLocaleLowerCase().includes(args)) || (val.CustomerName.includes(args)) ||
       (val.Executivename.includes(args)) || (val.Executivename.toLocaleLowerCase().includes(args));
      return rVal;
    })

  }
}

@Pipe({
  name: 'PropertyFilter',
  pure: false
})
export class Searchproperty implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!args) {
      return value;
    }
    return value.filter((val) => {
      let rVal = (val.registered_property.toLocaleLowerCase().includes(args)) || (val.registered_property.includes(args));
      return rVal;
    })

  }
}

@Pipe({
  name: 'duplication',
  pure: false
})

export class duplicatePipe implements PipeTransform {
    transform(value: any): any{
        if(value!== undefined && value!== null){
            return _.uniqBy(value, 'number');
        }
        return value;
    }
}