import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-closedleads',
  templateUrl: './closedleads.component.html',
  styleUrls: ['./closedleads.component.css']
})
export class ClosedleadsComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpservice:phpservice ) {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
    }, 1000);
  }

  closedleads:any;
  ngOnInit() {
    // this.getclosedleads();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }
  // getclosedleads(){
  //   if (localStorage.getItem('Role') == '1')
  //   {
  //   this._phpservice
  //   .getclosedlists()
  //   .subscribe(lead => {
  //       this.closedleads = lead;
  //   })
  // } else if(localStorage.getItem('Role') == '50002'){
  //   var id = localStorage.getItem('UserId');
  //   this._phpservice
  //   .getclosedlistsrm(id)
  //   .subscribe(lead => {
  //       this.closedleads = lead;
  //   })
  // } else if(localStorage.getItem('Role') == '50004') {
  //   var id = localStorage.getItem('UserId');
  //   this._phpservice
  //   .getclosedlistscs(id)
  //   .subscribe(lead => {
  //       this.closedleads = lead;
  //   })
  // }
  // }

}
