export class Enquiry {
   
    IDPK:any;
    name: any;
    number: any;
    mail: any;
    source: any;
    propertytype: any;
    timeline: any;
    size: any;
    budget: any;
    address: any;
    username: any;
}

export class leadforward {
    customersupport: string;
    telecallername: string;
    assignedleads: string;

}

export class leadadd {
    customer_name: any;
    customer_number: any;
    customer_source_IDPK: any;
}
