import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { phpservice } from '../../php.service';

@Component({
  selector: 'app-usv',
  templateUrl: './usv.component.html',
  styleUrls: ['./usv.component.css']
})
export class UsvComponent implements OnInit {
  public userDetails: any;
  constructor(private router: Router,private _phpservice:phpservice ) { 
  }

  uniquesite:any;
  ngOnInit() {
    this.getuniquesitevisit();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getuniquesitevisit(){
    if (localStorage.getItem('Role') == '1')
    {
    this._phpservice
    .getusv()
    .subscribe(unique => {
        this.uniquesite = unique;
    })
    } else if(localStorage.getItem('Role') == '50002'){
      var id = localStorage.getItem('UserId');
      this._phpservice
    .getrmusv(id)
    .subscribe(unique => {
        this.uniquesite = unique;
    })
    } else if(localStorage.getItem('Role') == '50004'){
      var id = localStorage.getItem('UserId');
      this._phpservice
    .getcsusv(id)
    .subscribe(unique => {
        this.uniquesite = unique;
    })
    }
  }

}
