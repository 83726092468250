import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { mandateservice } from '../../mandate.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-addcp',
  templateUrl: './addcp.component.html',
  styleUrls: ['./addcp.component.css']
})
export class AddcpComponent implements OnInit {

  cplists: any;
  status: any;
  responsedate: any;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location,private _phpService: phpservice, private _mandateservice: mandateservice) { }

  ngOnInit() {
    this._mandateservice
      .cplists()
      .subscribe(proplist => {
        this.cplists = proplist;
      });
  }

  addnewcp()
  {
    if($('#custname').val()=="")
    {
        $('#custname').focus().css("border-color","red").attr('placeholder','Please Enter Name');
        return false;
    }
    else{
      $('#custname').removeAttr("style");
  } 
if($('#contactperson').val()=="")
     {
         $('#contactperson').focus().css("border-color","red").attr('placeholder','Please Enter Person Name');
         return false;
     }
  else{
      $('#contactperson').removeAttr("style"); 
      }

if($('#custnum').val()=="")
     {
         $('#custnum').focus().css("border-color","red").attr('placeholder','Please Enter Phone Number');
         return false;
     }
  else{
    var mobilee = /^[0-9]{10}$/;
    if (mobilee.test($('#custnum').val())) {
      $('#custnum').removeAttr('style');
    } else {
      $('#custnum').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid contact number').val('');
      return false;
    }
      }
      if($('#custmail').val()=="")
      {
          $('#custmail').focus().css("border-color","red").attr('placeholder','Please Enter Email-id');
          return false;
      }
     else{
        var email=/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
          if(email.test($('#custmail').val()))
          {
              $('#custmail').removeAttr("style");
          }
           else{
                $('#custmail').focus().css("border-color","red").attr('placeholder','Please enter valid email-id').val('');
                return false;
           }
       }
       if($('#gstno').val()=="")
     {
         $('#gstno').focus().css("border-color","red").attr('placeholder','Please Enter GST');
         return false;
     }
else{
      $('#gstno').removeAttr("style"); 
      }
      if($('#rerano').val()=="")
     {
         $('#rerano').focus().css("border-color","red").attr('placeholder','Please Enter Rera');
         return false;
     }
  else{
        $('#rerano').removeAttr("style"); 
        }

    var param = {
      cpname: $('#custname').val(),
      person: $('#contactperson').val(),
      cpnumber: $('#custnum').val(),
      cpmail: $('#custmail').val(),
      cpsecondmail: $('#custmailsecondary').val(),
      addeduser: localStorage.getItem('Name'),
      rera: $('#rerano').val(),
      gst: $('#gstno').val(),
    }

    // console.log(param);
    
    this._mandateservice.addcp(param).subscribe((success) => {
      this.status = success.status;
      this.responsedate = success['cpadd'];
      // console.log(this.responsedate[0].CP_NAME);
      if (this.status == "True") {
        swal({
          title: 'Successfully Addedd',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            // location.reload();
          }
        });
        this.ngOnInit();
      }else{
        swal({
          title: 'CP Not Added',
          text: 'Given Number or Mail id is already registered with '+this.responsedate[0].CP_NAME,
          type: "error",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            // location.reload();
            // this.ngOnInit();
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })


  }

}
