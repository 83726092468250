import { Component, OnInit} from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { filtercnts } from './filter_content';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  selectedNav: string = "";
  public userDetails: any;
  
  contents: filtercnts[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _phpService:phpservice,
  ) {
    this.ngOnInit();
   }

  enqrycount:any;
  executives:any;
  projects:any;
  wishername: any;
  ngOnInit() {
    
    $('.ui.dropdown').dropdown();
    this.getcounts();
    this.getExecutives();
    this.getfilters();
    this.getProjects();
    this.getwishers();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }
  ngAfterViewInit()	{ }

  // ONLY-FOR-COUNTSVIEW
   
  counts:any;
  Enquirycounts:any;
  activecounts:any;
  inactivecounts:any;
  junkcounts:any;
  followcounts:any;
  face2facecounts:any;
  usvcounts:any;
  svcounts:any;
  rsvcounts:any;
  negocounts:any;
  closedcounts:any;

  activeleads:any;
  negotiate:any;
  filters:any;
  
  getcounts(){
    var defaultid = "1";
    this._phpService
    .getdashfilter(defaultid)
    .subscribe(count => {
      // this.counts = count;
      this.counts = count;
      var enqrcount = this.counts[0].Enquirycount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Enquirycounts = enqrcount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;

      this.negocounts = negocount;
      this.closedcounts = closedcount;
      // console.log("Counts",enqrcount);
    }) 
  }

  onSelect(id) { 
    // alert(id);
    this._phpService
    .getdashfilter(id)
    .subscribe(count => {
      this.counts = count;
      var enqrcount = this.counts[0].Enquirycount;
      var activecount = this.counts[0].activecount;
      var followcount = this.counts[0].followcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Enquirycounts = enqrcount;
      this.activecounts = activecount;
      this.followcounts = followcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
      // console.log("Counts",enqrcount);
    }) 
};

// ONLY-FOR-COUNTSVIEW

  getfilters(){
    this._phpService.getfilter().subscribe(filter => { this.filters = filter;})
  }
  getwishers(){
    this._phpService
    .getwish()
    .subscribe(wish => {
      this.wishername = wish[0];
    })
  }
  getExecutives(){
    this._phpService
      .getDashboardtotal()
      .subscribe(executives => {
        this.executives = executives;
      })
  }
  getProjects(){
    this._phpService
      .getbuilderslist()
      .subscribe(projects => {
        this.projects = projects;
      })
  }

}
