import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { leadadd } from '../enquiry/enq';
import { phpservice } from '../../php.service';
import { ok } from 'assert';
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.css']
})
export class ActiveComponent implements OnInit {

  adminview:boolean;
  rmexecview:boolean;
  custsprtview:boolean;
  public userDetails: any;
  leadmodel = new leadadd();
  execleads: any;
  unassign: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _phpService:phpservice,
    private _DomSanitizer:DomSanitizer
    ) {
      this.activeleads;
      setTimeout(() => {
        $('.ui.dropdown').dropdown();
        $('.ui.checkbox').checkbox();
      }, 1000);
     }
  static count: number;
  static count2: number;
    
  ngOnInit() {
    $(".wrapper").hide();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if (localStorage.getItem('Role') == '1')
            {
              this.getactiveleads();
              this.adminview = true;
            }
    else if(localStorage.getItem('Role') == '50001')
            {
              this.getexeculeads();
              this.rmexecview = true;
            }
    else if(localStorage.getItem('Role') == '50002')
            {
              this.getexeculeads();
              this.rmexecview = true;
            }
    else if(localStorage.getItem('Role') == '50003')
            {
              this.getcsactive();
              this.custsprtview = true;
            }
            else if(localStorage.getItem('Role') == '50004')
            {
              this.getcsactive();
              this.custsprtview = true;
            }
    ActiveComponent.count = 0;
    ActiveComponent.count2 = 0;
  }
  activeleadcount: any;
  rmexecutives: any;
  csexecutives: any;
  stageid: any;
  getactiveleads(){
    ActiveComponent.count = 0;
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: "",
      todate: "",
      rmid: "",
      csid: "",  
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })

    var execid = '';

    var param2 = {
      fromdate: "",
      todate: "",
      rmid: "",
      csid: "", 
    }
    this._phpService
    .getactiveleadscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
    })

    var rmid = '50002'
    this._phpService.getexecutivesbasedid(rmid).subscribe(rms =>{this.rmexecutives = rms;})

    var csid = '50004'
    this._phpService.getexecutivesbasedid(csid).subscribe(cs =>{this.csexecutives = cs;})
  }

  selecttodate(){
    if($('#fromdate').val()== ""){
      swal({
        title: 'Please Select the Date Range!',
        type: 'error',
        confirmButtonText: 'ok'
      })
    return false;
    }else{

    } 
    if($('#todate').val()== ""){
      swal({
        title: 'Please Select the Date Range!',
        type: 'error',
        confirmButtonText: 'ok'
      })
    return false;
    }else{

    }
    ActiveComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid, 
      unassign: this.unassign 
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid, 
    }
    this._phpService
    .getactiveleadscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
    })
  }

  rmchange(val){
    ActiveComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: val,
      csid: csid,  
      stages: this.stageid,
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: val,
      csid: csid, 
    }
    this._phpService
    .getactiveleadscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
    })
  }

  cschange(val){
    ActiveComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: val,  
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })

    var param2 = {
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: val, 
    }
    this._phpService
    .getactiveleadscounts(param2)
    .subscribe(activecounts => {
        this.activeleadcount = activecounts[0];
    })
  }

  activeclick()
  {
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    this.stageid = "";
    this.commonfilterclick();
  }

  followupclick()
  {
    $(".other_section").removeClass("active");
    $(".followup_class").addClass("active");
    this.stageid = "Follow Up";
    this.commonfilterclick();
  }

  f2fclick()
  {
    $(".other_section").removeClass("active");
    $(".f2f_class").addClass("active");
    this.stageid = "Face to Face Meeting";
    this.commonfilterclick();
  }

  usvclick()
  {
    $(".other_section").removeClass("active");
    $(".usv_class").addClass("active");
    this.stageid = "USV";
    this.commonfilterclick();
  }

  svclick()
  {
    $(".other_section").removeClass("active");
    $(".sv_class").addClass("active");
    this.stageid = "SV";
    this.commonfilterclick();
  }

  rsvclick()
  {
    $(".other_section").removeClass("active");
    $(".rsv_class").addClass("active");
    this.stageid = "RSV";
    this.commonfilterclick();
  }

  negoclick()
  {
    $(".other_section").removeClass("active");
    $(".nego_class").addClass("active");
    this.stageid = "Final Negotiation";
    this.commonfilterclick();
  }

  closeclick()
  {
    $(".other_section").removeClass("active");
    $(".leadclose_class").addClass("active");
    this.stageid = "Lead Closed";
    this.commonfilterclick();
  }

  unassignedclick()
  {
    $(".other_section").removeClass("active");
    $(".unassign_class").addClass("active");
    this.unassign = "Unassigned"
    ActiveComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      unassign: this.unassign
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })
  }

  commonfilterclick()
  {
    ActiveComponent.count = 0;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    this.unassign = "";
    var param = {
      limit: 0, 
      limitrows: 30,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,  
      stages: this.stageid,
    }
    this._phpService
    .getactiveleadslist(param)
    .subscribe(activelead => {
        this.activeleads = activelead;
    })
  }

  loadMore(){
    const limit = ActiveComponent.count+=30;
    let limitrows = 30;
    let livecount = this.activeleadcount.activecount;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var rmid = $('#rm_dropdown').val();
    var csid = $('#cs_dropdown').val();
    var param = {
      limit: limit, 
      limitrows: limitrows,
      fromdate: fromdate,
      todate: todate,
      rmid: rmid,
      csid: csid,  
      stages: this.stageid,
      unassign: this.unassign
    }
    if(this.activeleads.length <= livecount){
      return this._phpService
      .getactiveleadslist(param)
      .subscribe(activelead => {
          this.activeleads = this.activeleads.concat(activelead);
      })
    }else{
      
    }
  }

  getexeculeads(){
    var id = localStorage.getItem('UserId');
    this._phpService
    .getexecleadslist(id)
    .subscribe(activelead => {
        this.execleads = activelead;
    })
  }

  getcsactive()
  {
    var id = localStorage.getItem('UserId');
    this._phpService
    .getcsactivelead(id)
    .subscribe(activelead => {
        this.execleads = activelead;
    })
  }
  
  getactive(id){
    this.router.navigate(['/Customer-Details/' + id]);
  };

  filterItems: any;
  selected;
  activeleads: any;

  refresh()
  {
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    $('#fromdate').val("");
    $('#todate').val("");
    $('#rm_dropdown').dropdown('clear');
    $('#cs_dropdown').dropdown('clear');
    this.ngOnInit();
  }

}
