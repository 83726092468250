import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {  Router, ActivatedRoute  } from '@angular/router';
import { mandateservice } from '../../mandate.service';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandateassigned',
  templateUrl: './mandateassigned.component.html',
  styleUrls: ['./mandateassigned.component.css']
})
export class MandateassignedComponent implements OnInit {

  @ViewChild('cancel')cancel:ElementRef;

  assignedleads:any;
  totalleads:any;
  tookaction:any;
  noaction:any;
  freshleadtable = true;
  tookactionmenu = false;
  assignedactionmenu = false;
  assigntabelview:boolean;
  freshleadview:boolean;
  status: any;
  userid:any;
  assignedleadscounts:any;
  loadmorelivecounts:any;
  activeleadscount: any;
  followups: any;
  followupcounts: any;
  followupsectionid: any;
  stagesections: any;
  fromdate: any;
  todate: any;
  adminview= false;
  userview= false;
  leadcounts: any;
  freshcounts: any;
  assignedcounts: any;
  notjunk= false;
  junkdata = false;
  nofollowup = false;
  followupdata = false;
  assignexecutives: any;
  constructor(private _mandateService:mandateservice,
    private route: ActivatedRoute, private router: Router,
    private _phpService:phpservice
    ) {
     }
     private isCountdownInitialized: boolean;
     rmpaneldata = false;
     static count: number;
     ngOnInit() {
      $('.ui.dropdown').dropdown();
       if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
      else if(localStorage.getItem('Role') == '50002')
      {
        this.getrmassigned();
        this.rmpaneldata = true;
        this.adminview = false;
        this.userview = true;
      } else if(localStorage.getItem('Role') == '1'){
        this.getexecutivesassign();
        this.getrmassigned();
        this.adminview = true;
        this.userview = false;
      }
      const elements = document.getElementsByClassName("modalclick");
      while (elements.length > 0) elements[0].remove();
      const el = document.createElement('div');
      el.classList.add('modalclick');
      document.body.appendChild(el);
      MandateassignedComponent.count = 0;
      
     }

     ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      $('.modalclick').click(function(){
        // this.morefilter();
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
        // p.style.visibility = "hidden";
      });
    }
  }

  getexecutivesassign()
{
  const id = '50010';
  this._phpService.getexecutivesbasedid(id).subscribe(execute => {this.assignexecutives = execute;})
}

     getrmassigned(){
      this.route.queryParams.subscribe((paramss) => {
        const assigned = paramss['assigned'];
        const freshid = paramss['freshid'];
        const pending = paramss['pending'];
        const followup = paramss['followup'];
        const svfix = paramss['svfix'];
        const svrefix = paramss['svrefix'];
        const svdone = paramss['svdone'];
        const junk = paramss['junk'];
        const rmid = paramss['rmid'];
        
        var userid = localStorage.getItem('UserId');

        if(userid == "1"){
          if (this.route.snapshot.queryParams['rmid']){
            this.userid = rmid;
          }else{
            this.userid = "";
          }
        }else{
          if (this.route.snapshot.queryParams['rmid']){
            this.userid = rmid;
          }else{
            this.userid = userid;
          }
        }
        if(freshid == '1'){
          var d = new Date();
          this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
          this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
          this.assignedactionmenu = true;
          this.notjunk = true;
          MandateassignedComponent.count = 0;
          this.status = freshid;
          var param = {
            limit: 0, 
            limitrows: 150,
            datefrom: this.fromdate,
            dateto: this.todate,
            statuss: this.status,
            executid: this.userid,
          }
      this.freshleadview = true;
      this._mandateService.completeassignedRMLeads(param).subscribe(freshlead => {
          this.assignedleads = freshlead;
      })
        }else if(freshid == '2'){
          this.assignedactionmenu = true;
          this.tookactionmenu = true;
          this.notjunk = true;
          this.junkdata = false;
          this.nofollowup = true;
          this.followupdata = false;
      MandateassignedComponent.count = 0;
      this.status = freshid;
      var param2 = {
        limit: 0, 
        limitrows: 150,
        datefrom: "",
        dateto: "",
        statuss: this.status,
        executid: this.userid,
      }
      this.freshleadview = true;
      this._mandateService.completeassignedRMLeads(param2).subscribe(freshlead => {
          this.assignedleads = freshlead;
      })  
          } else if(pending == '1'){
            this.assignedactionmenu = true;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = false;
            this.followupdata = false;
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param3 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: '1',
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param3).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          } else if(followup == '1'){
            this.assignedactionmenu = true;
            this.tookactionmenu = true;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = false;
            this.followupdata = true;
            $('.tookactionclass').addClass('active');
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param4 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: "",
              followup: "followup",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param4).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          } else if(svfix == '1'){
            this.assignedactionmenu = true;
            this.tookactionmenu = true;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = true;
            this.followupdata = false;
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param5 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: "",
              svfix: "svfix",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param5).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          } else if(svrefix == '2'){
            this.assignedactionmenu = true;
            this.tookactionmenu = true;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = true;
            this.followupdata = false;
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param6 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: "",
              svrefix: "svrefix",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param6).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          } else if(svdone == '3'){
            this.assignedactionmenu = true;
            this.tookactionmenu = true;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = true;
            this.followupdata = false;
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param7 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: "",
              svdone: "svdone",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param7).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          } else if(junk == '1'){
            this.assignedactionmenu = true;
            this.tookactionmenu = true;
            this.notjunk = false;
            this.junkdata = true;
            this.nofollowup = true;
            this.followupdata = false;
            MandateassignedComponent.count = 0;
            this.status = freshid;
            var param8 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completejunks(param8).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })  
          }
          if(assigned == '1'){
            this.assignedactionmenu = true;
            this.tookactionmenu = false;
            this.notjunk = true;
            this.junkdata = false;
            this.nofollowup = true;
            this.followupdata = false;
            var param9 = {
              limit: 0, 
              limitrows: 150,
              datefrom: "",
              dateto: "",
              statuss: "",
              executid: this.userid,
            }
            this.freshleadview = true;
            this._mandateService.completeassignedRMLeads(param9).subscribe(freshlead => {
                this.assignedleads = freshlead;
            })
          }
          var params = {
            limit : 0,
            limitrows : 30,
            todate : "",
            fromdate : "",
            property : "",
            freshid: freshid
          }
          this._mandateService.getmandateleadcounts(params).subscribe(enquiryscount =>
            {
              this.leadcounts = enquiryscount[0].Total;
              this.freshcounts = enquiryscount[0].Fresh;
              this.assignedcounts = enquiryscount[0].Assigned;
            })
            var countparam = {
              todate : "",
              fromdate : "",
              executid: this.userid,
            }
            this._mandateService.completeassignedRMLeadscounts(countparam).subscribe(assignedcounts => {
              this.assignedleadscounts = assignedcounts[0];
              this.loadmorelivecounts = this.assignedleadscounts.TodayFreshcounts;
            })
    });
    
      
     }

     selecttodate()
  {
    this.route.queryParams.subscribe((param) => {
      MandateassignedComponent.count = 0;
    var param9 = {
      limit: 0, 
      limitrows: 150,
      datefrom: $('#fromdate').val(),
      dateto: $('#todate').val(),
      statuss: "",
      executid: this.userid,
    }
    this.freshleadview = true;
    this._mandateService.completeassignedRMLeads(param9).subscribe(freshlead => {
        this.assignedleads = freshlead;
    })

    var countparam = {
      todate : $('#todate').val(),
      fromdate : $('#fromdate').val(),
      executid: this.userid,
    }
    this._mandateService.completeassignedRMLeadscounts(countparam).subscribe(assignedcounts => {
      this.assignedleadscounts = assignedcounts[0];
      this.loadmorelivecounts = this.assignedleadscounts.TodayFreshcounts;
    })
    
  });
  }

     getexecutivesnames(event)
    {
      const Data = event.target.value;
      this.userid = Data;
      const params = { rmid: Data };
      this.router.navigate([], { 
        queryParams: params,
        queryParamsHandling: 'merge',
       });
       this.getrmassigned();
    }

     freshleadclick()
     {
      this.tookactionmenu = false;
     }

     assignedclick(){
      this.assignedactionmenu = true;
     }

     tookactionclick()
     {
      this.tookactionmenu = true;
     }

     noactionclick()
     {
      this.tookactionmenu = false;
     }


     getactive(id){
      this.router.navigate(['/Customer-Details/' + id]);
    };

    refresh()
    {
      // this.router.navigate(['/mandateleads?freshid=2']);
      const params = { freshid: '2' };
      this.router.navigate(['/mandateleads'], { 
        queryParams: params,
       });
      // $(".other_section").removeClass("active");
      // $(".first_section").addClass("active");
      // this.freshleadtable = true;
      // this.getrmassigned();
    }

    morefilter()
    {
      document.getElementsByClassName('more_filter_maindiv')[0].removeAttribute("hidden");
      $('.modalclick').addClass('modal-backdrop');
      $('.modalclick').addClass('fade');
      $('.modalclick').addClass('show');
    }

}
