import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-closedform',
  templateUrl: './closedform.component.html',
  styleUrls: ['./closedform.component.css']
})
export class ClosedformComponent implements OnInit {
  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  leadid = this.route.snapshot.params['id'];
  visitedpropertylists: any;
  suggestchecked: any;
  executeid: any;
  csid: any;
  status: any;
  adminview: boolean;
  execview: boolean;
  selectedItem = 0;
  setActive: (buttonName: any) => void;
  isActive: (buttonName: any) => boolean;
  uploads: string[] = [];
  closurefiles: string[] = [];
  userid: any;
  autoremarks: any;
  filterLoader: boolean = true;

  constructor(private router: Router,
    private route: ActivatedRoute, private _location: Location, private _phpService: phpservice) { }

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.scriptfunctions();
    this.loadimportantapi();

    this._phpService
      .getassignedrm(this.leadid,this.userid)
      .subscribe(cust => {
        this.executeid = cust[0].executiveid;
      })

    // this._phpService
    //   .getassignedcs(this.leadid)
    //   .subscribe(cust => {
    //     this.csid = cust[0].executiveid;
    //   })

    // this.suggestchecked = "";

    if (localStorage.getItem('Role') == null) {
      this.router.navigateByUrl('/login');
    }
    else if (localStorage.getItem('Role') == '1') {
      this.adminview = true;
      this.execview = false;
    } else {
      this.adminview = false;
      this.execview = true;
    }
  }

  scriptfunctions() {
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });

    this.setActive = function (buttonName) {
      this.activeButton = buttonName;
    }
    this.isActive = function (buttonName) {
      return this.activeButton === buttonName;
    }

  }

  imageuploads(i,event){
    var fileInput = $('#customFile'+i);
    var maxSize = fileInput.data('max-size');
    var fileSize = fileInput.get(i).files[i].size;
      if(fileSize>maxSize){
        swal({
          title: 'File Size Exceeded',
          text: 'File Size limit is 1MB',
          type: "error",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
          }
        });
        return false;
      }else{
        var fileName = $('#customFile'+i).val().split("\\").pop();
        $('#customFile'+i).siblings(".file-label-"+i).addClass("selected").html(fileName);
        for (let j = 0; j < event.target.files.length; j++){
          this.closurefiles.push(event.target.files[j]);
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.uploads.push(event.target.result);
          };
          reader.readAsDataURL(event.target.files[j]);
        }
      }
  }

  removeImage(i) {
    this.uploads.splice(i, 1);
    if(this.uploads.length == 0){
      $("#customFile" + i).val('');
      $('.file-label-'+i).html('Choose file ');
    }else{

    }
    // alert(this.uploads.length);
  }


  loadimportantapi() {
    this.filterLoader = false;
    var param = {
      leadid: this.leadid,
      execid: this.userid,
      stage: $('#customer_phase4').val(),
    }

    this._phpService
      .getvisitedsuggestproperties(param)
      .subscribe(visitsuggested => {
        this.visitedpropertylists = visitsuggested['visitedlists'];
        this.suggestchecked = this.visitedpropertylists.map((item) => { return item.propid }).join(',');
      });
  }

  selectsuggested(id) {
    var checkid = $("input[name='programming']:checked").map(function () {
      return this.value;
    }).get().join(',');
    this.suggestchecked = checkid;
  }

  unitselection(i) {
    var checkid = $("input[name='select1-" + i + "']:checked").map(function () {
      return this.value;
    }).get().join(',');
    // this.selectedunits = checkid;
    const a = document.getElementById("selectedunits-" + i) as HTMLInputElement;
    a.value = checkid;
    // let numArr = value.match(/[\d\.]+/g)
    // numArr = numArr.filter(n => n != '.')
  }

  closingrequest(i, propid, propname) {
    // USV DONE with Lead Closing

    if ($('#sectionselector').val() == "USV") {
      // alert("USV Detected");

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }

      if ($('#USVvisiteddate').val() == "") {
        $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisiteddate').removeAttr("style");
      }

      if ($('#USVvisitedtime').val() == "") {
        $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisitedtime').removeAttr("style");
      }

      // if ($('#usvfinalremarks').val() == "") {
      //   $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#usvfinalremarks').removeAttr("style");
      // }

      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($("#selectedunits-" + i).val() == "") {
        swal({
          title: 'Units Not Selected',
          text: 'Select any Unit for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else if ($("#unitnum-" + i).val() == "") {
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        return false;
      }else if ($("#dimension-" + i).val() == "") {
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#ratepersquarfeet-" + i).val() == "") {
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }else if ($("#remarks-" + i).val() == "") {
        $("#remarks-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type some comments/remarks');
        return false;
      }else if($("#customFile" + i).val() == ""){
        swal({
          title: 'No Files Uploaded',
          text: 'Upload atleast one file for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else{
        $("#unitnum-" + i).removeAttr("style");
        $("#dimension-" + i).removeAttr("style");
        $("#ratepersquarfeet-" + i).removeAttr("style");
        $("#remarks-" + i).removeAttr("style");
        this.filterLoader = true;
        var unitsselected = $("#selectedunits-" + i).val();
        var lastunit = unitsselected.replace(/,\s*$/, "");
        var totalunitscount = lastunit.split(",").length;
        // alert(totalunitscount);
        var unitnumbers = $("#unitnum-" + i).val();
        var lastuninumber = unitnumbers.replace(/,\s*$/, "");
        var totalunitnumbers = lastuninumber.split(",").length;
        // alert(lastuninumber);
        var dimensions = $("#dimension-" + i).val();
        var lastdimension = dimensions.replace(/,\s*$/, "");
        var totaldimensions = lastdimension.split(",").length;
        // alert(totaldimensions);
        var rpsft = $("#ratepersquarfeet-" + i).val();
        var lastsqft = rpsft.replace(/,\s*$/, "");
        var totalrpsft = lastsqft.split(",").length;
        // alert(totalrpsft);

        // Condition of selected only one unit or less than one & enetered more unit numbers
        if(totalunitscount <= 1 && totalunitnumbers > 1){
          if(totalunitnumbers != totaldimensions){
            if(totaldimensions == 1){
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimension Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimensions Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else if(totaldimensions != totalrpsft){
           
            if(totalrpsft == 1){
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Price Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Prices Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else{
            // parameters & API Submissions for the property sitevisit update
            var visitparam = {
              leadid: this.leadid,
              propid: this.suggestchecked,
              execid: this.userid,
              visitupdate: $('#visitupdate').val(),
              remarks: $('#propertyremarks').val(),
              stage: "USV",
            }
            // parameters & API Submissions for the property sitevisit update

            this._phpService.addpropertyvisitupdate(visitparam).subscribe((success) => {
              this.status = success.status;
              if (this.status == "True"){

                // alert("Total Units and Unit numbers matched");
             const formData = new FormData();
             formData.append('PropID', propid);
             formData.append('LeadID', this.leadid);
             formData.append('ExecID', this.executeid);
             for (var k = 0; k < this.closurefiles.length; k++) {
               formData.append('file[]', this.closurefiles[k]);
             }
             this._phpService.uploadFile(formData).subscribe((res) => {
               if (res['status'] === 'True') {
                var visiteddate = $('#USVvisiteddate').val();
                var visitedtime = $('#USVvisitedtime').val();
                // var usvfinalremarks = $('#usvfinalremarks').val();
                var usvfinalremarks = "USV Done";
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the USV";
                var leadusvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "USV",
                  stagestatus: '3',
                  textarearemarks: usvfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadusvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks,
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Request Send Successfully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                              this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                                  this.router.navigate([currentUrl]);
                              });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
               }else if(res['status'] === 'Duplicate Request'){
                 swal({
                   title: 'Already found the same property and same unit Closing request',
                   type: 'error',
                   confirmButtonText: 'ok'
                 });
               }},
               (err) => {
                 console.log(err);
               });

              }
            }, (err) => {
              console.log("Failed to Update");
            })
          }
        }
        // Condition of selected unit more & entered less unit numbers
        else if(totalunitscount > totalunitnumbers){
          if(totalunitnumbers == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Number',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Numbers',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        
        }
        // Condition of selected unit less and not equal one & entered more unit numbers
        else if(totalunitscount < totalunitnumbers){
          swal({
            title: totalunitscount+' Units Selected & '+totalunitnumbers+' Unit Numbers Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less dimensions
        else if(totalunitscount > totaldimensions){
          if(totaldimensions == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimension',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimensions',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');

        }
        // Condition of selected unit less & entered more dimensions
        else if(totalunitscount < totaldimensions){
          swal({
            title: totalunitscount+' Units Selected & '+totaldimensions+' Dimensions Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less ratepersqfeets
        else if(totalunitscount > totalrpsft){
          if(totalrpsft == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Price Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Prices Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        }
        // Condition of selected unit less & entered more ratepersqfeets
        else if(totalunitscount < totalrpsft){
          swal({
            title: totalunitscount+' Units Selected & '+totalrpsft+' Prices Found',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        else{
          this.filterLoader = true;

          // parameters & API Submissions for the property sitevisit update
          var visitparam = {
            leadid: this.leadid,
            propid: this.suggestchecked,
            execid: this.userid,
            visitupdate: $('#visitupdate').val(),
            remarks: $('#propertyremarks').val(),
            stage: "USV",
          }
          // parameters & API Submissions for the property sitevisit update

          this._phpService.addpropertyvisitupdate(visitparam).subscribe((success) => {
            this.status = success.status;
            if (this.status == "True"){

              const formData = new FormData();
              formData.append('PropID', propid);
              formData.append('LeadID', this.leadid);
              formData.append('ExecID', this.executeid);
             for (var k = 0; k < this.closurefiles.length; k++) {
               formData.append('file[]', this.closurefiles[k]);
             }
             this._phpService.uploadFile(formData).subscribe((res) => {
               if (res['status'] === 'True') {
                var visiteddate = $('#USVvisiteddate').val();
                var visitedtime = $('#USVvisitedtime').val();
                // var usvfinalremarks = $('#usvfinalremarks').val();
                var usvfinalremarks = "USV Done";
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the USV";
                var leadusvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "USV",
                  stagestatus: '3',
                  textarearemarks: usvfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadusvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Request Send Successfully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
               }else if(res['status'] === 'Duplicate Request'){
                 swal({
                   title: 'Already found the same property and same unit Closing request',
                   type: 'error',
                   confirmButtonText: 'ok'
                 });
               }},
               (err) => {
                 console.log(err);
               });

            }
          }, (err) => {
            console.log("Failed to Update");
          })

        }
      }
      
    }

    // USV DONE with Lead Closing

    // SV DONE with Lead Closing

    else if ($('#sectionselector').val() == "SV") {
      // alert("SV Detected");
      if ($('#SVvisiteddate').val() == "") {
        $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisiteddate').removeAttr("style");
      }

      if ($('#SVvisitedtime').val() == "") {
        $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisitedtime').removeAttr("style");
      }

      // if ($('#svfinalremarks').val() == "") {
      //   $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
      //   return false;
      // }
      // else {
      //   $('#svfinalremarks').removeAttr("style");
      // }

      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($("#selectedunits-" + i).val() == "") {
        swal({
          title: 'Units Not Selected',
          text: 'Select any Unit for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else if ($("#unitnum-" + i).val() == "") {
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        return false;
      }else if ($("#dimension-" + i).val() == "") {
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#ratepersquarfeet-" + i).val() == "") {
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }else if ($("#remarks-" + i).val() == "") {
        $("#remarks-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type some comments/remarks');
        return false;
      }else if($("#customFile" + i).val() == ""){
        swal({
          title: 'No Files Uploaded',
          text: 'Upload atleast one file for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else{

        $("#unitnum-" + i).removeAttr("style");
        $("#dimension-" + i).removeAttr("style");
        $("#ratepersquarfeet-" + i).removeAttr("style");
        $("#remarks-" + i).removeAttr("style");
        this.filterLoader = true;
        var unitsselected = $("#selectedunits-" + i).val();
        var lastunit = unitsselected.replace(/,\s*$/, "");
        var totalunitscount = lastunit.split(",").length;
        // alert(totalunitscount);
        var unitnumbers = $("#unitnum-" + i).val();
        var lastuninumber = unitnumbers.replace(/,\s*$/, "");
        var totalunitnumbers = lastuninumber.split(",").length;
        // alert(lastuninumber);
        var dimensions = $("#dimension-" + i).val();
        var lastdimension = dimensions.replace(/,\s*$/, "");
        var totaldimensions = lastdimension.split(",").length;
        // alert(totaldimensions);
        var rpsft = $("#ratepersquarfeet-" + i).val();
        var lastsqft = rpsft.replace(/,\s*$/, "");
        var totalrpsft = lastsqft.split(",").length;
        // alert(totalrpsft);

        // Condition of selected only one unit or less than one & enetered more unit numbers
        if(totalunitscount <= 1 && totalunitnumbers > 1){
          if(totalunitnumbers != totaldimensions){
            if(totaldimensions == 1){
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimension Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimensions Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else if(totaldimensions != totalrpsft){
           
            if(totalrpsft == 1){
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Price Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Prices Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else{
            // alert("Total Units and Unit numbers matched");
            const formData = new FormData();
            formData.append('PropID', propid);
            formData.append('LeadID', this.leadid);
            formData.append('ExecID', this.executeid);
            for (var k = 0; k < this.closurefiles.length; k++) {
              formData.append('file[]', this.closurefiles[k]);
            }
            this._phpService.uploadFile(formData).subscribe((res) => {
              if (res['status'] === 'True') {
                var visiteddate = $('#SVvisiteddate').val();
                var visitedtime = $('#SVvisitedtime').val();
                var svfinalremarks = "SV Done";
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the SV";
                var leadsvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "SV",
                  stagestatus: '3',
                  textarearemarks: svfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadsvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Requested Succefully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate([currentUrl]);
                        });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
                
              }else if(res['status'] === 'Duplicate Request'){
                swal({
                  title: 'Already found the same property and same unit Closing request',
                  type: 'error',
                  confirmButtonText: 'ok'
                });
              }},
              (err) => {
                console.log(err);
              });
          }
        }
        // Condition of selected unit more & entered less unit numbers
        else if(totalunitscount > totalunitnumbers){
          if(totalunitnumbers == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Number',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Numbers',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        
        }
        // Condition of selected unit less and not equal one & entered more unit numbers
        else if(totalunitscount < totalunitnumbers){
          swal({
            title: totalunitscount+' Units Selected & '+totalunitnumbers+' Unit Numbers Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less dimensions
        else if(totalunitscount > totaldimensions){
          if(totaldimensions == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimension',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimensions',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');

        }
        // Condition of selected unit less & entered more dimensions
        else if(totalunitscount < totaldimensions){
          swal({
            title: totalunitscount+' Units Selected & '+totaldimensions+' Dimensions Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less ratepersqfeets
        else if(totalunitscount > totalrpsft){
          if(totalrpsft == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Price Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Prices Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        }
        // Condition of selected unit less & entered more ratepersqfeets
        else if(totalunitscount < totalrpsft){
          swal({
            title: totalunitscount+' Units Selected & '+totalrpsft+' Prices Found',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        else{
          this.filterLoader = true;
          const formData = new FormData();
            formData.append('PropID', propid);
            formData.append('LeadID', this.leadid);
            formData.append('ExecID', this.executeid);
            for (var k = 0; k < this.closurefiles.length; k++) {
              formData.append('file[]', this.closurefiles[k]);
            }
            
            this._phpService.uploadFile(formData).subscribe((res) => {
              if (res['status'] === 'True') {
                var visiteddate = $('#SVvisiteddate').val();
                var visitedtime = $('#SVvisitedtime').val();
                // var svfinalremarks = $('#svfinalremarks').val();
                var svfinalremarks = "SV Done";
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the SV";
                var leadsvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "SV",
                  stagestatus: '3',
                  textarearemarks: svfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadsvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Requested Succefully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate([currentUrl]);
                        });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
                
              }else if(res['status'] === 'Duplicate Request'){
                swal({
                  title: 'Already found the same property and same unit Closing request',
                  type: 'error',
                  confirmButtonText: 'ok'
                });
              }},
              (err) => {
                console.log(err);
              });

        }


      }
    }

    // SV DONE with Lead Closing

    // RSV DONE with Lead Closing

    else if ($('#sectionselector').val() == "RSV") {
      // alert("RSV Detected");
      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#RSVvisiteddate').val() == "") {
        $('#RSVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisiteddate').removeAttr("style");
      }

      if ($('#RSVvisitedtime').val() == "") {
        $('#RSVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisitedtime').removeAttr("style");
      }

      // if ($('#rsvfinalremarks').val() == "") {
      //   $('#rsvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the RSV');
      //   return false;
      // }
      // else {
      //   $('#rsvfinalremarks').removeAttr("style");
      // }

      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($("#selectedunits-" + i).val() == "") {
        swal({
          title: 'Units Not Selected',
          text: 'Select any Unit for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else if ($("#unitnum-" + i).val() == "") {
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        return false;
      }else if ($("#dimension-" + i).val() == "") {
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#ratepersquarfeet-" + i).val() == "") {
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }else if ($("#remarks-" + i).val() == "") {
        $("#remarks-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type some comments/remarks');
        return false;
      }else if($("#customFile" + i).val() == ""){
        swal({
          title: 'No Files Uploaded',
          text: 'Upload atleast one file for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else{

        $("#unitnum-" + i).removeAttr("style");
        $("#dimension-" + i).removeAttr("style");
        $("#ratepersquarfeet-" + i).removeAttr("style");
        $("#remarks-" + i).removeAttr("style");
        this.filterLoader = true;
        var unitsselected = $("#selectedunits-" + i).val();
        var lastunit = unitsselected.replace(/,\s*$/, "");
        var totalunitscount = lastunit.split(",").length;
        // alert(totalunitscount);
        var unitnumbers = $("#unitnum-" + i).val();
        var lastuninumber = unitnumbers.replace(/,\s*$/, "");
        var totalunitnumbers = lastuninumber.split(",").length;
        // alert(lastuninumber);
        var dimensions = $("#dimension-" + i).val();
        var lastdimension = dimensions.replace(/,\s*$/, "");
        var totaldimensions = lastdimension.split(",").length;
        // alert(totaldimensions);
        var rpsft = $("#ratepersquarfeet-" + i).val();
        var lastsqft = rpsft.replace(/,\s*$/, "");
        var totalrpsft = lastsqft.split(",").length;
        // alert(totalrpsft);

        // Condition of selected only one unit or less than one & enetered more unit numbers
        if(totalunitscount <= 1 && totalunitnumbers > 1){
          if(totalunitnumbers != totaldimensions){
            if(totaldimensions == 1){
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimension Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimensions Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else if(totaldimensions != totalrpsft){
           
            if(totalrpsft == 1){
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Price Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Prices Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else{
            // parameters & API Submissions for the property sitevisit update
            var visitedparam = {
              leadid: this.leadid,
              propid: this.suggestchecked,
              execid: this.userid,
              visitupdate: $('#visitupdate').val(),
              remarks: $('#propertyremarks').val(),
              stage: "RSV",
            }
            // parameters & API Submissions for the property sitevisit update
            this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
              this.status = success.status;
              if (this.status == "True"){

                // alert("Total Units and Unit numbers matched");
            const formData = new FormData();
            formData.append('PropID', propid);
            formData.append('LeadID', this.leadid);
            formData.append('ExecID', this.executeid);
            for (var k = 0; k < this.closurefiles.length; k++) {
              formData.append('file[]', this.closurefiles[k]);
            }
            this._phpService.uploadFile(formData).subscribe((res) => {
              if (res['status'] === 'True') {
                var visiteddate = $('#RSVvisiteddate').val();
                var visitedtime = $('#RSVvisitedtime').val();
                // var rsvfinalremarks = $('#rsvfinalremarks').val();
                // var rsvfinalremarks = "RSV Done";
                var rsvfinalremarks = $('#propertyremarks').val();
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the RSV";
                var leadrsvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "RSV",
                  stagestatus: '3',
                  textarearemarks: rsvfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadrsvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Requested Succefully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate([currentUrl]);
                        });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
                
              }else if(res['status'] === 'Duplicate Request'){
                swal({
                  title: 'Already found the same property and same unit Closing request',
                  type: 'error',
                  confirmButtonText: 'ok'
                });
              }},
              (err) => {
                console.log(err);
              });

              }
            }, (err) => {
              console.log("Failed to Update");
            })
            


          }
        }
        // Condition of selected unit more & entered less unit numbers
        else if(totalunitscount > totalunitnumbers){
          if(totalunitnumbers == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Number',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Numbers',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        
        }
        // Condition of selected unit less and not equal one & entered more unit numbers
        else if(totalunitscount < totalunitnumbers){
          swal({
            title: totalunitscount+' Units Selected & '+totalunitnumbers+' Unit Numbers Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less dimensions
        else if(totalunitscount > totaldimensions){
          if(totaldimensions == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimension',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimensions',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');

        }
        // Condition of selected unit less & entered more dimensions
        else if(totalunitscount < totaldimensions){
          swal({
            title: totalunitscount+' Units Selected & '+totaldimensions+' Dimensions Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less ratepersqfeets
        else if(totalunitscount > totalrpsft){
          if(totalrpsft == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Price Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Prices Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        }
        // Condition of selected unit less & entered more ratepersqfeets
        else if(totalunitscount < totalrpsft){
          swal({
            title: totalunitscount+' Units Selected & '+totalrpsft+' Prices Found',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        else{
          // parameters & API Submissions for the property sitevisit update
          var visitedparam2 = {
            leadid: this.leadid,
            propid: this.suggestchecked,
            execid: this.userid,
            visitupdate: $('#visitupdate').val(),
            remarks: $('#propertyremarks').val(),
            stage: "RSV",
          }
          // parameters & API Submissions for the property sitevisit update
          this.filterLoader = true;

          this._phpService.addpropertyvisitupdate(visitedparam2).subscribe((success) => {
            this.status = success.status;
              if (this.status == "True"){
                const formData = new FormData();
          formData.append('PropID', propid);
          formData.append('LeadID', this.leadid);
          formData.append('ExecID', this.executeid);
          for (var k = 0; k < this.closurefiles.length; k++) {
            formData.append('file[]', this.closurefiles[k]);
          }
            
            this._phpService.uploadFile(formData).subscribe((res) => {
              if (res['status'] === 'True') {
                var visiteddate = $('#RSVvisiteddate').val();
                var visitedtime = $('#RSVvisitedtime').val();
                // var rsvfinalremarks = $('#rsvfinalremarks').val();
                // var rsvfinalremarks = "RSV Done";
                var rsvfinalremarks = $('#propertyremarks').val();
                this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the RSV";
                var leadrsvparam = {
                  leadid: this.leadid,
                  closedate: visiteddate,
                  closetime: visitedtime,
                  leadstage: "RSV",
                  stagestatus: '3',
                  textarearemarks: rsvfinalremarks,
                  userid: this.userid,
                  assignid: this.executeid,
                  autoremarks: this.autoremarks,
                  property: propid
                }

                this._phpService.addleadhistory(leadrsvparam).subscribe((success) => {
                  this.status = success.status;
                  if (this.status == "True") {
                    var closedate = $('#closeddate').val();
                    var closetime = $('#closedtime').val();
                    var textarearemarks = $("#remarks-" + i).val();
                    var dateformatchange = new Date(closedate).toDateString();

                    this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                    var leadhistparam = {
                      leadid: this.leadid,
                      closedate: closedate,
                      closetime: closetime,
                      leadstage: "Deal Closing Request",
                      stagestatus: '0',
                      textarearemarks: textarearemarks,
                      userid: this.userid,
                      assignid: this.executeid,
                      property: propid,
                      bhk: unitsselected,
                      bhkunit: unitnumbers,
                      dimension: dimensions,
                      ratepersft: rpsft,
                      autoremarks: this.autoremarks
                    }
                    this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                      this.status = success.status;
                      if (this.status == "True") {
                        this.filterLoader = false;
                        swal({
                          title: 'Deal Closing Requested Succefully',
                          type: "success",
                          confirmButtonText: 'OK!',
                        }).then((result) => {
                          if (result.value) {
                            let currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate([currentUrl]);
                        });
                          }
                        });
                      }else if (this.status == "Duplicate Request"){
                        swal({
                          title: 'Already got the request for this same Unit number',
                          type: "error",
                          confirmButtonText: 'OK!',
                        });
                      }
                    }, (err) => {
                      console.log("Failed to Update");
                    });
                  }
                }, (err) => {
                  console.log("Failed to Update");
                });
                
              }else if(res['status'] === 'Duplicate Request'){
                swal({
                  title: 'Already found the same property and same unit Closing request',
                  type: 'error',
                  confirmButtonText: 'ok'
                });
              }},
              (err) => {
                console.log(err);
              });
              }
          }, (err) => {
            console.log("Failed to Update");
          })
          

        }

      }

    }

    // RSV DONE with Lead Closing

    // NEGOTIATION DONE with Lead Closing

    else if ($('#sectionselector').val() == "Final Negotiation") {
      // alert("Nego Detected");
      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#negovisiteddate').val() == "") {
        $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisiteddate').removeAttr("style");
      }

      if ($('#negovisitedtime').val() == "") {
        $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisitedtime').removeAttr("style");
      }

      // if ($('#negofinalremarks').val() == "") {
      //   $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
      //   return false;
      // }
      // else {
      //   $('#negofinalremarks').removeAttr("style");
      // }

      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($("#selectedunits-" + i).val() == "") {
        swal({
          title: 'Units Not Selected',
          text: 'Select any Unit for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else if ($("#unitnum-" + i).val() == "") {
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        return false;
      }else if ($("#dimension-" + i).val() == "") {
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#ratepersquarfeet-" + i).val() == "") {
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }else if ($("#remarks-" + i).val() == "") {
        $("#remarks-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type some comments/remarks');
        return false;
      }else if($("#customFile" + i).val() == ""){
        swal({
          title: 'No Files Uploaded',
          text: 'Upload atleast one file for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }
      else{
        this.filterLoader = true;
        $("#unitnum-" + i).removeAttr("style");
        $("#dimension-" + i).removeAttr("style");
        $("#ratepersquarfeet-" + i).removeAttr("style");
        $("#remarks-" + i).removeAttr("style");

        var unitsselected = $("#selectedunits-" + i).val();
        var lastunit = unitsselected.replace(/,\s*$/, "");
        var totalunitscount = lastunit.split(",").length;
        // alert(totalunitscount);
        var unitnumbers = $("#unitnum-" + i).val();
        var lastuninumber = unitnumbers.replace(/,\s*$/, "");
        var totalunitnumbers = lastuninumber.split(",").length;
        // alert(lastuninumber);
        var dimensions = $("#dimension-" + i).val();
        var lastdimension = dimensions.replace(/,\s*$/, "");
        var totaldimensions = lastdimension.split(",").length;
        // alert(totaldimensions);
        var rpsft = $("#ratepersquarfeet-" + i).val();
        var lastsqft = rpsft.replace(/,\s*$/, "");
        var totalrpsft = lastsqft.split(",").length;
        // alert(totalrpsft);

       // Condition of selected only one unit or less than one & enetered more unit numbers
       if(totalunitscount <= 1 && totalunitnumbers > 1){
        if(totalunitnumbers != totaldimensions){
          if(totaldimensions == 1){
            swal({
              title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimension Detected',
              type: 'error',
              confirmButtonText: 'ok'
            })
          }else{
            swal({
              title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimensions Detected',
              type: 'error',
              confirmButtonText: 'ok'
            })
          }
        }else if(totaldimensions != totalrpsft){
         
          if(totalrpsft == 1){
            swal({
              title: totaldimensions+' Dimensions & '+ totalrpsft +' Price Found',
              type: 'error',
              confirmButtonText: 'ok'
            })
          }else{
            swal({
              title: totaldimensions+' Dimensions & '+ totalrpsft +' Prices Found',
              type: 'error',
              confirmButtonText: 'ok'
            })
          }
        }else{
          // alert("Total Units and Unit numbers matched");
          var visitedparamnego1 = {
            leadid: this.leadid,
            propid: this.suggestchecked,
            execid: this.userid,
            visitupdate: $('#visitupdate').val(),
            remarks: $('#propertyremarks').val(),
            stage: "Final Negotiation",
          }

          this._phpService.addpropertyvisitupdate(visitedparamnego1).subscribe((success) => {
            this.status = success.status;
              if (this.status == "True"){
                const formData = new FormData();
          formData.append('PropID', propid);
          formData.append('LeadID', this.leadid);
          formData.append('ExecID', this.executeid);
          for (var k = 0; k < this.closurefiles.length; k++) {
            formData.append('file[]', this.closurefiles[k]);
          }
          this._phpService.uploadFile(formData).subscribe((res) => {
            if (res['status'] === 'True') {
              var visiteddate = $('#negovisiteddate').val();
              var visitedtime = $('#negovisitedtime').val();
              // var negofinalremarks = $('#negofinalremarks').val();
              var negofinalremarks = "Final Negotiation Done";
              this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the Final Negotiation";
              var leadnegoparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "Final Negotiation",
                stagestatus: '3',
                textarearemarks: negofinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: propid
              }

              this._phpService.addleadhistory(leadnegoparam).subscribe((success) => {
                this.status = success.status;
                if (this.status == "True") {
                  var closedate = $('#closeddate').val();
                  var closetime = $('#closedtime').val();
                  var textarearemarks = $("#remarks-" + i).val();
                  var dateformatchange = new Date(closedate).toDateString();

                  this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                  var leadhistparam = {
                    leadid: this.leadid,
                    closedate: closedate,
                    closetime: closetime,
                    leadstage: "Deal Closing Request",
                    stagestatus: '0',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    property: propid,
                    bhk: unitsselected,
                    bhkunit: unitnumbers,
                    dimension: dimensions,
                    ratepersft: rpsft,
                    autoremarks: this.autoremarks
                  }
                  this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                    this.status = success.status;
                    if (this.status == "True") {
                      this.filterLoader = false;
                      swal({
                        title: 'Deal Closing Requested Succefully',
                        type: "success",
                        confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                        }
                      });
                    }else if (this.status == "Duplicate Request"){
                      swal({
                        title: 'Already got the request for this same Unit number',
                        type: "error",
                        confirmButtonText: 'OK!',
                      });
                    }
                  }, (err) => {
                    console.log("Failed to Update");
                  });
                }
              }, (err) => {
                console.log("Failed to Update");
              });
              
            }else if(res['status'] === 'Duplicate Request'){
              swal({
                title: 'Already found the same property and same unit Closing request',
                type: 'error',
                confirmButtonText: 'ok'
              });
            }},
            (err) => {
              console.log(err);
            });
              }
          }, (err) => {
            console.log("Failed to Update");
          })
          
        }
      }
      // Condition of selected unit more & entered less unit numbers
      else if(totalunitscount > totalunitnumbers){
        if(totalunitnumbers == 1){
          swal({
            title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Number',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }else{
          swal({
            title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Numbers',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
      
      }
      // Condition of selected unit less and not equal one & entered more unit numbers
      else if(totalunitscount < totalunitnumbers){
        swal({
          title: totalunitscount+' Units Selected & '+totalunitnumbers+' Unit Numbers Detected',
          type: 'error',
          confirmButtonText: 'ok'
        })
      }
      // Condition of selected unit more & entered less dimensions
      else if(totalunitscount > totaldimensions){
        if(totaldimensions == 1){
          swal({
            title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimension',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }else{
          swal({
            title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimensions',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');

      }
      // Condition of selected unit less & entered more dimensions
      else if(totalunitscount < totaldimensions){
        swal({
          title: totalunitscount+' Units Selected & '+totaldimensions+' Dimensions Detected',
          type: 'error',
          confirmButtonText: 'ok'
        })
      }
      // Condition of selected unit more & entered less ratepersqfeets
      else if(totalunitscount > totalrpsft){
        if(totalrpsft == 1){
          swal({
            title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Price Found',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }else{
          swal({
            title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Prices Found',
            type: 'error',
            confirmButtonText: 'ok'
          });
        }
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
      }
      // Condition of selected unit less & entered more ratepersqfeets
      else if(totalunitscount < totalrpsft){
        swal({
          title: totalunitscount+' Units Selected & '+totalrpsft+' Prices Found',
          type: 'error',
          confirmButtonText: 'ok'
        })
      }
      else{
        var visitedparamnego2 = {
          leadid: this.leadid,
          propid: this.suggestchecked,
          execid: this.userid,
          visitupdate: $('#visitupdate').val(),
          remarks: $('#propertyremarks').val(),
          stage: "Final Negotiation",
        }
        this.filterLoader = true;

        this._phpService.addpropertyvisitupdate(visitedparamnego2).subscribe((success) => {
          this.status = success.status;
              if (this.status == "True"){
                const formData = new FormData();
          formData.append('PropID', propid);
          formData.append('LeadID', this.leadid);
          formData.append('ExecID', this.executeid);
          for (var k = 0; k < this.closurefiles.length; k++) {
            formData.append('file[]', this.closurefiles[k]);
          }
          
          this._phpService.uploadFile(formData).subscribe((res) => {
            if (res['status'] === 'True') {
              var visiteddate = $('#negovisiteddate').val();
              var visitedtime = $('#negovisitedtime').val();
              // var negofinalremarks = $('#negofinalremarks').val();
              var negofinalremarks = "Final Negotiation Done";
              this.autoremarks = " Changed the status to Deal Closing Request after Successfully completed the Final Negotiation";
              var leadnegoparam = {
                leadid: this.leadid,
                closedate: visiteddate,
                closetime: visitedtime,
                leadstage: "Final Negotiation",
                stagestatus: '3',
                textarearemarks: negofinalremarks,
                userid: this.userid,
                assignid: this.executeid,
                autoremarks: this.autoremarks,
                property: propid
              }

              this._phpService.addleadhistory(leadnegoparam).subscribe((success) => {
                this.status = success.status;
                if (this.status == "True") {
                  var closedate = $('#closeddate').val();
                  var closetime = $('#closedtime').val();
                  var textarearemarks = $("#remarks-" + i).val();
                  var dateformatchange = new Date(closedate).toDateString();

                  this.autoremarks = " Send the Deal Closing Request for "+propname+" On "+dateformatchange+" "+closetime;
                  var leadhistparam = {
                    leadid: this.leadid,
                    closedate: closedate,
                    closetime: closetime,
                    leadstage: "Deal Closing Request",
                    stagestatus: '0',
                    textarearemarks: textarearemarks,
                    userid: this.userid,
                    assignid: this.executeid,
                    property: propid,
                    bhk: unitsselected,
                    bhkunit: unitnumbers,
                    dimension: dimensions,
                    ratepersft: rpsft,
                    autoremarks: this.autoremarks
                  }
                  this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
                    this.status = success.status;
                    if (this.status == "True") {
                      this.filterLoader = false;
                      swal({
                        title: 'Deal Closing Requested Succefully',
                        type: "success",
                        confirmButtonText: 'OK!',
                      }).then((result) => {
                        if (result.value) {
                          let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                        }
                      });
                    }else if (this.status == "Duplicate Request"){
                      swal({
                        title: 'Already got the request for this same Unit number',
                        type: "error",
                        confirmButtonText: 'OK!',
                      });
                    }
                  }, (err) => {
                    console.log("Failed to Update");
                  });
                }
              }, (err) => {
                console.log("Failed to Update");
              });
              
            }else if(res['status'] === 'Duplicate Request'){
              swal({
                title: 'Already found the same property and same unit Closing request',
                type: 'error',
                confirmButtonText: 'ok'
              });
            }},
            (err) => {
              console.log(err);
            });
              }
        }, (err) => {
          console.log("Failed to Update");
        })

        

      }

      }
    }

    // NEGOTIATION DONE with Lead Closing

    // Direct Lead Closing

    else {
      // alert("Direct Detected");
      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($("#selectedunits-" + i).val() == "") {
        swal({
          title: 'Units Not Selected',
          text: 'Select any Unit for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }else if ($("#unitnum-" + i).val() == "") {
        $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        return false;
      }else if ($("#dimension-" + i).val() == "") {
        $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#ratepersquarfeet-" + i).val() == "") {
        $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }else if ($("#remarks-" + i).val() == "") {
        $("#remarks-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type some comments/remarks');
        return false;
      }else if($("#customFile" + i).val() == ""){
        swal({
          title: 'No Files Uploaded',
          text: 'Upload atleast one file for ' + propname,
          type: 'error',
          confirmButtonText: 'ok'
        })
        return false;
      }
      else {
        $("#unitnum-" + i).removeAttr("style");
        $("#dimension-" + i).removeAttr("style");
        $("#ratepersquarfeet-" + i).removeAttr("style");
        $("#remarks-" + i).removeAttr("style");
        this.filterLoader = true;
        var unitsselected = $("#selectedunits-" + i).val();
        var lastunit = unitsselected.replace(/,\s*$/, "");
        var totalunitscount = lastunit.split(",").length;
        // alert(totalunitscount);
        var unitnumbers = $("#unitnum-" + i).val();
        var lastuninumber = unitnumbers.replace(/,\s*$/, "");
        var totalunitnumbers = lastuninumber.split(",").length;
        // alert(lastuninumber);
        var dimensions = $("#dimension-" + i).val();
        var lastdimension = dimensions.replace(/,\s*$/, "");
        var totaldimensions = lastdimension.split(",").length;
        // alert(totaldimensions);
        var rpsft = $("#ratepersquarfeet-" + i).val();
        var lastsqft = rpsft.replace(/,\s*$/, "");
        var totalrpsft = lastsqft.split(",").length;
        // alert(totalrpsft);

        // Condition of selected only one unit or less than one & enetered more unit numbers
        if(totalunitscount <= 1 && totalunitnumbers > 1){
          if(totalunitnumbers != totaldimensions){
            if(totaldimensions == 1){
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimension Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totalunitnumbers+' Unit Numbers & '+totaldimensions+' Dimensions Detected',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else if(totaldimensions != totalrpsft){
           
            if(totalrpsft == 1){
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Price Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }else{
              swal({
                title: totaldimensions+' Dimensions & '+ totalrpsft +' Prices Found',
                type: 'error',
                confirmButtonText: 'ok'
              })
            }
          }else{
            // alert("Total Units and Unit numbers matched");

            const formData = new FormData();
          formData.append('PropID', propid);
          formData.append('LeadID', this.leadid);
          formData.append('ExecID', this.executeid);
          for (var k = 0; k < this.closurefiles.length; k++) {
            formData.append('file[]', this.closurefiles[k]);
          }
          this._phpService.uploadFile(formData).subscribe((res) => {
            if(res['status'] === 'Duplicate Request'){
              swal({
                title: 'Already found the same property and same unit Closing request',
                type: 'error',
                confirmButtonText: 'ok'
              });
            }},
            (err) => {
              console.log(err);
            });

            var closedate = $('#closeddate').val();
            var closetime = $('#closedtime').val();
            var textarearemarks = $("#remarks-" + i).val();
            this.autoremarks = " Send the Deal Closing Request successfully.";
            var leadhistparam = {
              leadid: this.leadid,
              closedate: closedate,
              closetime: closetime,
              leadstage: "Deal Closing Request",
              stagestatus: '0',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              property: propid,
              bhk: unitsselected,
              bhkunit: unitnumbers,
              dimension: dimensions,
              ratepersft: rpsft,
              autoremarks: this.autoremarks
            }

            this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
              this.status = success.status;
              if (this.status == "True") {
                this.filterLoader = false;
                swal({
                  title: 'Deal Closing Requested Succefully',
                  type: "success",
                  confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                  }
                });
              }else if (this.status == "Duplicate Request"){
                swal({
                  title: 'Already got the request for this same Unit number',
                  type: "error",
                  confirmButtonText: 'OK!',
                });
              }
            }, (err) => {
              console.log("Failed to Update");
            });

          }
        }
        // Condition of selected unit more & entered less unit numbers
        else if(totalunitscount > totalunitnumbers){
          if(totalunitnumbers == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Number',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalunitnumbers+' Unit Numbers',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#unitnum-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Number');
        
        }
        // Condition of selected unit less and not equal one & entered more unit numbers
        else if(totalunitscount < totalunitnumbers){
          swal({
            title: totalunitscount+' Units Selected & '+totalunitnumbers+' Unit Numbers Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        // Condition of selected unit more & entered less dimensions
        else if(totalunitscount > totaldimensions){
          if(totaldimensions == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimension',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totaldimensions+' Dimensions',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#dimension-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');

        }
        // Condition of selected unit less & entered more dimensions
        else if(totalunitscount < totaldimensions){
          swal({
            title: totalunitscount+' Units Selected & '+totaldimensions+' Dimensions Detected',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
         // Condition of selected unit more & entered less ratepersqfeets
        else if(totalunitscount > totalrpsft){
          if(totalrpsft == 1){
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Price Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }else{
            swal({
              title: totalunitscount+' Units Selected & Found only '+totalrpsft+' Prices Found',
              type: 'error',
              confirmButtonText: 'ok'
            });
          }
          $("#ratepersquarfeet-" + i).focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        }
        // Condition of selected unit less & entered more ratepersqfeets
        else if(totalunitscount < totalrpsft){
          swal({
            title: totalunitscount+' Units Selected & '+totalrpsft+' Prices Found',
            type: 'error',
            confirmButtonText: 'ok'
          })
        }
        else{
          // alert("Total Units and Unit numbers matched");
          this.filterLoader = true;
          const formData = new FormData();
          formData.append('PropID', propid);
          formData.append('LeadID', this.leadid);
          formData.append('ExecID', this.executeid);
          for (var k = 0; k < this.closurefiles.length; k++) {
            formData.append('file[]', this.closurefiles[k]);
          }
          this._phpService.uploadFile(formData).subscribe((res) => {
            if (res['status'] === 'True') {
              var closedate = $('#closeddate').val();
            var closetime = $('#closedtime').val();
            var textarearemarks = $("#remarks-" + i).val();
            this.autoremarks = " Send the Deal Closing Request successfully.";
            var leadhistparam = {
              leadid: this.leadid,
              closedate: closedate,
              closetime: closetime,
              leadstage: "Deal Closing Request",
              stagestatus: '0',
              textarearemarks: textarearemarks,
              userid: this.userid,
              assignid: this.executeid,
              property: propid,
              bhk: unitsselected,
              bhkunit: unitnumbers,
              dimension: dimensions,
              ratepersft: rpsft,
              autoremarks: this.autoremarks
            }

            this._phpService.addleadhistory(leadhistparam).subscribe((success) => {
              this.status = success.status;
              if (this.status == "True") {
                this.filterLoader = false;
                swal({
                  title: 'Deal Closing Requested Succefully',
                  type: "success",
                  confirmButtonText: 'OK!',
                }).then((result) => {
                  if (result.value) {
                    let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                  }
                });
              }else if (this.status == "Duplicate Request"){
                swal({
                  title: 'Already got the request for this same Unit number',
                  type: "error",
                  confirmButtonText: 'OK!',
                });
              }
            }, (err) => {
              console.log("Failed to Update");
            });
            }else if(res['status'] === 'False'){
              swal({
                title: 'Some error Occured in Image upload',
                type: 'error',
                confirmButtonText: 'ok'
              });
            }
          },
            (err) => {
              console.log(err);
            });

            

        }
      }

      

    }

    // Direct Lead Closing
  }

}
