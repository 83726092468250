import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-csexecdashboard',
  templateUrl: './csexecdashboard.component.html',
  styleUrls: ['./csexecdashboard.component.css']
})
export class CsexecdashboardComponent implements OnInit {

  CSTLview:boolean;

  constructor(private router: Router,private _phpService:phpservice) {
    this.CSTLview = false;
   }

  ngOnInit() {
    $('.ui.dropdown').dropdown();
    this.getcounts();
    this.getwishers();
    this.getteam();
    this.getmeetings();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    else if(localStorage.getItem('Role') == '50003')
            {
              this.CSTLview = true;
            }
  }

  teamleader: any;
  leaderid: any;
  counts:any;
  Assigncounts:any;
  activecounts:any;
  inactivecounts:any;
  junkcounts:any;
  followcounts:any;
  face2facecounts:any;
  usvcounts:any;
  svcounts:any;
  rsvcounts:any;
  negocounts:any;
  closedcounts:any;
  wishername: any;
  teams: any;
  clients: any;

  getwishers(){
    this._phpService
    .getwish()
    .subscribe(wish => {
      this.wishername = wish[0];
    })
  }

  getteam(){
    this.teamleader = localStorage.getItem('Name');
    var id = localStorage.getItem('UserId');
    this.leaderid = localStorage.getItem('UserId');
    this._phpService.getmyteam(id).subscribe(team => {this.teams = team;})
  }

  getmeetings()
  {
    var id = localStorage.getItem('UserId');
    this._phpService.getCSmeetings(id).subscribe(meet => {
      this.clients = meet;
    })
  }

  getcounts(){
    var id = localStorage.getItem('UserId');
    this._phpService
    .getcsdashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
  }

  onSelect(id){
    this._phpService
    .getcsdashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
    this._phpService.getCSmeetings(id).subscribe(meet => {
      this.clients = meet;
    })
    
  }

}


@Component({
  selector: 'app-csexecutivedashboard',
  templateUrl: './csexecdashboard.component.html',
  styleUrls: ['./csexecdashboard.component.css']
})

export class csexecutdashboard {
  constructor(private router: Router, private _phpService:phpservice) { }

  ngOnInit() {
    $('.ui.dropdown').dropdown();
    this.getcounts();
    this.getwishers();
    this.getmeetings();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  teamleader: any;
  leaderid: any;
  counts:any;
  Assigncounts:any;
  activecounts:any;
  inactivecounts:any;
  junkcounts:any;
  followcounts:any;
  face2facecounts:any;
  usvcounts:any;
  svcounts:any;
  rsvcounts:any;
  negocounts:any;
  closedcounts:any;
  wishername: any;
  teams: any;
  clients: any;

  getwishers(){
    this._phpService
    .getwish()
    .subscribe(wish => {
      this.wishername = wish[0];
    })
  }

  getmeetings()
  {
    var id = localStorage.getItem('UserId');
    this._phpService.getCSmeetings(id).subscribe(meet => {
      this.clients = meet;
    })
  }

  getcounts(){
    var id = localStorage.getItem('UserId');
    this._phpService
    .getcsdashcounts(id)
    .subscribe(count => {
      this.counts = count;
      var assigncount = this.counts[0].assignedcount;
      var activecount = this.counts[0].activecount;
      var inactivecount = this.counts[0].Inactivecount;
      var junkcount = this.counts[0].Junkcount;
      var followcount = this.counts[0].followcount;
      var face2face = this.counts[0].facetoface;
      var usvcount = this.counts[0].usvcount;
      var svcount = this.counts[0].svcount;
      var rsvcount = this.counts[0].rsvcount;
      var negocount = this.counts[0].nego_count;
      var closedcount = this.counts[0].closed_count;
      this.Assigncounts = assigncount;
      this.activecounts = activecount;
      this.inactivecounts = inactivecount;
      this.junkcounts = junkcount;
      this.followcounts = followcount;
      this.face2facecounts = face2face;
      this.usvcounts = usvcount;
      this.svcounts = svcount;
      this.rsvcounts = rsvcount;
      this.negocounts = negocount;
      this.closedcounts = closedcount;
    }) 
  }

}
