export class leadforward {
    customersupport: string;
    telecallername: string;
    assignedleads: string;
    assignedrm: string;
    assignedcs: string;
    executivename: string;
    reassignedto: string;

}

export class reassignlead{
    selectedlead: string;
    selectedleadid: string;
    customersupport: string;
    telecallername: string;
}