import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './templates/login/login.component';
import { DashboardComponent } from './templates/dashboard/dashboard.component';
import { EnquiryComponent } from './templates/enquiry/enquiry.component';
import { ActiveComponent } from './templates/active/active.component';
import { AssignedrmComponent } from './templates/assignedrm/assignedrm.component';
import { FollowupComponent } from './templates/followup/followup.component';
import { FacetofaceComponent } from './templates/facetoface/facetoface.component';
import { UsvComponent } from './templates/usv/usv.component';
import { SvComponent } from './templates/sv/sv.component';
import { RsvComponent } from './templates/rsv/rsv.component';
import { NegotiateComponent } from './templates/negotiate/negotiate.component';
import { ClosedleadsComponent } from './templates/closedleads/closedleads.component';
import { InactiveComponent } from './templates/inactive/inactive.component';
import { RMTLDashboardComponent,RMExecDashboardComponent } from './templates/rmtldashboard/rmtldashboard.component';
import { LeadsComponent } from './templates/leads/leads.component';
import { MandatelistingComponent } from './templates/mandatelisting/mandatelisting.component';
import { CustomersComponent } from './templates/customers/customers.component';
import { MandateleaddetailsComponent } from './templates/mandateleaddetails/mandateleaddetails.component';
import { CsexecdashboardComponent,csexecutdashboard } from './templates/csexecdashboard/csexecdashboard.component';
import { ExecutivesComponent } from './templates/executives/executives.component';
import { LeadassignComponent } from './templates/leadassign/leadassign.component';
import { JunkComponent } from './templates/junk/junk.component';
import { RegistrationdataComponent } from './templates/registrationdata/registrationdata.component';
import { TodaysComponent } from './templates/todays/todays.component';
import { MandatetodaysComponent } from './templates/mandatetodays/mandatetodays.component';
import { AddpropertiesComponent } from './templates/addproperties/addproperties.component';
import { MandateassignedComponent } from './templates/mandateassigned/mandateassigned.component'
import { AddcpComponent } from './templates/addcp/addcp.component'
import { TestComponent } from './test/test.component';
import { AssisstedClientsComponent } from './templates/assissted-clients/assissted-clients.component';
import {MandateCustomerDetailsComponent } from './templates/mandate-customer-details/mandate-customer-details.component';
import { ClientleadsComponent } from './templates/clientleads/clientleads.component';
import { NewdashboardComponent } from './templates/newdashboard/newdashboard.component';
import { BuildermandaterequestComponent } from './templates/buildermandaterequest/buildermandaterequest.component';


const routes: Routes = [
  
  // {path:'', redirectTo:"/login", pathMatch:"full"},
  {path:"login", component:LoginComponent},
  {path:"adminlogin", component:LoginComponent},
  {path:"Admin", component:DashboardComponent},
  {path:"dashboard", component:NewdashboardComponent},
  {path:"test", component:TestComponent},
  // {path:"RMTeamleader/Dashboard", component:RMTLDashboardComponent},
  // {path:"RMExecutive/Dashboard", component:RMExecDashboardComponent},
  // {path:"CSTeamleader/Dashboard", component:CsexecdashboardComponent},
  // {path:"CSExecutive/Dashboard", component:csexecutdashboard},
  {path:"Enquiry", component:EnquiryComponent},
  {path:"ActiveLeads", component:ActiveComponent},
  {path:"todaysmeetings", component:TodaysComponent},
  {path:"missedmeetings", component:TodaysComponent},
  {path:"RMassignedleads", component:AssignedrmComponent},
  {path:"CSassignedleads", component:AssignedrmComponent},
  {path:"followup", component:FollowupComponent},
  {path:"facetoface", component:FacetofaceComponent},
  {path:"usv", component:UsvComponent},
  {path:"sv", component:SvComponent},
  {path:"rsv", component:RsvComponent},
  {path:"finalnegotiation", component:NegotiateComponent},
  {path:"closedleads", component:ClosedleadsComponent},
  {path:"junks", component:JunkComponent},
  {path:"Inactive", component:InactiveComponent},
  {path:"leads", component:LeadsComponent},
  {path:"clientleads", component:ClientleadsComponent},
  {path:"registrationlist", component:RegistrationdataComponent},
  {path:"Executives", component:ExecutivesComponent},
  {path:"rmleadassign", component:LeadassignComponent},
  {path:"csleadassign", component:LeadassignComponent},
  {path:"Customer-Details/:id", component:CustomersComponent},
  {path:"mandateleads", component:MandatelistingComponent},
  {path:"mandatetodaysmeetings", component:MandatetodaysComponent},
  {path:"mandatemissedmeetings", component:MandatetodaysComponent},
  {path:"assignedleadsrm", component:MandateassignedComponent},
  {path:"mandate-lead-details/:id", component:MandateleaddetailsComponent},
  {path:"addproperty", component:AddpropertiesComponent},
  {path:"cpregister", component:AddcpComponent},
  {path:"mandaterequests", component:BuildermandaterequestComponent},
  {path:"mandateassistedclients", component:AssisstedClientsComponent},
  {path:"mandate-customers/:id", component:MandateCustomerDetailsComponent},

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
    
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
  
 }
