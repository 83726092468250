import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-f2fform',
  templateUrl: './f2fform.component.html',
  styleUrls: ['./f2fform.component.css']
})
export class F2fformComponent implements OnInit {

  ftofFixed = true;
  ftofreFix = false;
  ftofDone = false;

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location) {
      
     }

  ngOnInit() {
    $('.ui.dropdown').dropdown();
  }

  onFToFFixed(){
    this.ftofreFix = false;
    this.ftofFixed = true;
    this.ftofDone = false;
  }

  onFToFreFix(){
    this.ftofreFix = true;
    this.ftofFixed = false;
    this.ftofDone = false;
  }

  onFToFDone(){
    this.ftofreFix = false;
    this.ftofFixed = false;
    this.ftofDone = true;
  }

}
