import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { phpservice } from '../../php.service';
import { Enquiry,leadforward } from './enq';
import {Router} from '@angular/router';
import { Alert } from 'selenium-webdriver';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css'],
})

export class EnquiryComponent implements OnInit {
  @ViewChild('cancel')cancel:ElementRef;
  public userDetails: any;

  constructor(
    private _phpService:phpservice,
    private router: Router,
    private _location: Location,
  ) { 
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
      $('.ui.checkbox').checkbox();
    }, 1000);
  }

  enquiries:any;
  freshleads:any;
  executlist: any;
  typelist: any;
  propetytimeline: any;
  propertiespurpose: any;
  propertysize: any;
  sources:any;
  static count: number;
  static count2: number;
  homes247leadbtn = true;
  othersleadbtn = false;
  builders: any;
  builderid: any;
  properties: any;
  loadershow = false;
  showDetail = false;
  assignselection = true;
  teamselectionmodal = false;
  propertyselection = false;
  builderleadassignmodal = false;
  builderleads: any;
  filterLoader: boolean = true;
  selectedprop: any;

  ngOnDestroy(){
    $('.modal').remove();
  }
  ngOnInit() {
    this.getenquiries();
    this.getcitylist();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
    EnquiryComponent.count = 0;
    EnquiryComponent.count2 = 0;
    $('.ui.accordion').accordion();
  }

  refresh()
  {
    this.filterLoader = true;
    this.homes247leadbtn = true;
    this.othersleadbtn = false;
    this.cityid = "";
    this.ngOnInit();
  }

  onChange(source){
   // alert(source);
  }

  rowidentifier(i){
    // alert(i);
    if($('.duplicateidentify-'+i).hasClass('active')){
      $('.duplicateidentify-'+i).removeClass('active');
      $('.duplicateidentify-'+i).addClass('hidden');
    }else{
      $('.subduplicates').removeClass('active');
      $('.subduplicates').addClass('hidden');
      $('.duplicateidentify-'+i).addClass('active');
      $('.duplicateidentify-'+i).removeClass('hidden');
    }
  }
 
  button_close(){
    $('body').removeClass('scrolling');
    $('body').removeClass('dimmable');
    $('body').removeClass('blurring');
  }
   // ENQUIRY-INSERT-FUNC-STARTS

   model = new Enquiry();
   resposeData : any;
   status : any;
   executives:any;
   cities:any;
   cityid: any;

   getcitylist(){
    this._phpService.getcities().subscribe(citylist => {
      this.cities = citylist;
      console.log(this.cities);
    })
   }

   getcitybasedleads(event)
    {
      // const name = event.target.options[event.target.options.selectedIndex].text;
      const id = event.target.value;
      this.cityid = id;
      this.gethomes247leads();
    }


  sizeselection(event) {
    var value = event.target.value;
    const a = document.getElementById("sizeselect") as HTMLInputElement;
    a.value = value;
    let numArr = value.match(/[\d\.]+/g)
    numArr = numArr.filter(n => n != '.')
    // alert(numArr);
    this.model.size = numArr;
  }

  typeselection(event) {
    var value = event.target.value;
    const a = document.getElementById("proptypeselect") as HTMLInputElement;
    a.value = value;
    // let numArr = value.match(/[\d\.]+/g)
    // numArr = numArr.filter(n => n != '.')
    // alert(numArr);
    this.model.propertytype = value;
  }

  possessionselection(event)
  {
    var value = event.target.value;
    const a = document.getElementById("possessionselect") as HTMLInputElement;
    a.value = value;
    this.model.timeline = value;
  }

  budgetselection(event)
  {
    var value = event.target.value;
    const a = document.getElementById("budgetselect") as HTMLInputElement;
    a.value = value;
    this.model.budget = value;
  }

   addenquiry(){
    this.model.username =  localStorage.getItem('Name');
    if($('#custname').val()=="")
    {
        $('#custname').focus().css("border-color","red").attr('placeholder','Please Enter Name');
        return false;
    }
    else{
          var nameFilter=/^([a-zA-Z]+\s)*[a-zA-Z]+$/;
          if(nameFilter.test($('#custname').val()))
          {
           $('#custname').removeAttr("style");
          }
         else{
              $('#custname').focus().css("border-color","red").attr('placeholder','Please enter valid name').val('');
              return false;
         }
} 
if($('#custnum').val()=="")
     {
         $('#custnum').focus().css("border-color","red").attr('placeholder','Please Enter Phone Number');
         return false;
     }
else{
  var mobilee = /^[0-9]{10}$/;
  if (mobilee.test($('#custnum').val())) {
    $('#custnum').removeAttr('style');
  } else {
    $('#custnum').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid contact number').val('');
    return false;
  }
      }
      if($('#custmail').val()=="")
      {
          $('#custmail').focus().css("border-color","red").attr('placeholder','Please Enter Email-id');
          return false;
      }
     else{
        var email=/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
          if(email.test($('#custmail').val()))
          {
              $('#custmail').removeAttr("style");
          }
           else{
                $('#custmail').focus().css("border-color","red").attr('placeholder','Please enter valid email-id').val('');
                return false;
           }
       }

       if($('#sourceselect').val()== undefined)
        {
            $('.sourcename').focus().css("border-color","red").attr('placeholder','Please Enter Name');
            return false;
        }
        else
        {      
        $('.sourcename').removeAttr("style"); 
        }

        if($('#proptypeselect').val()=="")
        {
          swal({
            title: 'Please select the Property Type',
            text:  "Select any kind of Type",
            type: 'error',
            showConfirmButton: true,
            })
            return false;
        }
        else
        {      
        $('#proptypeselect').removeAttr("style"); 
        }

        if($('#possessionselect').val()=="")
        {
          swal({
            title: 'Please select the Possession',
            text:  "Select any Timeline for the client.",
            type: 'error',
            showConfirmButton: true,
            })
            return false;
        }
        else
        {      
        $('#possessionselect').removeAttr("style"); 
        }

        if($('#sizeselect').val()=="")
        {
          swal({
            title: 'Please select the Size',
            text:  "Select any BHK for the Final Submission",
            type: 'error',
            showConfirmButton: true,
            })
            return false;
        }
        else
        {      
        $('#sizeselect').removeAttr("style"); 
        }

        if($('#budgetselect').val()=="")
        {
          swal({
            title: 'Please select the Budget',
            text:  "Select any budget range ",
            type: 'error',
            showConfirmButton: true,
            })
            return false;
        }
        else
        {      
        $('#budgetselect').removeAttr("style"); 
        }
        if($('#address').val()=="")
    {
        $('#address').focus().css("border-color","red").attr('placeholder','Please Enter the address');
        return false;
    }
    else{
      $('#address').removeAttr("style");
    } 
    var params = this.model;
    this.loadershow = true;
    
    // console.log(params);
    this._phpService.addenquiry(params).subscribe((success)=> {
      this.status = success.code;
      if(this.status == "0"){
        this.loadershow = false;
        
      swal({
        title: 'Lead Added Succefully',
        text: 'Added as a new Lead',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.getenquiries();
        this.save();
      }else if(this.status == "1"){
        this.loadershow = false;
        swal({
          title: 'Existing Lead Found',
          text: 'Added as a Duplicate Entry',
          type: 'success',
          confirmButtonText: 'ok'
          })
          this.getenquiries();
          this.save();
      }else{
        this.loadershow = false;
        swal({
          title: 'Some Error Occured',
          text: 'Lead Not Passed',
          type: 'error',
          confirmButtonText: 'ok'
          })
          this.getenquiries();
          this.save();
      }
    },(err) => {
      console.log("Failed to Update");
    })
 };

 save(){
  $('.modal').removeClass('in');
  $('#add_test').hide();
  $('.modal-backdrop').remove();
  $('body').removeClass('modal-open');
  $('body').css('padding-right',"");
  $('#cancel').click();
  this.model.name = "";
  this.model.number = "";
  this.model.mail = "";
  this.model.propertytype = "";
  this.model.size = "";
  this.model.source = "";
  this.model.timeline = "";
  this.model.budget = "";
  this.model.address = "";
  $(".radiocheck").prop( "checked", false );
 }

//  ENQUIRY-VIEW-FROM-DB
getsource: any;
  getenquiries(){
    EnquiryComponent.count = 0;
    var limitparam = 0;
    var limitrows = 30;
    this.getsource = "OtherLeads";
    var cityid = this.cityid;
    this._phpService.getenquirylist(limitparam,limitrows,this.getsource,cityid).subscribe(enquirys =>
      {
        this.filterLoader = false;
        this.enquiries = enquirys;
        this.freshleads = enquirys;
        for(let i=0; i>=this.enquiries.length; i++){
          console.log(this.enquiries[i].duplicate);
        }
        
      })
  }

  gethomes247leads()
  {
    this.filterLoader = true;
    this.homes247leadbtn = false;
    this.othersleadbtn = true;
    EnquiryComponent.count = 0;
    var limitparam = 0;
    var limitrows = 30;
    this.getsource = "Homes247";
    var cityid = this.cityid;
    this._phpService.getenquirylist(limitparam,limitrows,this.getsource,cityid).subscribe(enquirys =>
      {
        this.filterLoader = false;
        this.enquiries = enquirys;
        this.freshleads = enquirys;
      })
  }
  
//  ENQUIRY-VIEW-FROM-DB

loadMore(){
  const limit = EnquiryComponent.count+=30;
  let limitrows = 30;
  let livecount = this.enquiries.length;
  if(livecount < 10000){
    return this._phpService.getenquirylist(limit,limitrows,this.getsource,this.cityid).subscribe(enquirys =>{
      this.enquiries = this.enquiries.concat(enquirys);
    })
  }else{
    
  }
}

// LEADS_BEFORE_ASSIGNED

loadMore2(){
  const limit = EnquiryComponent.count2+=30;
  let limitrows = 30;
  let livecount = this.freshleads.length;
  if(livecount < 10000){
    return this._phpService.getenquirylist(limit,limitrows,this.getsource,this.cityid).subscribe(callerleads =>{
      this.freshleads = this.freshleads.concat(callerleads);
    })
  }else{
    
  }
}

// LEADS_BEFORE_ASSIGNED

// executives_LIST

// executives_LIST
csleadassign = false;
rmleadassign = false;
getexecutives(event)
{
  const id = event.target.options[event.target.options.selectedIndex].value;
  if(id == '50002'){
    this.rmleadassign = true;
    this.csleadassign = false;
  }else if(id == '50004'){
    this.rmleadassign = false;
    this.csleadassign = true;
  }
  this._phpService.getexecutivesbasedid(id).subscribe(execute => {this.executives = execute;})
}

getexecutivesnames(event)
{
  const name = event.target.options[event.target.options.selectedIndex].text;
  this.leadforwards.telecallername = name;
}

// EXECUTIVELIST-FROM-DB

// EXECUTIVELIST-FROM-DB

leadforwards = new leadforward();

getselectedleads(id){
  var checkid = $("input[name='programming']:checked").map(function(){
    return this.value;
  }).get().join(',');
  console.log(checkid);
  this.leadforwards.assignedleads = checkid;
}

// INSERT_ASSIGNED_LEADS
assigntorm(){
  // alert("RM Submission");
  if($('#executives').val()== "")
        {
          swal({
            title: 'Please Select One Executive!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('.executives').removeAttr("style");
        }

        if($('#selectedleads').val()== "")
        {
          swal({
            title: 'Please Select Some Leads!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('#selectedleads').removeAttr("style");
        }
        var param = this.leadforwards;
        this._phpService.leadassignrm(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
        swal({
        title: 'Assigned Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.cancel.nativeElement.click();
        this.getenquiries();
        $('.ui.dropdown').dropdown('clear');
  }else{
    swal({
    title: 'Authentication Failed!',
    text: 'Please try agin',
    type: 'error',
    confirmButtonText: 'ok'
  })
}
}, (err) => {
console.log("Connection Failed")
});
}
assigntocs()
{
  // alert("CS Submission");
  if($('#executives').val()== "")
        {
          swal({
            title: 'Please Select One Executive!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('.executives').removeAttr("style");
        }

        if($('#selectedleads').val()== "")
        {
          swal({
            title: 'Please Select Some Leads!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('#selectedleads').removeAttr("style");
        }
        var param = this.leadforwards;
        this._phpService.leadassigncs(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
        swal({
        title: 'Assigned Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.cancel.nativeElement.click();
        this.getenquiries();
        $('.ui.dropdown').dropdown('clear');
  }else{
    swal({
    title: 'Authentication Failed!',
    text: 'Please try agin',
    type: 'error',
    confirmButtonText: 'ok'
  })
}
}, (err) => {
console.log("Connection Failed")
});
}

// INSERT_ASSIGNED_LEADS

assignmodalval: any;
assignmodalfetch(val){
  this.filterLoader = true;
  this.assignmodalval = val;
  if(val == '1'){
    this.teamselectionmodal = true;
  }else{
    this.propertyselection = true;
  }
  this.assignselection = false;
  this.filterLoader = false;
}

modalback(){
  if(this.assignmodalval == '1'){
    this.teamselectionmodal = false;
  }else if(this.assignmodalval == '2'){
    this.builderleadassignmodal = false;
    this.propertyselection = true;
    this.assignselection = false;
    this.assignmodalval = '3';
  }else if(this.assignmodalval == '3'){
    this.propertyselection = false;
    this.assignselection = true;
    this.assignmodalval = "";
  }
  else{
    this.teamselectionmodal = false;
    this.builderleadassignmodal = false;
    this.propertyselection = false;
    $('.close').click();
  }
}

fetchbuilderleads(val){
  this.propertyselection = false;
  this.filterLoader = true;
  this.builderleadassignmodal = true;
  this.selectedprop = val;
  var propertyname = val;
  this._phpService.fetchbuilderleads(propertyname).subscribe(builderslead =>
    {
      this.filterLoader = false;
      this.builderleads = builderslead;
    })
}

}
