import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
// import { leadforward, reassignlead } from './leadassign';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-assissted-clients',
  templateUrl: './assissted-clients.component.html',
  styleUrls: ['./assissted-clients.component.css']
})
export class AssisstedClientsComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private _phpService: phpservice, public datepipe: DatePipe) {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
      $('.ui.label.fluid.dropdown').dropdown({
        useLabels: false
      });
    }, 1000);
  }
  private isCountdownInitialized: boolean;

  filterLoader: boolean = true;

  Executivename = "";
  rmpaneldata = false;
  rmdata = false;
  // cspaneldata = false;
  // csdata = false;
  assignedleadscounts: any;
  fromdate: any;
  todate: any;
  rmid: any;
  // csid: any;
  activeleadscount: any;
  tookactionmenu = false;
  activemenu = false;
  followupmenu = false;
  assignedleadstable = true;
  tookactiontable = false;
  closedtable = false;
  followuptable = false;
  followupcounts: any;
  stagesections: any;
  tookaction: any;
  closedleads: any;
  followups: any;
  followupsectionid: any;
  static count: number;
  static activecount: number;
  static closedcount: number;
  static followupscount: number;
  static reassignedcount: number;
  callerleads: any;
  callerleadscounts: any;
  telecallers: any;
  leads: any;
  // leadforwards = new leadforward();
  // reassignleadforwards = new leadforward();
  status: any;
  // leadreassign = new reassignlead();
  executives: any;
  actionid: any;
  loadmorecountactivecounts: any;
  loadmorecountfollowups: any;
  loadmoreclosedcounts: any;

  totalcounts: any;
  tookactioncounts: any;
  pendingcounts: any;
  activecount: any;
  inactivecount: any;
  f2fcount: any;
  usvcount: any;
  svcount: any;
  rsvcount: any;
  negocount: any;
  closedcount: any;
  leadreassigning: any;
  // csleadassign = false;
  rmleadassign = false;
  inactivetable = false;
  junkleads: any;
  onlyrm = false;
  onlycs = false;
  rmonly: any;
  csonly: any;
  followupsections:any;
  filterdata = false;
  executivefilterview = false;
  statusfilterview = false;
  stagefilterview = false;
  statusvalue: any;
  stagevalue: any;
  execname: any;
  fupsectionname: any;
  selecteduser: any;

  ngOnInit() {
    this.roleid = localStorage.getItem('Role');
    this.userid = localStorage.getItem('UserId');
    this.getleadsdata();
    if (localStorage.getItem('Role') == null) {
      this.router.navigateByUrl('/login');
    }
    if (localStorage.getItem('Role') == '1') {
      if (this.router.url.indexOf('mandateassistedclients') > -1) {
        this.rmpaneldata = true;
        this.rmdata = true;
        
      } 
      // else if (this.router.url.indexOf('csleadassign') > -1) {
      //   this.cspaneldata = true;
      //   this.csdata = true;
      // }
    } else {
      if (this.router.url.indexOf('mandateassistedclients') > -1) {
        this.rmid = localStorage.getItem('UserId');
        this.rmpaneldata = false;
        this.rmdata = true;
        this.onlyrm = true;
        
      } 
      // else if (this.router.url.indexOf('csleadassign') > -1) {
      //   this.csid = localStorage.getItem('UserId');
      //   this.cspaneldata = false;
      //   this.csdata = true;
      //   this.onlycs = true;
      // }
      
    }
    this._phpService
      .getfollowupsections()
      .subscribe(followupsection => {
        this.followupsections = followupsection;
      });
    const elements = document.getElementsByClassName("modalclick");
    while (elements.length > 0) elements[0].remove();
    const el = document.createElement('div');
    el.classList.add('modalclick');
    document.body.appendChild(el);
    AssisstedClientsComponent.count = 0;
    AssisstedClientsComponent.activecount = 0;
    AssisstedClientsComponent.followupscount = 0;
    AssisstedClientsComponent.reassignedcount = 0;
    AssisstedClientsComponent.closedcount = 0;
  }

  ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      $('.modalclick').click(function(){
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
      });
    }
  }


  getleadsdata() {
    this.filterLoader = true;
    AssisstedClientsComponent.count = 0;
    this.actionid = "";
    this.route.queryParams.subscribe((paramss) => {
      const allassigned = paramss['allassigned'];
      const actiontook = paramss['actiontook'];
      const active = paramss['active'];
      const inactive = paramss['inactive'];
      const closed = paramss['closed'];
      const follow = paramss['follow'];
      const f2f = paramss['f2f'];
      const usv = paramss['usv'];
      const sv = paramss['sitevisit'];
      const rsv = paramss['rsv'];
      const nego = paramss['nego'];
      const callback = paramss['callback'];
      const rnr = paramss['rnr'];
      const off = paramss['off'];
      const notconnect = paramss['notconnect'];
      const numbusy = paramss['numbusy'];
      const noanswer = paramss['noanswer'];
      const noreach = paramss['noreach'];
      const fromdate = paramss['from'];
      const todate = paramss['to'];

      if (localStorage.getItem('Role') == '1'){
        if (this.router.url.indexOf('mandateassistedclients') > -1){
          this.rmonly = "RMonly";
          const rm = paramss['rm'];
          if(rm != ""){
            this.rmid = rm;
          }else{
            this.rmid = "";
          }
        }
      }else if(localStorage.getItem('Role') == '50002'){

        this.rmid = localStorage.getItem('UserId');
        

      }
      // else if(localStorage.getItem('Role') == '50004'){

      //   this.csid = localStorage.getItem('UserId');
      // }
      

      if(fromdate != ""){
        this.fromdate = fromdate;
      }else{
        this.fromdate = "";
      }

      if(todate != ""){
        this.todate = todate;
      }else{
        this.todate = "";
      }

      

      // All assigned Leads
      if(allassigned == '1'){
        this.totalassignedclick();
      }
      // All assigned Leads

      // Tookaction Leads
      else if(actiontook == '1'){
        this.actionclick();
      }
      // Tookaction Leads

      // Active Leads
      else if(active == '1'){
        this.activeclick();
      }
      // Active Leads

      // Inactive Leads
      else if(inactive == '1'){
        this.inactiveclick();
      }
      // Inactive Leads

      // Closed Leads
      else if(closed == '1'){
        this.closedclick();
      }
      // Closed Leads

      // Followupleads
      else if(follow == '1'){
        this.followclick();
      }
      // Followupleads

      // F2F Leads
      else if(f2f == '1'){
        this.f2fclick();
      }
      // F2F Leads

      // USV
      else if(usv == '1'){
        this.usvclick();
      }
      // USV

      // SV
      else if(sv == '1'){
        this.svclick();
      }
      // SV

      // RSV
      else if(rsv == '1'){
        this.rsvclick();
      }
      // RSV

      // Final Nego
      else if(nego == '1'){
        this.negoclick();
      }
      // Final Nego

      // Callbacks
      else if(callback == '1'){
        this.callbackclick();
      }
      // Callbacks

      // RNR
      else if(rnr == '1'){
        this.rnrclick();
      }
      // RNR

      // Switchoff
      else if(off == '1'){
        this.switchoffclick();
      }
      // Switchoff

      // Notconnected
      else if(notconnect == '1'){
        this.notconnectclick();
      }
      // Notconnected

      // Numberbusy
      else if(numbusy == '1'){
        this.numberbusyclick();
      }
      // Numberbusy

      // Not Answered
      else if(noanswer == '1'){
        this.notanswerclick();
      }
      // Not Answered

      // Not Reachable
      else if(noreach == '1'){
        this.notreachclick();
      }
      // Not Reachable

    });

  }


  totalassignedclick() {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'allassigned': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid },
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'allassigned': 1, 'from': this.fromdate, 'to': this.todate },
        }); 
      }
    }
    // AssisstedClientsComponent.count = 0;
    $(".other_section").removeClass("active");
    $(".first_section").addClass("active");
    this.assignedleadstable = true;
    this.tookactiontable = false;
    this.inactivetable = false;
    this.closedtable = false;
    this.followuptable = false;
    this.tookactionmenu = false;
    this.activemenu = false;
    this.followupmenu = false;
    this.actionid = "";
    this.commonfilterclick();
    this.countsfetcher();
    this.loadmorecountactivecounts = "";
    this.loadmorecountfollowups = "";
  }

  actionclick() {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'actiontook': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'actiontook': 1, 'from': this.fromdate, 'to': this.todate }, 
        }); 
      }
      
    }
    
    AssisstedClientsComponent.activecount = 0;
    this.stagesections = "";
    this.followupsectionid = "";
    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    this.followupmenu = false;
    this.tookactionmenu = true;
    this.assignedleadstable = false;
    this.tookactiontable = true;
    this.followuptable = false;
    this.inactivetable = false;
    this.closedtable = false;
    this.activeleadsclick();
    this.countsfetcher();
    this.loadmorecountactivecounts = this.activecount;
  }


  activeclick() {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'active': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'active': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'active': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'active': 1, 'from': this.fromdate, 'to': this.todate}, 
    //     });
    //   }
    // }
    this.followupmenu = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.assignedleadstable = false;
    this.followuptable = false;
    this.tookactiontable = true;
    this.inactivetable = false;
    this.closedtable = false;
    // this.stagesections = "";
    this.followupsectionid = "";
    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    this.activeleadsclick();
    this.countsfetcher();
    
  }

  inactiveclick() 
  {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'inactive': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'inactive': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'inactive': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'inactive': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".inactive_section_class").addClass("active");
    this.tookactiontable = false;
    this.followupmenu = false;
    this.assignedleadstable = false;
    this.followuptable = false;
    this.inactivetable = true;
    this.closedtable = false;
    this.tookactionmenu = true;
    this.activemenu = false;
    // this.stagesections = "";
    this.followupsectionid = "";
    this.inactiveleadsclick();
    this.countsfetcher();
  }

  closedclick() {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'closed': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'closed': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'closed': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'closed': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }
    $(".other_section").removeClass("active");
    $(".close_class").addClass("active");
    $(".tookaction").addClass("active");
    $(".closed_section_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.inactivetable = false;
    this.followuptable = false;
    this.closedtable = true;
    this.followupmenu = false;
    this.tookactionmenu = true;
    // this.stagesections = "Lead Closed";
    this.closedleadsclick();
    this.countsfetcher();
  }

  followclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'follow': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'follow': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'follow': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'follow': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }
    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followupmenu = true;
    this.followuptable = true;
    this.inactivetable = false;
    this.closedtable = false;
    this.followupsectionid = "";
    this.stagesections = "Follow Up";
    this.followupfilterclick();
    this.countsfetcher();
  }

  f2fclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'f2f': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'f2f': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'f2f': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'f2f': 1, 'from': this.fromdate, 'to': this.todate}, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".f2f_class").addClass("active");
    this.assignedleadstable = false;
    this.followupmenu = false;
    this.tookactiontable = true;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = false;
    this.inactivetable = false;
    this.closedtable = false;
    this.stagesections = "Face to Face Meeting";
    // this.loadmorecountactivecounts = this.activeleadscount.facetoface;
    this.activeleadsclick();
    this.countsfetcher();
  }

  usvclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'usv': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'usv': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'usv': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'usv': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".usv_class").addClass("active");
    this.assignedleadstable = false;
    this.followupmenu = false;
    this.followuptable = false;
    this.tookactiontable = true;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.inactivetable = false;
    this.closedtable = false;
    this.stagesections = "USV";
    this.activeleadsclick();
    this.countsfetcher();
  }

  svclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'sitevisit': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'sitevisit': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'sitevisit': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'sitevisit': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".sv_class").addClass("active");
    this.assignedleadstable = false;
    this.followupmenu = false;
    this.followuptable = false;
    this.tookactiontable = true;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.inactivetable = false;
    this.closedtable = false;
    this.stagesections = "SV";
    // this.loadmorecountactivecounts = this.activeleadscount.svcount;
    this.activeleadsclick();
    this.countsfetcher();
  }

  rsvclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'rsv': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'rsv': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'rsv': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'rsv': 1, 'from': this.fromdate, 'to': this.todate}, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".rsv_class").addClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    this.assignedleadstable = false;
    this.followupmenu = false;
    this.followuptable = false;
    this.tookactiontable = true;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.inactivetable = false;
    this.closedtable = false;
    this.stagesections = "RSV";
    // this.loadmorecountactivecounts = this.activeleadscount.rsvcount;
    this.activeleadsclick();
    this.countsfetcher();
  }

  negoclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'nego': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'nego': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'nego': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'nego': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".nego_class").addClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    this.assignedleadstable = false;
    this.followupmenu = false;
    this.followuptable = false;
    this.tookactiontable = true;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.inactivetable = false;
    this.closedtable = false;
    this.stagesections = "Final Negotiation";
    // this.loadmorecountactivecounts = this.activeleadscount.nego_count;
    this.activeleadsclick();
    this.countsfetcher();
  }

  callbackclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'callback': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'callback': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'callback': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'callback': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".callback_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.callbackcount;
    this.followupsectionid = '1';
    this.followupfilterclick();
    this.countsfetcher();
  }

  rnrclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'rnr': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'rnr': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'rnr': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'rnr': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".rnr_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.rnrcount;
    this.followupsectionid = '2';
    this.followupfilterclick();
    this.countsfetcher();
  }

  switchoffclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'off': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'off': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'off': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'off': 1, 'from': this.fromdate, 'to': this.todate}, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".switchoff_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.switchoffcount;
    this.followupsectionid = '3';
    this.followupfilterclick();
    this.countsfetcher();
  }

  notconnectclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'notconnect': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'notconnect': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'notconnect': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'notconnect': 1, 'from': this.fromdate, 'to': this.todate}, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".notconnect_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.notconnectedcount;
    this.followupsectionid = '4';
    this.followupfilterclick();
    this.countsfetcher();
  }

  numberbusyclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'numbusy': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'numbusy': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'numbusy': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'numbusy': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".numberbusy_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.numberbusycount;
    this.followupsectionid = '5';
    this.followupfilterclick();
    this.countsfetcher();
  }

  notanswerclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'noanswer': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'noanswer': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'noanswer': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'noanswer': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".notanswer_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.noanswercount;
    this.followupsectionid = '6';
    this.followupfilterclick();
    this.countsfetcher();
  }

  notreachclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      if (localStorage.getItem('Role') == '1'){
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'noreach': 1, 'from': this.fromdate, 'to': this.todate, 'rm': this.rmid }, 
        }); 
      }else{
        this.router.navigate(['/mandateassistedclients'], { 
          relativeTo: this.route, 
          queryParams: { 'noreach': 1, 'from': this.fromdate, 'to': this.todate}, 
        }); 
      }
    }
    // else{
    //   if (localStorage.getItem('Role') == '1'){
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'noreach': 1, 'from': this.fromdate, 'to': this.todate, 'cs': this.csid }, 
    //     });
    //   }else{
    //     this.router.navigate(['/csleadassign'], { 
    //       relativeTo: this.route, 
    //       queryParams: { 'noreach': 1, 'from': this.fromdate, 'to': this.todate }, 
    //     });
    //   }
    // }

    $(".other_section").removeClass("active");
    $(".tookaction").addClass("active");
    $(".active_section_class").addClass("active");
    $(".followup_class").addClass("active");
    $(".notreach_class").addClass("active");
    this.assignedleadstable = false;
    this.tookactiontable = false;
    this.tookactionmenu = true;
    this.activemenu = true;
    this.followuptable = true;
    this.closedtable = false;
    this.followupmenu = true;
    // this.loadmorecountfollowups = this.followupcounts.noreachcount;
    this.followupsectionid = '7';
    this.followupfilterclick();
    this.countsfetcher();
  }

  //  ENQUIRY-VIEW-FROM-DB
  filteredexecutives: any;
  rmselecttodate() {
    // this.filterLoader = true;
    this.fromdate = $('#fromdate').val();
    this.todate = $('#todate').val();
    
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 'from': this.fromdate, 'to': this.todate }, 
      queryParamsHandling: 'merge', 
    }); 
    this.actionid = "";
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      executid: this.rmid,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
      this.callerleads = compleads; 
      this.callerleadscounts = compleads; 
      this.filterLoader = false;
    })

    var paramcount = {
      datefrom: this.fromdate,
      dateto: this.todate,
      rmid: this.rmid,
    }
    this._phpService.assignedcountsadmin(paramcount).subscribe(assignedcounts => { 
      this.assignedleadscounts = assignedcounts[0]; 
      this.totalcounts = this.assignedleadscounts.Totalcounts; 
      this.tookactioncounts = this.assignedleadscounts.Tookactioncounts;
      this.pendingcounts = this.assignedleadscounts.Pendingcounts;
    })
    this.commonfilterclick();
    this.activeleadsclick();
    this.followupfilterclick();
  }

  rmreassignselectdate(){
    this.filterLoader = true;
    this.fromdate = $('#fromdatereassign').val();
    this.todate = $('#todatereassign').val();
    this.actionid = "";
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      executid: this.rmid,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
      this.callerleads = compleads; 
      this.callerleadscounts = compleads; 
      this.filterLoader = false;
    })
  }

  // csselecttodate() {
  //   this.filterLoader = true;
  //   this.fromdate = $('#fromdate').val();
  //   this.todate = $('#todate').val();
  //   this.router.navigate([], { 
  //     relativeTo: this.route, 
  //     queryParams: { 'from': this.fromdate, 'to': this.todate }, 
  //     queryParamsHandling: 'merge', 
  //   }); 
  //   this.actionid = "";
  //   var param = {
  //     limit: 0,
  //     limitrows: 30,
  //     datefrom: this.fromdate,
  //     dateto: this.todate,
  //     statuss: this.actionid,
  //     executid: this.csid,
  //   }
  //   this._phpService.completeassignedCSLeads(param).subscribe(compleads => { 
  //     this.callerleads = compleads; 
  //     this.callerleadscounts = compleads; 
  //     this.filterLoader = false;
  //   })

  //   var paramcount = {
  //     datefrom: this.fromdate,
  //     dateto: this.todate,
  //     csid: this.csid,
  //   }
  //   this._phpService.assignedcountscsadmin(paramcount).subscribe(assignedcounts => { 
  //     this.assignedleadscounts = assignedcounts[0]; 
  //     this.totalcounts = this.assignedleadscounts.Totalcounts; 
  //     this.tookactioncounts = this.assignedleadscounts.Tookactioncounts;
  //     this.pendingcounts = this.assignedleadscounts.Pendingcounts;
  //   })
  //   this.commonfilterclick();
  //   this.activeleadsclick();
  //   this.followupfilterclick();
  // }

  // csreassignselecttodate()
  // {
  //   this.filterLoader = true;
  //   this.fromdate = $('#fromdatereassign').val();
  //   this.todate = $('#todatereassign').val();
  //   this.actionid = "";
  //   var param = {
  //     limit: 0,
  //     limitrows: 30,
  //     datefrom: this.fromdate,
  //     dateto: this.todate,
  //     statuss: this.actionid,
  //     executid: this.csid,
  //   }
  //   this._phpService.completeassignedCSLeads(param).subscribe(compleads => { 
  //     this.callerleads = compleads; 
  //     this.filterLoader = false;
  //   })
  // }

  leadstatuschange(val){
    this.filterdata = true;
    this.statusfilterview = true;
    this.statusvalue = val;
    if(val == "active"){
      this.activeclick();
    }else if(val == "inactive"){
      this.inactiveclick();
    }else if(val == "booked"){
      this.closedclick();
    }
  }

  leadstagechange(val){
    this.filterdata = true;
    this.stagefilterview = true;
    this.stagevalue = val;
    this.stagesections = val;
    if(val == "GF"){
      this.followclick();
    }else if(val == "USV"){
      this.usvclick();
    }else if(val == "SV"){
      this.svclick();
    }else if(val == "RSV"){
      this.rsvclick();
    }else if(val == "Nego"){
      this.negoclick();
    }
  }

  rmchange(vals) {
    this.execname = vals.target.options[vals.target.selectedIndex].text;
    const val = vals.target.value;
    this.filterLoader = true;
    this.rmid = val;
    this.filterdata = true;
    this.executivefilterview = true;
    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: { 'rm': this.rmid }, 
      queryParamsHandling: 'merge', 
    }); 
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      executid: this.rmid,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
      this.callerleads = compleads; 
      this.filterLoader = false;
    })

    var paramcount = {
      datefrom: this.fromdate,
      dateto: this.todate,
      rmid: this.rmid,
    }
    this._phpService.assignedcountsadmin(paramcount).subscribe(assignedcounts => {
      this.assignedleadscounts = assignedcounts[0];
      this.totalcounts = this.assignedleadscounts.Totalcounts;
      this.tookactioncounts = this.assignedleadscounts.Tookactioncounts;
      this.pendingcounts = this.assignedleadscounts.Pendingcounts;
    })
    // this.commonfilterclick();
    // this.activeleadsclick();
    // this.inactiveleadsclick();
    // this.followupfilterclick();
    var deptid = '50002';
    this._phpService.getexecutivesbasedfilter(deptid,this.rmid).subscribe(execute => { this.filteredexecutives = execute; })
  }

  fupsectionchange(vals){
    this.fupsectionname = vals.target.options[vals.target.selectedIndex].text;
    if(this.fupsectionname == "GF"){

    }
  }

  commonfilterclick() {
    this.filterLoader = true;
    if (this.router.url.indexOf('mandateassistedclients') > -1) {
      var param = {
        limit: 0,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        executid: this.rmid,
      }
      this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.callerleads = compleads; 
        this.filterLoader = false;
      })
    } 
  }

  followupfilterclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      AssisstedClientsComponent.followupscount = 0;
    var param = {
      limit: 0,
      limitrows: 30,
      fromdate: this.fromdate,
      todate: this.todate,
      rmid: this.rmid,
      sectionid: this.followupsectionid,

    }
    this._phpService
      .Rmfollowups(param)
      .subscribe(follow => {
        this.followups = follow;
        this.filterLoader = false;
      })
    }
  }

  countsfetcher(){
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      var param3 = {
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        onlyrm: this.rmonly,
      }
      this._phpService
        .getactivermleadscounts(param3)
        .subscribe(activecounts => {
          this.activeleadscount = activecounts[0];
          this.activecount = this.activeleadscount.activecount;
          this.inactivecount = this.activeleadscount.inactivecount;
          this.closedcount = this.activeleadscount.closedcount;
          this.f2fcount = this.activeleadscount.facetoface;
          this.usvcount = this.activeleadscount.usvcount;
          this.svcount = this.activeleadscount.svcount;
          this.rsvcount = this.activeleadscount.rsvcount;
          this.negocount = this.activeleadscount.nego_count;
          this.loadmoreclosedcounts = this.activeleadscount.closedcount;
          if(this.stagesections == "Face to Face Meeting"){
            this.loadmorecountactivecounts = this.activeleadscount.facetoface;
          }else if(this.stagesections == "USV"){
            this.loadmorecountactivecounts = this.activeleadscount.usvcount;
          }else if(this.stagesections == "SV"){
            this.loadmorecountactivecounts = this.activeleadscount.svcount;
          }else if(this.stagesections == "RSV"){
            this.loadmorecountactivecounts = this.activeleadscount.rsvcount;
          }else if(this.stagesections == "Final Negotiation"){
            this.loadmorecountactivecounts = this.activeleadscount.nego_count;
          }else{
            this.loadmorecountactivecounts = this.activeleadscount.activecount;
          }
        })
        
        var param5 = {
          fromdate: this.fromdate,
          todate: this.todate,
          rmid: this.rmid,
        }
        this._phpService
          .Rmfollowupcounts(param5)
          .subscribe(followcounts => {
            this.followupcounts = followcounts[0];
            if(this.followupsectionid == '1'){
              this.loadmorecountfollowups = this.followupcounts.callbackcount;
            }else if(this.followupsectionid == '2'){
              this.loadmorecountfollowups = this.followupcounts.rnrcount;
            }else if(this.followupsectionid == '3'){
              this.loadmorecountfollowups = this.followupcounts.switchoffcount;
            }else if(this.followupsectionid == '4'){
              this.loadmorecountfollowups = this.followupcounts.notconnectedcount;
            }else if(this.followupsectionid == '5'){
              this.loadmorecountfollowups = this.followupcounts.numberbusycount;
            }else if(this.followupsectionid == '6'){
              this.loadmorecountfollowups = this.followupcounts.noanswercount;
            }else if(this.followupsectionid == '7'){
              this.loadmorecountfollowups = this.followupcounts.noreachcount;
            }else{
              this.loadmorecountfollowups = this.followupcounts.followupcount;
            }
          });

    }
    // else if (this.router.url.indexOf('csleadassign') > -1){

    //   var param4 = {
    //     fromdate: this.fromdate,
    //     todate: this.todate,
    //     csid: this.csid,
    //     onlycs: this.csonly,
    //   }
    //   this._phpService
    //     .getactivecsleadscounts(param4)
    //     .subscribe(activecounts => {
    //       this.activeleadscount = activecounts[0];
    //       this.activecount = this.activeleadscount.activecount;
    //       this.inactivecount = this.activeleadscount.inactivecount;
    //       this.closedcount = this.activeleadscount.closedcount;
    //       this.f2fcount = this.activeleadscount.facetoface;
    //       this.usvcount = this.activeleadscount.usvcount;
    //       this.svcount = this.activeleadscount.svcount;
    //       this.rsvcount = this.activeleadscount.rsvcount;
    //       this.negocount = this.activeleadscount.nego_count;
    //       if(this.stagesections == "Face to Face Meeting"){
    //         this.loadmorecountactivecounts = this.activeleadscount.facetoface;
    //       }else if(this.stagesections == "USV"){
    //         this.loadmorecountactivecounts = this.activeleadscount.usvcount;
    //       }else if(this.stagesections == "SV"){
    //         this.loadmorecountactivecounts = this.activeleadscount.svcount;
    //       }else if(this.stagesections == "RSV"){
    //         this.loadmorecountactivecounts = this.activeleadscount.rsvcount;
    //       }else if(this.stagesections == "Final Negotiation"){
    //         this.loadmorecountactivecounts = this.activeleadscount.nego_count;
    //       }else{
    //         this.loadmorecountactivecounts = this.activeleadscount.activecount;
    //       }
    //     })

    //     var param6 = {
    //       fromdate: this.fromdate,
    //       todate: this.todate,
    //       csid: this.csid,
    //     }
    //     this._phpService
    //       .Csfollowupcounts(param6)
    //       .subscribe(followcounts => {
    //         this.followupcounts = followcounts[0];
    //         if(this.followupsectionid == '1'){
    //           this.loadmorecountfollowups = this.followupcounts.callbackcount;
    //         }else if(this.followupsectionid == '2'){
    //           this.loadmorecountfollowups = this.followupcounts.rnrcount;
    //         }else if(this.followupsectionid == '3'){
    //           this.loadmorecountfollowups = this.followupcounts.switchoffcount;
    //         }else if(this.followupsectionid == '4'){
    //           this.loadmorecountfollowups = this.followupcounts.notconnectedcount;
    //         }else if(this.followupsectionid == '5'){
    //           this.loadmorecountfollowups = this.followupcounts.numberbusycount;
    //         }else if(this.followupsectionid == '6'){
    //           this.loadmorecountfollowups = this.followupcounts.noanswercount;
    //         }else if(this.followupsectionid == '7'){
    //           this.loadmorecountfollowups = this.followupcounts.noreachcount;
    //         }else{
    //           this.loadmorecountfollowups = this.followupcounts.followupcount;
    //         }
    //       });
    // }

  }

  activeleadsclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      AssisstedClientsComponent.activecount = 0;
    var param = {
      limit: 0,
      limitrows: 30,
      fromdate: this.fromdate,
      todate: this.todate,
      rmid: this.rmid,
      stages: this.stagesections,
    }
    this._phpService
      .getactivermleadslist(param)
      .subscribe(tookactionleads => {
        this.tookaction = tookactionleads;
        this.filterLoader = false;
      })
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   AssisstedClientsComponent.activecount = 0;
    // var param2 = {
    //   limit: 0,
    //   limitrows: 30,
    //   fromdate: this.fromdate,
    //   todate: this.todate,
    //   csid: this.csid,
    //   stages: this.stagesections,
    // }
    // this._phpService
    //   .getactivecsleadslist(param2)
    //   .subscribe(tookactionleads => {
    //     this.tookaction = tookactionleads;
    //     this.filterLoader = false;
    //   })
    // }
  }

  inactiveleadsclick() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      AssisstedClientsComponent.activecount = 0;
    var param = {
      limit: 0,
      limitrows: 30,
      fromdate: this.fromdate,
      todate: this.todate,
      rmid: this.rmid,
    }
    this._phpService
      .getjunksrm(param)
      .subscribe(inactiveleads => {
        this.junkleads = inactiveleads;
        this.filterLoader = false;
      })

      var param3 = {
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
      }
      this._phpService
        .getactivermleadscounts(param3)
        .subscribe(activecounts => {
          this.activeleadscount = activecounts[0];
          this.activecount = this.activeleadscount.activecount;
          this.inactivecount = this.activeleadscount.inactivecount;
          this.closedcount = this.activeleadscount.closedcount;
          this.f2fcount = this.activeleadscount.facetoface;
          this.usvcount = this.activeleadscount.usvcount;
          this.svcount = this.activeleadscount.svcount;
          this.rsvcount = this.activeleadscount.rsvcount;
          this.negocount = this.activeleadscount.nego_count;
        })
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   AssisstedClientsComponent.activecount = 0;
    //   var param = {
    //     limit: 0,
    //     limitrows: 30,
    //     fromdate: this.fromdate,
    //     todate: this.todate,
    //     rmid: this.rmid,
    //   }
    //   this._phpService
    //     .getjunkscs(param)
    //     .subscribe(inactiveleads => {
    //       this.junkleads = inactiveleads;
    //       this.filterLoader = false;
    //     })

    //   var param4 = {
    //     fromdate: this.fromdate,
    //     todate: this.todate,
    //     csid: this.csid,

    //   }
    //   this._phpService
    //     .getactivecsleadscounts(param4)
    //     .subscribe(activecounts => {
    //       this.activeleadscount = activecounts[0];
    //       this.activecount = this.activeleadscount.activecount;
    //       this.inactivecount = this.activeleadscount.inactivecount;
    //       this.closedcount = this.activeleadscount.closedcount;
    //       this.f2fcount = this.activeleadscount.facetoface;
    //       this.usvcount = this.activeleadscount.usvcount;
    //       this.svcount = this.activeleadscount.svcount;
    //       this.rsvcount = this.activeleadscount.rsvcount;
    //       this.negocount = this.activeleadscount.nego_count;
    //     })
    // }
  }

  closedleadsclick(){
      AssisstedClientsComponent.closedcount = 0;
    var param = {
      limit: 0,
      limitrows: 30,
      fromdate: this.fromdate,
      todate: this.todate,
      rmid: this.rmid,
      // csid: this.csid,
      onlyrm: this.rmonly,
      onlycs: this.csonly,
    }
    this._phpService
      .getclosedlists(param)
      .subscribe(closingleads => {
        this.closedleads = closingleads;
        this.filterLoader = false;
      })
  }

  loadMoreassignedleads() {
   
    if (this.router.url.indexOf('mandateassistedclients') > -1) {
      const limit = AssisstedClientsComponent.count+=30;
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        executid: this.rmid,
      }
      if(this.callerleads.length <= this.assignedleadscounts.Totalcounts){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          this.callerleads = this.callerleads.concat(compleads); 
          this.filterLoader = false;
        })
      }
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   const limit = AssisstedClientsComponent.count+=30;
    //   var param = {
    //     limit: limit,
    //     limitrows: 30,
    //     datefrom: this.fromdate,
    //     dateto: this.todate,
    //     statuss: this.actionid,
    //     executid: this.csid,
    //   }
    //   if(this.callerleads.length <= this.assignedleadscounts.Totalcounts){
    //     this.filterLoader = true;
    //     return this._phpService.completeassignedCSLeads(param).subscribe(compleads => { 
    //       this.callerleads = this.callerleads.concat(compleads); 
    //       this.filterLoader = false;
    //     })
    //   }
    // }
    
  }

  loadMorereassignedleads() {
    
    if (this.router.url.indexOf('mandateassistedclients') > -1) {
      const limit = AssisstedClientsComponent.count+=30;
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        executid: this.rmid,
      }
      if(this.callerleads.length <= this.assignedleadscounts.Totalcounts){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          this.callerleads = this.callerleads.concat(compleads); 
          this.filterLoader = false;
        })
      }
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   const limit = AssisstedClientsComponent.count+=30;
    //   var param = {
    //     limit: limit,
    //     limitrows: 30,
    //     datefrom: this.fromdate,
    //     dateto: this.todate,
    //     statuss: this.actionid,
    //     executid: this.csid,
    //   }
    //   if(this.callerleads.length <= this.assignedleadscounts.Totalcounts){
    //     this.filterLoader = true;
    //     return this._phpService.completeassignedCSLeads(param).subscribe(compleads => { 
    //       this.callerleads = this.callerleads.concat(compleads); 
    //       this.filterLoader = false;
    //     })
    //   }
    // }

  }

  loadMoreactiveleads() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      const limit = AssisstedClientsComponent.activecount+=30;
      var param = {
        limit:limit,
        limitrows: 30,
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        stages: this.stagesections,
      }
      if(this.tookaction.length <= this.loadmorecountactivecounts){
        this.filterLoader = true;
        return this._phpService.getactivermleadslist(param).subscribe(tookactionleads => {
          this.tookaction = this.tookaction.concat(tookactionleads);
          this.filterLoader = false;
        })
      }
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   const limit = AssisstedClientsComponent.activecount+=30;
    // var param2 = {
    //   limit:limit,
    //   limitrows: 30,
    //   fromdate: this.fromdate,
    //   todate: this.todate,
    //   csid: this.csid,
    //   stages: this.stagesections,
    // }
    // if(this.tookaction.length <= this.loadmorecountactivecounts){
    //   this.filterLoader = true;
    //   return this._phpService.getactivecsleadslist(param2).subscribe(tookactionleads => {
    //     this.tookaction = this.tookaction.concat(tookactionleads);
    //     this.filterLoader = false;
    //   })
    // }
    // }
    

  }

  loadMoreinactiveleads() {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      const limit = AssisstedClientsComponent.activecount+=30;
      var param = {
        limit:limit,
        limitrows: 30,
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        stages: this.stagesections,
      }
      if(this.junkleads.length <= this.inactivecount){
        this.filterLoader = true;
        return this._phpService
        .getjunksrm(param).subscribe(inactiveleads => {
          this.junkleads = this.junkleads.concat(inactiveleads);
          this.filterLoader = false;
        })
      }
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1) {
    //   const limit = AssisstedClientsComponent.activecount+=30;
    // var param2 = {
    //   limit:limit,
    //   limitrows: 30,
    //   fromdate: this.fromdate,
    //   todate: this.todate,
    //   csid: this.csid,
    //   stages: this.stagesections,
    // }
    // if(this.tookaction.length <= this.loadmorecountactivecounts){
    //   this.filterLoader = true;
    //   return this._phpService
    //   .getjunkscs(param2).subscribe(inactiveleads => {
    //     this.junkleads = this.junkleads.concat(inactiveleads);
    //     this.filterLoader = false;
    //   })
    // }
    // }
    

  }

  loadMorefollowups()
  {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      const limit = AssisstedClientsComponent.followupscount+=30;
      var param = {
        limit: limit,
        limitrows: 30,
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        sectionid: this.followupsectionid,
      }
      if(this.followups.length <= this.loadmorecountfollowups){
        this.filterLoader = true;
        return this._phpService.Rmfollowups(param).subscribe(follow => {
          this.followups = this.followups.concat(follow);
          this.filterLoader = false;
        })
      }
    } 
    // else if (this.router.url.indexOf('csleadassign') > -1){
    //   const limit = AssisstedClientsComponent.followupscount+=30;
    //   var param2 = {
    //     limit: limit,
    //     limitrows: 30,
    //     fromdate: this.fromdate,
    //     todate: this.todate,
    //     csid: this.csid,
    //     sectionid: this.followupsectionid,
    //   }
    //   if(this.followups.length <= this.loadmorecountfollowups){
    //     this.filterLoader = true;
    //     return this._phpService.Csfollowups(param2).subscribe(follow => {
    //       this.followups = this.followups.concat(follow);
    //       this.filterLoader = false;
    //     })
    //   }
    // }
    
  }

  loadMorereassign()
  {
    if (this.router.url.indexOf('mandateassistedclients') > -1){
      const limit = AssisstedClientsComponent.reassignedcount+=30;
      var param = {
        limit: limit,
        limitrows: 30,
        CS: "NOTINCS"
      }
    
    if(this.leadreassigning.length <= this.assignedleadscounts.Totalcounts){
      this.filterLoader = true;
      return this._phpService.getreassignleads(param).subscribe(reassignleads => { 
        this.leadreassigning = this.leadreassigning.concat(reassignleads); 
        this.filterLoader = false;
      })
    
    }
    }
    // else if (this.router.url.indexOf('csleadassign') > -1){
    //   const limit = AssisstedClientsComponent.reassignedcount+=30;
    //   var param2 = {
    //     limit: limit,
    //     limitrows: 30,
    //     RM: "NOTINRM"
    //   }
    
    // if(this.leadreassigning.length <= this.assignedleadscounts.Totalcounts){
    //   this.filterLoader = true;
    //   return this._phpService.getreassignleads(param2).subscribe(reassignleads => { 
    //     this.leadreassigning = this.leadreassigning.concat(reassignleads); 
    //     this.filterLoader = false;
    //   })
    // }
    // }
    


  }

  loadMoreclosedleads(){
      const limit = AssisstedClientsComponent.closedcount+=30;
      var param = {
        limit:limit,
        limitrows: 30,
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        // csid: this.csid,
      }
      if(this.closedleads.length <= this.loadmoreclosedcounts){
        this.filterLoader = true;
        return this._phpService.getclosedlists(param).subscribe(closedsessleads => {
          this.closedleads = this.closedleads.concat(closedsessleads);
          this.filterLoader = false;
        })
      }
  }
  
  loadreassignleads(){
    if (this.router.url.indexOf('mandateassistedclients') > -1) {
      AssisstedClientsComponent.reassignedcount = 0;
    var param = {
      limit: 0,
      limitrows: 30,
      CS: "NOTINCS"
    }
    this._phpService.getreassignleads(param).subscribe(reassignleads => { this.leadreassigning = reassignleads; })
    }
    // else if (this.router.url.indexOf('csleadassign') > -1){
    //   AssisstedClientsComponent.reassignedcount = 0;
    // var param2 = {
    //   limit: 0,
    //   limitrows: 30,
    //   RM: "NOTINRM"
    // }
    // this._phpService.getreassignleads(param2).subscribe(reassignleads => { this.leadreassigning = reassignleads; })
    // }
    
    
  }

  // gettelacallername(event) {
  //   if (this.router.url.indexOf('mandateassistedclients') > -1){
  //     this.csleadassign = true;
  //   }else if (this.router.url.indexOf('csleadassign') > -1){
  //     this.mandateassistedclients = true;
  //   }
    
  //   const name = event.target.options[event.target.options.selectedIndex].text;
  //   this.leadforwards.telecallername = name;
  //   this.leadreassign.telecallername = name;
  // }

  getselectedleads(id){
    var checkid = $("input[name='programming']:checked").map(function(){
      return this.value;
    }).get().join(',');
    // this.leadforwards.assignedleads = checkid;
    // this.reassignleadforwards.assignedleads = checkid;
  }


  refresh() {
    this.filterLoader = true;
    document.location.href = "/mandateassistedclients?actiontook=1&from=&to=&rm=";
  }

  resetcounts(){
    var paramcount = {
      datefrom: this.fromdate,
      dateto: this.todate,
      rmid: this.rmid,
    }
    this._phpService.assignedcountsadmin(paramcount).subscribe(assignedcounts => {
      this.assignedleadscounts = assignedcounts[0];
      this.totalcounts = this.assignedleadscounts.Totalcounts;
      this.tookactioncounts = this.assignedleadscounts.Tookactioncounts;
      this.pendingcounts = this.assignedleadscounts.Pendingcounts;
    });

    var param2 = {
      fromdate: this.fromdate,
      todate: this.todate,
      rmid: this.rmid,
      csid: "",
      onlyrm: "RMonly",
    }
    this._phpService
      .getactiveleadscounts(param2)
      .subscribe(activecounts => {
        this.activeleadscount = activecounts[0];
        this.activecount = this.activeleadscount.activecount;
        this.closedcount = this.activeleadscount.closedcount;
        this.inactivecount = this.activeleadscount.inactivecount;
        this.f2fcount = this.activeleadscount.facetoface;
        this.usvcount = this.activeleadscount.usvcount;
        this.svcount = this.activeleadscount.svcount;
        this.rsvcount = this.activeleadscount.rsvcount;
        this.negocount = this.activeleadscount.nego_count;
      });

      var param3 = {
        fromdate: this.fromdate,
        todate: this.todate,
        rmid: this.rmid,
        csid: "",
        onlyrm: "RMonly",
      }
      this._phpService
        .Rmfollowupcounts(param3)
        .subscribe(followcounts => {
          this.followupcounts = followcounts[0];
        });
  }

  leaddetails = false;
  roleid:any;
  userid:any;
  leadtrack:any;
  historyfetch(id){
    this.leaddetails = true;
    this._phpService
  .getassignedrm(id,this.userid)
  .subscribe(cust => {
     this.selecteduser = cust[0];
  });
    
    var param2 = {
      leadid: id,
      roleid: this.roleid,
      userid: this.userid
    }
    this._phpService
    .gethistory(param2)
    .subscribe(history => {
      this.leadtrack = history['Leadhistory'];
    })
    // $('.panel-2').removeAttr("style");
    $('.CSassigned_table').attr("style", "height: auto;"); 
    $('.remarksection').attr("style", "display: none;"); 
    $('.crm-auto-scroll').attr("style", "height: 650px;"); 
  }
  closehistory(){
    // $('.panel-2').attr("style", "display: none;"); 
    this.leaddetails = false;
    $('.remarksection').removeAttr("style");
    $('.panel-1').removeAttr("style");
    $('.CSassigned_table').removeAttr("style");
  }

  morefilter()
    {
      document.getElementsByClassName('more_filter_maindiv')[0].removeAttribute("hidden");
      $('.modalclick').addClass('modal-backdrop');
      $('.modalclick').addClass('fade');
      $('.modalclick').addClass('show');
    }

}
