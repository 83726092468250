export class Executive {
   
    name: string;
    email: string;
    username: string;
    mobilenum: string;
    designation: string;
    password: string;
    performer:string;
    birthday:string;
    dobdate:string;
}

export class Executiveupdate {
   
    executives_IDPK: any;
    executives_name: any;
    executives_number: any;
    executives_dob: any;
    executives_email: any;
    exec_dept_IDFK:any;
    exec_desig_IDFK:any;
    executive_address:any;

}
