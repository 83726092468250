import { Component, OnInit, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {IMyOptions, IMyInputFieldChanged} from 'mydatepicker';
declare var $: any;

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.css']
})
export class AccordianComponent implements OnInit {
  
  private myDatePickerOptions: IMyOptions = {
    showTodayBtn : false,
    firstDayOfWeek: 'su',
    allowSelectionOnlyInCurrentMonth : true,
    markCurrentDay : true,
    sunHighlight : false,
    yearSelector : true,
    inline: true
};

  tables : Array<any>;
  
  // current: number = 0;
  welcome : string;
  constructor(
    private _phpService:phpservice,
    private route: ActivatedRoute
  ) { 
    this.ngOnInit();
  }
  
  ngOnInit() {
   
    this.accord();
    // this.usvview();
    // this.rsvview();
    // this.followview();
    // this.faceview();
    // this.finalview();
    // this.closedview();
  }
  ngOnChanges()	{    
   
  }
  
  accord(){
    var target = target;
    $('.panel-title > a[data-toggle="collapse"]').click(function(e){
      target = $(this).attr('href')
      if ($(target).hasClass('in')) {
        e.stopPropagation()
      }
    })
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });
  
    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });
  }

  accordusv: any;
  accordrsv: any;
  accordfollow: any;
  accordface: any;
  accordfinal: any[];
  accordclosed:any;
  // USV-VIEW-FROM-ACCORDION
  // usvview(){
  //   this._phpService
  //   .getuniquevisit()
  //   .subscribe(unique => {
  //       this.accordusv = unique;
  //   })
  // }
  // USV-VIEW-FROM-ACCORDION
  // RSV-VIEW-FROM-ACCORDION
// rsvview(){
//   this._phpService
//     .getrepeatvisit()
//     .subscribe(repeat => {
//       this.accordrsv = repeat;
//     })
// }
  // RSV-VIEW-FROM-ACCORDION

  // FOLLOWUP-VIEW-FROM-ACCORDION
  // followview(){
  //   this._phpService
  //   .getfollowuplist()
  //   .subscribe(follow =>{
  //     this.accordfollow = follow;
  //   })
  // }
   // FOLLOWUP-VIEW-FROM-ACCORDION
   
    // FACETOFACE-VIEW-FROM-ACCORDION
  //   faceview(){
  //     this._phpService
  //     .getface2facelist()
  //     .subscribe(facelist =>{
  //       this.accordface = facelist;
  //     })
  //  }
    // FACETOFACE-VIEW-FROM-ACCORDION

     // FINALNEGOTIATE-VIEW-FROM-ACCORDION
    //  finalview(){
    //   this._phpService
    //   .getfinalnegotiate()
    //   .subscribe(fnegotiate => {
    //     this.accordfinal = fnegotiate;
    //   })
    //  }

      // FINALNEGOTIATE-VIEW-FROM-ACCORDION

      // CLOSED-VIEW-FROM-ACCORDION
      // closedview(){
      //   this._phpService
      //   .getclosedleadlist()
      //   .subscribe(lead => {
      //       this.accordclosed = lead;
      //   })
      // }

      // CLOSED-VIEW-FROM-ACCORDION

  
  // getaccordtitles(){
  //   this._phpService
  //   .getaccordtitle()
  //   .subscribe(titles => {
  //     this.items = titles;
  //   })
  // }

}
