import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { mandateservice } from '../../mandate.service';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-mandateclosed',
  templateUrl: './mandateclosed.component.html',
  styleUrls: ['./mandateclosed.component.css']
})
export class MandateclosedComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  leadid = this.route.snapshot.params['id'];
  visitedpropertylists: any;
  executeid: any;
  csid: any;
  status: any;

  constructor(private router: Router,
    private route: ActivatedRoute,private _phpService: phpservice,private _mandateService:mandateservice,) { }

  ngOnInit() {
    this.scriptfunctions();

    this._mandateService
    .getassignedrm(this.leadid)
    .subscribe(cust => {
      this.executeid = cust[0].executiveid;
    })

  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
  }

  leadclosing(){
    // SV DONE with Lead Closing

    if( $('#sectionselector').val() == "SV"){
      if ($('#SVvisiteddate').val() == "") {
        $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisiteddate').removeAttr("style");
      }
  
      if ($('#SVvisitedtime').val() == "") {
        $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisitedtime').removeAttr("style");
      }
  
      if ($('#svfinalremarks').val() == "") {
        $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
        return false;
      }
      else {
        $('#svfinalremarks').removeAttr("style");
      }

      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($('#closedtextarearemarks').val() == "") {
        $('#closedtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the Closure');
        return false;
      }
      else {
        $('#closedtextarearemarks').removeAttr("style");
      }

      var visiteddate = $('#SVvisiteddate').val();
      var visitedtime = $('#SVvisitedtime').val();
      var svleadstage = "SV";
      var svstagestatus = '3';
      var svfinalremarks = $('#svfinalremarks').val();

      var closedate = $('#closeddate').val();
      var closetime = $('#closedtime').val();
      var stagestatus = '0';
      var negoleadstage = "Lead Closed";
      var textarearemarks = $('#closedtextarearemarks').val();
      var leadid = this.leadid;
      var userid = localStorage.getItem('UserId');
      var assignid = this.executeid;
      var csid = this.csid;

      this._mandateService.addleadhistory(leadid,visiteddate,visitedtime,svleadstage,svstagestatus,svfinalremarks,userid,assignid).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
          this._mandateService.addleadhistory(leadid,closedate,closetime,negoleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
            this.status = success.status;
            if(this.status == "True"){
            swal({
              title: 'Lead Closed Succefully',
              type: "success",
              confirmButtonText: 'OK!',
              }).then((result) => {
                if (result.value) {
                  location.reload();
                }
              });
            }
          },(err) => {
            console.log("Failed to Update");
          });
        }
      },(err) => {
        console.log("Failed to Update");
      });
    }

    // SV DONE with Lead Closing

    // Direct Lead Closing

    else{
      if ($('#closeddate').val() == "") {
        $('#closeddate').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Date');
        return false;
      }
      else {
        $('#closeddate').removeAttr("style");
      }
      if ($('#closedtime').val() == "") {
        $('#closedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select closed Time');
        return false;
      }
      else {
        $('#closedtime').removeAttr("style");
      }
      if ($('#closedtextarearemarks').val() == "") {
        $('#closedtextarearemarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks for the Closure');
        return false;
      }
      else {
        $('#closedtextarearemarks').removeAttr("style");
      }
  
      var closedate = $('#closeddate').val();
      var closetime = $('#closedtime').val();
      var stagestatus = '0';
      var negoleadstage = "Lead Closed";
      var textarearemarks = $('#closedtextarearemarks').val();
      var leadid = this.leadid;
      var userid = localStorage.getItem('UserId');
      var assignid = this.executeid;
      var csid = this.csid;
  
      this._mandateService.addleadhistory(leadid,closedate,closetime,negoleadstage,stagestatus,textarearemarks,userid,assignid).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
        swal({
          title: 'Lead Closed Succefully',
          type: "success",
          confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              location.reload();
            }
          });
        }
      },(err) => {
        console.log("Failed to Update");
      });
    }

    // Direct Lead Closing
  }

}
