import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-assignedrm',
  templateUrl: './assignedrm.component.html',
  styleUrls: ['./assignedrm.component.css']
})
export class AssignedrmComponent implements OnInit {

  @ViewChild('cancel')cancel:ElementRef;

  assignedleads:any;
  totalleads:any;
  tookaction:any;
  noaction:any;
  freshleadtable = true;
  tookactionmenu = false;
  tookactiontable = false;
  followuptable = false;
  assigntabelview:boolean;
  freshleadview:boolean;
  status: any;
  userid:any;
  assignedleadscounts:any;
  loadmorelivecounts:any;
  activeleadscount: any;
  followups: any;
  followupcounts: any;
  followupsectionid: any;
  stagesections: any;
  fromdate: any;
  todate: any;
  constructor(
    private router: Router,
    private _phpService:phpservice
    ) {
     }
     rmpaneldata = false;
     cspaneldata = false;
     static count: number;
     ngOnInit() {
       if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
      else if(localStorage.getItem('Role') == '50002')
      {
        this.getrmassigned();
        this.rmpaneldata = true;
        // this.assignedview = true;
      }
      else if(localStorage.getItem('Role') == '50004')
      {
        this.getcsassigned();
        this.cspaneldata = true;
        // this.assignedview = true;
      }
      AssignedrmComponent.count = 0;
     }

     getrmassigned(){
      var d = new Date();
      this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
      this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
      AssignedrmComponent.count = 0;
      this.status = "1";
      this.userid = localStorage.getItem('UserId');
      var param = {
        limit: 0, 
        limitrows: 150,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.status,
        executid: this.userid,
      }
      this.freshleadview = true;
      this._phpService.completeassignedRMLeads(param).subscribe(freshlead => {
          this.assignedleads = freshlead;
      })
      this._phpService.completeassignedRMLeadscounts(this.userid).subscribe(assignedcounts => {
        this.assignedleadscounts = assignedcounts[0];
        this.loadmorelivecounts = this.assignedleadscounts.TodayFreshcounts;
      })
     }

     getcsassigned()
     {
      var d = new Date();
      this.fromdate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
      this.todate = d.getFullYear()  + "-" + (d.getMonth()+1) + "-" + d.getDate();
      AssignedrmComponent.count = 0;
      this.status = "1";
      this.userid = localStorage.getItem('UserId');
      var param = {
        limit: 0, 
        limitrows: 500,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.status,
        executid: this.userid,
      }
      this.freshleadview = true;
      this._phpService.completeassignedCSLeads(param).subscribe(freshlead => { this.assignedleads = freshlead; })

      this._phpService.completeassignedCSLeadscounts(this.userid).subscribe(assignedcounts => {
        this.assignedleadscounts = assignedcounts[0];
        this.loadmorelivecounts = this.assignedleadscounts.TodayFreshcounts;
      })
     }

     freshleadclick()
     {
      $(".other_section").removeClass("active");
      $(".first_section").addClass("active");
      this.tookactiontable = false;
      this.tookactionmenu = false;
      this.followuptable = false;
      this.freshleadtable = true;
      this.ngOnInit();
     }

     tookactionclick()
     {
      $(".other_section").removeClass("active");
      $(".tookaction_class").addClass("active");
      $(".active_section_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "";
      this.activeleadsclick();
     }

     noactionclick()
     {
      $(".other_section").removeClass("active");
      $(".noaction_class").addClass("active");
      this.freshleadtable = true;
      this.tookactiontable = false;
      this.tookactionmenu = false;
      this.followuptable = false;
      this.status = "1";
      this.pendingclick();
     }

     followclick()
     {
      $(".other_section").removeClass("active");
      $(".followup_class").addClass("active");
      this.freshleadtable = false;
      this.tookactiontable = false;
      this.tookactionmenu = true;
      this.followuptable = true;
      this.followupsectionid = "";
      this.followupfilterclick();
     }
     f2fclick()
     {
      $(".other_section").removeClass("active");
      $(".f2f_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "Face to Face Meeting";
      this.activeleadsclick();
     }
     usvclick()
     {
      $(".other_section").removeClass("active");
      $(".usv_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "USV";
      this.activeleadsclick();
     }
     svclick()
     {
      $(".other_section").removeClass("active");
      $(".sv_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "SV";
      this.activeleadsclick();
     }
     rsvclick()
     {
      $(".other_section").removeClass("active");
      $(".rsv_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "RSV";
      this.activeleadsclick();
     }
     negoclick()
     {
      $(".other_section").removeClass("active");
      $(".nego_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "Final Negotiation";
      this.activeleadsclick();
     }
     closedclick()
     {
      $(".other_section").removeClass("active");
      $(".close_class").addClass("active");
      this.freshleadtable = false;
      this.followuptable = false;
      this.tookactiontable = true;
      this.tookactionmenu = true;
      this.stagesections = "Lead Closed";
      this.activeleadsclick();
     }

     callbackclick()
     {
      $(".other_section").removeClass("active");
      $(".callback_class").addClass("active");
      this.followupsectionid = '1';
      this.followupfilterclick();
     }
     rnrclick()
     {
      $(".other_section").removeClass("active");
      $(".rnr_class").addClass("active");
      this.followupsectionid = '2';
      this.followupfilterclick();
     }
     switchoffclick()
     {
      $(".other_section").removeClass("active");
      $(".switchoff_class").addClass("active");
      this.followupsectionid = '3';
      this.followupfilterclick();
     }
     notconnectclick()
     {
      $(".other_section").removeClass("active");
      $(".notconnect_class").addClass("active");
      this.followupsectionid = '4';
      this.followupfilterclick();
     }
     numberbusyclick()
     {
      $(".other_section").removeClass("active");
      $(".numberbusy_class").addClass("active");
      this.followupsectionid = '5';
      this.followupfilterclick();
     }
     notanswerclick()
     {
      $(".other_section").removeClass("active");
      $(".notanswer_class").addClass("active");
      this.followupsectionid = '6';
      this.followupfilterclick();
     }
     notreachclick()
     {
      $(".other_section").removeClass("active");
      $(".notreach_class").addClass("active");
      this.followupsectionid = '7';
      this.followupfilterclick();
     }

     activeleadsclick()
     {
      if (this.router.url.indexOf('RMassignedleads') > -1){
        var param2 = {
          fromdate: "",
          todate: "",
          rmid: this.userid,
          csid: "", 
          onlyrm: "RMonly",
          onlycs: "",
        }
        this._phpService
        .getactiveleadscounts(param2)
        .subscribe(activecounts => {
            this.activeleadscount = activecounts[0];
        })
      var param = {
        limit: 0, 
        limitrows: 500,
        fromdate: "",
        todate: "",
        rmid: this.userid,
        csid: "",  
        stages: this.stagesections,
        onlyrm: "RMonly",
        onlycs: "",
      }
      this._phpService
      .getactiveleadslist(param)
      .subscribe(tookactionleads => {
          this.tookaction = tookactionleads;
      })
      } else if (this.router.url.indexOf('CSassignedleads') > -1){
        var param3 = {
          fromdate: "",
          todate: "",
          rmid: "",
          csid: this.userid, 
          onlyrm: "",
          onlycs: "CSonly",
        }
        this._phpService
        .getactiveleadscounts(param3)
        .subscribe(activecounts => {
            this.activeleadscount = activecounts[0];
        })
      var param4 = {
        limit: 0, 
        limitrows: 500,
        fromdate: "",
        todate: "",
        rmid: "",
        csid: this.userid,  
        stages: this.stagesections,
        onlyrm: "",
        onlycs: "CSonly",
      }
      this._phpService
      .getactiveleadslist(param4)
      .subscribe(tookactionleads => {
          this.tookaction = tookactionleads;
      })
      }
          
     }

     pendingclick()
     {
      if (this.router.url.indexOf('RMassignedleads') > -1){
        var param = {
          limit: 0, 
          limitrows: 150,
          datefrom: "",
          dateto: "",
          statuss: this.status,
          executid: this.userid,
        }
        this._phpService.completeassignedRMLeads(param).subscribe(freshlead => {
            this.assignedleads = freshlead;
        })
      } else if (this.router.url.indexOf('CSassignedleads') > -1) {
        var param = {
          limit: 0, 
          limitrows: 150,
          datefrom: "",
          dateto: "",
          statuss: this.status,
          executid: this.userid,
        }
        this.freshleadview = true;
        this._phpService.completeassignedCSLeads(param).subscribe(freshlead => { this.assignedleads = freshlead; })
      }
      
     }

     followupfilterclick()
     {
      if (this.router.url.indexOf('RMassignedleads') > -1){
        var param = {
          limit: 0, 
          limitrows: 150,
          fromdate: "",
          todate: "",
          rmid: this.userid,
          csid: "",
          sectionid: this.followupsectionid,
          onlyrm: "RMonly",
          onlycs: "",
        }
        this._phpService
        .followup(param)
        .subscribe(follow =>{
          this.followups = follow;
        })
        var param2 = {
          fromdate: "",
          todate: "",
          rmid: this.userid,
          csid: "",
          onlyrm: "RMonly",
          onlycs: "",
        }
        this._phpService
        .followupcounts(param2)
        .subscribe(followcounts => {
            this.followupcounts = followcounts[0];
        });
      }else if (this.router.url.indexOf('CSassignedleads') > -1){
        var param3 = {
          limit: 0, 
          limitrows: 150,
          fromdate: "",
          todate: "",
          rmid: "",
          csid: this.userid,  
          sectionid: this.followupsectionid,
          onlyrm: "",
          onlycs: "CSonly",
        }
        this._phpService
        .followup(param3)
        .subscribe(follow =>{
          this.followups = follow;
        })
        var param4 = {
          fromdate: "",
          todate: "",
          rmid: "",
          csid: this.userid, 
          onlyrm: "",
          onlycs: "CSonly",
        }
        this._phpService
        .followupcounts(param4)
        .subscribe(followcounts => {
            this.followupcounts = followcounts[0];
        });
      }
     }


     getactive(id){
      this.router.navigate(['/Customer-Details/' + id]);
    };

    refresh()
    {
      $(".other_section").removeClass("active");
      $(".first_section").addClass("active");
      this.tookactiontable = false;
      this.followuptable = false;
      this.freshleadtable = true;
      this.getrmassigned();
    }


}
