import { Component, OnInit } from '@angular/core';
import { mandateservice } from '../../mandate.service';

@Component({
  selector: 'app-buildermandaterequest',
  templateUrl: './buildermandaterequest.component.html',
  styleUrls: ['./buildermandaterequest.component.css']
})
export class BuildermandaterequestComponent implements OnInit {

  requestslists: any;

  constructor(private _mandateservice: mandateservice) { }

  ngOnInit() {
    this._mandateservice
      .mandaterequestlists()
      .subscribe(proplist => {
        this.requestslists = proplist;
      });
  }

}
