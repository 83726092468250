import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
declare var $: any;

@Component({
  selector: 'app-facetoface',
  templateUrl: './facetoface.component.html',
  styleUrls: ['./facetoface.component.css']
})
export class FacetofaceComponent implements OnInit {
  public userDetails: any;
  constructor(
    private router: Router,
    private _phpservice:phpservice
  ) { 
  }

  facetoface:any;
  ngOnInit() {
    this.getfacetoface();
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } 
  }

  getfacetoface(){
    if (localStorage.getItem('Role') == '1')
    {
    this._phpservice
    .face2face()
    .subscribe(facelist =>{
      this.facetoface = facelist;
    })
  } else if(localStorage.getItem('Role') == '50002'){
    var id = localStorage.getItem('UserId');
    this._phpservice
    .face2facerm(id)
    .subscribe(facelist =>{
      this.facetoface = facelist;
    })
  } else if(localStorage.getItem('Role') == '50004') {
    var id = localStorage.getItem('UserId');
    this._phpservice
    .face2facecs(id)
    .subscribe(facelist =>{
      this.facetoface = facelist;
    })
  }
  }

}
