import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { Observable, Subject } from 'rxjs'; 
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-dashboardheader',
  templateUrl: './dashboardheader.component.html',
  styleUrls: ['./dashboardheader.component.css']
})
export class DashboardheaderComponent implements OnInit {

  items: any;
  searchs:any;
  public userDetails: any;
  adminview:boolean;
  RMTLview:boolean;
  RMEXview:boolean;
  CSTLview:boolean;
  CSEXview:boolean;
  MandateRMEXview:boolean;
  builderexecview:boolean;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private _phpService:phpservice) {
      setTimeout(() => {
        $(function ($) {
  
          $(".sidebar-dropdown > a").click(function() {
        $(".sidebar-submenu").slideUp(200);
        if (
          $(this)
            .parent()
            .hasClass("active")
        ) {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .parent()
            .removeClass("active");
        } else {
          $(".sidebar-dropdown").removeClass("active");
          $(this)
            .next(".sidebar-submenu")
            .slideDown(200);
          $(this)
            .parent()
            .addClass("active");
        }
      });
      
      $("#close-sidebar").click(function() {
        $(".page-wrapper").removeClass("toggled");
      });
      $("#show-sidebar").click(function() {
        $(".page-wrapper").addClass("toggled");
      });
         
      });
      }, 1000);
      const data = localStorage.getItem("Name");
      this.userDetails = data;
      this.adminview = false;
      this.RMTLview = false;
      this.CSTLview = false;
      this.CSEXview = false;
     }

     backToWelcome(){
      this.router.navigate(['/login']);
    }
    logout(){
      localStorage.clear();
      setTimeout(() => this.backToWelcome(), 1000);
      
    }
       public clients: any;  
       private searchTerms = new Subject<string>();  
       private mandatesearchTerms = new Subject<string>();  
       public customer_name = '';  
       public flag: boolean; 

  ngOnInit() {
    this.getcustomer();
            if(localStorage.getItem('Department') == '10004'){
              this.MandateRMEXview = true;
            }else{
              if (localStorage.getItem('Role') == null)
            {
              this.router.navigateByUrl('/login');
            } 
            else if (localStorage.getItem('Role') == '1')
            {
              this.adminview = true;
            }
            else if(localStorage.getItem('Role') == '50001')
            {
              this.RMTLview = true;
            }
            else if(localStorage.getItem('Role') == '50002')
            {
              this.RMEXview = true;
              this.getmandatecustomer();
            }
            else if(localStorage.getItem('Role') == '50003')
            {
              this.CSTLview = true;
            }
            else if(localStorage.getItem('Role') == '50004')
            {
              this.CSEXview = true;
            } else if(localStorage.getItem('Role') == '50011'){
              this.builderexecview = true;
            }
            }
  }
  customer_id:any;  
         status: any;

         getcustomer(){
          this.clients = this.searchTerms  
          .debounceTime(300)        // wait for 300ms pause in events  
            .distinctUntilChanged()   // ignore if next search term is same as previous  
           .switchMap(param => param   // switch to new observable each time  
           // return the http search observable  
              ? this._phpService.search(param)  
            // or the observable of empty heroes if no search term  
            : Observable.of<any[]>([]))  
           .catch(error => {  
           // TODO: real error handling  
             console.log(error);  
             return Observable.of<any[]>([]);     
         });     
   }

   getmandatecustomer(){
     var userid = localStorage.getItem('UserId');
     this.clients = this.mandatesearchTerms  
     .debounceTime(300)        // wait for 300ms pause in events  
       .distinctUntilChanged()   // ignore if next search term is same as previous  
      .switchMap(param => param   // switch to new observable each time  
      // return the http search observable  
         ? this._phpService.mandatesearch(param,userid)  
       // or the observable of empty heroes if no search term  
       : Observable.of<any[]>([]))  
      .catch(error => {  
      // TODO: real error handling  
        console.log(error);  
        return Observable.of<any[]>([]);     
    });     
}

   dataRefresher: any;

searchClient(param: string): void {  
      this.flag = true;  
      this.searchTerms.next(param);
      if(param==""){
       this.flag = false;
      }  
    } 

    mandatesearch(param: string): void {  
     this.flag = true;  
     this.mandatesearchTerms.next(param);
     if(param==""){
      this.flag = false;
     }  
   }  

    onselectClient(id) {     
      if (id.customer_id != 0) {  
        this.customer_name = id.customer_name;       
        this.flag = false;
       this.router.navigate(['/Customer-Details/' + id])
       this.customer_name='';
       //  window.open('http://localhost:4200/Customer-Details/' + id ,'_blank');
      }  
      else {  
        return false;  
    }  
    }  

}
