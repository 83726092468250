import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
import {Router} from '@angular/router';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-clientleads',
  templateUrl: './clientleads.component.html',
  styleUrls: ['./clientleads.component.css']
})
export class ClientleadsComponent implements OnInit {

  constructor(private _phpService:phpservice,) { }

  enquiries: any;
  source: any;
  locality: any;
  leadsource: string = "";
  leadlocality: string = "";
  static count: number;
  leadcounts: any;
  filtermaindiv = false;

  ngOnInit() {
    this.getleads();
    ClientleadsComponent.count = 0;
  }

  getleads()
  {
    ClientleadsComponent.count = 0;
    var limitparam = 0;
    var limitrows = 30;
    var todate = "";
    var fromdate = "";
    var source = "";
    this._phpService.getleads(limitparam,limitrows,fromdate,todate,source).subscribe(enquirys =>
      {
        this.enquiries = enquirys;
      })
    this._phpService.getleadcounts(fromdate,todate,source).subscribe(enquiryscount =>
      {
        this.leadcounts = enquiryscount[0].leadcounts;
      })
  }

  refresh()
  {
    this.getleads();
    $('#fromdate').val("");
    $('#todate').val("");
    $('#source_dropdown').dropdown('clear');
    $('#locality_dropdown').dropdown('clear');
  }

  loadMore(){
    const limit = ClientleadsComponent.count+=30;
    const counts = this.leadcounts;
    let limitrows = 30;
    let livecount = this.enquiries.length;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var source = this.leadsource;
    if(livecount <= counts){
      return this._phpService.getleads(limit,limitrows,fromdate,todate,source).subscribe(enquirys =>{
        this.enquiries = this.enquiries.concat(enquirys);
      })
    }else{
      
    }
  }

  selecttodate()
  {
    ClientleadsComponent.count = 0;
    var limitparam = 0;
    var limitrows = 30;
    var todate = $('#todate').val();
    var fromdate = $('#fromdate').val();
    var source = this.leadsource;
    this._phpService.getleads(limitparam,limitrows,fromdate,todate,source).subscribe(enquirys =>{
      this.enquiries = enquirys;
    })
    this._phpService.getleadcounts(fromdate,todate,source).subscribe(enquiryscount =>
      {
        this.leadcounts = enquiryscount[0].leadcounts;
      })
  }

morefilter()
{
  this.filtermaindiv = true;
}

}
