export class Junk {
   
    customer_IDPK: string;
    customer_id: string;
    customer_name: string;
    customer_number: string;
    customer_source: string;
    customer_date: string;
    customer_time: string;
}
