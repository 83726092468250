import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute,Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { AuthService } from '../../auth-service';
import { UniquePipe } from '../../pipe-filter';
import { Enquiry ,Follow, Face, Usv, Finalnego, closure, leadforward} from './customer';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
 
  @ViewChild('cancel')cancel:ElementRef;
  @ViewChild('myModalClose') modalClose;
  followup = true;
  USV = true;
  SV = true;
  RSV = true;
  Negotiation = true;
  leadclose = true;
  junkmove = true;
  filterLoader: boolean = true;

  showHide:boolean;
  commentshow:boolean;
  modelaadd = new Follow();
  facemodel = new Face();
  addusvmodel = new Usv();
  svmodel = new Usv();
  negmodel = new Finalnego();
  addclose = new closure();
  addpay = new closure();
  usvmodelone : any;
  resposeData : any;
  countries : any[];
  sections : any;
  projects : any;
  activeinputs : any;
  locality: any;
  newpropertybox = false;
  adminview:boolean;
  execview:boolean;
  followupform = false;
  junkform = false;
  commonformbtn = false;
  followupformbtn = false;
  junkformbtn = false;
  followform = false;
  f2fform = false;
  usvform = false;
  svform = false;
  rsvform = false;
  finalnegoform = false;
  leadclosedform = false;

    // ONLY-CUSTOMER-ViEW-FROM-DB-based-ON-ID
    show_cnt= new Enquiry();
    executlist: any;
    sources:any;
    followmodels: any;
    facemodels: any;
    usvmodels:any;
    svmodels:any;
    rsvmodels:any;
    finalmodels:any;
    closedmodels:any;
    typelist: any;
    propetytimeline: any;
    propertiespurpose: any;
    properties: any;
    propertysize: any;
    usvdates: any;
    current: number = 0;
    // items: Array<any>;
    leadstatus: any;
    activestagestatus: any;
    datavisitwithothersid: any;
    datavisitwithothersname: any;
    suggestedproperties: any;
    suggestedpropertiesname: any;
    autoremarks: any;

  // dropdownSettings = {};
  // propertylists:any = [];
  // selectedItems = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _phpService:phpservice,
    public authService: AuthService,
    private _unique:UniquePipe,
    private _location: Location
  ) {
    // this.ngOnInit();
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
    // this._phpService.mouseenterlisten().subscribe((m: any) => {
    //   this.ngOnInit();
    // })
    this.showHide = true;
    this.commentshow = false; 
    $('.ui.dropdown').dropdown();
    this.activeinputs = false;
  }
  ngAfterViewInit(){
   
  }
  backClicked() {
    this._location.back();
}
  

  checkbox: boolean;


  ngOnDestroy(){
    $('.modal').remove();
  }
  followups_date: any;
  payment_name: any;
  roleid: any;
  userid:any;
  username: any;
  selectedlocality: any;
  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');
    this.getcustomerview();
    this.getsize();
    this.getprprtytype();
    this.getstages();
    this.getstatus();
    this.getlocalitylist();

    var param = {
      leadid: this.id,
      execid: this.userid
    }

    this._phpService
    .propertylist(param)
    .subscribe(propertylist => {
      this.properties = propertylist;
    });
    if (localStorage.getItem('Role') == null)
    {
      this.router.navigateByUrl('/login');
    } else if (localStorage.getItem('Role') == '1')
    {
      this.adminview = true;
      this.execview = false;
      this.triggerhistory();
    }else{
      this.adminview = false;
      this.execview = true;
    }
  }

  public getExstendsion(image) {
    if (image.endsWith('jpg') || image.endsWith('jpeg') || image.endsWith('png')) {
      return 'jpg';
    }
    if (image.endsWith('pdf')) {
      return 'pdf';
    }
  }
  
  addusv(){
    this.usvs.push({});
  }

usvs=[];
rsvs=[];
rsvmodel: any={};
closedmodel: any={};
executeid: any;
csid: any;
assignedrm: any;
assignedcs: any;
usvstagedetection: any;
usvstagestatusdetection: any;
show_cnt_subarray: any;
// CUSTOMER-VIEW-FROM-ENQUIRY
getcustomerview(){

  this._phpService
  .getcustomeredit(this.id)
  .subscribe(cust => {
    this.filterLoader = false;
     this.show_cnt = cust[0];
     this.show_cnt_subarray = cust[0].assignedrm;
     this.facemodel = cust[0];
     $('#proptypeselect').val(this.show_cnt['proptypeid']);
     $('#sizeselect').val(this.show_cnt['propertysizeid']);
     $('#budgetselect').val(this.show_cnt['customer_budget']);
     $('#possessionselect').val(this.show_cnt['timelineid']);
    
     if(this.show_cnt['customer_phase'] == null){
      this.show_cnt['customer_phase'] = 'Fresh lead';
    }else{
    }
  })

  
  this._phpService
  .getassignedrm(this.id,this.userid)
  .subscribe(cust => {
     this.assignedrm = cust;
     this.executeid = cust[0].executiveid;
     this.usvstagedetection = cust[0].leadstage;
    this.usvstagestatusdetection = cust[0].leadstatus;
    if(this.usvstagedetection == "USV" && this.usvstagestatusdetection == "3" && cust[0].visitstatus == "0"){
      this.actionChange(this.usvstagedetection);
    }
  })
  
  // this._phpService
  // .getassignedcs(this.id)
  // .subscribe(cust => {
  //    this.assignedcs = cust[0];
  //    this.csid = cust[0].executiveid;
  // })
  
  
};

addpropertiestolist00() {
  this.datavisitwithothersid = $(".onchangeforvisitwithothers00").dropdown("get value");
  this.datavisitwithothersname = $('.onchangeforvisitwithothers00 option:selected').toArray().map(item => item.text).join();
  this.suggestedproperties = $(".onchangeforsuggestproperties00").dropdown("get value");
  this.suggestedpropertiesname = $('.onchangeforsuggestproperties00 option:selected').toArray().map(item => item.text).join();

  if (this.suggestedproperties == "" && this.datavisitwithothersid == "") {
    swal({
      title: 'Add any one property',
      type: 'error',
      confirmButtonText: 'ok'
    })
    return false;
  } else {
    $('.onchangeforsuggestproperties00').removeAttr("style");
  }

  if(this.suggestedproperties.length == 0){
    // alert("No need push to database");
  }else{
    // alert("Should push to database");
    var param = {
      leadid: this.id,
      suggestproperties: this.suggestedproperties,
      assignid: this.userid,
      stage: "Common Area",
    }
    this.autoremarks = " suggested some properties like "+this.suggestedpropertiesname;
    this._phpService.addsuggestedproperties(param).subscribe((success) => {
    this.status = success.status;
    if (this.status == "True") {
      swal({
        title: 'Suggested Successfully Added',
        type: "success",
        confirmButtonText: 'OK!',
      }).then((result) => {
        if (result.value) {
          this.modalClose.nativeElement.click();
          $(".radiocheck").prop('checked', false);  
          this.followform = false;
          this.followupform = false;
          this.followupformbtn = false;
          this.f2fform = false;
          this.usvform = false;
          this.svform = false;
          this.rsvform = false;
          this.finalnegoform = false;
          this.leadclosedform = false;
          this.junkform = false;
          this.junkformbtn = false;
          this.commonformbtn = false;
          this.ngOnInit();
        }
      });
    }
    $(".onchangeforvisitwithothers00").dropdown('clear');
    $(".onchangeforvisitwithothers00").dropdown('destroy');
    $(".onchangeforvisitwithothers00").dropdown('restore defaults');
    $(".onchangeforsuggestproperties00").dropdown('clear');
    $(".onchangeforsuggestproperties00").dropdown('destroy');
    $(".onchangeforsuggestproperties00").dropdown('restore defaults');
  }, (err) => {
    console.log("Failed to Update");
  })
  }

  if(this.datavisitwithothersid.length == 0){
    // alert("No need push to database");
  }else{
    // alert("Should push to database");
    var param2 = {
      leadid: this.id,
      assignid: this.executeid,
      visitedproperties: this.datavisitwithothersid
    }
    this.autoremarks = "While fixing the USV "+this.username+" added few already visited properties with others Like, "+this.datavisitwithothersname;
    this._phpService.addvisitedpropertiesothers(param2).subscribe((success) => {
      this.status = success.status;
      if (this.status == "True") {
        swal({
          title: 'Visited Successfully Added',
          type: "success",
          confirmButtonText: 'OK!',
        }).then((result) => {
          if (result.value) {
            this.modalClose.nativeElement.click();
            $(".radiocheck").prop('checked', false);
            this.followform = false;
            this.followupform = false;
            this.followupformbtn = false;
            this.f2fform = false;
            this.usvform = false;
            this.svform = false;
            this.rsvform = false;
            this.finalnegoform = false;
            this.leadclosedform = false;
            this.junkform = false;
            this.junkformbtn = false;
            this.commonformbtn = false;
            this.ngOnInit();
            // let currentUrl = this.router.url;
            //     this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            //         this.router.navigate([currentUrl]);
            //     });
          }
        });
      }
    }, (err) => {
      console.log("Failed to Update");
    })
  }

}

typeselection(event) {
  var value = event.target.value;
  const a = document.getElementById("proptypeselect") as HTMLInputElement;
  a.value = value;
}

possessionselection(event)
{
  var value = event.target.value;
  const a = document.getElementById("possessionselect") as HTMLInputElement;
  a.value = value;
}

sizeselection(event) {
  var value = event.target.value;
  const a = document.getElementById("sizeselect") as HTMLInputElement;
  a.value = value;
  let numArr = value.match(/[\d\.]+/g)
  numArr = numArr.filter(n => n != '.')
}

budgetselection(event)
{
  var value = event.target.value;
  const a = document.getElementById("budgetselect") as HTMLInputElement;
  a.value = value;
}

showinput()
{
this.newpropertybox = true;
}

getlocalitylist(){
  this._phpService.localitylist().subscribe(localities =>{
    this.locality = localities;
    this.selectedlocality = this.show_cnt['localityid'];
  })
}

getprprtytype(){
  this._phpService
  .getpropertytypelist()
  .subscribe(proprttype => {
    this.typelist = proprttype;
  })
}

builderName: any;

statesChange(vals){
  const val = vals.target.value;
  this._phpService
  .getpropertylist_ID(val)
  .subscribe(prope => {
    this.projects = prope;
  })
  $('.projts.ui.dropdown').dropdown('restore defaults');
  this.negmodel.final_builder_info = vals.target.options[vals.target.selectedIndex].text;
}
  // CUSTOMER-VIEW-INSIDE-MODAL
  modela= new Enquiry();
  editmodela =new Enquiry();
  followmodel:any={};
  // MODAL-VIEW-FROM-ENQUIRY
  id = this.route.snapshot.params['id'];

  localityid: any;
  getcustomerupdate(id){
    this._phpService.getcustomeredit(id).subscribe(test =>{
      this.modela = test[0];
      this.editmodela = test[0];
      this.localityid = this.editmodela['localityid'];
      // this.selectedItems = this.editmodela['customer_properties'];
      if(this.editmodela['customer_phase'] == null){
        this.editmodela['customer_phase'] = 'Fresh lead';
      }else{
      }
      // console.log("Results:",this.editmodela);
      })
  }

  getsize(){
    this._phpService.getbhk().subscribe(propsize => {this.propertysize = propsize;})
  }
  getstatus(){
    this._phpService.leadstatus().subscribe(status => {this.leadstatus = status;})
  }

  // FOLLOWUP-ACCORDION-SECTION-ONLY-VIEW & UPDATE
  
   getactive(id){
    var idpk = id;
    this._phpService
    .getfollowupcustomeredit(idpk)
    .subscribe(followcstmr => {
    this.followmodels = followcstmr;
      })
    };

    getfaceactive(id){
      var idpk = id;
      this._phpService
      .getfacecustomeredit(idpk)
      .subscribe(facecstmr => {
      this.facemodels = facecstmr;
        })
      };
    getusvactive(id){
      var idpk = id;
      this._phpService
      .getusvcustomerview(idpk)
      .subscribe(usvcstmr => {
      this.usvmodels = usvcstmr;
        })
      };
    getsvactive(id){
      var idpk = id;
      this._phpService
      .getsvcustomerview(idpk)
      .subscribe(svcstmr => {
      this.svmodels = svcstmr;
        })
      };
    getrsvactive(id){
        var idpk = id;
        this._phpService
        .getrsvcustomerview(idpk)
        .subscribe(rsvcstmr => {
        this.rsvmodels = rsvcstmr;
          })
        };
    getfinalactive(id){
      var idpk = id;
      this._phpService
      .getfinalcustomerview(idpk)
      .subscribe(finalcstmr => {
      this.finalmodels = finalcstmr;
        })
      };
    getclosedactive(id){
      var idpk = id;
      this._phpService
      .getclosedcustomerview(idpk)
      .subscribe(closecstmr => {
      this.closedmodels = closecstmr;
        })
      };

  // FOLLOWUP-ACCORDION-SECTION-ONLY-VIEW & UPDATE
  currentstage: any;
  currentstagestatus: any;
  getstages(){
    var userid = localStorage.getItem('UserId');
    this._phpService
      .getactiveleadsstatus(this.id,userid)
      .subscribe(stagestatus => {
        if(stagestatus.status == "True"){
          this.activestagestatus = stagestatus['activeleadsstatus'];
          this.currentstage = this.activestagestatus[0].stage;
        if(this.activestagestatus[0].stagestatus == "1" && this.activestagestatus[0].followupstatus == "0" || this.activestagestatus[0].stagestatus == "1" && this.activestagestatus[0].followupstatus == null){
          this.currentstagestatus = "Fixed";
        }else if(this.activestagestatus[0].stagestatus == "2" && this.activestagestatus[0].followupstatus == "0" || this.activestagestatus[0].stagestatus == "2" && this.activestagestatus[0].followupstatus == null){
          this.currentstagestatus = "Refixed";
        }else if(this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].followupstatus == "0" || this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].followupstatus == null){
          this.currentstagestatus = "Done";
        }else if(this.activestagestatus[0].stagestatus == "1" && this.activestagestatus[0].followupstatus == "4"){
          this.currentstagestatus = "Fixed - Followup";
        }else if(this.activestagestatus[0].stagestatus == "2" && this.activestagestatus[0].followupstatus == "4"){
          this.currentstagestatus = "Refixed - Followup";
        }else if(this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].followupstatus == "4"){
          this.currentstagestatus = "Done - Followup";
        }
          if(this.activestagestatus[0].stage == "Lead Closed" || this.activestagestatus[0].stage == "Move to Junk"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              this.sections = section;
            });
            this.USV = false;
          }else if(this.activestagestatus[0].stage == "Lead Stage" && this.activestagestatus[0].followupstatus == "4"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[2].phase;
              delete section[3].phase;
              delete section[4].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.SV = false;
            this.RSV = false;
            this.Negotiation = false;
            this.leadclose = false;
          }else if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "1" || this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "2" || this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "4"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[2].phase;
              delete section[3].phase;
              delete section[4].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.SV = false;
            this.RSV = false;
            this.Negotiation = false;
            this.leadclose = false;
          }else if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "1"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              this.sections = section;
            });
            this.USV = false;
          }else if(this.activestagestatus[0].stage == "USV" && this.activestagestatus[0].stagestatus == "3" && this.activestagestatus[0].visitstatus == "0"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[2].phase;
              delete section[3].phase;
              delete section[4].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.followup = false;
            this.SV = false;
            this.RSV = false;
            this.Negotiation = false;
            this.leadclose = false;
            this.junkmove = false;
            this.usvform = true;
          }else if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "1" || this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "2" || this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "4"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              delete section[2].phase;
              delete section[4].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.USV = false;
            this.SV = false;
            this.Negotiation = false;
            this.leadclose = false;
          }else if(this.activestagestatus[0].stage == "RSV" && this.activestagestatus[0].stagestatus == "3" ){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              this.sections = section;
            });
            this.USV = false;
          }else if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "1" || this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "2" || this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "4"){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              delete section[2].phase;
              delete section[3].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.USV = false;
            this.SV = false;
            this.RSV = false;
            this.leadclose = false;
          }else if(this.activestagestatus[0].stage == "Final Negotiation" && this.activestagestatus[0].stagestatus == "3" ){
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[1].phase;
              this.sections = section;
            });
            this.USV = false;
          }
          else{
            this._phpService
            .getstageslist()
            .subscribe(stage => {
              this.sections = stage;
            })
          }
        }else if(stagestatus.status == "False"){
          this.currentstage = "Lead Stage";
          this._phpService
            .getstageslist()
            .subscribe(stage => {
              const section = stage;
              delete section[2].phase;
              delete section[3].phase;
              delete section[4].phase;
              delete section[5].phase;
              this.sections = section;
            });
            this.SV = false;
            this.RSV = false;
            this.Negotiation = false;
            this.leadclose = false;
        }
        
      });
    
  }
  
    // CUSTOMER-VIEW-FROM-ENQUIRY-ENDS

    // ROUTING-DETAILS
  junk(){
    this.router.navigate(['/JunkFiles']);
  }
  // ROUTING-DETAILS
   // UPDATE-CUSTOMER-DETAILS-IN-DB

   updateCustomer(){
    // var params = this.editmodela;
    if ($('#customer_name').val() === '') {
      $('#customer_name').focus().css('border-color', 'red').attr('placeholder', 'Please Enter Name');
      return false;
    } else {
      var nameFilter = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
      if (nameFilter.test($('#customer_name').val())) {
        $('#customer_name').removeAttr('style');
      } else {
        $('#customer_name').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid name').val('');
        return false;
      }
    }
   

      if($('#customer_mail').val()=="")
      {
        $('#customer_mail').focus().css("border-color","red").attr('placeholder','Please Enter Name');
        return false;
      }
      else {
        let enameFilter = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (enameFilter.test($('#customer_mail').val())) {
          $('#customer_mail').removeAttr('style');
        } else {
          $('#customer_mail').focus().css('border-color', 'red').attr('placeholder', 'Please enter valid email').val('');
          return false;
        }
      }
      
                      var customername = $('#customer_name').val();
                      var customermail = $('#customer_mail').val();
                      var customerlocation = $('#customer_location').val();
                      var customerproptype = $('#proptypeselect').val();
                      var possession = $('#possessionselect').val();
                      var customersize = $('#sizeselect').val();
                      var customerbudget = $('#budgetselect').val();
                      var customeraddress = $('#customer_address').val();      
                      var propertyselect = $('#property_select').val();
                      
                      var param = {
                        name: customername,
                        mail: customermail,
                        budget: customerbudget,
                        location: customerlocation,
                        proptype: customerproptype,
                        size: customersize,
                        property: propertyselect,
                        address: customeraddress,
                        leadid: this.id,
                        possession: possession
                      }
                      this._phpService.datashortupdate(param).subscribe((success)=> {
                        this.status = success.status;
                        this.resposeData = success;
                        if(this.status == "True"){
                        swal({
                          title: 'Updated Succefully',
                          type: 'success',
                          confirmButtonText: 'ok'
                          });
                          this.modalClose.nativeElement.click();
                          this.ngOnInit();
                          this.getlocalitylist();
                        }
                      },(err) => {
                        console.log("Failed to Update");
                      })
 }
    closepropertyname: any;
    requestedunits: any;
    verifyrequest(leadid,propid,execid,propname){
      this.closepropertyname = propname;
      var param = {
        leadid: leadid,
        propid: propid,
        execid: execid
      }
      this._phpService.fetchrequestedvalues(param)
      .subscribe(requested => {
        this.requestedunits = requested['requestedvals'];
        // console.log(this.requestedunits);
      });
    }

    requestapproval(leadid,execid,propid){
      var param = {
        leadid: leadid,
        propid: propid,
        execid: execid,
        statusid: '1',
        remarks: "No Comments"
      }
      this._phpService.closingrequestresponse(param)
      .subscribe(requestresponse => {
        if(requestresponse['status']=='True-0'){
          swal({
            title: 'Request Approved Succefully',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            $('.modal-backdrop').closest('div').remove();
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }else{
          swal({
            title: 'Some Error Occured',
            type: "error",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }
      });
    }

    modaldropadd(){
    //   $('.modal').on('shown.bs.modal', function (e) {
    //     $('.modal.show').each(function (index) {
    //         $(this).css('z-index', 1101 + index*2);
    //     });
    //     $('.modal-backdrop').each(function (index) {
    //         $(this).css('z-index', 1101 + index*2-1);
    //     });
    // });
    }

    requestrejection(leadid,execid,propid){
      
      if ($(".rejectedtextarea").val() == "") {
        $(".rejectedtextarea").focus().css("border-color", "red").attr('placeholder', 'Please add the reason for rejection');
        return false;
      }else{
        $(".rejectedtextarea").removeAttr("style");
      }

      var remarkscontent = $(".rejectedtextarea").val();
      var param = {
        leadid: leadid,
        propid: propid,
        execid: execid,
        statusid: '2',
        remarks: remarkscontent
      }
      this._phpService.closingrequestresponse(param)
      .subscribe(requestresponse => {
         if(requestresponse['status']=='True-1'){
          swal({
            title: 'Request Rejected',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            $('.modal-backdrop').closest('div').remove();
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }else{
          swal({
            title: 'Some Error Occured',
            type: "error",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }
      });

    }

    resubmitdata(leadid,execid,propid){
      var bhk = $("#unit").val();
      var bhkunit = $("#unit_number").val();
      var dimension = $("#dimension").val();
      var ratepersqft = $("#rate_per_sqft").val();
      
      if ($("#unit").val() == "") {
        $("#unit").focus().css("border-color", "red").attr('placeholder', 'Please type the Unit Size');
        return false;
      }else if ($("#unit_number").val() == "") {
        $("#unit_number").focus().css("border-color", "red").attr('placeholder', 'Please type the unit number');
        return false;
      }else if ($("#dimension").val() == "") {
        $("#dimension").focus().css("border-color", "red").attr('placeholder', 'Please type the Dimension');
        return false;
      }else if ($("#rate_per_sqft").val() == "") {
        $("#rate_per_sqft").focus().css("border-color", "red").attr('placeholder', 'Please type the Rate Per Squarefeet');
        return false;
      }

      var param = {
        leadid: leadid,
        propid: propid,
        execid: execid,
        bhk: bhk,
        bhkunit: bhkunit,
        dimension: dimension,
        ratepersqft: ratepersqft
      }
      this._phpService.requestresubmition(param)
      .subscribe(requestsubmition => {
         if(requestsubmition['status']=='True'){
          swal({
            title: 'Resubmited Successfully',
            type: "success",
            confirmButtonText: 'OK!',
          }).then((result) => {
            $('.modal-backdrop').closest('div').remove();
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }else{
          swal({
            title: 'Some Error Occured',
            type: "error",
            confirmButtonText: 'OK!',
          }).then((result) => {
            if (result.value) {
              let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                    this.router.navigate([currentUrl]);
                });
            }
          });
        }
      });
      
    }
  

  actionChange(val){
    $('#sectionselector').val("");
    if(val == "Follow Up"){
      this.followform = true;
      this.followupform = true;
      this.followupformbtn = true;
      this.f2fform = false;
      this.usvform = false;
      this.svform = false;
      this.rsvform = false;
      this.finalnegoform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
      this.commonformbtn = false;
      $('#customer_phase4').val('Follow Up');
    }else if(val == "USV"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.usvform = true;
      this.f2fform = false;
      this.svform = false;
      this.rsvform = false;
      this.finalnegoform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
      $('#customer_phase4').val('USV');
    }else if(val == "RSV"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = true;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.finalnegoform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
      $('#customer_phase4').val('RSV');
    }else if(val == "Final Negotiation"){
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.finalnegoform = true;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.leadclosedform = false;
      this.junkform = false;
      this.junkformbtn = false;
      $('#customer_phase4').val('Final Negotiation');
    }else if(val == "Lead Closed"){
      this.leadclosedform = true;
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.finalnegoform = false;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.junkform = false;
      this.junkformbtn = false;
      $('#customer_phase4').val('Lead Closed');
    }else if(val == "Move to Junk"){
      this.junkform = true;
      this.junkformbtn = true;
      this.f2fform = false;
      this.followform = false;
      this.followupform = false;
      this.followupformbtn = false;
      this.rsvform = false;
      this.svform = false;
      this.usvform = false;
      this.f2fform = false;
      this.commonformbtn = false;
      this.leadclosedform = false;
      $('#customer_phase4').val('Move to Junk');
    }else{
      this.followupform = false;
      this.junkform = false;
      this.commonformbtn = true;
      this.followupformbtn = false;
      this.junkformbtn = false;
    }
  }
  status:any;

  openjourney() {
    document.getElementById("myNav").style.width = "100%";
  }

  closejourney() {
    // @ts-ignore
    document.getElementById("myNav").style.width = "0%";
  }

 


addjunkdata(){
  if($('#junksection').val()==null)
          {
            swal({
              title: 'Please Select one Reason for Junk',
              type: 'error',
              confirmButtonText: 'ok'
              })
            return false;
          }
    else{    
      $('#junksection').removeAttr("style"); 
          }
  if($('#nextactiondate').val()=="")
          {
            $('#nextactiondate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
            return false;
          }
    else{    
      $('#nextactiondate').removeAttr("style"); 
          }
          if($('#textarearemarks').val()=="")
          {
            $('#textarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
            return false;
          }
    else{    
      $('#textarearemarks').removeAttr("style"); 
          }
    var junksection = $('#junksection').val();
    var nextdate = $('#nextactiondate').val();
    var leadstage = $('#customer_phase4').val();
    var textarearemarks = $('#textarearemarks').val();
    var leadid = this.id;
    var userid = localStorage.getItem('UserId');

    this._phpService.addjunkhistory(leadid,nextdate,leadstage,junksection,textarearemarks,userid).subscribe((success)=> {
      this.status = success.status;
      if(this.status == "True"){
        $('#nextactiondate').val('');
        $('#customer_phase4').val('');
        $('#textarearemarks').val('');
      swal({
        title: 'Updated Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.ngOnInit();
        this.router.navigate(['/todaysmeetings']);
      }
    },(err) => {
      console.log("Failed to Update");
    })
};



 // JUNK-INSERTING

 movejunk(){
  this.authService.postData(this.show_cnt, "junkinsert").then((result) =>{
    this.junk();
    this.resposeData = result;
    this.authService.postData(this.show_cnt, "enquirydelete").then((result) =>{
      this.junk();
      this.resposeData = result;
    },(err) => {
      console.log("Failed to Delete");
    });
  },(err) => {
    console.log("Failed to Insert");
  });
  
};

  // PREVIOUS-DATE-FETCHING-FOLLOWUP
  getfollowdatelist(){
    var id = "";
    this._phpService
    .getfollowuplist(id)
    .subscribe(followdate =>{
      this.followups_date = followdate;
    })
  }

  // PREVIOUS-DATE-FETCHING-FOLLOWUP
  leadtrack: any;
  triggerhistory(){
    this.roleid = localStorage.getItem('Role');
    this.userid = localStorage.getItem('UserId');
    var param2 = {
      leadid: this.id,
      roleid: this.roleid,
      userid: this.userid
    }
    this._phpService
    .gethistory(param2)
    .subscribe(history => {
      this.leadtrack = history['Leadhistory'];
      // console.log("Lead Track - ", this.leadtrack);
    })
  }
 

  //  ASSIGN LEAD SECTION
leadforwards= new leadforward();
executives: any;
csleadassign = false;
rmleadassign = false;
getexecutives(event)
{
  const id = event.target.options[event.target.options.selectedIndex].value;
  if(id == '50002'){
    this.rmleadassign = true;
    this.csleadassign = false;
  }else if(id == '50004'){
    this.rmleadassign = false;
    this.csleadassign = true;
  }
  this._phpService.getexecutivesbasedid(id).subscribe(execute => {this.executives = execute;})
}

// getexecutivesnames(event)
// {
//   const name = event.target.options[event.target.options.selectedIndex].text;
//   this.leadforwards.telecallername = name;
// }
assigntorm(){
  if($('#executives').val()== "")
        {
          swal({
            title: 'Please Select One Executive!',
            text: 'Please try agin',
            type: 'error',
            confirmButtonText: 'ok'
          })
        return false;
        }
    else
        {
          $('.executives').removeAttr("style");
        }
        this.leadforwards.assignedleads = this.id;
        var param = this.leadforwards;
        this.leadforwards.customersupport = $("#executives").dropdown("get value");
        // console.log(values);
        this._phpService.leadassignrm(param).subscribe((success) => {
        this.status = success.status;
        if(this.status == "True"){
        swal({
        title: 'Assigned Succefully',
        type: 'success',
        confirmButtonText: 'ok'
        })
        this.cancel.nativeElement.click();
        $('.ui.dropdown').dropdown('clear');
        this.ngOnInit();
      }else{
        swal({
        title: 'Authentication Failed!',
        text: 'Please try again',
        type: 'error',
        confirmButtonText: 'ok'
      })
    }
    }, (err) => {
    console.log("Connection Failed")
    });
}

  //  ASSIGN LEAD SECTION

}
